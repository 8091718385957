import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/styles/makeStyles";

const useStyles = makeStyles(() => ({
	paper: {
		borderRadius: 12,
		boxShadow: "rgba(115, 82, 199, 0.176) 0px 0.5rem 1.25rem",
		minHeight: "100px",
		display: "flex",
		flexDirection: "column",
		marginBottom: "1rem",
	},
	header: {
		borderBottom: "1px solid rgb(239 232 251)",
	},
}));

export function Card({ children }) {
	const classes = useStyles();
	return (
		<Paper component={"div"} className={classes.paper}>
			{children}
		</Paper>
	);
}
export function CardHeader({ children }) {
	const classes = useStyles();
	return (
		<Box display={"flex"} alignItems={"center"} justifyContent="space-between" p={4} className={classes.header}>
			{children}
		</Box>
	);
}
