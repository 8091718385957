import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useMemo } from "react";
// import { DisplayKeyValuePair } from "../commons/DisplayKeyValuePair";
import { STATUS_MAP } from "src/helpers/constants";
import { Card, CardHeader } from "../commons/Card";
import { Chip } from "../commons/Chip";
import { SchedulesList } from "../commons/SchedulesList";

export function VendorDashboard({ activeVendorServicesWithMatches = [], allVendorServices = [] }) {
	const matches = useMemo(() => {
		return activeVendorServicesWithMatches.reduce((acc, vendorService) => {
			(vendorService.matches || []).forEach((match) => {
				acc.push({
					...match,
					vendorServiceName: vendorService.name,
				});
			});
			return acc;
		}, []);
	}, [activeVendorServicesWithMatches]);
	return (
		<div className="grid grid-cols-1 gap-4">
			<div className="grid grid-cols-1 md:grid-cols-2 gap-4 min-h-[250px]">
				<Card>
					<CardHeader>
						<span className="text-lg">Services Offered</span>
					</CardHeader>
					<div className="text-slate-400 flex justify-center items-center h-full">No Data Found</div>
				</Card>
				<Card>
					<CardHeader>
						<span className="text-lg">Schedules</span>
					</CardHeader>
					<SchedulesList acceptedMatches={[]} />
				</Card>
			</div>
			<Card>
				<div className="p-4 bg-indigo-300 rounded-t-xl">
					<h2 className="text-lg">Match Found</h2>
				</div>
				{matches.length ? (
					matches.map((match) => {
						return (
							<div key={`match-opp-${match.id}`} className="flex w-full items-center p-4 gap-4">
								<div className="p-4 border border-primary rounded-md">
									<img src="/images/icons/opportunity-match.svg" width={45} height={45} />
								</div>
								<div className="flex-grow">
									<div className="font-bold text-lg">{match.name}</div>
									<div className="text-lg">{match.matches.length} match(es) found</div>
								</div>
								{match.matches.length > 0 && (
									<div>
										<Button
											variant="contained"
											color="primary"
											component={Link}
											to={`/vendor/${match.id}/matches`}
										>
											View Matches
										</Button>
									</div>
								)}
							</div>
						);
					})
				) : (
					<div className="p-8 text-center text-slate-400">There are no pending matches at the moment</div>
				)}
			</Card>
			{/* <SuccessfulMatches acceptedMatches={acceptedMatches} currentRole={currentRole} /> */}
			{/* <PendingMatches pendingMatches={acceptedMatches} currentRole={currentRole} /> */}
			<hr className="mb-4" />
			<Card>
				<CardHeader>
					<span className="text-xl">All Services</span>
				</CardHeader>
				{allVendorServices.data.map((service) => {
					return (
						<div key={`all-opportunity-${service.id}`} className="p-4 text-lg flex justify-between">
							<div>
								{service.name}{" "}
								<Chip classes="text-sm inline" status={service.status}>
									{STATUS_MAP[service.status]}
								</Chip>{" "}
							</div>
							<Button component={Link} variant="contained" color="primary" to={`/service/${service.id}/details`}>
								View
							</Button>
						</div>
					);
				})}
			</Card>
		</div>
	);
}
