import { useEffect, useState, useCallback, useMemo, Fragment } from "react";
import { useLoaderData, useParams } from "react-router-dom";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Snackbar from "@material-ui/core/Snackbar";
import Grid from "@material-ui/core/Grid";
import SaveOutlined from "@material-ui/icons/SaveOutlined";
import Add from "@material-ui/icons/Add";
import MAlert from "@material-ui/lab/Alert";
import makeStyles from "@material-ui/styles/makeStyles";

import { bulkUpsert, getAll, deleteMany } from "src/api/questions";
import { QUESTION_CATEGORIES, QUESTION_TYPES } from "src/helpers/constants";
import { Main } from "src/components/commons/Main";
import { StepForm } from "src/components/admin/StepForm";
import { Question } from "src/components/admin/Question";
import { QuestionListItem } from "src/components/admin/QuestionListItem";
import setByPath from "src/helpers/setByPath";
import Alert from "src/helpers/alert";
import { StepListItem } from "src/components/admin/StepListItem";

const titleMap = {
	campaign: "🙋 Founder",
	opportunity: "💰 Funder",
	vendor: "🛎️ Vendor",
	funder_profile: "💰Funder Profile",
	founder_profile: "🙋Founder Profile",
	vendor_profile: "👨🏻‍🔧Vendor Profile",
	founder_member: "🙋Founder Team Member",
	team_member: "Team Member",
	funder_organization: "Funder Organization",
	vendor_organization: "👨🏻‍🔧Vendor Organization",
	accelerator: "Accelerator",
};

const useStyles = makeStyles((theme) => ({
	saveBtn: {
		marginLeft: 8,
	},
	list: {
		cursor: "pointer",
		borderBottom: "1px solid rgb(239 232 251)",
		"&.step": {
			backgroundColor: "rgb(246 243 252)",
		},
		"&.selected": {
			backgroundColor: "#e2e2e3",
		},
		"&:hover": {
			boxShadow: "rgba(0, 0, 0, 0.2) 0px 3px 10px 0px",
			transform: "translateY(-4px)",
			borderBottomColor: "transparent",
			textDecoration: "none",
			backgroundColor: "rgb(245, 247, 250)",
		},
	},
	header: {
		color: theme.palette.common.white,
		borderRadius: "12px 12px 0 0",
		borderBottom: "1px solid rgb(239 232 251)",
	},
	campaign: {
		background: "linear-gradient(90deg, #7f42f6, #61d1c5, transparent)",
	},
	opportunity: {
		background: "linear-gradient(90deg, #f642cd, #d19e61, transparent)",
	},
	vendor: {
		background: "linear-gradient(90deg, #9b0000, #cfd1ea, transparent)",
	},
	founder_profile: {
		background: "linear-gradient(90deg, #310303, #cfd1ea, transparent)",
	},
	funder_profile: {
		background: "linear-gradient(90deg, #fa6601, #cfd1ea, transparent)",
	},
	vendor_profile: {
		background: "linear-gradient(90deg, #fa6601, #ffd1ea, transparent)",
	},
	team_member: {
		background: "linear-gradient(90deg, #fb6601, #cfd1ea, transparent)",
	},
	founder_member: {
		background: "linear-gradient(90deg, #fb6601, #cfd1ea, transparent)",
	},
	funder_organization: {
		background: "linear-gradient(90deg, #fac601, #cfd1ea, transparent)",
	},
	vendor_organization: {
		background: "linear-gradient(90deg, #fac601, #afd1ea, transparent)",
	},
	accelerator: {
		background: "linear-gradient(45deg, #3ab901, #afd1fa, transparent)",
	},
}));

function validate(steps, category) {
	let errors = {},
		hasError = false,
		errorItemToFocus = [];
	steps.forEach((step, idx) => {
		errors[`step${idx}`] = { step: "", questions: {} };
		let error = errors[`step${idx}`];
		if (!(step.group || "").trim()) {
			error.step = "Step Name cannot be empty";
			errorItemToFocus.push({ type: "STEP", stepIndex: idx, questionIndex: -1 });
			hasError = true;
		} else if (step.questions.length <= 0) {
			error.empty = "Step must have at least one question";
			errorItemToFocus.push({ type: "STEP", stepIndex: idx, questionIndex: -1 });
			hasError = true;
		} else {
			step.questions.forEach((question, qidx) => {
				if (!(question.question || "").trim()) {
					setByPath(error.questions, [`question${qidx}`, "question"], "Cannot be empty");
					errorItemToFocus.push({ type: "QUESTION", stepIndex: idx, questionIndex: qidx });
					hasError = true;
				}
				if (!question.type) {
					setByPath(error.questions, [`question${qidx}`, "type"], "Select a question type");
					errorItemToFocus.push({ type: "QUESTION", stepIndex: idx, questionIndex: qidx });
					hasError = true;
				}
				if (
					![QUESTION_CATEGORIES.CAMPAIGN, QUESTION_CATEGORIES.OPPORTUNITY, QUESTION_CATEGORIES.VENDOR].includes(
						category
					) &&
					!question.meta.field
				) {
					setByPath(error.questions, [`question${qidx}`, "field"], "Cannot be empty");
					errorItemToFocus.push({ type: "QUESTION", stepIndex: idx, questionIndex: qidx });
					hasError = true;
				}
				if (question.type === QUESTION_TYPES.UPLOAD_FILE) {
					if (!question.meta.path) {
						setByPath(error.questions, [`question${qidx}`, "path"], "Path cannot be empty");
						errorItemToFocus.push({ type: "QUESTION", stepIndex: idx, questionIndex: qidx });
						hasError = true;
					}
				}
				if (question.type === QUESTION_TYPES.COMBO) {
					if ((question.meta.comboFields || []).length <= 0) {
						setByPath(error.questions, [`question${qidx}`, "comboFields"], "At lease 1 field is necessary");
						errorItemToFocus.push({ type: "QUESTION", stepIndex: idx, questionIndex: qidx });
						hasError = true;
					} else {
						question.meta.comboFields.forEach((field, index) => {
							if (field.type === QUESTION_TYPES.AUTOCOMPLETE && !(field.fieldName && field.type && field.answers)) {
								setByPath(
									error.questions,
									[`question${qidx}`, `comboFields${index}`],
									"Name, type and answers are mandatory"
								);
								errorItemToFocus.push({ type: "QUESTION", stepIndex: idx, questionIndex: qidx });
								hasError = true;
								return;
							}
							if (!(field.fieldName && field.type)) {
								setByPath(error.questions, [`question${qidx}`, `comboFields${index}`], "Name and type are mandatory");
								errorItemToFocus.push({ type: "QUESTION", stepIndex: idx, questionIndex: qidx });
								hasError = true;
							}
						});
					}
				}
				if (
					(question.type === QUESTION_TYPES.RADIO ||
						question.type === QUESTION_TYPES.MULTIPLE_CHOICE ||
						question.type === QUESTION_TYPES.SELECT ||
						question.type === QUESTION_TYPES.MULTI_LEVEL_CHOICE) &&
					(!question.id || question._loadedAnswers !== undefined)
				) {
					if ((question.answers || []).length < 2) {
						setByPath(error.questions, [`question${qidx}`, "answers"], "Cannot have less than 2 choices");
						errorItemToFocus.push({ type: "QUESTION", stepIndex: idx, questionIndex: qidx });
						hasError = true;
					} else {
						question.answers.forEach((answer, idx) => {
							if (!(answer.answer || "").trim()) {
								setByPath(error.questions, [`question${qidx}`, `answer${idx}`], "Cannot be empty");
								errorItemToFocus.push({ type: "QUESTION", stepIndex: idx, questionIndex: qidx });
								hasError = true;
							}
						});
					}
				}
				if(question.meta.contextRules) {
					if(question.meta.contextRules.find(cr => !cr.context || !cr.operator)) {
						setByPath(error.questions, [`question${qidx}`, "context"], "Cannot have empty context");
						errorItemToFocus.push({ type: "QUESTION", stepIndex: idx, questionIndex: qidx });
						hasError = true;
					}
				}
			});
		}
	});
	console.log("Validation errors", errors);
	return { errors, hasError, firstError: errorItemToFocus[0] };
}

export function Questions() {
	const questions = useLoaderData();
	let params = useParams();
	const classes = useStyles();
	const [steps, setSteps] = useState([]);
	const [currentStep, setCurrentStep] = useState(null); //{type: "QUESTION|STEP", stepIndex: 0, questionIndex: -1}
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [isDirty, setIsDirty] = useState(false);
	const [errors, setErrors] = useState({});
	const [hasError, setHasError] = useState(false);
	const [isSubmitting, setSubmitting] = useState(false);
	const [responseStatus, setResponseStatus] = useState({ status: "success", message: "Saved Successfully" });

	const prepareData = useCallback((questions) => {
		const steps = questions.reduce((acc, question) => {
			acc[question.group] = {
				group: question.group,
				groupDescription: question.meta.groupDescription,
				step: question.meta.step,
				questions: acc[question.group]?.questions || [],
			};
			acc[question.group].questions.push(question);
			return acc;
		}, {});
		const sortedSteps = Object.values(steps)
			.map((step) => {
				step.questions = step.questions
					.map((question) => {
						question.answers = (question.answers || []).sort((a, b) => a.order - b.order);
						return question;
					})
					.sort((a, b) => a.order - b.order);
				return step;
			})
			.sort((a, b) => {
				return a.step - b.step;
			});
		setSteps(sortedSteps);
	}, []);

	const { labels, referrersByLabel } = useMemo(() => {
		const referrersByLabel = {};
		const labels = steps.reduce((acc, { questions }, stepidx) => {
			questions.map((question, qidx) => {
				if (question.meta.label) {
					acc[question.meta.label] = { index: `${stepidx + 1}.${qidx + 1}`, question: question.question };
				}
				if (question.answers && question.answers.length > 0) {
					question.answers.forEach((answer) => {
						(answer.meta?.display || []).forEach((qLabel) => {
							referrersByLabel[qLabel] ||= [];
							referrersByLabel[qLabel].push({
								index: `${question.meta.step + 1}.${question.order}`,
								question: question.question,
								answer: answer.answer,
							});
						});
					});
				}
			});
			return acc;
		}, {});

		return { labels, referrersByLabel };
	}, [steps]);

	useEffect(() => {
		// Needed so when route changes, all are reinitialized. Otherwise data is reused
		setSteps([]);
		setCurrentStep(null);
		setIsDirty(false);
		setErrors({});
		setHasError(false);
		prepareData(questions);
	}, [questions]);

	function toggleSnackBar(open) {
		return setOpenSnackbar(open);
	}
	function addStep(index) {
		setSteps((steps) => {
			const _steps = Object.assign([], steps);
			_steps.splice(index + 1, -1, {
				group: "",
				groupDescription: "",
				questions: [
					{
						question: "",
						order: 0,
						type: "",
						answers: [],
						meta: {},
					},
				],
			});
			return _steps;
		});
		setCurrentStep({ type: "STEP", stepIndex: index + 1, questionIndex: -1 });
	}

	async function save() {
		setSubmitting(true);
		const { errors, hasError, firstError } = validate(steps, params.type.toUpperCase());
		setErrors(errors);
		setHasError(hasError);
		if (hasError && firstError) {
			setCurrentStep({
				type: firstError.type,
				stepIndex: firstError.stepIndex,
				questionIndex: firstError.questionIndex,
			});
		}
		if (!hasError) {
			const allQuestionsAnswers = steps
				.map((step, sidx) => {
					return step.questions
						.sort((a, b) => a.order - b.order)
						.map((question, idx) => {
							const _question = {
								...question,
								group: step.group,
								answers: question.answers
									.sort((a, b) => a.order - b.order)
									.map((answer, aIdx) => {
										answer.answer = (answer.answer || "").trim();
										answer.order = aIdx + 1;
										return answer;
									}),
								order: idx + 1,
								meta: { ...question.meta, step: sidx, groupDescription: step.groupDescription },
								category: params.type.toUpperCase(),
							};
							if (_question._loadedAnswers !== true && _question.answers.length <= 0) {
								delete _question.answers;
							}
							return _question;
						});
				})
				.flat(3);
			try {
				const savedQuestionsAnswers = await bulkUpsert(allQuestionsAnswers);
				prepareData(savedQuestionsAnswers);
				toggleSnackBar(true);
				setResponseStatus({
					status: "success",
					message: "Saved Successfully",
				});
				setIsDirty(false);
			} catch (err) {
				toggleSnackBar(true);
				setResponseStatus({
					status: "error",
					message: err,
				});
			}
		}
		setSubmitting(false);
	}

	const addQuestion = useCallback(
		function _addQuestion(stepIndex, questionIndex) {
			const { group, groupDescription, questions } = steps[stepIndex];
			const _questions = [...questions];
			_questions.splice(questionIndex + 1, -1, {
				question: "",
				order: questions.length + 1,
				type: "",
				answers: [],
				meta: {},
			});
			_questions.forEach((q, idx) => {
				q.order = idx + 1;
			});

			onChange({
				group,
				groupDescription,
				questions: _questions,
				stepIndex,
			});
			setCurrentStep({ type: "QUESTION", stepIndex: stepIndex, questionIndex: questionIndex + 1 });
		},
		[steps, currentStep]
	);

	const onChange = useCallback(
		({ group, groupDescription, questions, stepIndex }) => {
			setSteps((steps) => {
				const _steps = Object.assign([], steps);
				_steps[stepIndex] = { group, groupDescription, questions };
				return _steps;
			});
			setIsDirty(true);
		},
		[steps, currentStep]
	);

	const deleteQuestion = useCallback(
		async function _deleteQuestion(questionIndex, stepIndex) {
			if (currentStep && currentStep.stepIndex === stepIndex && currentStep.questionIndex === questionIndex) {
				setCurrentStep(null);
			}
			setSteps((steps) => {
				const _steps = [...steps];
				_steps[stepIndex].questions.splice(questionIndex, 1);
				return _steps;
			});
		},
		[currentStep, steps]
	);

	const deleteStep = useCallback(
		async function _deleteStep(stepIndex) {
			try {
				if (currentStep && currentStep.stepIndex === stepIndex) {
					setCurrentStep(null);
				}
				const _steps = [...steps];
				const questionIdsToDelete = _steps[stepIndex].questions.reduce((acc, q) => {
					if (q.id) {
						acc.push(q.id);
					}
					return acc;
				}, []);
				await deleteMany(questionIdsToDelete);
				_steps.splice(stepIndex, 1);
				setSteps(_steps);
			} catch (err) {
				console.error("Unable to delete step", err);
				Alert.error("Unable to delete step");
				throw err;
			}
		},
		[currentStep, steps]
	);

	return (
		<Main>
			<Box
				padding={4}
				display={"flex"}
				justifyContent="space-between"
				className={clsx([classes[params.type], classes.header])}
			>
				<Typography component={"h2"} variant="h6">
					{titleMap[params.type]}s Form
				</Typography>
				{hasError && (
					<Box>
						<MAlert severity="error">Please fix all errors and save again</MAlert>
					</Box>
				)}
				<Box>
					<Button
						className={classes.saveBtn}
						color="primary"
						elevation={3}
						type="button"
						variant="contained"
						startIcon={<SaveOutlined />}
						disabled={!isDirty || isSubmitting}
						onClick={save}
					>
						{isSubmitting ? "Saving..." : "Save All"}
					</Button>
				</Box>
			</Box>
			<Grid container style={{ height: "calc(100vh - 136px - 1.5rem)" }}>
				<Grid component={"ul"} item xs={3} style={{ height: "100%", overflow: "auto" }}>
					{steps.length === 0 && (
						<Box component={"li"} textAlign="center" py={3}>
							<Button
								variant="contained"
								color="primary"
								size="small"
								disabled={false}
								startIcon={<Add />}
								onClick={() => {
									addStep(-1);
								}}
							>
								Add Step
							</Button>
						</Box>
					)}
					{steps.map(({ group, groupDescription }, idx) => {
						return (
							<Fragment key={`step_${idx}_${group}`}>
								<StepListItem
									idx={idx}
									group={group}
									groupDescription={groupDescription}
									errors={errors}
									classes={classes}
									currentStep={currentStep}
									onClick={() => setCurrentStep({ type: "STEP", stepIndex: idx, questionIndex: -1 })}
									onDelete={deleteStep}
								/>
								{steps[idx].questions.map((question, qidx) => {
									return (
										<QuestionListItem
											question={question}
											idx={qidx}
											stepIndex={idx}
											errors={errors}
											key={`step_${idx}_q_${qidx}`}
											isSelected={currentStep && currentStep.stepIndex === idx && currentStep.questionIndex === qidx}
											onAdd={addQuestion}
											onSelect={() => setCurrentStep({ type: "QUESTION", stepIndex: idx, questionIndex: qidx })}
											onDelete={() => {
												deleteQuestion(qidx, idx);
											}}
										/>
									);
								})}
								<Box component={"li"} justifyContent="center" display={"flex"} py={3} style={{ gap: 12 }}>
									<Button
										variant="contained"
										color="primary"
										size="small"
										disabled={false}
										startIcon={<Add />}
										onClick={() => {
											addStep(idx);
										}}
									>
										Add Step
									</Button>
									<Button
										variant="contained"
										color="primary"
										size="small"
										disabled={false}
										startIcon={<Add />}
										onClick={() => {
											addQuestion(idx, steps[idx].questions.length - 1);
										}}
									>
										Add Question
									</Button>
								</Box>
							</Fragment>
						);
					})}
				</Grid>
				<Grid item xs={9} style={{ height: "100%" }}>
					<Box height={"100%"} overflow="auto" borderLeft={"1px solid rgb(239 232 251)"}>
						{currentStep ? (
							<>
								{currentStep.type === "STEP" && (
									<StepForm
										group={steps[currentStep.stepIndex].group}
										groupDescription={steps[currentStep.stepIndex].groupDescription}
										questions={steps[currentStep.stepIndex].questions}
										onChange={(data) => {
											data.stepIndex = currentStep.stepIndex;
											onChange(data);
										}}
										onStepOrderChange={(order) => {
											setSteps((steps) => {
												const _steps = Object.assign([], steps);
												const step = _steps.splice(currentStep.stepIndex, 1);
												_steps.splice(order, 0, step[0]);
												return _steps;
											});
											setCurrentStep({ type: "STEP", stepIndex: order, questionIndex: -1 });
											setIsDirty(true);
										}}
										steps={steps}
										stepIndex={currentStep.stepIndex}
									/>
								)}
								{currentStep.type === "QUESTION" && steps[currentStep.stepIndex] && (
									<Question
										question={steps[currentStep.stepIndex].questions[currentStep.questionIndex]}
										steps={steps}
										labels={labels}
										referrersByLabel={referrersByLabel}
										errors={
											(errors[`step${currentStep.stepIndex}`]?.questions || {})[
												`question${currentStep.questionIndex}`
											] || {}
										}
										onDelete={() => {
											deleteQuestion(currentStep.questionIndex, currentStep.stepIndex);
										}}
										setAnswers={(answers) => {
											setSteps((steps) => {
												const _steps = Object.assign([], steps);
												const { group, groupDescription, questions } = _steps[currentStep.stepIndex];
												questions[currentStep.questionIndex].answers = answers;
												questions[currentStep.questionIndex]._loadedAnswers = true;
												_steps[currentStep.stepIndex] = { group, groupDescription, questions };
												return _steps;
											});
										}}
										onChange={(question, idx) => {
											const { group, groupDescription, questions } = steps[currentStep.stepIndex];
											const _questions = [...questions];
											const existingOrder = _questions[idx].order;
											const newOrder = Math.min(Math.max(question.order, 0), _questions.length);
											if (question.order && existingOrder !== question.order) {
												_questions.splice(newOrder - 1, 0, _questions.splice(existingOrder - 1, 1)[0]);
												_questions.forEach((q, idx) => {
													q.order = idx + 1;
												});
											} else {
												_questions[idx] = question;
											}
											onChange({ group, groupDescription, questions: _questions, stepIndex: currentStep.stepIndex });
											setCurrentStep({
												stepIndex: currentStep.stepIndex,
												type: "QUESTION",
												questionIndex: Math.min(Math.max(newOrder - 1, 0), _questions.length - 1),
											});
										}}
										onStepChange={(stepIndex, question, questionIdx) => {
											let finalQuestionIndex = questionIdx;
											setSteps((steps) => {
												const _steps = Object.assign([], steps);
												let questions = _steps[currentStep.stepIndex].questions;
												let _questions = [...questions];
												_questions.splice(questionIdx, 1);
												_questions.map((q, idx) => (q.order = idx));
												_steps[currentStep.stepIndex].questions = _questions;

												questions = _steps[stepIndex].questions;
												_questions = [...questions];
												const _question = { ...question };
												_question.meta.step = stepIndex;
												_questions.splice(questionIdx, 0, _question);
												_questions.map((q, idx) => (q.order = idx));
												_steps[stepIndex].questions = _questions;
												if (questionIdx > _questions.length - 1) {
													finalQuestionIndex = _questions.length - 1;
												}
												return _steps;
											});
											setCurrentStep({ stepIndex, type: "QUESTION", questionIndex: finalQuestionIndex });
											setIsDirty(true);
										}}
										idx={currentStep.questionIndex}
									/>
								)}
							</>
						) : (
							<Box height={"100%"} display={"flex"} justifyContent="center" alignItems={"center"}>
								Select a step or a question
							</Box>
						)}
					</Box>
				</Grid>
			</Grid>
			<Snackbar
				open={openSnackbar}
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
				onClose={() => toggleSnackBar(false)}
			>
				<MAlert
					onClose={() => toggleSnackBar(false)}
					variant="filled"
					severity={responseStatus.status}
					sx={{ width: "100%" }}
				>
					{responseStatus.message}
				</MAlert>
			</Snackbar>
		</Main>
	);
}

export const questionsLoader = async ({ request, params }) => {
	return getAll(
		{ filter: `category||$eq||${(params.type || "").toUpperCase()}`, limit: 500 },
		{ signal: request.signal }
	);
};
