import { fetchAdminV1 } from "../lib/requester/v1";

export function getAll(params) {
	return fetchAdminV1("/external_funders", { method: "GET", data: params }, { returnWholeResponse: true });
}

export function update(data) {
	return fetchAdminV1(`/external_funders/${data.id}`, { method: "PUT", data });
}

export function remove(data) {
	return fetchAdminV1(`/external_funders/${data.id}`, { method: "DELETE" });
}

export function create(data) {
	return fetchAdminV1(`/external_funders`, { method: "POST", data });
}

export function bulk(data) {
	return fetchAdminV1(`/external_funders/bulk`, { method: "POST", data });
}