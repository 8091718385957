import { useMemo } from "react";
import { QUESTION_TYPES } from "src/helpers/constants";
import { DisplayKeyValuePair } from "./DisplayKeyValuePair";

export function DisplayUserAnswers({ id, answers = [], isAdmin = false, type }) {
	const steps = useMemo(() => {
		const groups = answers.reduce((acc, userAnswer) => {
			if (!userAnswer.question) {
				return acc;
			}
			const { group, meta, order, id, type, question } = userAnswer.question;
			acc[group] = {
				group: group,
				step: meta.step,
				questions: acc[group]?.questions || {},
			};
			const _question = acc[group]?.questions[id] || { id, type, order, question, meta, answers: [] };
			if (userAnswer.question.type === QUESTION_TYPES.COMBO || userAnswer.question.meta.multiple === true) {
				try {
					_question.answers = JSON.parse(userAnswer.response);
				} catch (err) {
					console.error("Unable to parse combo response", userAnswer.response, userAnswer);
					_question.answers = [userAnswer.response];
				}
			} else if (userAnswer.question.meta.field?.toLowerCase().includes("industry")) {
				let response = [];
				try {
					if (userAnswer.response) {
						response = JSON.parse(userAnswer.response);
					}
				} catch (err) {
					console.error("Unable to parse industry response", userAnswer.response, userAnswer);
				}
				if (userAnswer.response && response.length) {
					_question.answers.push.apply(_question.answers, response);
				}
			} else if (userAnswer.response || userAnswer.answer?.answer) {
				_question.answers.push(userAnswer.response || userAnswer.answer?.answer);
			}
			acc[group].questions[id] = _question;
			return acc;
		}, {});
		const steps = Object.values(groups)
			.map((step) => {
				step.questions = Object.values(step.questions).sort((a, b) => a.order - b.order);
				return step;
			})
			.sort((a, b) => {
				return a.step - b.step;
			});
		if (!isAdmin && steps.length > 10) {
			steps.pop();
			if (type === "campaign") {
				steps.pop();
			}
		}
		return steps;
		/* const questionAnswersMap = new Map();
		const _ans = answers
			.filter((i) => i.question)
			.sort((a, b) => {
				return a.question.order + a.question.meta.step * 100 - (b.question.order + b.question.meta.step * 100);
			});
		_ans.forEach((ua) => {
			const res = questionAnswersMap.get(ua.question.id) || {
				index: ua.question.meta.step + 1 + "." + ua.question.order,
				question: ua.question.question,
				answers: [],
			};
			if (ua.question.type === QUESTION_TYPES.COMBO || ua.question.meta.multiple === true) {
				try {
					res.answers = JSON.parse(ua.response);
				} catch (err) {
					console.error("Unable to parse combo response", ua.response, ua);
				}
			} else if (ua.question.type === QUESTION_TYPES.MULTI_LEVEL_CHOICE) {
				try {
					const industry = JSON.parse(ua.response);
					industry[0].name = ua.answer.answer;
					res.answers.push(industry);
				} catch (err) {
					console.error("Unable to parse industry response", ua.response, ua);
				}
			} else {
				res.answers.push(ua.response || ua.answer?.answer);
			}
			questionAnswersMap.set(ua.question.id, res);
		});
		return Array.from(questionAnswersMap.values()); */
	}, [id, answers]);
	return steps.map((step, idx) => {
		return (
			<div key={`${idx}-step`}>
				<h3 className="text-lg py-2 px-4 bg-slate-200 my-4">{step.group}</h3>
				{step.questions.map((question, qidx) => {
					return (
						<div key={`${idx}-question-${question.order}-${qidx}`} className="mb-2 px-4">
							<div className="mb-1">
								<>{isAdmin ? `${idx + 1}.${question.order}` : qidx + 1}&#41;</> {question.question}
							</div>
							<DisplayKeyValuePair
								id={`${idx}-question-${question.index}-${qidx}`}
								type={question.type}
								meta={question.meta}
								data={question.answers}
							/>
						</div>
					);
				})}
			</div>
		);
	});
}
