import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Card, CardHeader } from "src/components/commons/Card";

export function EntityListView({ category, onSelect, paginatedEntities, selectedEntity = null }) {
	return (
		<Grid item xs={12} md={3}>
			<Card>
				<CardHeader>
					<div className="text-lg capitalize">
						{category} ({paginatedEntities.total})
					</div>
				</CardHeader>
				<Box height={"calc(100vh - 230px)"} overflow={"auto"}>
					{paginatedEntities.data.length <= 0 ? (
						<Box
							display={"flex"}
							flexDirection={"column"}
							justifyContent="center"
							alignItems={"center"}
							textAlign={"center"}
						>
							<Box component={"p"} m={3} className="placeholder">
								No Result
							</Box>
						</Box>
					) : (
						<List className={"ul-reset"} component={"ul"}>
							{paginatedEntities.data.map((entity) => {
								return (
									<ListItem
										key={`${category}_id_${entity.id}`}
										component={"li"}
										button
										selected={selectedEntity?.id === entity.id}
										onClick={() => onSelect(entity)}
									>
										<Box display={"flex"} flexDirection="column" width={"100%"}>
											<div className="font-bold">
												{entity.name} #{entity.id}{" "}
												{entity.score && <span className="ml-2 text-slate-500 text-xs p-1 bg-slate-200 rounded-sm">🔼 {Number(entity.score).toFixed(2)}</span>}
											</div>
											<p className="text-slate-400">
												{[entity.creator?.firstName, entity.creator?.lastName].join(" ")} - {entity.creator?.email}
											</p>
										</Box>
									</ListItem>
								);
							})}
						</List>
					)}
				</Box>
			</Card>
		</Grid>
	);
}
