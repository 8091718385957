import { fetchV1 } from "../lib/requester/v1";

export function getAll(params) {
	return fetchV1("/opportunity_organizations", { method: "GET", data: params });
}

export function create(data) {
	return fetchV1(`/opportunity_organizations`, { method: "POST", data });
}

export function update(data) {
	return fetchV1(`/opportunity_organizations/${data.id}`, { method: "PUT", data });
}