// import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useCallback, useEffect, useState } from "react";
import { create, getAll, remove, update } from "src/api/tenants";
import { debounce } from "src/helpers/debounce";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Alert from "src/helpers/alert";
import { getPresignedUrlToUpload } from "src/api/userAnswers";
// import { steppedScale as steppedScale } from "src/helpers/image_resize";

function Row({ row, onSave, onDelete, onCancel }) {
	const [saving, setSaving] = useState(false);
	const [editing, setEditing] = useState(!row.id);
	const [deleting, setDeleting] = useState(false);
	const [uploading, setUploading] = useState(false);
	const [files, setFiles] = useState(null);
	const [data, setData] = useState(() => ({ ...row }));
	function onChange(field, value) {
		setData({ ...data, [field]: value });
	}

	async function save() {
		try {
			setSaving(true);
			let res = data;
			if (!data.name || !data.domain) {
				Alert.error("Name or domain is empty");
				return false;
			}
			if (data.tncLink && data.tncLink.substr(0, 4) !== "http" && data.tncLink.substr(0, 2) !== "//") {
				data.tncLink = "//" + data.tncLink;
			}
			if (
				data.privacyPolicyLink &&
				data.privacyPolicyLink.substr(0, 4) !== "http" &&
				data.privacyPolicyLink.substr(0, 2) !== "//"
			) {
				data.privacyPolicyLink = "//" + data.privacyPolicyLink;
			}
			if (data.id) {
				res = await update(data);
			} else {
				res = await create(data);
			}
			onSave(res);
			setEditing(false);
		} catch (err) {
			console.error("Unable to save tenants", err);
			Alert.error("Unable to save");
		} finally {
			setSaving(false);
		}
	}

	async function deleteRow() {
		try {
			setDeleting(true);
			await remove(data);
			onDelete();
		} catch (err) {
			console.error("Unable to delete tenant", err);
			Alert.error("Unable to delete");
		} finally {
			setDeleting(false);
		}
	}

	async function upload() {
		setUploading(true);
		try {
			if (!files) {
				Alert.error("No file selected");
				return false;
			}
			let res = data;
			// if (files.type.includes("svg")) {
			const url = await getPresignedUrlToUpload({
				path: `/tenant/${row.id}/logo/logo.${files.type.split("/")[1]}`,
			});
			await window.fetch(url, { method: "PUT", body: files });
			const _url = url.split("?")[0];
			data.logo["1x"] = data.logo["1x"] || {};
			data.logo["1x"].path = _url;
			data.logo["2x"] = data.logo["2x"] || {};
			data.logo["2x"].path = _url;
			data.logo["3x"] = data.logo["3x"] || {};
			data.logo["3x"].path = _url;
			// } else {
			// const [x3, x2, x1] = await Promise.all([
			// 	steppedScale(files, files.type, 512, 0.5),
			// 	steppedScale(files, files.type, 256, 0.5),
			// 	steppedScale(files, files.type, 128, 0.5),
			// ]);
			// 	const fileMap = { "1x": files, "2x": files, "3x": files };
			// 	const promises = ["1x", "2x", "3x"].map(async (res) => {
			// 		if (fileMap[res]) {
			// 			const url = await getPresignedUrlToUpload({
			// 				path: `/tenant/${row.id}/logo/${res}/logo.${fileMap[res].type.split("/")[1]}`,
			// 			});
			// 			await window.fetch(url, { method: "PUT", body: fileMap[res] });
			// 			data.logo[res] = data.logo[res] || {};
			// 			data.logo[res].path = url.split("?")[0];
			// 		}
			// 	});
			// 	await Promise.all(promises);
			// }
			if (data.id) {
				res = await update(data);
			} else {
				res = await create(data);
			}
			onSave(res);
			setEditing(false);
		} catch (error) {
			console.error("Unable to upload tenant logo", error);
			Alert.error("Unable to upload");
		} finally {
			setUploading(false);
		}
	}

	return (
		<TableRow key={data.id}>
			<TableCell className="pl-4">
				{editing ? (
					<input
						className="p-2 border rounded-sm"
						placeholder="Name"
						value={data.name}
						type="text"
						onChange={(e) => onChange("name", e.target.value)}
					/>
				) : (
					data.name
				)}
			</TableCell>
			<TableCell>
				{editing ? (
					<input
						className="w-full p-2 border rounded-sm"
						placeholder="Domain"
						value={data.domain}
						type="text"
						onChange={(e) => onChange("domain", e.target.value)}
					/>
				) : (
					data.domain
				)}
			</TableCell>
			<TableCell>
				{editing ? (
					<textarea
						className="w-full p-2 border rounded-sm"
						placeholder="Description"
						value={data.description}
						onChange={(e) => onChange("description", e.target.value)}
					/>
				) : (
					data.description
				)}
			</TableCell>
			<TableCell>
				{editing ? (
					<>
						<label className="p-2 bg-indigo-600 rounded-sm text-white cursor-pointer mr-1">
							Choose 512x512
							<input className="hidden" type="file" accept="image/*" onChange={(e) => setFiles(e.target.files[0])} />
						</label>
						<div className="mt-2">
							<Button variant="contained" color="primary" disabled={uploading} onClick={upload} size="small">
								{uploading ? "Uploading..." : "Upload"}
							</Button>{" "}
							{files?.name}
						</div>
					</>
				) : data.logo["1x"] ? (
					<img src={data.logo["1x"].path} width={64} />
				) : null}
			</TableCell>
			<TableCell>
				{editing ? (
					<>
						<input
							className="w-full p-2 border rounded-sm d-block"
							placeholder="Contact Phone"
							value={data.contactPhone}
							type="text"
							onChange={(e) => onChange("contactPhone", e.target.value)}
						/>
						<input
							className="w-full p-2 border rounded-sm"
							placeholder="Contact Email"
							value={data.contactEmail}
							type="text"
							onChange={(e) => onChange("contactEmail", e.target.value)}
						/>
						<input
							className="w-full p-2 border rounded-sm"
							placeholder="T&C Link"
							value={data.tncLink}
							type="text"
							onChange={(e) => onChange("tncLink", e.target.value)}
						/>
						<input
							className="w-full p-2 border rounded-sm"
							placeholder="Privacy Policy Link"
							value={data.privacyPolicyLink}
							type="text"
							onChange={(e) => onChange("privacyPolicyLink", e.target.value)}
						/>
					</>
				) : (
					<>
						<div>{data.contactPhone}</div>
						<div>{data.contactEmail}</div>
						<a className="mr-3 underline" href={data.tncLink} target="_blank" rel="noreferrer">
							TnC
						</a>
						<a className="underline" href={data.privacyPolicyLink} target="_blank" rel="noreferrer">
							Privacy Policy
						</a>
					</>
				)}
			</TableCell>
			<TableCell>
				<div className="flex gap-2">
					{editing && (
						<>
							<Button variant="contained" color="primary" disabled={saving} onClick={save} size="small">
								{saving ? "Saving..." : "Save"}
							</Button>
							<Button
								variant="contained"
								color="Secondary"
								onClick={() => {
									setEditing(false);
									onCancel();
								}}
								size="small"
							>
								Cancel
							</Button>
						</>
					)}
					{!editing && (
						<Button variant="contained" color="primary" size="small" onClick={() => setEditing(true)}>
							Edit
						</Button>
					)}
					{!editing && (
						<Button variant="contained" color="secondary" disabled={deleting} size="small" onClick={deleteRow}>
							{deleting ? "Deleting..." : "Delete"}
						</Button>
					)}
				</div>
			</TableCell>
		</TableRow>
	);
}

export function Tenants() {
	const [rows, setRows] = useState([]);
	const [loading, setLoading] = useState(false);
	const [total, setTotal] = useState(-1);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(20);
	const [search, setSearch] = useState("");
	const [deferredSearch, setDeferredSearch] = useState("");
	const [error, setError] = useState(null);

	const updateSearch = useCallback(
		debounce(function _updateSearch(search) {
			setDeferredSearch(search);
		}, 500),
		[]
	);
	useEffect(() => {
		updateSearch(search);
	}, [search]);
	useEffect(() => {
		const options = { limit: perPage, page: currentPage };
		if (search) {
			options.filter = `name||$contL||${search}`;
			options.or = `domain||$contL||${search}`;
		}
		(async () => {
			try {
				setError(null);
				setLoading(true);
				const res = await getAll(options);
				setRows(res.data);
				setTotal(res.total);
			} catch (err) {
				console.error("Unable to fetch clients", err);
				setError("Unable to load");
			} finally {
				setLoading(false);
			}
		})();
	}, [currentPage, deferredSearch, perPage]);
	return (
		<TableContainer component={Paper}>
			<Box className="flex justify-between items-center p-4">
				<Box className="text-xl">White Label Clients</Box>
				<Box>
					{loading && "Loading..."}
					{error && <Box className="text-red-600">{error}</Box>}
				</Box>
				<Box>
					<TextField
						fullWidth
						variant="outlined"
						label="Search"
						type="text"
						size="small"
						value={search}
						onChange={(e) => {
							setSearch(e.target.value);
						}}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										onClick={() => {
											setSearch("");
										}}
									>
										<Close />
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				</Box>
			</Box>
			<Table aria-label="tenants table">
				<TableHead>
					<TableRow>
						<TableCell>Name</TableCell>
						<TableCell>Domain</TableCell>
						<TableCell>Description</TableCell>
						<TableCell>Logo (svg/png/jpeg 512x512)</TableCell>
						<TableCell>Links</TableCell>
						<TableCell style={{ width: 200 }}>Actions</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{error && (
						<TableRow>
							<TableCell colSpan={5}>{error}</TableCell>
						</TableRow>
					)}
					{rows.length <= 0 && (
						<TableRow>
							<TableCell colSpan={5}>{loading ? "Loading..." : "No data"}</TableCell>
						</TableRow>
					)}
					{rows.length > 0 &&
						rows.map((row, idx) => (
							<Row
								key={`row-${row.id || idx}`}
								row={row}
								onSave={(res) => {
									const _rows = [...rows];
									_rows[idx] = res;
									setRows(_rows);
								}}
								onDelete={() => {
									const _rows = [...rows];
									_rows.splice(idx, 1);
									setRows(_rows);
								}}
								onCancel={() => {
									if (row.id) {
										return;
									}
									const _rows = [...rows];
									_rows.splice(idx, 1);
									setRows(_rows);
								}}
							/>
						))}
				</TableBody>
				<TableFooter>
					<TableRow>
						<TableCell>
							<Button
								variant="contained"
								color="primary"
								onClick={() => {
									const _rows = [...rows];
									_rows.push({ name: "", domain: "", description: "" });
									setRows(_rows);
								}}
							>
								+ Add White Label Client
							</Button>
						</TableCell>
						<TablePagination
							rowsPerPageOptions={[20, 50, 100]}
							count={total}
							rowsPerPage={perPage}
							page={currentPage - 1}
							SelectProps={{
								inputProps: { "aria-label": "rows per page" },
								native: true,
							}}
							onPageChange={(e, newPage) => {
								setCurrentPage(newPage + 1);
							}}
							onRowsPerPageChange={(e) => {
								setPerPage(parseInt(e.target.value, 10));
								setCurrentPage(1);
							}}
							// ActionsComponent={TablePaginationActions}
						/>
					</TableRow>
				</TableFooter>
			</Table>
		</TableContainer>
	);
}
