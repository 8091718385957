import React, { useState, useEffect, useRef } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
// import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
// import { alpha } from "@material-ui/core/styles";

import { getAllForAdmin as getAllOpportunities, getOpportunityWithUserAnswersForAdmin } from "src/api/opportunities";
import { getAllForAdmin as getAllCampaigns, getCampaignWithUserAnswersForAdmin } from "src/api/campaigns";
import { Card, CardHeader } from "src/components/commons/Card";
import { create, getMatchByParams } from "src/api/matches";
import Alert from "src/helpers/alert";
import { DisplayKeyValuePair } from "src/components/commons/DisplayKeyValuePair";
import { DisplayUserAnswers } from "src/components/commons/DisplayUserAnswers";
import { populateParameters as populateCampaignParameters } from "src/api/campaignMatchingParameters";
import { populateParameters as populateOpportunityParameters } from "src/api/opportunityMatchingParameters";

export function RecommendCampaignsForOpportunity() {
	const [campaigns, setCampaigns] = useState({});
	const [opportunities, setOpportunities] = useState({});
	const [selectedCampaign, setSelectedCampaign] = useState(null);
	const [selectedOpportunity, setSelectedOpportunity] = useState(null);
	const [isSaving, setSaving] = useState(false);
	const [isFetchingCampaignAnswers, setFetchingCampaignAnswers] = useState(false);
	const [isFetchingOpportunityAnswers, setFetchingOpportunityAnswers] = useState(false);
	const [canMatch, setCanMatch] = useState(false);
	const [campaignSearch, setCampaignSearch] = useState("");
	const [campaignSearching, setCampaignSearching] = useState(false);
	const [oppSearch, setOppSearch] = useState("");
	const [oppSearching, setOppSearching] = useState(false);
	const campaignQuestionAnswers = useRef(new Map());
	const opportunityQuestionAnswers = useRef(new Map());

	async function save() {
		if (!canMatch) {
			return false;
		}
		setSaving(true);
		try {
			await create({ opportunityId: selectedOpportunity.id, campaignId: selectedCampaign.id });
			Alert.success("Match Saved Successfully");
			setCanMatch(false);
		} catch (err) {
			Alert.error("Unable to save the match");
			console.error("Unable to save the matches", err);
		} finally {
			setSaving(false);
		}
	}

	useEffect(() => {
		if (selectedCampaign) {
			if (!campaignQuestionAnswers.current.get(selectedCampaign.id)) {
				(async function campaignSelection() {
					setFetchingCampaignAnswers(true);
					const campaign = await getCampaignWithUserAnswersForAdmin(selectedCampaign.id);
					campaignQuestionAnswers.current.set(selectedCampaign.id, campaign.userAnswers);
					setFetchingCampaignAnswers(false);
				})();
			}
		}
	}, [selectedCampaign]);

	useEffect(() => {
		if (selectedOpportunity) {
			// (async function recommendCampaigns(opp) {
			// 	try {
			// 		setLoadingRecommendation(true);
			// 		setSelectedCampaign(null);
			// 		const campaigns = await getRecommendedCampaignsForOpportunityForAdmin(opp.id);
			// 		setCampaigns(campaigns);
			// 		setLoadingRecommendation(false);
			// 	} catch (err) {
			// 		console.error("error while fetching recommendation for opp", err);
			// 		Alert.error("Unable to fetch recommendation");
			// 	}
			// })(selectedOpportunity);
			if (!opportunityQuestionAnswers.current.get(selectedOpportunity.id)) {
				(async function oppSelection() {
					setFetchingOpportunityAnswers(true);
					const opportunity = await getOpportunityWithUserAnswersForAdmin(selectedOpportunity.id);
					opportunityQuestionAnswers.current.set(selectedOpportunity.id, opportunity.userAnswers);
					setFetchingOpportunityAnswers(false);
				})();
			}
		}
	}, [selectedOpportunity]);

	useEffect(() => {
		if (selectedCampaign && selectedOpportunity) {
			(async function fetchMatch() {
				const result = await getMatchByParams({
					filter: [`campaignId||$eq||${selectedCampaign.id}`, `opportunityId||$eq||${selectedOpportunity.id}`],
				});
				setCanMatch(result.length === 0);
			})();
		}
	}, [selectedCampaign, selectedOpportunity]);

	async function oppSearchFn(e) {
		e.preventDefault();
		setOppSearching(true);
		try {
			const search = {
				$and: [
					{ status: "ACTIVE" },
					{
						$or: [
							{ name: { $contL: oppSearch } },
							{ "creator.firstName": { $contL: oppSearch } },
							{ "creator.firstName": { $contL: oppSearch } },
							{ "creator.email": { $contL: oppSearch } },
						],
					},
				],
			};
			const res = await getAllOpportunities({
				join: ["creator", "matchingParameter"],
				s: JSON.stringify(search),
				limit: 100,
			});
			setOpportunities(res);
		} catch (error) {
			console.error("Error while fetching opportunities", error);
			Alert.error("Unable to search");
		} finally {
			setOppSearching(false);
		}
	}
	async function campaignSearchFn(e) {
		e.preventDefault();
		setCampaignSearching(true);
		try {
			const search = {
				$and: [
					{ status: "ACTIVE" },
					{
						$or: [
							{ name: { $contL: campaignSearch } },
							{ "creator.firstName": { $contL: campaignSearch } },
							{ "creator.firstName": { $contL: campaignSearch } },
							{ "creator.email": { $contL: campaignSearch } },
						],
					},
				],
			};
			const res = await getAllCampaigns({
				join: ["creator", "matchingParameter"],
				s: JSON.stringify(search),
				limit: 100,
			});
			setCampaigns(res);
		} catch (error) {
			console.error("Error while fetching campaigns", error);
			Alert.error("Unable to search");
		} finally {
			setCampaignSearching(false);
		}
	}
	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<Box display={"flex"} justifyContent="space-between">
					<Box display={"flex"} alignItems={"center"}>
						<Typography component={"h2"} variant={"h5"}>
							Match:{" "}
						</Typography>
						<Typography component={"p"} variant={"h6"} style={{ marginLeft: 8 }}>
							{selectedOpportunity?.name || (
								<Box component="span" className="placeholder">
									Select An Opportunity
								</Box>
							)}
							&nbsp;&lt;=&gt;&nbsp;
							{selectedCampaign?.name || (
								<Box component="span" className="placeholder">
									Select A Campaign
								</Box>
							)}
						</Typography>
					</Box>
					<Button variant="contained" disabled={!canMatch || isSaving} color="secondary" onClick={save}>
						Match
					</Button>
				</Box>
			</Grid>
			<Grid item xs={12} md={3}>
				<Card>
					<CardHeader>
						<div className="flex w-full flex-col">
							<div className="text-lg">Opportunities ({opportunities.total || 0})</div>
							<form className="flex" onSubmit={oppSearchFn}>
								<input
									type="text"
									className="border flex-grow rounded-sm p-1"
									placeholder="Search"
									value={oppSearch}
									onChange={(e) => setOppSearch(e.target.value)}
								/>
								<button
									disabled={!oppSearch || oppSearching}
									className="px-2 py-1 rounded-sm bg-indigo-500 disabled:bg-indigo-100 ml-2"
								>
									🔍
								</button>
							</form>
						</div>
					</CardHeader>
					<Box height={"calc(100vh - 230px)"} overflow={"auto"}>
						{(opportunities.data?.length || 0) <= 0 ? (
							<Box
								display={"flex"}
								flexDirection={"column"}
								justifyContent="center"
								alignItems={"center"}
								textAlign={"center"}
							>
								<Box component={"p"} m={3} className="placeholder">
									{oppSearching ? "Loading..." : "Search by user name or email or opportunity name"}
								</Box>
							</Box>
						) : (
							<List className={"ul-reset"} component={"ul"}>
								{opportunities.data?.map((opportunity) => {
									return (
										<ListItem
											key={`opp_id_${opportunity.id}`}
											component={"li"}
											button
											selected={selectedOpportunity?.id === opportunity.id}
											onClick={() => {
												const _opportunity = { ...opportunity };
												if (_opportunity.matchingParameter) {
													_opportunity.matchingParameter.description = _opportunity.description;
												}
												setSelectedOpportunity(_opportunity);
											}}
										>
											<Box display={"flex"} flexDirection="column" width={"100%"}>
												<div className="font-bold">
													{opportunity.name} #{opportunity.id}
												</div>
												<p className="text-slate-400">
													{[opportunity.creator.firstName, opportunity.creator.lastName].join(" ")} -{" "}
													{opportunity.creator.email}
												</p>
											</Box>
										</ListItem>
									);
								})}
							</List>
						)}
					</Box>
				</Card>
			</Grid>
			<Grid item xs={12} md={3}>
				<Card>
					<CardHeader>
						<div className="text-lg">{selectedOpportunity?.name || "Selected Opportunity"}</div>
					</CardHeader>
					<Box height={"calc(100vh - 230px)"} overflow={"auto"} p={4}>
						{selectedOpportunity ? (
							<>
								<div className="grid grid-cols-12 max-w-full mb-2">
									<div className="col-span-12 capitalize">Email:</div>
									<div className="col-span-12">
										<div className="inline-block bg-slate-200 rounded-md p-1 mb-1 ml-1 font-bold">
											{selectedOpportunity.creator.email}
										</div>
									</div>
								</div>
								{selectedOpportunity.matchingParameter ? (
									<DisplayKeyValuePair
										data={selectedOpportunity.matchingParameter}
										excludeKeys={["id", "createdAt", "updatedAt", "deletedAt", "opportunityId", "industry"]}
										id={`sel-opp-${selectedOpportunity.id}`}
									/>
								) : (
									<div className="flex justify-center mb-4">
										<Button
											variant="outlined"
											color="primary"
											onClick={async () => {
												try {
													const oppMatchingParameters = await populateOpportunityParameters(selectedOpportunity.id);
													const _allOpportunities = { ...opportunities };
													const index = _allOpportunities.data.findIndex((i) => i.id === selectedOpportunity.id);
													_allOpportunities.data[index].matchingParameter = oppMatchingParameters;
													setOpportunities(_allOpportunities);
													setSelectedOpportunity({
														...selectedOpportunity,
														matchingParameter: { ...oppMatchingParameters },
													});
												} catch (err) {
													Alert.error("Unable to populate");
													console.error("error while populating opportunity params", err);
												}
											}}
										>
											Populate Signals
										</Button>
									</div>
								)}
								<div className="p-4 -mx-4 bg-slate-200">Answers</div>
								{isFetchingOpportunityAnswers ? (
									<Box height={"100%"} display="flex" justifyContent={"center"} alignItems={"center"}>
										<CircularProgress />
									</Box>
								) : (
									<DisplayUserAnswers
										id={`opp-${selectedOpportunity.id}`}
										answers={opportunityQuestionAnswers.current.get(selectedOpportunity.id)}
										type="opportunity"
										isAdmin={true}
									/>
								)}
							</>
						) : (
							<Typography component={"p"} variant="subtitle2" className="placeholder" align="center">
								No opportunity is selected
							</Typography>
						)}
					</Box>
				</Card>
			</Grid>
			<Grid item xs={12} md={3}>
				<Card>
					<CardHeader>
						<div className="text-lg">{selectedCampaign?.name || "Selected Campaign"}</div>
					</CardHeader>
					<Box height={"calc(100vh - 230px)"} overflow={"auto"} p={4}>
						{selectedCampaign ? (
							<>
								<div className="grid grid-cols-12 max-w-full mb-2">
									<div className="col-span-12 capitalize">Email:</div>
									<div className="col-span-12">
										<div className="inline-block bg-slate-200 rounded-md p-1 mb-1 ml-1 font-bold">
											{selectedCampaign.creator.email}
										</div>
									</div>
								</div>
								{selectedCampaign.matchingParameter ? (
									<DisplayKeyValuePair
										data={selectedCampaign.matchingParameter}
										excludeKeys={["id", "createdAt", "updatedAt", "deletedAt", "campaignId", "industry"]}
										id={`campaign-${selectedCampaign.id}`}
									/>
								) : (
									<div className="flex justify-center mb-4">
										<Button
											variant="outlined"
											color="primary"
											onClick={async () => {
												try {
													const campaignMatchingParameters = await populateCampaignParameters(selectedCampaign.id);
													const _allCampaigns = { ...campaigns };
													const index = _allCampaigns.data.findIndex((i) => i.id === selectedCampaign.id);
													_allCampaigns.data[index].matchingParameter = campaignMatchingParameters;
													setCampaigns(_allCampaigns);
													setSelectedCampaign({
														...selectedCampaign,
														matchingParameter: { ...campaignMatchingParameters },
													});
												} catch (err) {
													Alert.error("Unable to populate");
													console.error("error while populating campaign params", err);
												}
											}}
										>
											Populate Signals
										</Button>
									</div>
								)}
								<div className="p-4 -mx-4 bg-slate-200">Answers</div>
								{isFetchingCampaignAnswers ? (
									<Box height={"100%"} display="flex" justifyContent={"center"} alignItems={"center"}>
										<CircularProgress />
									</Box>
								) : (
									<DisplayUserAnswers
										id={`campaign-${selectedCampaign.id}`}
										isAdmin={true}
										type="campaign"
										answers={campaignQuestionAnswers.current.get(selectedCampaign.id)}
									/>
								)}
							</>
						) : (
							<Typography component={"p"} variant="subtitle2" className="placeholder" align="center">
								No campaign is selected
							</Typography>
						)}
					</Box>
				</Card>
			</Grid>
			<Grid item xs={12} md={3}>
				<Card>
					<CardHeader>
						<div className="flex w-full flex-col">
							<div className="text-lg">Campaigns ({campaigns.total || 0})</div>
							<form className="flex" onSubmit={campaignSearchFn}>
								<input
									type="text"
									className="border flex-grow rounded-sm p-1"
									placeholder="Search"
									value={campaignSearch}
									onChange={(e) => setCampaignSearch(e.target.value)}
								/>
								<button
									disabled={!campaignSearch || campaignSearching}
									className="px-2 py-1 rounded-sm bg-indigo-500 disabled:bg-indigo-100 ml-2"
								>
									🔍
								</button>
							</form>
						</div>
					</CardHeader>
					<Box height={"calc(100vh - 230px)"} overflow={"auto"}>
						{(campaigns.data?.length || 0) <= 0 ? (
							<Box
								display={"flex"}
								flexDirection={"column"}
								justifyContent="center"
								alignItems={"center"}
								textAlign={"center"}
							>
								<Box component={"p"} m={3} className="placeholder">
									{campaignSearching ? "Loading..." : "Search by user name or email or campaign name"}
								</Box>
							</Box>
						) : (
							<List className={"ul-reset"} component={"ul"}>
								{campaigns.data?.map((campaign) => {
									return (
										<ListItem
											key={`campaign_id_${campaign.id}`}
											component={"li"}
											button
											selected={selectedCampaign?.id === campaign.id}
											onClick={() => {
												const _campaign = { ...campaign };
												_campaign.matchingParameter = {
													description: _campaign.description,
													..._campaign.matchingParameter,
												};
												setSelectedCampaign(_campaign);
											}}
										>
											<Box display={"flex"} flexDirection="column" width={"100%"}>
												<div className="font-bold">
													{campaign.name} #{campaign.id}
												</div>
												<p className="text-slate-400">
													{[campaign.creator.firstName, campaign.creator.lastName].join(" ")} - {campaign.creator.email}
												</p>
											</Box>
										</ListItem>
									);
								})}
							</List>
						)}
					</Box>
				</Card>
			</Grid>
		</Grid>
	);
}
