import { Box, Grid, IconButton } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import DeleteForever from "@material-ui/icons/DeleteForever";
import { QUESTION_TYPES } from "src/helpers/constants";
import { Answers } from "./Answers";
export function ComboAnswer({ question }) {
	return (
		<>
			<Grid item xs={12}>
				<Grid container>
					<Grid item xs={10}>
						<Grid container spacing={4}>
							{(question.meta?.comboFields || []).map((field, comboIdx) => (
								<Grid key={`combo-field-ans-${field.name}-${comboIdx}`} item xs={4}>
									<Answers
										question={{
											type: ![QUESTION_TYPES.AUTOCOMPLETE].includes(field.type) ? field.type : QUESTION_TYPES.TEXT,
											meta: { placeholder: field.placeholder },
										}}
									/>
								</Grid>
							))}
						</Grid>
					</Grid>
					{question.meta.multiple && (
						<Grid item xs={2}>
							<Box display={"flex"} mt={0} minWidth={"40px"} alignItems="flex-start">
								<IconButton variant="contained" color="primary" size="small" disabled>
									<Add size="small" />
								</IconButton>
								<IconButton variant="contained" color="secondary" size="small" disabled>
									<DeleteForever size="small" />
								</IconButton>
							</Box>
						</Grid>
					)}
				</Grid>
			</Grid>
		</>
	);
}
