import { QUESTION_TYPES, ROLES } from "./constants";

export function getFunctionalRoles(roles) {
	return roles.filter((role) => {
		return [ROLES.FOUNDER, ROLES.FUNDER, ROLES.VENDOR].includes(role);
	}, []);
}

const questionTypeDisplayName = {
	[QUESTION_TYPES.SELECT]: "DROPDOWN",
	[QUESTION_TYPES.FREE_TEXT]: "PARAGRAPH",
	[QUESTION_TYPES.TEXT]: "SINGLE LINE TEXT",
};
export function getTypeDisplayName(type) {
	return (questionTypeDisplayName[type] || type).replace(/_/g, " ");
}

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
	return item && typeof item === "object" && !Array.isArray(item);
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target, ...sources) {
	if (!sources.length) return target;
	const source = sources.shift();

	if (isObject(target) && isObject(source)) {
		for (const key in source) {
			if (isObject(source[key])) {
				if (!target[key]) Object.assign(target, { [key]: {} });
				mergeDeep(target[key], source[key]);
			} else {
				Object.assign(target, { [key]: source[key] });
			}
		}
	}

	return mergeDeep(target, ...sources);
}

export function readable(str) {
	return str.replace(/([a-z])([A-Z])/g, "$1 $2").replace(/([A-Z]+)(?=[A-Z][a-z]|$)/g, "$1 ");
}

export function getMatchId(id) {
	return id.toString().padStart(3, "0");
}

export function fillTemplate(str, vars) {
  return str.replace(/\{(.*?)\}/g, (_, key) => vars[key] || `${key}`);
}