import { removeItem } from "../storage/local";
function handleNoConnection() {}
export function fetchWrapper(baseURI, { onBeforeStart = () => {}, onEnd = () => {} } = {}) {
	return async function fetch(
		endpoint,
		{ method = "GET", data, headers: customHeaders = {}, formData, ...customConfig } = { method: "GET", headers: {} },
		{ returnWholeResponse = false, returnWholeError = false, signal } = {
			returnWholeResponse: false,
			returnWholeError: false,
			signal: null,
		}
	) {
		try {
			onBeforeStart();
			const config = {
				method: method,
				headers: {
					// Authorization: token ? `Bearer ${token}` : undefined,
					"Content-Type": data ? "application/json" : undefined,
					...customHeaders,
				},
				credentials: "include",
				...customConfig,
			};
			if (signal) {
				config.signal = signal;
			}

			let url = `${baseURI}${endpoint}`;
			if (method !== "GET") {
				config.body = formData ? formData : data ? JSON.stringify(data) : undefined;
			}
			if (method === "GET" && data) {
				const _url = new URL(url);
				Object.keys(data).forEach((key) => {
					const values = Array.isArray(data[key]) ? data[key] : [data[key]];
					values.forEach((item) => {
						_url.searchParams.append(key, item);
					});
				});
				url = _url.toString();
			}

			if (formData) delete config.headers["Content-Type"];

			const response = await window.fetch(url, config);
			if (response.status === 0) {
				onEnd();
				return handleNoConnection();
			}
			if (response.status === 401) {
				onEnd();
				removeItem("user");
				window.history.pushState({}, "", "/login");
			}
			if (response.ok && (response.headers.get("content-disposition") || "").includes("attachment")) {
				onEnd();
				return await response.blob();
			}
			if (response.status === 202) {
				return void 0;
			}
			const responseBody = await response.json();
			if (!response.ok) {
				onEnd();
				return Promise.reject(
					returnWholeError ? responseBody : responseBody.data ? responseBody.data.message : responseBody.message
				);
			}
			onEnd();
			return returnWholeResponse ? responseBody : responseBody.data || responseBody;
		} catch (error) {
			console.error(`Error while fetching ${endpoint}`, error);
			onEnd();
			return Promise.reject(error);
		}
	};
}
