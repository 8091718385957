import { fetchV1 } from "../lib/requester/v1";

export function getAll(params, options) {
	return fetchV1("/user_answers", { method: "GET", data: params }, options);
}

export function bulkUpsert(data) {
	return fetchV1("/user_answers/bulk", { method: "POST", data: { bulk: data } });
}

export function getPresignedUrlToUpload(params) {
	return fetchV1("/user_answers/presigned_url", { method: "GET", data: params });
}
