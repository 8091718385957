import { Box, Chip, makeStyles, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useMemo, useState } from "react";
import getByPath from "src/helpers/getByPath";
import setByPath from "src/helpers/setByPath";

const useStyles = makeStyles(() => {
	return {
		answers: {
			maxWidth: "100%",
			overflow: "auto",
			paddingBottom: "1rem",
			"& .MuiInputBase-root": {
				borderRadius: 30,
			},
			"& .MuiFormLabel-root": {
				width: "80%",
				textOverflow: "ellipsis",
				overflow: "hidden",
				whiteSpace: "nowrap",
				"&.MuiFormLabel-focused": {
					width: "100%",
				},
			},
			"& li ul": {
				marginLeft: "2rem",
			},
			"& ul": {
				listStyle: "none",
			},
			"& ul li": {
				position: "relative",
				paddingLeft: "1rem",
				"&.autocomplete": {
					paddingBottom: "0.5rem",
				},
				"&:before, &:after": {
					content: "''",
					position: "absolute",
					background: "#ccc",
				},
				"&:before": {
					top: 0,
					left: 2,
					height: "100%",
					width: 2,
				},
				"&:after": {
					top: 15,
					left: 2,
					width: 14,
					height: 2,
				},
				"&:first-child:before": {
					top: 0,
				},
				"&:first-child:after": {
					top: 35,
				},
				"&:last-child:before": {
					height: 15,
				},
				"&:only-child:before": {
					height: 35,
				},
			},
			"& li:not(:last-child)": {
				paddingBottom: "1rem",
			},
		},
	};
});

function RenderList({ child, level, list, userAnswer, path, onChange, name }) {
	const [value, setValue] = useState(null);
	return (
		<ul>
			{list.length > 0 && (
				<Box component={"li"} className="autocomplete" width={300}>
					<Autocomplete
						options={list}
						getOptionLabel={(option) => option.name || ""}
						getOptionDisabled={(option) => !!child?.children?.find((l) => l.name === option.name)}
						openOnFocus
						size="small"
						onChange={(event, newValue) => {
							if (!newValue) return;
							const _answers = [...userAnswer];
							const children = getByPath(_answers, [...path, "children"]);
							if (!children) {
								setByPath(_answers, [...path, "children"], []);
							}
							setByPath(_answers, [...path, "children", children ? children.length : 0], {
								name: newValue.name,
								children: [],
							});
							onChange(undefined, _answers, false);
							setValue(null);
						}}
						value={value}
						renderInput={(params) => (
							<TextField
								size="small"
								variant="outlined"
								{...params}
								label={`Sub-category of ${child.name || name}`}
								margin="normal"
							/>
						)}
					/>
				</Box>
			)}
			{(child?.children || []).map((item, idx) => {
				const children = (list.find((l) => l.name === item.name) || {}).children || [];
				return (
					<li key={`ques_answer_mlc_l${level}_${idx}`}>
						<Chip
							color="primary"
							onDelete={() => {
								const _answers = [...userAnswer];
								const children = getByPath(_answers, [...path, "children"]);
								children.splice(idx, 1);
								onChange(undefined, _answers, true);
							}}
							label={item.name}
						/>
						<RenderList
							child={item}
							level={level + 1}
							list={children}
							userAnswer={userAnswer}
							path={[...path, "children", idx]}
							onChange={onChange}
						/>
					</li>
				);
			})}
		</ul>
	);
}

export function MultiLevelChoice({ question, questionIndex, answers = [], userAnswerObject = {}, onChange }) {
	const classes = useStyles();
	const [value, setValue] = useState(null);
	const idAnswerMap = useMemo(() => {
		return answers.reduce((acc, item) => {
			acc[item.id] = item;
			acc[item.name] = item.id;
			return acc;
		}, {});
	}, [answers]);
	return (
		<Box className={classes.answers}>
			<ul>
				<Box component={"li"} className="autocomplete" width={400} maxWidth={"100%"}>
					<Autocomplete
						options={answers}
						size="small"
						className={classes.autoCompleteInput}
						getOptionLabel={(option) => option.answer || ""}
						getOptionDisabled={(option) => (userAnswerObject.answerIds || []).includes(option.id)}
						openOnFocus
						onChange={(event, newValue) => {
							if (newValue) {
								onChange(newValue, undefined, false);
								setValue(null);
							}
						}}
						value={value}
						renderInput={(params) => (
							<TextField
								variant="outlined"
								{...params}
								value={value}
								label={question.meta.placeholder || "Answer"}
								margin="normal"
							/>
						)}
					/>
				</Box>
				{(userAnswerObject.answerIds || []).map((id, idx) => {
					const answer = idAnswerMap[id + ""];
					return (
						<li key={`ques_${questionIndex}_answer_${idx}_mlc`}>
							<Chip
								color="primary"
								onDelete={() => {
									const _answers = [...(userAnswerObject.answer || [])];
									_answers.splice(idx, 1);
									onChange(answer, _answers, true);
								}}
								label={answer.answer}
							/>
							<RenderList
								child={(userAnswerObject.answer || [])[idx] || [{ name: answer.answer }]}
								level={2}
								list={answer.meta?.children || []}
								userAnswer={userAnswerObject.answer || []}
								path={[idx]}
								onChange={onChange}
								name={answer.answer}
							/>
						</li>
					);
				})}
			</ul>
		</Box>
	);
}
