import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import { Alert } from "@material-ui/lab";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

export function AttributeExtraction({ question, onChange, idx, errors = {} }) {
	return (
		<>
			<div className="mb-1 text-lg ml-2">
				Data Extraction Attributes{" "}
				{errors.attributes && (
					<Box>
						<Alert severity="error">{errors.attributes}</Alert>
					</Box>
				)}
			</div>
			<div className="mb-1 text-lg ml-2">
				<Grid container spacing={0}>
					<Grid item xs={12} className="flex gap-4">
						<FormControlLabel
							control={
								<Checkbox
									checked={!!question.meta.leanCanvas}
									onChange={(e) => {
										const _question = { ...question };
										if (e.target.checked) {
											_question.meta.leanCanvas = {};
										} else {
											delete _question.meta.leanCanvas;
										}
										onChange(_question, idx);
									}}
								/>
							}
							label="Lean Canvas"
						/>
						{question.meta.leanCanvas && (
							<TextField
								variant="outlined"
								value={question.meta.leanCanvas?.property || ""}
								label="Property Name"
								required
								size={"small"}
								onChange={(e) => {
									const _question = { ...question };
									_question.meta.leanCanvas.property = e.target.value;
									onChange(_question, idx);
								}}
							/>
						)}
					</Grid>
					<Grid item xs={12} className="flex gap-4">
						<FormControlLabel
							control={
								<Checkbox
									checked={!!question.meta.dashboardSummary}
									onChange={(e) => {
										const _question = { ...question };
										if (e.target.checked) {
											_question.meta.dashboardSummary = {};
										} else {
											delete _question.meta.dashboardSummary;
										}
										onChange(_question, idx);
									}}
								/>
							}
							label="Show in Dashboard Summary"
						/>
						{question.meta.dashboardSummary && (
							<TextField
								variant="outlined"
								value={question.meta.dashboardSummary?.property || ""}
								label="Property Name"
								required
								size={"small"}
								onChange={(e) => {
									const _question = { ...question };
									_question.meta.dashboardSummary.property = e.target.value;
									onChange(_question, idx);
								}}
							/>
						)}
					</Grid>
					<Grid item xs={12} className="flex gap-4">
						<FormControlLabel
							control={
								<Checkbox
									checked={!!question.meta.businessCanvas}
									onChange={(e) => {
										const _question = { ...question };
										if (e.target.checked) {
											_question.meta.businessCanvas = {};
										} else {
											delete _question.meta.businessCanvas;
										}
										onChange(_question, idx);
									}}
								/>
							}
							label="Business Canvas"
						/>
						{question.meta.businessCanvas && (
							<TextField
								variant="outlined"
								value={question.meta.businessCanvas?.property || ""}
								label="Property Name"
								required
								size={"small"}
								onChange={(e) => {
									const _question = { ...question };
									_question.meta.businessCanvas.property = e.target.value;
									onChange(_question, idx);
								}}
							/>
						)}
					</Grid>
				</Grid>
			</div>
		</>
	);
}
