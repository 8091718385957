import React from "react";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/styles/makeStyles";
import CmtImage from "src/@coremat/CmtImage";
import { useSelector } from "react-redux";
// const bgImage = "/images/auth/auth-bg-pattern.png";

const useStyles = makeStyles((theme) => ({
	authWrap: {
		width: "100%",
		height: "100%",
		minHeight: "630px",
		display: "flex",
		flexDirection: "column",
		// justifyContent: "center",
		alignItems: "center",
		overflow: "auto",
		padding: "1rem",
		// backgroundImage: props => (props.variant === 'bgColor' ? `URL(${bgImage})` : ''),
		// backgroundPosition: props => (props.variant === 'bgColor' ? 'center center' : ''),
		// backgroundRepeat: props => (props.variant === 'bgColor' ? 'no-repeat' : ''),
		// backgroundSize: props => (props.variant === 'bgColor' ? 'cover' : ''),
		// position: 'relative',
		// '&:before': {
		//   content: '""',
		//   position: 'absolute',
		//   left: 0,
		//   top: 0,
		//   zIndex: 1,
		//   width: '100%',
		//   height: '100%',
		//   backgroundColor: props => (props.variant === 'bgColor' ? alpha(theme.palette.primary.main, 0.5) : ''),
		// },
	},
	authCard: {
		position: "relative",
		zIndex: 3,
		maxWidth: (props) => (props.variant === "default" ? "850px" : "420px"),
		width: "92%",
		marginTop: (props) => (props.screen === "signup" ? "3rem" : "0"),
		top: "50%",
		transform: "translateY(-50%)",
		borderRadius: 12,
		boxShadow: "rgba(115, 82, 199, 0.176) 0px 0.5rem 1.25rem",
		backgroundColor: theme.palette.background.paper,
		color: theme.palette.text.secondary,
		display: "flex",
		flexDirection: "column",
		[theme.breakpoints.up("xl")]: {
			maxWidth: (props) => (props.variant === "default" ? "1050px" : "750px"),
		},
	},
	authContent: {
		padding: 16,
		[theme.breakpoints.up("md")]: {
			width: (props) => (props.variant === "default" ? "50%" : "100%"),
			order: 1,
		},
		[theme.breakpoints.up("xl")]: {
			padding: 50,
		},
	},
	logo: {
		width: "200px",
		margin: "1rem auto 0 auto",
	},
}));

const AuthWrapper = ({ children, variant, screen }) => {
	const classes = useStyles({ variant, screen });
	const { tenant } = useSelector(({ auth }) => auth);
	return (
		<Box className={classes.authWrap}>
			<Box className={classes.authCard}>
				<CmtImage
					className={classes.logo}
					alt="InvestorMatch Logo"
					height="100px"
					src={(tenant.logo || {})["2x"]?.path || "/images/logo-v1.png"}
				/>
				<Box className={classes.authContent}>{children}</Box>
			</Box>
		</Box>
	);
};

export default AuthWrapper;
