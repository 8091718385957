import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Stepper from "@material-ui/core/Stepper";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/styles/makeStyles";
import withStyles from "@material-ui/styles/withStyles";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import ArrowRight from "@material-ui/icons/ArrowRight";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useContext } from "react";
import { Context } from "./Context";
import { QuestionGroups } from "./QuestionGroups";

const BorderLinearProgress = withStyles((theme) => ({
	root: {
		width: "165px",
		height: 10,
		borderRadius: 5,
	},
	colorPrimary: {
		backgroundColor: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
	},
	bar: {
		borderRadius: 5,
		backgroundColor: "#4caf50",
	},
}))(LinearProgress);

const useStyles = makeStyles(() => ({
	section: {
		overflowX: "auto",
	},
	stepper: {
		padding: "1rem 0 2rem 0",
		"& .MuiStep-root": {
			minWidth: "130px",
		},
	},
	description: {
		whiteSpace: "pre-line",
	},
	questionRange: {
		color: "#bbbcc2",
		fontWeight: 900,
	},
}));
//TODO: Scroll reveal https://github.com/terwanerik/ScrollTrigger
export function Details({ showConfirm, saveBtn = null }) {
	const classes = useStyles();
	const { errors, steps, isPreparing, currentStep, validate, validateStep, setCurrentStep, canDisplayStep } =
		useContext(Context);
	if (isPreparing) {
		return (
			<Box component={"section"} height="100%" textAlign={"center"}>
				Loading...
			</Box>
		);
	}
	if (steps.length <= 0) {
		return (
			<Box component={"section"} height="100%" textAlign={"center"}>
				No questions found
			</Box>
		);
	}

	function hasReachedLastStep() {
		if (steps.length - 1 === currentStep) {
			return true;
		}
		let flag = false;
		for (let i = currentStep+1; i < steps.length; i++) {
			flag = !canDisplayStep(i);
			if(flag === false) {
				break;
			}
		}
		return flag;
	}

	const isNextBtnDisabled = hasReachedLastStep()

	return (
		<>
			{steps.length > 1 && (
				<Box component={"section"} className={classes.section}>
					<Stepper nonLinear activeStep={currentStep} alternativeLabel className={classes.stepper}>
						{steps.map(({ group }, idx) => {
							if (!canDisplayStep(idx)) {
								return null;
							}
							return (
								<Step key={`step_${idx}`} completed={false}>
									<StepButton color="inherit" onClick={() => setCurrentStep(idx)}>
										{/* <Typography variant="caption" className={classes.questionRange}>
										{Math.floor(questions.length * 0.6)} - {questions.length} Questions
									</Typography> */}
										<Typography
											variant="subtitle1"
											color={Object.keys(errors[`step${idx}`] || {}).length > 0 ? "error" : "inherit"}
										>
											{group}
										</Typography>
									</StepButton>
								</Step>
							);
						})}
					</Stepper>
				</Box>
			)}

			{steps[currentStep] && (
				<>
					{steps.length > 1 && (
						<>
							{steps[currentStep].questions[0].meta.groupDescription && (
								<Box marginBottom={4} className={"bg-slate-200 -mx-4  px-4 py-2"}>
									<Typography component={"h3"} variant={"body1"} color="textSecondary" className={classes.description}>
										{steps[currentStep].questions[0].meta.groupDescription}
									</Typography>
								</Box>
							)}
							<Box display="flex" justifyContent="space-between" mt={"auto"} paddingBottom={4}>
								<Button
									variant="text"
									color="primary"
									disabled={currentStep === 0}
									onClick={() => {
										setCurrentStep(currentStep - 1);
										window.scrollTo(0, 0);
									}}
									startIcon={<ArrowLeft />}
								>
									Prev
								</Button>
								<Button
									variant="text"
									color="primary"
									disabled={isNextBtnDisabled}
									onClick={() => {
										if (validateStep(currentStep).isValid) {
											setCurrentStep(currentStep + 1);
											window.scrollTo(0, 0);
										}
									}}
									endIcon={<ArrowRight />}
								>
									Next
								</Button>
							</Box>
						</>
					)}
					<QuestionGroups questions={steps[currentStep].questions} />
				</>
			)}
			{steps.length > 1 && (
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems={"center"}
					margin={"8px -16px -4px -16px"}
					zIndex={5}
					borderTop={"1px solid rgb(239 232 251)"}
					padding={2}
					position={"sticky"}
					bottom={0}
					bgcolor={"white"}
					borderRadius={"0 0 12px 12px"}
				>
					<Button
						variant="text"
						color="primary"
						disabled={currentStep === 0}
						onClick={() => {
							setCurrentStep(currentStep - 1);
							window.scrollTo(0, 0);
						}}
						startIcon={<ArrowLeft />}
					>
						Prev
					</Button>
					<Box
						display="flex"
						flexDirection={"column"}
						alignItems={"center"}
						textAlign={"center"}
						flex={1}
						minWidth={0}
						px={2}
					>
						<BorderLinearProgress variant="determinate" value={(currentStep / steps.length) * 100} />
						<Box color={"#999"} overflow={"hidden"} textOverflow={"ellipsis"} whiteSpace={"nowrap"} width={"100%"}>{`${
							currentStep + 1
						}.${steps[currentStep].group}`}</Box>
					</Box>
					{isNextBtnDisabled ? (
						saveBtn ? (
							saveBtn
						) : (
							<Button
								color="secondary"
								variant="contained"
								size="small"
								onClick={() => {
									const { isValid } = validate();
									if (isValid) {
										showConfirm(true);
									}
								}}
							>
								🚀 Launch
							</Button>
						)
					) : (
						<Button
							variant="text"
							color="primary"
							disabled={isNextBtnDisabled}
							onClick={() => {
								if (validateStep(currentStep).isValid) {
									setCurrentStep(currentStep + 1);
									window.scrollTo(0, 0);
								}
							}}
							endIcon={<ArrowRight />}
						>
							Next
						</Button>
					)}
				</Box>
			)}
		</>
	);
}
