import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ROLES } from "src/helpers/constants";
import { getOpportunityWithMatches } from "src/api/opportunities";
import { Async } from "src/components/commons/Async";
import { Matches } from "src/components/opportunities/Matches";

export function OpportunityMatches() {
	const { id } = useParams();
	const { currentRole } = useSelector(({ auth }) => auth);
	if (currentRole !== ROLES.FUNDER) {
		throw new Error("Not a funder");
	}
	return (
		<div>
			<Async promise={getOpportunityWithMatches(id)}>
				{([opportunity]) => {
					return (
						<div>
							<h2 className="text-xl mb-4">Matches for {opportunity.name}</h2>
							<Matches opportunity={opportunity} currentRole={currentRole} />
						</div>
					);
				}}
			</Async>
		</div>
	);
}
