import { Grid, Typography } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useContext } from "react";
import snarkdown from "snarkdown";
import Popover from "src/components/commons/Popover";

import { Answers } from "./Answers";
import { Context } from "./Context";

const useStyles = makeStyles(() => {
	return {
		question: {
			whiteSpace: "pre-line",
			"& a": {
				background: "#3f51b5",
				color: "white",
				borderRadius: "4px",
				padding: "0 0.2rem",
			},
		},
	};
});

export function Question({ question, idx }) {
	const classes = useStyles();
	const { errors, currentStep } = useContext(Context);
	const hasError = (errors[`step${currentStep}`] || {})[`question${question.id}`];
	return (
		<Grid key={`question_${idx}`} container spacing={2}>
			<Grid item xs={12}>
				<Typography
					id={`question-title-${question.id}`}
					data-field={question.meta.field}
					color={hasError ? "error" : "inherit"}
					component={"h2"}
					variant={"h6"}
					className={classes.question}
				>
					<span className="&>a[]" dangerouslySetInnerHTML={{ __html: snarkdown(question.question) }} />{" "}
					{question.meta.optional ? "" : <span style={{ color: "red" }}>*</span>}{" "}
					{question.description && <Popover content={question.description} />}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Answers questionIndex={idx} question={question} />
			</Grid>
		</Grid>
	);
}
