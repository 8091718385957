// import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useCallback, useEffect, useState } from "react";
import { getAll, remove, updateAsAdmin } from "src/api/auth";
import { getAll as getAllTenants, update as tenantUpdate } from "src/api/tenants";
import { debounce } from "src/helpers/debounce";
import IconButton from "@material-ui/core/IconButton";
import { Close } from "@material-ui/icons";
import Alert from "src/helpers/alert";
import { LoginAs } from "src/components/admin/LoginAs";
import { ResetPassword } from "src/components/admin/ResetPassword";
import { ROLES } from "src/helpers/constants";
import { MarkAsTenantAdmin } from "src/components/admin/MarkAsTenantAdminForm";
import { CreateUser } from "src/components/admin/CreateUser";
import { Modal } from "@material-ui/core";

function Row({ row, onDelete, onUpdate }) {
	const [deleting, setDeleting] = useState(false);
	const [markingTenantAdmin, setMarkingTenantAdmin] = useState(false);
	const [data] = useState(() => ({ ...row }));

	async function deleteRow() {
		try {
			setDeleting(true);
			await remove(data);
			onDelete();
		} catch (err) {
			console.error("Unable to delete user", err);
			Alert.error("Unable to delete");
		} finally {
			setDeleting(false);
		}
	}

	async function markAsTenantAdmin() {
		onUpdate(data, true);
	}

	async function revokeAsTenantAdmin() {
		try {
			setMarkingTenantAdmin(true);
			const roles = [...row.roles];
			const index = roles.findIndex((i) => i === ROLES.TENANT_ADMIN);
			if (index >= 0) {
				roles.splice(index, 1);
				const user = await updateAsAdmin({ ...row, roles });
				const tenant = await getAllTenants({ filter: `userId||$eq||${user.id}` });
				const _tenant = tenant.data[0];
				await tenantUpdate({ id: _tenant.id, userId: null });
				onUpdate(user);
			}
			Alert.success("Revoked privilege");
		} catch (err) {
			console.error("Unable to revoke user as tenant admin", err);
			Alert.error("Unable to revoke user privilege");
		} finally {
			setMarkingTenantAdmin(false);
		}
	}

	return (
		<TableRow key={data.id}>
			<TableCell className="pl-4">{data.firstName}</TableCell>
			<TableCell>{data.lastName}</TableCell>
			<TableCell>{data.email}</TableCell>
			<TableCell>{data.isEmailVerified ? "Yes" : "No"}</TableCell>
			<TableCell>{data.isBlocked ? "Yes" : "No"}</TableCell>
			<TableCell>
				<div className="flex gap-2">
					<Button variant="contained" color="secondary" disabled={deleting} size="small" onClick={deleteRow}>
						{deleting ? "Deleting..." : "Delete"}
					</Button>
					{row.roles.includes(ROLES.TENANT_ADMIN) ? (
						<Button
							variant="contained"
							color="secondary"
							disabled={markingTenantAdmin}
							size="small"
							onClick={revokeAsTenantAdmin}
						>
							{markingTenantAdmin ? "Revoking..." : "Revoke WL Admin"}
						</Button>
					) : (
						<Button
							variant="contained"
							color="secondary"
							disabled={markingTenantAdmin}
							size="small"
							onClick={markAsTenantAdmin}
						>
							{markingTenantAdmin ? "Making..." : "Make WL Admin"}
						</Button>
					)}
				</div>
			</TableCell>
		</TableRow>
	);
}

export function Users() {
	const [rows, setRows] = useState([]);
	const [loading, setLoading] = useState(false);
	const [total, setTotal] = useState(-1);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(20);
	const [search, setSearch] = useState("");
	const [deferredSearch, setDeferredSearch] = useState("");
	const [error, setError] = useState(null);
	const [tenants, setTenants] = useState([]);
	const [openFormModal, setOpenFormModal] = useState(false);
	const [createUserModal, setCreateUserModal] = useState(false);
	const [currentUser, setCurrentUser] = useState(null);

	const updateSearch = useCallback(
		debounce(function _updateSearch(search) {
			setDeferredSearch(search);
		}, 500),
		[]
	);
	useEffect(() => {
		(async () => {
			try {
				const tenants = await getAllTenants();
				setTenants(tenants.data.reverse());
			} catch (e) {
				console.error("Unable to fetch tenants");
				Alert.error("Unable to fetch tenants");
			}
		})();
	}, []);
	useEffect(() => {
		updateSearch(search);
	}, [search]);
	useEffect(() => {
		const options = { limit: perPage, page: currentPage };
		if (search) {
			options.s = JSON.stringify({
				$or: [{ firstName: { $contL: search } }, { firstName: { $contL: search } }, { email: { $contL: search } }],
			});
		}
		(async () => {
			try {
				setError(null);
				setLoading(true);
				const res = await getAll(options);
				setRows(res.data);
				setTotal(res.total);
			} catch (err) {
				console.error("Unable to fetch Users", err);
				setError("Unable to load");
			} finally {
				setLoading(false);
			}
		})();
	}, [currentPage, deferredSearch, perPage]);
	return (
		<div>
			<ResetPassword tenants={tenants} />
			<LoginAs tenants={tenants} />
			<MarkAsTenantAdmin
				tenants={tenants}
				currentUser={currentUser}
				openFormModal={openFormModal}
				setOpenFormModal={setOpenFormModal}
				onSubmit={async (tenantId) => {
					try {
						const [user] = await Promise.all([
							updateAsAdmin({ ...currentUser, roles: [...currentUser.roles, ROLES.TENANT_ADMIN] }),
							tenantUpdate({ id: tenantId, userId: currentUser.id }),
						]);
						const index = rows.findIndex((r) => r.id === user.id);
						if (index >= 0) {
							const _rows = [...rows];
							_rows[index] = user;
							setRows(_rows);
						}

						Alert.success("User marked as tenant admin");
					} catch (err) {
						console.error("Unable to mark user as tenant admin", err);
						Alert.error("Unable to mark user as tenant admin");
					} finally {
						setOpenFormModal(false);
					}
				}}
			/>
			{createUserModal && (
				<Modal open={true} onClose={() => setCreateUserModal(false)} >
					<CreateUser tenants={tenants} open={createUserModal} onClose={() => setCreateUserModal(false)} />
				</Modal>
			)}
			<TableContainer className="mt-4" component={Paper}>
				<Box className="flex justify-between items-center p-4">
					<Box className="text-xl">
						Users{" "}
						<Button variant="contained" color="primary" size="small" onClick={() => setCreateUserModal(true)}>
							+ Create
						</Button>
					</Box>
					<Box>
						{loading && "Loading..."}
						{error && <Box className="text-red-600">{error}</Box>}
					</Box>
					<Box>
						<TextField
							fullWidth
							variant="outlined"
							label="Search name"
							type="text"
							size="small"
							value={search}
							onChange={(e) => {
								setSearch(e.target.value);
							}}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											onClick={() => {
												setSearch("");
											}}
										>
											<Close />
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</Box>
				</Box>
				<Table aria-label="Users table">
					<TableHead>
						<TableRow>
							<TableCell>First Name</TableCell>
							<TableCell>Last Name</TableCell>
							<TableCell>Email Address</TableCell>
							<TableCell>Email Verified?</TableCell>
							<TableCell>Blocked?</TableCell>
							<TableCell style={{ width: 200 }}>Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{error && (
							<TableRow>
								<TableCell colSpan={3}>{error}</TableCell>
							</TableRow>
						)}
						{rows.length <= 0 && (
							<TableRow>
								<TableCell colSpan={3}>{loading ? "Loading..." : "No data"}</TableCell>
							</TableRow>
						)}
						{rows.length > 0 &&
							rows.map((row, idx) => (
								<Row
									key={`row-${row.id || idx}`}
									row={row}
									onDelete={() => {
										const _rows = [...rows];
										_rows.splice(idx, 1);
										setRows(_rows);
									}}
									onUpdate={(data, isMark) => {
										setCurrentUser(data);
										if (isMark) {
											setOpenFormModal(true);
										}
									}}
								/>
							))}
					</TableBody>
					<TableFooter>
						<TableRow>
							<TableCell>
								{/* <Button
									variant="contained"
									color="primary"
									onClick={() => {
										const _rows = [...rows];
										_rows.push({ name: "", synonyms: "" });
										setRows(_rows);
									}}
								>
									+ Add Industry
								</Button> */}
							</TableCell>
							<TablePagination
								rowsPerPageOptions={[20, 50, 100]}
								count={total}
								rowsPerPage={perPage}
								page={currentPage - 1}
								SelectProps={{
									inputProps: { "aria-label": "rows per page" },
									native: true,
								}}
								onPageChange={(e, newPage) => {
									setCurrentPage(newPage + 1);
								}}
								onRowsPerPageChange={(e) => {
									setPerPage(parseInt(e.target.value, 10));
									setCurrentPage(1);
								}}
								// ActionsComponent={TablePaginationActions}
							/>
						</TableRow>
					</TableFooter>
				</Table>
			</TableContainer>
		</div>
	);
}
