import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import Edit from "@material-ui/icons/Edit";
import clsx from "clsx";
import { Link } from "react-router-dom";

const statusMap = { IN_PROGRESS: "In Progress", ACTIVE: "Active", INACTIVE: "Inactive" };
const statusColorMap = { IN_PROGRESS: "warning", ACTIVE: "active", INACTIVE: "inactive" };

export function CampaignListItem({ classes, campaign, onEdit }) {
	return (
		<ListItem
			className={classes.listItem}
			button
			component={"li"}
			key={`campaign-list-${campaign.id}`}
			alignItems="flex-start"
		>
			<Box display={"flex"} width="100%" flexWrap={"wrap"} component={Link} to={`/campaign/${campaign.id}/details`}>
				<Avatar
					className={clsx([classes.avatar, "listAvatar"])}
					variant={"rounded"}
					alt={campaign.name}
					src={"/images/icons/campaign.svg"}
				/>
				<Box display={"flex"} flex="1" flexDirection={"column"} ml={4} alignItems={"flex-start"} width={"100%"}>
					<Typography component={"h3"} variant="h6" color="textPrimary">
						{campaign.name}{" "}
						<Chip
							size="small"
							label={statusMap[campaign.status]}
							className={`${classes.chip} ${statusColorMap[campaign.status]}`}
						/>
					</Typography>
					<Typography component={"p"} color="textSecondary">
						{campaign.description}
					</Typography>
				</Box>
				<Box display={"flex"} ml={4} mt={4} width="100%" alignItems={"end"} justifyContent={"flex-end"}>
					<Button
						startIcon={<Edit />}
						style={{ marginRight: "0.5rem" }}
						color="primary"
						size="small"
						variant="contained"
						onClick={(e) => {
							e.preventDefault();
							onEdit(campaign);
						}}
					>
						Edit
					</Button>
					<Button
						startIcon={<Edit />}
						style={{ marginRight: "0.5rem" }}
						color="primary"
						size="small"
						variant="contained"
					>
						Fill
					</Button>
					{/* <Button
						startIcon={<KeyboardArrowRightIcon />}
						color="secondary"
						size="small"
						variant="contained"
						disabled={isLaunching}
						onClick={async (e) => {
							e.preventDefault();
							setLaunching(true);
							await onLaunch(campaign);
							setLaunching(false);
						}}
					>
						Launch
					</Button> */}
				</Box>
			</Box>
		</ListItem>
	);
}
