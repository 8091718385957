export function FounderProfileDisplay({ userDetails }) {
	return (
		<div className="p-4 flex flex-col gap-4">
			{userDetails.details.picture && (
				<div>
					<img
						className="rounded-md"
						src={userDetails.details.picture}
						width={300}
						height={300}
						alt="details picture"
					/>
				</div>
			)}
			<div className="flex gap-4">
				<div>Name: </div>
				<div className="font-extrabold">{userDetails.displayName}</div>
			</div>
			<div className="flex gap-4">
				<div>Email: </div>
				<a className="font-extrabold underline" href={"mailto:" + userDetails.email}>
					{userDetails.email}
				</a>
			</div>
			<div className="flex gap-4">
				<div>Position: </div>
				<div className="font-extrabold">{(userDetails.details.positions || userDetails.positions)?.join(", ")}</div>
			</div>
			<div className="flex gap-4">
				<div>Address: </div>
				<div className="font-extrabold">
					{[
						userDetails.details.address,
						userDetails.details.city,
						userDetails.details.country || userDetails.details.locatedCountry,
					].join(", ")}
				</div>
			</div>
			<div className="flex gap-4">
				<div>LinkedIn URL: </div>
				<a href={userDetails.details.linkedinUrl} target="_blank" rel="noreferrer" className="font-extrabold underline">
					{userDetails.details.linkedinUrl}
				</a>
			</div>
			<div className="flex gap-4">
				<div>Degree: </div>
				<div className="font-extrabold">{userDetails.details.degree || userDetails.details.academicDegreeCategory}</div>
			</div>
			<div className="flex gap-4">
				<div>Expertise: </div>
				<div className="font-extrabold">{userDetails.details.expertise}</div>
			</div>
			<div className="flex gap-4">
				<div>Had Prior Exits?: </div>
				<div className="font-extrabold">{userDetails.details.hadExit}</div>
			</div>
			<div className="flex gap-4">
				<div>Last Employment Duration: </div>
				<div className="font-extrabold">{userDetails.details.lastEmploymentDuration}</div>
			</div>
			<div className="flex gap-4">
				<div>Remuneration Type: </div>
				<div className="font-extrabold">{userDetails.details.remuneration}</div>
			</div>
			<div className="flex gap-4">
				<div>Time Commitment: </div>
				<div className="font-extrabold">{userDetails.details.timeCommitment}</div>
			</div>
		</div>
	);
}
