import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Grid from "@material-ui/core/Grid";
// import makeStyles from "@material-ui/core/styles/makeStyles";

import { defer, Await, useLoaderData, Link, useParams } from "react-router-dom";
import { getMatchedOpportunityWithUserAnswers } from "src/api/matches";
import { Suspense } from "react";
import { Main } from "src/components/commons/Main";
import { DisplayUserAnswers } from "src/components/commons/DisplayUserAnswers";
import { Card, CardHeader } from "src/components/commons/Card";
import { FunderProfileDisplay } from "src/components/commons/FunderProfileDisplay";
import { FunderOrganizationDisplay } from "src/components/commons/FunderOrganizationDisplay";

export function MatchedOpportunity() {
	const { data } = useLoaderData();
	const { id } = useParams();

	return (
		<>
			<Breadcrumbs separator="›" aria-label="breadcrumb" style={{ marginBottom: 16 }}>
				<Link to={"/dashboard"}>
					<Typography color="textSecondary">Dashboard</Typography>
				</Link>
				<Typography color="textPrimary">Match #{id}</Typography>
			</Breadcrumbs>

			<Suspense
				fallback={
					<Box height={"100%"} display="flex" justifyContent={"center"} alignItems={"center"}>
						<CircularProgress />
					</Box>
				}
			>
				<Await
					resolve={data}
					errorElement={
						<Box padding={4} textAlign="center">
							Could not load the opportunity
						</Box>
					}
					children={({ opportunity }) => {
						const userDetails = { ...opportunity.creator, details: { ...opportunity.creator.profile } };
						return (
							<Box pb={4}>
								<Grid container spacing={1}>
									<Grid item xs={12} md={6}>
										<Card>
											<CardHeader>
												<h4 className="text-xl">Funder Profile</h4>
											</CardHeader>
											<FunderProfileDisplay userDetails={userDetails} />
										</Card>
									</Grid>
									{opportunity.organization && <Grid item xs={12} md={6}>
										<Card>
											<CardHeader>
												<h4 className="text-xl">Funder Organization</h4>
											</CardHeader>
											<FunderOrganizationDisplay orgDetails={opportunity.organization} />
										</Card>
									</Grid>}
								</Grid>
								<Main>
									<h3 className="text-center text-2xl pt-4">Details</h3>
									<DisplayUserAnswers
										id="match-opp"
										answers={opportunity.userAnswers}
										isAdmin={false}
										type="opportunity"
									/>
								</Main>
							</Box>
						);
					}}
				/>
			</Suspense>
		</>
	);
}

export function matchedOpportunityLoader({ params }) {
	const promise = Promise.all([getMatchedOpportunityWithUserAnswers(params.id)]).then(([opportunity]) => {
		// try {
		// 	const groups = opportunity.userAnswers.reduce((acc, userAnswer) => {
		// 		if (!userAnswer.question) {
		// 			return acc;
		// 		}
		// 		const { group, meta, order, id, type, question } = userAnswer.question;
		// 		acc[group] = {
		// 			group: group,
		// 			step: meta.step,
		// 			questions: acc[group]?.questions || [],
		// 		};
		// 		const _question = { id, type, order, question, answer: userAnswer.response || userAnswer.answer.answer };
		// 		acc[group].questions.push(_question);
		// 		return acc;
		// 	}, {});
		// 	opportunity.steps = Object.values(groups)
		// 		.map((step) => {
		// 			step.questions = step.questions.sort((a, b) => a.order - b.order);
		// 			return step;
		// 		})
		// 		.sort((a, b) => {
		// 			return a.step - b.step;
		// 		});
		// 	opportunity.steps.pop();
		// } catch (err) {
		// 	console.error("Error while transforming opportunity question answerss data", err);
		// 	opportunity.steps = [];
		// }
		return {
			opportunity,
		};
	});
	return defer({
		data: promise,
	});
}
