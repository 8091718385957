import { getItem, setItem, removeItem, clear } from "./local";
let _storage = {};
export const localstorage = {
	getItem(key) {
		try {
			return getItem(key);
		} catch (err) {
			return _storage[key];
		}
	},
	setItem(key, value) {
		try {
			setItem(key, value);
		} catch (err) {
			_storage[key] = value;
		}
	},
	removeItem(key) {
		try {
			removeItem(key);
		} catch (err) {
			delete _storage[key];
		}
	},
	clear() {
		try {
			clear();
		} catch (err) {
			_storage = {};
		}
	}
};
