import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Grid from "@material-ui/core/Grid";
// import makeStyles from "@material-ui/core/styles/makeStyles";

import { defer, Await, useLoaderData, Link, useParams } from "react-router-dom";
import { getMatchedCampaignWithUserAnswers } from "src/api/matches";
import { Suspense } from "react";
import { Main } from "src/components/commons/Main";
import { DisplayUserAnswers } from "src/components/commons/DisplayUserAnswers";
import { FounderProfileDisplay } from "src/components/commons/FounderProfileDisplay";
import { Card, CardHeader } from "src/components/commons/Card";

export function MatchedCampaign() {
	const { data } = useLoaderData();
	const { id } = useParams();

	return (
		<>
			<Breadcrumbs separator="›" aria-label="breadcrumb" style={{ marginBottom: 16 }}>
				<Link to={"/dashboard"}>
					<Typography color="textSecondary">Dashboard</Typography>
				</Link>
				<Typography color="textPrimary">Match #{id}</Typography>
			</Breadcrumbs>
			<div>
				<Suspense
					fallback={
						<Box height={"100%"} display="flex" justifyContent={"center"} alignItems={"center"}>
							<CircularProgress />
						</Box>
					}
				>
					<Await
						resolve={data}
						errorElement={
							<Box padding={4} textAlign="center">
								Could not load the campaign
							</Box>
						}
						children={({ campaign }) => {
							return (
								<Box pb={4}>
									<Grid container spacing={1}>
										{campaign.teamMembers.map((member) => {
											if (!member.details.isContactPerson && member.isVisible === false) {
												return null;
											}
											const userDetails = {
												...member,
												details: {
													...member.details,
													...(member.email === campaign.creator.email ? campaign.creator.profile : {}),
												},
											};
											return (
												<Grid key={`member_${member.id}`} item xs={12}>
													<Card>
														<CardHeader>
															<h4 className="text-xl">Founding Team Member</h4>
														</CardHeader>
														<FounderProfileDisplay userDetails={userDetails} />
													</Card>
												</Grid>
											);
										})}
									</Grid>
									<Main>
										<h3 className="text-center text-2xl pt-4">Details</h3>
										<DisplayUserAnswers
											id="match-camp"
											answers={campaign.userAnswers}
											isAdmin={false}
											type="campaign"
										/>
									</Main>
								</Box>
							);
						}}
					/>
				</Suspense>
			</div>
		</>
	);
}

export function matchedCampaignLoader({ params }) {
	const promise = Promise.all([getMatchedCampaignWithUserAnswers(params.id)]).then(([campaign]) => {
		// try {
		// 	const groups = opportunity.userAnswers.reduce((acc, userAnswer) => {
		// 		if (!userAnswer.question) {
		// 			return acc;
		// 		}
		// 		const { group, meta, order, id, type, question } = userAnswer.question;
		// 		acc[group] = {
		// 			group: group,
		// 			step: meta.step,
		// 			questions: acc[group]?.questions || [],
		// 		};
		// 		const _question = { id, type, order, question, answer: userAnswer.response || userAnswer.answer.answer };
		// 		acc[group].questions.push(_question);
		// 		return acc;
		// 	}, {});
		// 	opportunity.steps = Object.values(groups)
		// 		.map((step) => {
		// 			step.questions = step.questions.sort((a, b) => a.order - b.order);
		// 			return step;
		// 		})
		// 		.sort((a, b) => {
		// 			return a.step - b.step;
		// 		});
		// 	opportunity.steps.pop();
		// } catch (err) {
		// 	console.error("Error while transforming opportunity question answerss data", err);
		// 	opportunity.steps = [];
		// }
		return {
			campaign,
		};
	});
	return defer({
		data: promise,
	});
}
