import { useEffect, useState } from "react";
import { getMatchId } from "src/helpers";
import { acceptSchedule, updateSchedule } from "src/api/matches";
import { Card } from "src/components/commons/Card";
import Alert from "src/helpers/alert";
import { isPast } from "src/helpers/date";
import { ConfirmDialog } from "../commons/Dialog";
import { Schedule } from "../commons/Schedule";
import { ChooseSchedule } from "../commons/ChooseSchedule";
import { EachAcceptedMatch } from "./EachAcceptedMatch";

export function SuccessfulMatches({ acceptedMatches, currentRole }) {
	const [scheduleMatch, setScheduleMatch] = useState(null);
	const [scheduleError, setScheduleError] = useState(null);
	const [matches, setMatches] = useState([]);

	useEffect(() => {
		setMatches(acceptedMatches);
	}, [acceptedMatches]);

	async function saveSchedule(times) {
		try {
			const match = await updateSchedule({ id: scheduleMatch.id, times, currentRole });
			Alert.success("Schedule sent successfully");
			const _matches = [...acceptedMatches];
			const index = _matches.findIndex((m) => m.id === match.id);
			_matches[index] = match;
			setMatches(_matches);
			setScheduleMatch(null);
		} catch (err) {
			console.error("Unable to save schedule", err);
			if (err.code && err.code === "E_MA_42204") {
				setScheduleError(err.data);
				Alert.error(err.message);
				return;
			}
			Alert.error("Unable to save schedule");
			// TODO: Error display
		}
	}
	async function saveSelectedSlot(selectedSlot) {
		try {
			if (isPast(selectedSlot.datetime)) {
				Alert.error("Time slot is in the past");
				return false;
			}
			const match = await acceptSchedule({ id: scheduleMatch.id, currentRole, timeSlot: selectedSlot });
			const _matches = [...acceptedMatches];
			const index = _matches.findIndex((m) => m.id === match.id);
			_matches[index] = match;
			setMatches(_matches);
			setScheduleMatch(null);
			Alert.success("Schedule booked successfully");
		} catch (error) {
			if (error.code && error.code === "E_MA_42205") {
				Alert.error(error.message);
				return;
			}
			Alert.error("Unable to save slot");
			console.error("Error while saving selected slot", error);
		}
	}
	return (
		<Card>
			<div className="p-4 bg-green-300 rounded-t-xl">
				<h2 className="text-lg">Successful Matches</h2>
			</div>
			{matches.length ? (
				matches.map((match) => {
					return (
						<EachAcceptedMatch
							key={`match-${match.id}`}
							match={match}
							currentRole={currentRole}
							onAction={(match) => {
								setScheduleMatch(match);
							}}
						/>
					);
				})
			) : (
				<div className="p-8 text-center text-slate-400">There are no accepted matches at the moment</div>
			)}
			{scheduleMatch && (
				<ConfirmDialog
					show={true}
					title={`Time Slots for match #${getMatchId(scheduleMatch.id)}`}
					onClose={() => setScheduleMatch(null)}
					body={
						scheduleMatch.proposedSchedules.length === 0 || scheduleMatch.proposedSchedules[0]?.role === currentRole ? (
							<Schedule
								errors={scheduleError}
								scheduleTimes={scheduleMatch.proposedSchedules[0]?.times || []}
								viewOnly={!!scheduleMatch.proposedSchedules.length}
								onDone={(times) => {
									saveSchedule(times);
								}}
								onClose={() => setScheduleMatch(null)}
							/>
						) : (
							<ChooseSchedule
								errors={scheduleError}
								scheduleTimes={scheduleMatch.proposedSchedules[0]?.times || []}
								onDone={(selectedSlot) => {
									return saveSelectedSlot(selectedSlot);
								}}
								onClose={() => setScheduleMatch(null)}
							/>
						)
					}
					showFooter={false}
				/>
			)}
		</Card>
	);
}
