import { Button } from "@material-ui/core";
import { CalendarTodayOutlined, ChevronRight, TimerOutlined } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getMatchId } from "src/helpers";
import { months } from "src/helpers/constants";
import { ConfirmDialog } from "./Dialog";
import { AddToCalendar } from "./AddToCalendar";

export function SchedulesList({ acceptedMatches }) {
	const [schedules, setSchedules] = useState([]);
	const [selectedItem, setSelectedItem] = useState(null);
	useEffect(() => {
		const schedules = acceptedMatches.filter((match) => match.selectedSchedule.timeSlot);
		setSchedules(schedules);
	}, [acceptedMatches]);
	return (
		<>
			{schedules.length > 0 ? (
				schedules.map((schedule) => {
					const dt = new Date(schedule.selectedSchedule.timeSlot.datetime);
					const date = `${dt.getDate().toString().padStart(2, "0")} ${months[dt.getMonth()].substring(0, 3)}`;
					const time = `${dt.getHours().toString().padStart(2, "0")}:${dt.getMinutes().toString().padStart(2, "0")}`;
					const endDt = new Date(schedule.selectedSchedule.timeSlot.datetime);
					endDt.setTime(endDt.getTime() + schedule.selectedSchedule.timeSlot.durationInMins * 60 * 1000);
					const endTime = `${endDt.getHours().toString().padStart(2, "0")}:${endDt
						.getMinutes()
						.toString()
						.padStart(2, "0")}`;
					const matchedEntity = schedule.opportunity ? "opportunity" : "campaign";
					return (
						<div
							key={`schedule_${schedule.id}`}
							className="m-2 p-2 rounded-md text-sm flex flex-col bg-slate-100 text-slate-500"
						>
							{/* <div>Meeting with {schedule.opportunity.name}</div> */}
							<div className="flex items-center gap-2">
								<div className="text-slate-600">Match #{getMatchId(schedule.id)}</div>
								<Button
									color="primary"
									onClick={() => {
										setSelectedItem(schedule);
									}}
								>
									<img
										src="/images/icons/calendar-plus.svg"
										alt="Add to calendar"
										width={14}
										height={14}
										className="mr-1"
									/>{" "}
									Add to Calendar
								</Button>
							</div>
							<div className="flex gap-2">
								<div className="flex items-center">
									<CalendarTodayOutlined fontSize="small" className="text-slate-400 mr-1" /> {date}
								</div>
								<div className=" flex items-center">
									<TimerOutlined fontSize="small" className="text-slate-400 mr-1" /> {time} - {endTime}
								</div>
								<div className="flex-grow flex items-center justify-end">
									<Button component={Link} to={`/match/${schedule.id}/${matchedEntity}`} color="primary">
										View Details <ChevronRight />
									</Button>
								</div>
							</div>
						</div>
					);
				})
			) : (
				<div className="text-slate-400 flex justify-center items-center h-full">No schedules yet</div>
			)}
			{selectedItem && (
				<ConfirmDialog
					title="Add to Calendar"
					show={true}
					body={
						<>
							<AddToCalendar
								durationInMins={selectedItem.selectedSchedule.timeSlot.durationInMins}
								datetime={selectedItem.selectedSchedule.timeSlot.datetime}
								location={selectedItem.selectedSchedule.timeSlot.meetingAddress}
							/>
							<div className="pt-2 px-2 flex justify-end gap-2 border-t -mx-6">
								<Button variant="text" onClick={() => setSelectedItem(null)}>
									Close
								</Button>
							</div>
						</>
					}
					showFooter={false}
				/>
			)}
		</>
	);
}
