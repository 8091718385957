import { AlertTitle } from "@material-ui/lab";
import { supportEmail } from "src/helpers/constants";

export function GENERIC_REQUEST_ERROR(code) {
	if (!code) {
		throw Error("Code is mandatory");
	}
	return (
		<>
			<AlertTitle>Unable to complete the request. Try again</AlertTitle>
			<span>
				If the issue persists, please report to{" "}
				<a target={"_blank"} href={`mailto:${supportEmail}?subject=E:${code} - Unable to complete request`}>
					{supportEmail}
				</a>{" "}
				with the error code E:{code}
			</span>
		</>
	);
}
