import { useMemo } from "react";
import Typography from "@material-ui/core/Typography";
import { CardHeader } from "../commons/Card";

export function DrawLeanCanvas({ data }) {
	const canvasData = useMemo(() => {
		if(data.error) {
			return {};
		}
		return Object.keys(data).reduce((acc, item) => {
			acc[item] = data[item].value.map((val) => val.question + "\n▶️ " + val.answer).join("\n\n");
			return acc;
		}, {});
	}, [data]);

	return (
		<div>
			<CardHeader>
				<Typography component={"h1"} variant={"h6"} color="textSecondary">
					Lean Canvas
				</Typography>
			</CardHeader>
			{data.error ? (
				<div className="p-4 text-2xl text-center text-gray-400">{data.error}</div>
			) : (
				<div className="p-4">
					<div className="grid grid-cols-5 [&>*]:border [&>*]:-m-[1px]">
						<div className="p-4">
							<h3 className="text-center font-bold text-xl pb-3 text-red-600">Problem</h3>
							<p className="whitespace-pre-wrap">{canvasData.Problem || "-"}</p>
						</div>
						<div className="flex flex-col">
							<div className="p-4 border-b">
								<h3 className="text-center font-bold text-xl pb-3 text-red-600">Solution</h3>
								<p className="whitespace-pre-wrap">{canvasData.Solution || "-"}</p>
							</div>
							<div className="p-4">
								<h3 className="text-center font-bold text-xl pb-3 text-red-600">Key Metrics</h3>
								<p className="whitespace-pre-wrap">{canvasData["Key Metrics"] || "-"}</p>
							</div>
						</div>
						<div className="p-4">
							<h3 className="text-center font-bold text-xl pb-3 text-red-600">Unique Value Proposition</h3>
							<p className="whitespace-pre-wrap">{canvasData["Unique Value Proposition"] || "-"}</p>
						</div>
						<div className="flex flex-col">
							<div className="p-4 min-h-[25vh] border-b">
								<h3 className="text-center font-bold text-xl pb-3 text-red-600">Unfair Advantage</h3>
								<p className="whitespace-pre-wrap">{canvasData["Unfair Advantage"] || "-"}</p>
							</div>
							<div className="p-4">
								<h3 className="text-center font-bold text-xl pb-3 text-red-600">Channels</h3>
								<p className="whitespace-pre-wrap">{canvasData.Channels || "-"}</p>
							</div>
						</div>
						<div className="p-4">
							<h3 className="text-center font-bold text-xl pb-3 text-red-600">Customer Segments</h3>
							<p className="whitespace-pre-wrap">{canvasData["Customer Segments"] || "-"}</p>
						</div>
					</div>
					<div className="flex">
						<div className="p-4 border w-[50%]">
							<h3 className="text-center font-bold text-xl pb-3 text-red-600">Cost Structure</h3>
							<p className="whitespace-pre-wrap">{canvasData["Cost Structure"] || "-"}</p>
						</div>
						<div className="p-4 border w-[50%]">
							<h3 className="text-center font-bold text-xl pb-3 text-red-600">Revenue Streams</h3>
							<p className="whitespace-pre-wrap">{canvasData["Revenue streams"] || "-"}</p>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
