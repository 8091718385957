import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import { useCallback } from "react";
import { TITLES } from "src/helpers/constants";

export function FullyQualifiedPersonName({ fqpname, onChange }) {
	const onUpdate = useCallback(
		(e) => {
			const { name, value } = e.target;
			onChange({ ...fqpname, [name]: value });
		},
		[fqpname, onChange]
	);

	return (
		<Grid container spacing={4}>
			<Grid item xs={12} sm={2}>
				<TextField
					variant="outlined"
					label="Title"
					required
					fullWidth
					type="text"
					name="title"
					select
					value={fqpname.title ? (TITLES.includes(fqpname.title) ? fqpname.title : "other") : ""}
					onChange={onUpdate}
				>
					{TITLES.map((option) => (
						<MenuItem key={option} value={option}>
							{option}
						</MenuItem>
					))}
					<MenuItem key={"other"} value={"other"}>
						Other
					</MenuItem>
				</TextField>
			</Grid>
			{fqpname.title && TITLES.includes(fqpname.title) === false && (
				<Grid item xs={12} sm={2}>
					<TextField
						variant="outlined"
						id="other-title"
						required
						label="Title"
						type="text"
						value={fqpname.title !== "other" ? fqpname.title : ""}
						name="title"
						onChange={onUpdate}
					/>
				</Grid>
			)}
			<Grid item xs={12} sm={3}>
				<TextField
					fullWidth
					required
					variant="outlined"
					label="First Name"
					name="firstname"
					type="text"
					value={fqpname.firstname || ""}
					onChange={onUpdate}
				/>
			</Grid>
			<Grid item xs={12} sm={2}>
				<TextField
					fullWidth
					variant="outlined"
					label="Middle Name"
					name="middlename"
					type="text"
					value={fqpname.middlename || ""}
					onChange={onUpdate}
				/>
			</Grid>
			<Grid item xs={12} sm={3}>
				<TextField
					fullWidth
					required
					variant="outlined"
					label="Last Name"
					type="text"
					name="lastname"
					value={fqpname.lastname || ""}
					onChange={onUpdate}
				/>
			</Grid>
			<Grid item xs={12} sm={2}>
				<TextField
					fullWidth
					variant="outlined"
					label="Suffix"
					type="text"
					name="suffix"
					value={fqpname.suffix || ""}
					onChange={onUpdate}
				/>
			</Grid>
		</Grid>
	);
}
