import Box from "@material-ui/core/Box";
import EmojiNature from "@material-ui/icons/EmojiNature";
import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { ROLES } from "src/helpers/constants";

export function EmptyState({classes, entity}) {
	return (
		<Box
			display={"flex"}
			flexDirection={"column"}
			justifyContent="center"
			alignItems={"center"}
			textAlign={"center"}
			className={classes.emptyState}
		>
			<Box>
				<EmojiNature fontSize="large" />
			</Box>
			{entity === ROLES.FOUNDER && (
				<>
					<Box component={"p"} mb={3} fontSize={16}>
						Looks like you don&apos;t have any active campaigns yet
					</Box>
					<Box component={"div"} display="flex" flexDirection={"column"} gridGap={16} justifyContent={"center"}>
						<Link to={"/campaigns"} state={{ create: true }}>
							<Button color="secondary" variant="contained" startIcon={<AddCircleRoundedIcon />}>
								Create Campaign
							</Button>
						</Link>
						<Button color="primary" variant="contained" component={Link} to="/campaigns">
							Go to My Campaigns
						</Button>
					</Box>
				</>
			)}
			{entity === ROLES.FUNDER && (
				<>
					<Box component={"p"} mb={3} fontSize={16}>
						Looks like you don&apos;t have any active opportunities yet
					</Box>
					<Box component={"div"} display="flex" flexDirection={"column"} gridGap={16} justifyContent={"center"}>
						<Link to={"/opportunities"} state={{ create: true }}>
							<Button color="secondary" variant="contained" startIcon={<AddCircleRoundedIcon />}>
								Create Opportunity
							</Button>
						</Link>
						<Button color="primary" variant="contained" component={Link} to="/opportunities">
							Go to My Opportunities
						</Button>
					</Box>
				</>
			)}
			{entity === ROLES.VENDOR && (
				<>
					<Box component={"p"} mb={3} fontSize={16}>
						Looks like you don&apos;t have any active services yet
					</Box>
					<Box component={"div"} display="flex" flexDirection={"column"} gridGap={16} justifyContent={"center"}>
						<Link to={"/services"} state={{ create: true }}>
							<Button color="secondary" variant="contained" startIcon={<AddCircleRoundedIcon />}>
								Create Service
							</Button>
						</Link>
						<Button color="primary" variant="contained" component={Link} to="/services">
							Go to My Services
						</Button>
					</Box>
				</>
			)}
		</Box>
	);
}
