import { fetchV1, fetchAdminV1, fetchTenantAdminV1 } from "../lib/requester/v1";

export function getAll(params) {
	return fetchV1("/opportunities", { method: "GET", data: params }, { returnWholeResponse: true });
}

export function getAllForAdmin(params) {
	return fetchAdminV1("/opportunities", { method: "GET", data: params }, { returnWholeResponse: true });
}

export function searchForAdmin(data) {
	return fetchAdminV1("/opportunities/search", { method: "POST", data }, { returnWholeResponse: true });
}

export function getOpportunityWithUserAnswersForAdmin(id) {
	return fetchAdminV1(`/opportunities/${id}/user_answers`, { method: "GET" });
}

export function getById(id) {
	return fetchV1(`/opportunities/${id}`, { method: "GET" });
}

export function create(data) {
	return fetchV1(`/opportunities`, { method: "POST", data });
}

export function update(data) {
	return fetchV1(`/opportunities/${data.id}`, { method: "PUT", data });
}

export function remove(id) {
	return fetchV1(`/opportunities/${id}`, { method: "DELETE" });
}

export function launch(id) {
	return fetchV1(`/opportunities/${id}/launch`, { method: "GET" }, { returnWholeError: true });
}

export function getActiveOpportunitiesWithMatches() {
	return fetchV1("/opportunities/matches", { method: "GET" });
}

export function getOpportunityWithMatches(id) {
	return fetchV1(`/opportunities/${id}/matches`, { method: "GET" });
}

export function getAllForTenantAdmin(params) {
	return fetchTenantAdminV1("/opportunities", { method: "GET", data: params }, { returnWholeResponse: true });
}

export function getOpportunitiesWithUserAnswersForTenantAdmin(id) {
	return fetchTenantAdminV1(`/opportunities/${id}/user_answers`, { method: "GET" });
}

export function exportOpportunities() {
	return fetchTenantAdminV1("/opportunities/export", { method: "GET" });
}
