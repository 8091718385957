import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import { alpha } from "@material-ui/core/styles";
import MAlert from "@material-ui/lab/Alert";
import { defer, useLoaderData } from "react-router-dom";
import { getAll } from "src/api/faqs";
import { Async } from "src/components/commons/Async";
import { Main } from "src/components/commons/Main";
import { ManageFAQs } from "src/components/admin/FAQs";
import { useState } from "react";
import SaveOutlined from "@material-ui/icons/SaveOutlined";

const useStyles = makeStyles((theme) => ({
	emptyState: {
		padding: "6rem 0",
		color: alpha(theme.palette.common.black, 0.3),
	},
}));
export function AdminFAQs() {
	const { data } = useLoaderData();
	const classes = useStyles();
	const [isSubmitting, setSubmitting] = useState(false);
	const [isDirty, setIsDirty] = useState(false);
	const [hasError, setHasError] = useState(null);
	return (
		<Main>
			<Box display={"flex"} justifyContent="space-between" p={4} px={4} borderBottom={"1px solid rgb(239 232 251)"}>
				<Typography component={"h2"} variant="h6">
					FAQs
				</Typography>
				{hasError && (
					<Box>
						<MAlert severity="error">Please fix all errors and save again</MAlert>
					</Box>
				)}
				<Box>
					<Button
						color="primary"
						elevation={3}
						type="submit"
						variant="contained"
						startIcon={<SaveOutlined />}
						disabled={!isDirty || isSubmitting}
						form="manage-faq"
					>
						{isSubmitting ? "Saving..." : "Save All"}
					</Button>
				</Box>
			</Box>
			<Async error={"Unable to fetch the faqs"} promise={data}>
				{({ faqs }) => {
					return (
						<ManageFAQs
							formId="manage-faq"
							faqs={faqs}
							onSaving={(v) => setSubmitting(v)}
							onDirty={(v) => setIsDirty(v)}
						/>
					);
				}}
			</Async>
		</Main>
	);
}

export function FAQsLoader() {
	const promise = Promise.all([getAll({ per_page: 100 })]).then(([faqs]) => ({ faqs }));
	return defer({
		data: promise,
	});
}
