import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card, CardHeader } from "src/components/commons/Card";
import { MATCH_ACTION, STATUS_MAP } from "src/helpers/constants";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { SchedulesList } from "../commons/SchedulesList";
import { Chip } from "../commons/Chip";
import { SuccessfulMatches } from "./SuccessfulMatches";
// import { PendingMatches } from "./PendingMatches";

export function FounderDashboard({ activeCampaignsWithMatches = [], allCampaigns = [] }) {
	const { currentRole } = useSelector(({ auth }) => auth);
	const [acceptedMatches, setAcceptedMatches] = useState([]);
	const [pendingMatches, setPendingMatches] = useState([]);

	useEffect(() => {
		const acceptedMatches = [];
		activeCampaignsWithMatches.forEach((campaign) => {
			campaign.matches.forEach((match) => {
				const funderDisplayDetails = Object.keys(match.funderDisplayDetails).reduce((acc, key) => {
					let value = match.funderDisplayDetails[key];
					if (value && !Array.isArray(value) && typeof value === "object") {
						value = Object.keys(value);
					}
					acc[key] = value;
					return acc;
				}, {});
				const _match = {
					...match,
					funderDisplayDetails,
					campaignName: campaign.name,
				};
				if (match.founderAction === MATCH_ACTION.ACCEPTED && match.funderAction === MATCH_ACTION.ACCEPTED) {
					acceptedMatches.push(_match);
				}
				if (match.founderAction === MATCH_ACTION.ACCEPTED && match.funderAction === MATCH_ACTION.UNDECIDED) {
					pendingMatches.push(_match);
				}
			});
		});
		setAcceptedMatches(acceptedMatches);
		setPendingMatches(pendingMatches);
	}, [activeCampaignsWithMatches]);

	return (
		<div>
			<div className="grid grid-cols-1 gap-4">
				<div className="grid grid-cols-1 md:grid-cols-3 gap-4 min-h-[250px]">
					<Card>
						<CardHeader>
							<span className="text-lg">Round</span>
						</CardHeader>
						<div className="text-slate-400 flex justify-center items-center h-full">No Data Found</div>
					</Card>
					<Card>
						<CardHeader>
							<span className="text-lg">Valuation</span>
						</CardHeader>
						<div className="text-slate-400 flex justify-center items-center h-full">No Data Found</div>
					</Card>
					<Card>
						<CardHeader>
							<span className="text-lg">Schedules</span>
						</CardHeader>
						<SchedulesList acceptedMatches={acceptedMatches} />
					</Card>
				</div>
				<Card>
					<div className="p-4 bg-indigo-300 rounded-t-xl">
						<h2 className="text-lg">Match Found</h2>
					</div>
					{activeCampaignsWithMatches.length ? (
						activeCampaignsWithMatches.map((campaign) => {
							return (
								<div key={`match-opp-${campaign.id}`} className="flex w-full items-center p-4 gap-4">
									<div className="p-4 border border-primary rounded-md">
										<img src="/images/icons/opportunity-match.svg" width={45} height={45} />
									</div>
									<div className="flex-grow">
										<div className="font-bold text-lg">{campaign.name}</div>
										<div className="text-lg">{campaign.matches.length} match(es) found</div>
									</div>
									{campaign.matches.length > 0 && (
										<div>
											<Button
												variant="contained"
												color="primary"
												component={Link}
												to={`/campaign/${campaign.id}/matches`}
											>
												View Matches
											</Button>
										</div>
									)}
								</div>
							);
						})
					) : (
						<div className="p-8 text-center text-slate-400">There are no pending matches at the moment</div>
					)}
				</Card>
				<SuccessfulMatches acceptedMatches={acceptedMatches} currentRole={currentRole} />
				{/* <PendingMatches pendingMatches={pendingMatches} currentRole={currentRole} /> */}
				<hr className="my-4" />
				<Card>
					<CardHeader>
						<span className="text-xl">All Campaigns</span>
					</CardHeader>
					{allCampaigns.data.map((campaign) => {
						return (
							<div key={`all-campaign-${campaign.id}`} className="p-4 text-lg flex justify-between">
								<div>
									{campaign.name}{" "}
									<Chip classes="text-sm inline" status={campaign.status}>
										{STATUS_MAP[campaign.status]}
									</Chip>{" "}
								</div>
								<Button variant="contained" color="primary" href={`/campaign/${campaign.id}/details`}>
									View
								</Button>
							</div>
						);
					})}
				</Card>
			</div>
		</div>
	);
}
