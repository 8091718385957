import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ONBOARDING_STEPS, ROLES } from "src/helpers/constants";
import { getAll } from "src/api/teamMembers";
import { Typography } from "@material-ui/core";
// import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
	disabled: {
		"& img": {
			filter: "grayscale(0.9) contrast(0.6)",
		},
	},
}));
export default function FounderOnboardingWizard({ onCompleteOnboarding }) {
	const classes = useStyles();
	const navigate = useNavigate();
	const { authUser } = useSelector(({ auth }) => auth);
	const [addingTeam, setAddingTeam] = useState(false);
	const [completingOnboarding, setCompletingOnboarding] = useState(false);

	async function gotoAddTeamMember() {
		setAddingTeam(true);
		try {
			const teamMembers = await getAll({ filter: `userId||$eq||${authUser.id}` });
			navigate(`/team/${teamMembers.data[0].teamId}/member/add`);
		} catch (err) {
			console.error("Error while fetching team member in onboarding", err);
			navigate("/teams");
		} finally {
			setAddingTeam(false);
		}
	}
	return (
		<Box className="grid grid-cols-12 p-1">
			<Typography component={"h1"} variant="h4"  className="py-4 col-start-2 col-span-10 text-center">
				Welcome to InvestorMatch.AI
			</Typography>
			<Box className="col-start-2 col-span-10">
				<Box
					className={
						"flex p-4 gap-4 justify-start " +
						(authUser.onboarding[ROLES.FOUNDER]?.completedStep != ONBOARDING_STEPS.SIGNUP
							? `pointer-events-none text-slate-300 ${classes.disabled}`
							: "")
					}
				>
					<img src="/images/onboarding-profile.svg" width={"200px"} className="aspect-square" />
					<Box className="[&>*]:mb-2">
						<h2 className="text-2xl">Step 1: Profile</h2>
						<p className="text-xl">Fill in your personal and business profile details</p>
						<p className="text-lg">Let&apos;s get to know you and your business</p>
						<Box className="pt-4">
							<Button
								component={Link}
								to="/profile"
								variant="contained"
								disabled={authUser.onboarding[ROLES.FOUNDER]?.completedStep != ONBOARDING_STEPS.SIGNUP}
								color="primary"
							>
								Get Started
							</Button>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box className="col-start-2 col-span-10">
				<Box
					className={
						"flex p-4 gap-4 justify-start " +
						(authUser.onboarding[ROLES.FOUNDER]?.completedStep != ONBOARDING_STEPS.PROFILE
							? `pointer-events-none text-slate-300 ${classes.disabled}`
							: "")
					}
				>
					<img src="/images/onboarding-form.svg" width={200} className="aspect-square" />
					<Box className="[&>*]:mb-2">
						<h2 className="text-2xl">Step 2: Campaign</h2>
						<p className="text-lg">Create and fill in your fundraising campaign</p>
						<p className="text-md p-2 bg-purple-100 rounded-lg">
							Here we ask about your vertical and fund requirements together with your organization structure and
							readiness to receive funding. This section of the form would take between 30-45 mins.
						</p>
						<p className="text-xl">Let&apos;s find you the ideal funder!</p>
						<Box className="pt-4">
							<Button
								component={Link}
								to="/campaigns"
								state={{ create: true }}
								variant="contained"
								color="primary"
								disabled={authUser.onboarding[ROLES.FOUNDER]?.completedStep != ONBOARDING_STEPS.PROFILE}
							>
								Create Campaign
							</Button>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box className="col-start-2 col-span-10">
				<Box
					className={
						"flex p-4 gap-4 justify-start " +
						(authUser.onboarding[ROLES.FOUNDER]?.completedStep != ONBOARDING_STEPS.CAMPAIGN
							? `pointer-events-none text-slate-300 ${classes.disabled}`
							: "")
					}
				>
					<img src="/images/onboarding-team.svg" width={200} />
					<Box className="[&>*]:mb-2">
						<h2 className="text-2xl">Step 3: Team Members</h2>
						<p className="text-lg">
							Your team plays a critical role in getting you recognized as an ideal funding opportunity
						</p>
						<p className="text-xl">Let&apos;s highlight your incredible team</p>
						<div className="flex gap-4 pt-4">
							<Button
								variant="contained"
								color="primary"
								disabled={authUser.onboarding[ROLES.FOUNDER]?.completedStep != ONBOARDING_STEPS.CAMPAIGN || addingTeam}
								onClick={gotoAddTeamMember}
							>
								Add Team Members
							</Button>
							<Button
								variant="contained"
								disabled={
									authUser.onboarding[ROLES.FOUNDER]?.completedStep != ONBOARDING_STEPS.CAMPAIGN || completingOnboarding
								}
								onClick={async () => {
									setCompletingOnboarding(true);
									await onCompleteOnboarding();
									setCompletingOnboarding(false);
								}}
							>
								Completed Team Details
							</Button>
						</div>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}
FounderOnboardingWizard.propTypes = {};
