import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import DeleteForever from "@material-ui/icons/DeleteForever";
import { Alert } from "@material-ui/lab";

import { CONTEXT_OPERATORS } from "src/helpers/constants";

export function ContextRules({ question, onChange, idx, errors = {} }) {
	function onRuleChange(property, value, index) {
		const _contextRules = [...question.meta.contextRules];
		_contextRules[index][property] = value;
		onChange({ ...question, meta: { ...(question.meta || {}), contextRules: _contextRules } }, idx);
	}
	return (
		<>
			<div className="mb-1 text-lg ml-2">
				Show only when{" "}
				{
					errors.context &&
					<Box>
						<Alert severity="error">{errors.context}</Alert>
					</Box>
				}
			</div>
			{question.meta.contextRules?.length > 0 && (
				<Grid xs={12} item>
					{question.meta.contextRules?.map((rule, index) => {
						return (
							<Grid key={`rules-${idx}-quesId-${question.id}`} container spacing={4}>
								<Grid item xs={4}>
									<TextField
										variant="outlined"
										label="Context"
										fullWidth
										placeholder="Variable"
										size="small"
										value={rule.context || ""}
										onChange={(e) => {
											onRuleChange("context", e.target.value, index);
										}}
									/>
								</Grid>
								<Grid item xs={3}>
									<TextField
										select
										fullWidth
										variant="outlined"
										value={rule.operator}
										label="Operator"
										required
										size={"small"}
										onChange={(e) => {
											onRuleChange("operator", e.target.value, index);
										}}
									>
										<MenuItem value={""}>Operator</MenuItem>
										{Object.values(CONTEXT_OPERATORS).map((val) => (
											<MenuItem key={`rules-${idx}-quesId-${question.id}-operator-${val}`} value={val}>
												{val}
											</MenuItem>
										))}
									</TextField>
								</Grid>
								<Grid item xs={4}>
									<TextField
										variant="outlined"
										label="Value"
										placeholder="Value"
										fullWidth
										size="small"
										value={rule.value || ""}
										onChange={(e) => {
											onRuleChange("value", e.target.value, index);
										}}
									/>
								</Grid>
								<Grid item xs={1}>
									<Button
										variant="text"
										color="secondary"
										onClick={() => {
											const _question = { ...question };
											_question.meta.contextRules = _question.meta.contextRules || [];
											_question.meta.contextRules.splice(index, 1);
											onChange(_question, idx);
										}}
									>
										<DeleteForever />
									</Button>
								</Grid>
							</Grid>
						);
					})}
				</Grid>
			)}
			<Grid xs={12} item>
				<Button
					variant="contained"
					color="primary"
					onClick={() => {
						const _question = { ...question };
						_question.meta.contextRules = _question.meta.contextRules || [];
						_question.meta.contextRules.push({ context: "", operator: "", value: "" });
						onChange(_question, idx);
					}}
				>
					+ Add Context Rule
				</Button>
			</Grid>
		</>
	);
}
