import { fetchAdminV1, fetchV1 } from "../lib/requester/v1";

export function getMatchedOpportunityWithUserAnswers(matchId) {
	return fetchV1(`/matches/${matchId}/opportunity/answers`, { method: "GET" });
}

export function getMatchedCampaignWithUserAnswers(matchId) {
	return fetchV1(`/matches/${matchId}/campaign/answers`, { method: "GET" });
}

export function getRecommendedCampaignsForOpportunityForAdmin(id) {
	return fetchAdminV1(`/matches/recommend/campaign/opportunity?id=${id}`, { method: "GET" });
}

export function getMatchByParams(params) {
	return fetchV1(`/matches`, { method: "GET", data: params });
}

export function create(data) {
	return fetchAdminV1("/matches", { method: "POST", data });
}

export function updateAction(data) {
	return fetchV1(`/matches/${data.id}/action`, { method: "PUT", data });
}

export function updateSchedule(data) {
	return fetchV1(`/matches/${data.id}/schedule`, { method: "PUT", data }, { returnWholeError: true });
}

export function acceptSchedule(data) {
	return fetchV1(`/matches/${data.id}/schedule/accept`, { method: "PUT", data }, { returnWholeError: true });
}
