import { fetchV1 } from "../lib/requester/v1";

export function getAll(params) {
	return fetchV1("/teams", { method: "GET", data: params }, { returnWholeResponse: true });
}

export function getTeam(id) {
	return fetchV1(`/teams/${id}`, { method: "GET" });
}

export function update(data) {
	return fetchV1(`/teams/${data.id}`, { method: "PUT", data });
}

export function create(data) {
	return fetchV1(`/teams`, { method: "POST", data });
}
