import { useState } from "react";
import { updateAction } from "src/api/matches";
import { ConfirmDialog } from "src/components/commons/Dialog";
import Alert from "src/helpers/alert";
import { EachMatch } from "src/components/opportunities/EachMatch";
import { MATCH_ACTION } from "src/helpers/constants";
import { clone } from "src/helpers/clone";
import { getMatchId } from "src/helpers";
const action = {
	ACCEPTED: "accept",
	REJECTED: "reject",
	ARCHIVED: "archive",
};

export function Matches({ opportunity, currentRole }) {
	const [selectedMatch, setSelectedMatch] = useState(null);
	const [opportunityLcl, setOpportunityLcl] = useState({ ...opportunity });
	return (
		<>
			<div className="border rounded-md bg-white shadow-md text-lg">
				{opportunityLcl.matches.length > 0 ? (
					opportunityLcl.matches.map((match) => {
						return (
							<EachMatch
								key={`opp-match-${match.id}`}
								match={match}
								currentRole={currentRole}
								onAction={(match, action) => {
									setSelectedMatch({
										id: match.id,
										action,
										role: currentRole,
									});
								}}
							/>
						);
					})
				) : (
					<div>There are no matches at the moment. Come back later</div>
				)}
			</div>
			{selectedMatch && (
				<ConfirmDialog
					title={`Match #${getMatchId(selectedMatch.id)}`}
					body={
						<div className="text-center text-xl py-4">Are you sure you want to {action[selectedMatch.action]}?</div>
					}
					onClose={() => setSelectedMatch(null)}
					onOK={async () => {
						try {
							const match = await updateAction(selectedMatch);
							const _opportunityLcl = clone(opportunityLcl);
							const matchIndex = _opportunityLcl.matches.findIndex((mat) => mat.id == match.id);
							_opportunityLcl.matches[matchIndex] = match;
							if (match.funderAction === MATCH_ACTION.ACCEPTED) {
								Alert.success("Accepted Successfully");
							} else if (match.funderAction === MATCH_ACTION.REJECTED) {
								Alert.success("Match is ignored");
								_opportunityLcl.matches.splice(matchIndex, 1);
								//TODO: Need to collect feedback why
							} else if (match.funderAction === MATCH_ACTION.ARCHIVED) {
								_opportunityLcl.matches.splice(matchIndex, 1);
								Alert.success("Match is archived");
							}
							setOpportunityLcl(_opportunityLcl);
							setSelectedMatch(null);
						} catch (err) {
							Alert.error("Unable to complete the request");
							throw err;
						}
					}}
					buttonText="Yes"
					show={true}
				/>
			)}
		</>
	);
}
