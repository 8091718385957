import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useContext } from "react";
import { Context } from "./Context";
import { Question } from "./Question";

export function QuestionGroups({ questions }) {
	const { canDisplayQuestion } = useContext(Context);
	const _questions = questions.length
		? questions
				.map((question, idx) => {
					if (canDisplayQuestion(question) === false) {
						return null;
					}
					return (
						<Grid key={`question_${idx}`} item xs={12} data-question={question.id}>
							<Question question={question} idx={idx} />
						</Grid>
					);
				})
				.filter((i) => i)
		: [];
	return (
		<>
			<Grid container spacing={10} style={{ minHeight: 400 }}>
				{_questions.length > 0 ? (
					_questions
				) : (
					<Grid item xs={12}>
						<Typography color="textSecondary" align="center" component={"p"} variant="subtitle2">
							You are all caught up here. Go to the next step.
						</Typography>
					</Grid>
				)}
			</Grid>
		</>
	);
}
