import { Box, Button, DialogActions, FormHelperText, TextField } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Form, useNavigate } from "react-router-dom";
import AppDatePicker from "src/@jumbo/components/Common/formElements/AppDatePicker";
import { create, update } from "src/api/campaigns";
import { GENERIC_REQUEST_ERROR } from "../commons/Errors";

export function CampaignForm({ campaign = null, close, teams, totalCampaigns }) {
	const navigate = useNavigate();
	const { tenant } = useSelector(({ auth }) => auth);
	// const actionData = useActionData() || {};
	const [name, setName] = useState(campaign?.name || "");
	const [description, setDescription] = useState(campaign?.description || "");
	const [endDate, setEndDate] = useState(getDate(campaign?.endDate) || "");
	const [errors, setErrors] = useState(null);
	const [saving, setSaving] = useState(false);
	// const [teamId, setTeamId] = useState(campaign?.teamId || teams[0]?.id || "");
	function getDate(dt) {
		if (dt) {
			const [yyyy, mm, dd] = dt.split("-");
			return `${mm}-${dd}-${yyyy}`;
		}
		return null;
	}

	return (
		<Form
			replace={false}
			method="post"
			action="/campaigns"
			onSubmit={async (e) => {
				e.preventDefault();
				setSaving(true);
				const formData = new FormData(e.target);
				const id = formData.get("id");
				const name = formData.get("name");
				const description = formData.get("description");
				const teamId = formData.get("teamId");
				const endDate = formData.get("endDate") || null;
				const errors = {};
				if (typeof name !== "string" || !name.trim()) {
					errors.name = "Name must be a minimum of 3 characters";
				}
				if (endDate) {
					const endDateTS = new Date(endDate).getTime();
					if (!endDateTS) {
						errors.endDate = "Invalid Date";
					} else if (Date.now() >= endDateTS) {
						errors.endDate = "Need funds by cannot be in the past";
					}
				}
				if (Object.keys(errors).length) {
					setSaving(false);
					setErrors(errors);
					return;
				}

				try {
					let data = {
						name,
						description,
						endDate,
						tenantId: tenant.id
					};
					if(teamId) {
						data.teamId = teamId;
					}
					if (id) {
						data.id = id;
						await update(data);
						close();
					} else {
						const response = await create(data);
						navigate(`/campaign/${response.id}/details`, { state: { isFirstTime: totalCampaigns <= 0 } });
					}
				} catch (err) {
					console.error("Error while saving campaign", err);
					if (err instanceof Error) {
						errors.server = GENERIC_REQUEST_ERROR("CN001");
					}
					if (err && err[0]) {
						errors.server = err[0];
					}
					setErrors(errors);
				} finally {
					setSaving(false);
				}
			}}
		>
			{errors?.server && (
				<Box mb={5}>
					<Alert severity="error">{errors?.server}</Alert>
				</Box>
			)}
			<input type="hidden" value={campaign?.id || ""} name="id" />
			<input type="hidden" value={campaign?.teamId || teams[0]?.id || ""} name="teamId" />
			<Box px={5} pb={2}>
				<TextField
					autoFocus
					variant="outlined"
					margin="dense"
					id="camp-name"
					label="Your Fundraising Campaign Name"
					type="text"
					size="medium"
					name="name"
					required
					error={!!errors?.name}
					helperText={errors?.name || ""}
					value={name || ""}
					fullWidth
					onChange={(e) => setName(e.target.value)}
				/>
				<FormHelperText>Example: Series A Campaign</FormHelperText>
				<FormHelperText>Visible to you only</FormHelperText>
			</Box>
			<Box px={5} pb={4}>
				<TextField
					multiline
					variant="outlined"
					margin="dense"
					minRows={3}
					maxRows={4}
					id="campaign-name"
					label="Campaign Description"
					type="text"
					size="medium"
					name="description"
					fullWidth
					value={description || ""}
					onChange={(e) => setDescription(e.target.value)}
				/>
				<FormHelperText>Example: Raising funds for Series A</FormHelperText>
			</Box>
			<Box px={5} pb={4}>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<AppDatePicker
						fullWidth
						inputVariant={"outlined"}
						format={"MM/dd/yyyy"}
						name="endDate"
						value={endDate ? new Date(endDate) : null}
						disablePast
						label="Need Funds By"
						placeholder="mm/dd/yyyy"
						size="medium"
						error={!!errors?.endDate}
						helperText={errors?.endDate || ""}
						onChange={(value) => {
							if (value && (value || {}).toString() !== "Invalid Date") {
								setEndDate(`${value.getMonth() + 1}/${value.getDate()}/${value.getFullYear()}`);
								if (errors && errors.endDate) {
									const _errors = { ...errors };
									delete _errors.endDate;
									setErrors(_errors);
								}
							}
						}}
					/>
				</MuiPickersUtilsProvider>
			</Box>
			{/* <Box px={5} pb={4}>
				<TextField
					select
					variant="outlined"
					margin="dense"
					id="campaign-team"
					label="Team"
					size="medium"
					name="teamId"
					required
					fullWidth
					value={teamId || ""}
					onChange={(e) => setTeamId(e.target.value)}
				>
					{teams.map((team) => (
						<MenuItem key={`team_${team.id}`} value={team.id}>
							{team.name}
						</MenuItem>
					))}
				</TextField>
			</Box> */}
			<Box px={3} pb={4}>
				<DialogActions>
					<Button type="button" onClick={close}>
						Close
					</Button>
					<Button disabled={saving} type="submit" variant="contained" color="primary">
						{saving ? "Saving..." : "Save"}
					</Button>
				</DialogActions>
			</Box>
		</Form>
	);
}
