import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import withStyles from "@material-ui/styles/withStyles";
import { useCallback, useEffect, useState } from "react";
import Papa from "papaparse";
import { bulk, create, getAll, remove, update } from "src/api/externalFunders";
import { debounce } from "src/helpers/debounce";
import Alert from "src/helpers/alert";
import { Chip, Input, MenuItem, Select } from "@material-ui/core";
import { Tags } from "src/components/campaigns/Details/Tags";

const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: 48 * 4.5 + 8,
			width: 250,
		},
	},
};
const LeftStickyTableCell = withStyles((theme) => ({
	head: {
		left: 0,
		position: "sticky",
		zIndex: theme.zIndex.appBar + 2,
	},
	body: {
		backgroundColor: "#ddd",
		left: 0,
		position: "sticky",
		zIndex: theme.zIndex.appBar + 1,
	},
}))(TableCell);

const RightStickyTableCell = withStyles(() => ({
	head: {
		left: "auto",
		right: 0,
	},
	body: {
		backgroundColor: "#ddd",
		left: "auto",
		right: 0,
	},
}))(LeftStickyTableCell);

function Row({ row, onSave, onDelete, onCancel }) {
	const [saving, setSaving] = useState(false);
	const [editing, setEditing] = useState(!row.id);
	const [deleting, setDeleting] = useState(false);
	const [data, setData] = useState(() => ({ ...row }));
	function onChange(field, value) {
		setData({ ...data, [field]: value });
	}

	async function save() {
		try {
			setSaving(true);
			let res = data;
			if (!data.name) {
				Alert.error("Name cannot be empty");
				return;
			}
			data.searchField = Object.values(data)
				.filter((i) => i)
				.join(" ");
			if (data.id) {
				res = await update(data);
			} else {
				res = await create(data);
			}
			onSave(res);
			setEditing(false);
		} catch (err) {
			console.error("Unable to save industry", err);
			Alert.error("Unable to save");
		} finally {
			setSaving(false);
		}
	}

	async function deleteRow() {
		try {
			setDeleting(true);
			await remove(data);
			onDelete();
		} catch (err) {
			console.error("Unable to delete industry", err);
			Alert.error("Unable to delete");
		} finally {
			setDeleting(false);
		}
	}

	return (
		<TableRow key={data.id}>
			{editing ? (
				<>
					<LeftStickyTableCell className="pl-4">
						<input
							className="p-2 border rounded-sm"
							placeholder="Name"
							value={data.name || ""}
							type="text"
							onChange={(e) => onChange("name", e.target.value)}
						/>
					</LeftStickyTableCell>
					<TableCell className="pl-4">
						<input
							className="p-2 border rounded-sm"
							placeholder="Website"
							value={data.website || ""}
							type="text"
							onChange={(e) => onChange("website", e.target.value)}
						/>
					</TableCell>
					<TableCell className="pl-4">
						<input
							className="p-2 border rounded-sm"
							placeholder="Vision"
							value={data.vision || ""}
							type="text"
							onChange={(e) => onChange("vision", e.target.value)}
						/>
					</TableCell>
					<TableCell className="pl-4">
						<input
							className="p-2 border rounded-sm"
							placeholder="Contact Email"
							value={data.contactEmail || ""}
							type="text"
							onChange={(e) => onChange("contactEmail", e.target.value)}
						/>
					</TableCell>
					<TableCell className="pl-4">
						<Select
							multiple
							value={data.type || []}
							className="p-2 border rounded-sm"
							placeholder="Type"
							onChange={(e) => onChange("type", e.target.value)}
							input={<Input fullWidth />}
							renderValue={(selected) => {
								if (selected.length <= 0) {
									return <em>Type</em>;
								}
								return selected.map((value) => <Chip key={`row-${row.id}-type-${value}`} label={value} />);
							}}
							MenuProps={MenuProps}
						>
							{["VC", "Angel", "Angel Group", "Family Office", "Accelerator", "Incubator"].map((name) => (
								<MenuItem key={name} value={name}>
									{name}
								</MenuItem>
							))}
						</Select>
					</TableCell>
					<TableCell className="pl-4">
						<Tags
							question={{
								meta: {
									multiple: false,
									placeholder: "Industry",
									dataSourceURL: "/industries?filter=name||$contL||{value}&or=synonyms||$contL||{value}",
								},
							}}
							answers={[data.industries || []]}
							setUserAnswer={(_, industries) => {
								onChange("industries", [...industries[0]]);
							}}
							error={null}
						/>
					</TableCell>
					<TableCell className="pl-4">
						<input
							className="p-2 border rounded-sm"
							placeholder="Fund Size"
							value={data.fundSize || ""}
							type="text"
							onChange={(e) => onChange("fundSize", e.target.value)}
						/>
					</TableCell>
					<TableCell className="pl-4">
						<input
							className="p-2 border rounded-sm"
							placeholder="Min. Cheque Size"
							value={data.minChequeSize || ""}
							type="text"
							onChange={(e) => onChange("minChequeSize", e.target.value)}
						/>
					</TableCell>
					<TableCell className="pl-4">
						<input
							className="p-2 border rounded-sm"
							placeholder="Max. Cheque Size"
							value={data.maxChequeSize || ""}
							type="text"
							onChange={(e) => onChange("maxChequeSize", e.target.value)}
						/>
					</TableCell>
					<TableCell className="pl-4">
						<label>
							<input
								className="p-2 border rounded-sm mr-2"
								value={data.canLeadRounds || false}
								type="checkbox"
								onChange={(e) => onChange("canLeadRounds", e.target.checked)}
							/>
							Can Lead Rounds?
						</label>
					</TableCell>
					<TableCell className="pl-4">
						<label>
							<input
								className="p-2 border rounded-sm mr-2"
								placeholder="Is Currently Investing?"
								value={data.isCurrentlyInvesting || false}
								type="checkbox"
								onChange={(e) => onChange("isCurrentlyInvesting", e.target.checked)}
							/>
							Is Currently Investing?
						</label>
					</TableCell>
					<TableCell className="pl-4">
						<input
							className="p-2 border rounded-sm"
							placeholder="Currently Investing Updated At"
							value={data.currentlyInvestingUpdatedAt}
							type="date"
							onChange={(e) => onChange("currentlyInvestingUpdatedAt", e.target.value)}
						/>
					</TableCell>
					<TableCell className="pl-4">
						<input
							className="p-2 border rounded-sm"
							placeholder="Fund Closed Year"
							value={data.fundClosedYear || ""}
							type="text"
							onChange={(e) => onChange("fundClosedYear", e.target.value)}
						/>
					</TableCell>
					<TableCell className="pl-4">
						<Select
							multiple
							value={data.fundStages || []}
							className="p-2 border rounded-sm"
							onChange={(e) => onChange("fundStages", e.target.value)}
							input={<Input fullWidth placeholder="Fund Stages" />}
							renderValue={(selected) => {
								if (selected.length === 0) {
									return <em>Fund Stages</em>;
								}
								return selected.map((value) => <Chip key={`row-${row.id}-type-${value}`} label={value} />);
							}}
							MenuProps={MenuProps}
						>
							{["Pre-Seed", "Seed", "Series A", "Series B", "Series C", "Series D", "Series E", "Series F", "Any"].map(
								(name) => (
									<MenuItem key={name} value={name}>
										{name}
									</MenuItem>
								)
							)}
						</Select>
					</TableCell>
					<TableCell className="pl-4">
						<Tags
							question={{
								meta: {
									multiple: false,
									placeholder: "Location",
								},
							}}
							answers={[data.locations || []]}
							setUserAnswer={(_, locations) => {
								onChange("locations", [...locations[0]]);
							}}
							error={null}
						/>
					</TableCell>
					<TableCell className="pl-4">
						<input
							className="p-2 border rounded-sm"
							placeholder="Previous Investments"
							value={data.previousInvestments || ""}
							type="text"
							onChange={(e) => onChange("previousInvestments", e.target.value)}
						/>
					</TableCell>
					<TableCell className="pl-4 w-16">
						<input
							className="p-2 border rounded-sm"
							placeholder="Additional Services"
							value={data.additionalServices || ""}
							type="text"
							onChange={(e) => onChange("additionalServices", e.target.value)}
						/>
					</TableCell>
					<TableCell className="pl-4">
						<input
							className="p-2 border rounded-sm"
							placeholder="Notes"
							value={data.notes || ""}
							type="text"
							onChange={(e) => onChange("notes", e.target.value)}
						/>
					</TableCell>
					<TableCell className="pl-4">
						<input
							className="p-2 border rounded-sm"
							placeholder="LinkedIn"
							value={data.linkedin || ""}
							type="text"
							onChange={(e) => onChange("linkedin", e.target.value)}
						/>
					</TableCell>
					<TableCell className="pl-4">
						<input
							className="p-2 border rounded-sm"
							placeholder="Twitter"
							value={data.twitter || ""}
							type="text"
							onChange={(e) => onChange("twitter", e.target.value)}
						/>
					</TableCell>
					<TableCell className="pl-4">
						<input
							className="p-2 border rounded-sm"
							placeholder="Angel List"
							value={data.angelList || ""}
							type="text"
							onChange={(e) => onChange("angelList", e.target.value)}
						/>
					</TableCell>
					<TableCell className="pl-4">
						<label>
							<input
								className="p-2 border rounded-sm mr-2"
								value={data.isOnBoarded}
								type="checkbox"
								onChange={(e) => onChange("isOnBoarded", e.target.checked)}
							/>
							Is On Boarded?
						</label>
					</TableCell>
					<RightStickyTableCell>
						<div className="flex gap-2">
							<Button variant="contained" color="primary" disabled={saving} onClick={save} size="small">
								{saving ? "Saving..." : "Save"}
							</Button>
							<Button
								variant="contained"
								color="secondary"
								onClick={() => {
									setEditing(false);
									onCancel();
								}}
								size="small"
							>
								Cancel
							</Button>
						</div>
					</RightStickyTableCell>
				</>
			) : (
				<>
					<LeftStickyTableCell className="pl-4">{data.name}</LeftStickyTableCell>
					<TableCell>
						{data.website ? (
							<a href={data.website} className="underline text-indigo-700" target="_blank" rel="noreferrer nofollow">
								{data.website}
							</a>
						) : (
							"-"
						)}
					</TableCell>
					<TableCell>{data.vision || "-"}</TableCell>
					<TableCell>{data.contactEmail || "-"}</TableCell>
					<TableCell>{data.type.join(", ") || "-"}</TableCell>
					<TableCell>{data.industries.join(", ") || "-"}</TableCell>
					<TableCell>{data.fundSize || "-"}</TableCell>
					<TableCell>{data.minChequeSize || "-"}</TableCell>
					<TableCell>{data.maxChequeSize || "-"}</TableCell>
					<TableCell>{data.canLeadRounds || "-"}</TableCell>
					<TableCell>{data.isCurrentlyInvesting || "-"}</TableCell>
					<TableCell>{data.currentlyInvestingUpdatedAt || "-"}</TableCell>
					<TableCell>{data.fundClosedYear || "-"}</TableCell>
					<TableCell>{data.fundStages.join(", ") || "-"}</TableCell>
					<TableCell>{data.locations.join(", ") || "-"}</TableCell>
					<TableCell>{data.previousInvestments || "-"}</TableCell>
					<TableCell>{data.additionalServices || "-"}</TableCell>
					<TableCell>{data.notes || "-"}</TableCell>
					<TableCell>{data.linkedin || "-"}</TableCell>
					<TableCell>{data.twitter || "-"}</TableCell>
					<TableCell>{data.angelList || "-"}</TableCell>
					<TableCell>{data.isOnBoarded ? "Yes" : "No"}</TableCell>
					<RightStickyTableCell>
						<div className="flex gap-2">
							<Button variant="contained" color="primary" size="small" onClick={() => setEditing(true)}>
								Edit
							</Button>
							<Button
								className="ml-2"
								variant="contained"
								color="secondary"
								disabled={deleting}
								size="small"
								onClick={deleteRow}
							>
								{deleting ? "Deleting..." : "Delete"}
							</Button>
						</div>
					</RightStickyTableCell>
				</>
			)}
		</TableRow>
	);
}

export function FunderDatabase() {
	const [rows, setRows] = useState([]);
	const [loading, setLoading] = useState(false);
	const [total, setTotal] = useState(-1);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(20);
	const [search, setSearch] = useState("");
	const [deferredSearch, setDeferredSearch] = useState("");
	const [error, setError] = useState(null);
	const [bulkSaving, setBulkSaving] = useState(false);
	const [refresh, setRefresh] = useState(1);

	const updateSearch = useCallback(
		debounce(function _updateSearch(search) {
			setDeferredSearch(search);
		}, 500),
		[]
	);
	useEffect(() => {
		updateSearch(search);
	}, [search]);
	useEffect(() => {
		const options = { limit: perPage, page: currentPage };
		if (search) {
			options.filter = `searchField||$contL||${search}`;
		}
		(async () => {
			try {
				setError(null);
				setLoading(true);
				const res = await getAll(options);
				setRows(res.data);
				setTotal(res.total);
			} catch (err) {
				console.error("Unable to fetch external funders", err);
				setError("Unable to load");
			} finally {
				setLoading(false);
			}
		})();
	}, [currentPage, deferredSearch, perPage, refresh]);
	return (
		<>
			<Box className="flex justify-between items-center p-4">
				<Box className="text-xl">Funders Database</Box>
				<Box>
					{loading && "Loading..."}
					{error && <Box className="text-red-600">{error}</Box>}
				</Box>
				<Box>
					<TextField
						fullWidth
						variant="outlined"
						label="Search"
						type="text"
						size="small"
						value={search}
						onChange={(e) => {
							setSearch(e.target.value);
						}}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										onClick={() => {
											setSearch("");
										}}
									>
										<Close />
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				</Box>
			</Box>
			<TableContainer component={Paper} className="max-h-[calc(100vh_-_210px)]">
				<Table
					aria-label="funders-database table"
					stickyHeader
					className="[&>thead>tr>th]:min-w-[150px] [&>thead>tr>th.double]:min-w-[250px] [&>thead>tr>th]:max-w-[250px] [&>thead>tr>th]:border [&>tbody>tr>td]:border"
				>
					<TableHead>
						<TableRow>
							<LeftStickyTableCell>Name</LeftStickyTableCell>
							<TableCell>Website</TableCell>
							<TableCell>Vision</TableCell>
							<TableCell>Contact Email</TableCell>
							<TableCell>Type</TableCell>
							<TableCell className="double">Industries</TableCell>
							<TableCell>Fund Size</TableCell>
							<TableCell>Min. Cheque Size</TableCell>
							<TableCell>Max. Cheque Size</TableCell>
							<TableCell>Lead Rounds?</TableCell>
							<TableCell>Now Investing?</TableCell>
							<TableCell>Now Investing UpdatedAt</TableCell>
							<TableCell>Fund Closed Year</TableCell>
							<TableCell>Fund Stages</TableCell>
							<TableCell>Locations</TableCell>
							<TableCell className="double">Previous Investments</TableCell>
							<TableCell>Additional Services</TableCell>
							<TableCell className="double">Notes</TableCell>
							<TableCell>LinkedIn</TableCell>
							<TableCell>Twitter</TableCell>
							<TableCell>Angel List</TableCell>
							<TableCell>OnBoarded?</TableCell>
							<RightStickyTableCell>Actions</RightStickyTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{error && (
							<TableRow>
								<TableCell colSpan={20}>{error}</TableCell>
							</TableRow>
						)}
						{rows.length <= 0 && (
							<TableRow>
								<TableCell colSpan={20}>{loading ? "Loading..." : "No data"}</TableCell>
							</TableRow>
						)}
						{rows.length > 0 &&
							rows.map((row, idx) => (
								<Row
									key={`row-${row.id || idx}`}
									row={row}
									onSave={(res) => {
										const _rows = [...rows];
										_rows[idx] = res;
										setRows(_rows);
									}}
									onDelete={() => {
										const _rows = [...rows];
										_rows.splice(idx, 1);
										setRows(_rows);
									}}
									onCancel={() => {
										if (row.id) {
											return;
										}
										const _rows = [...rows];
										_rows.splice(idx, 1);
										setRows(_rows);
									}}
								/>
							))}
					</TableBody>
				</Table>
			</TableContainer>
			<div className="flex justify-between py-2">
				<Button
					variant="contained"
					color="primary"
					size="small"
					onClick={() => {
						const _rows = [...rows];
						_rows.push({ name: "" });
						setRows(_rows);
					}}
				>
					+ Add Funder
				</Button>
				<Button variant="contained" disabled={bulkSaving} color="primary" size="small" component="label">
					Bulk Import
					<input
						type="file"
						className="sr-only"
						accept="text/csv"
						value={""}
						onChange={(e) => {
							const file = e.target.files[0];
							if (!file.type.includes("csv")) {
								Alert.error("File type must be csv");
								return false;
							}
							const fileReader = new FileReader();
							fileReader.onload = async function () {
								const contents = this.result;
								const { data, error } = Papa.parse(contents, {
									header: true,
									transform: (value, colName) => {
										if (
											colName === "industries" ||
											colName === "locations" ||
											colName === "fundStages" ||
											colName === "type"
										) {
											return value ? value.split(",").map((i) => i.trim()) : [];
										}
										if (
											colName === "canLeadRounds" ||
											colName === "isCurrentlyInvesting" ||
											colName === "isOnBoarded"
										) {
											const val = value.toLowerCase();
											return val === "yes" || val === "true" || val == 1;
										}
										return value || null;
									},
								});
								if (error) {
									Alert.error(`Parsing failed with error ${error.message}`);
									console.error("Error while parsing csv", error);
									return false;
								}
								try {
									setBulkSaving(true);
									data.forEach((d) => {
										d.searchField = Object.values(d)
											.filter((i) => i)
											.join(" ");
										return d;
									});
									await bulk({ bulk: data });
									setRefresh(Date.now());
								} catch (err) {
									console.error("error while uploading csv file", err);
									Alert.error("Unable to save");
								} finally {
									setBulkSaving(false);
								}
							};
							fileReader.readAsText(file);
						}}
					/>
				</Button>
				<Button
					variant="contained"
					color="primary"
					size="small"
					onClick={() => {
						const data = [
							[
								"name",
								"vision",
								"type",
								"industries",
								"website",
								"contactEmail",
								"fundSize",
								"minChequeSize",
								"maxChequeSize",
								"canLeadRounds",
								"isCurrentlyInvesting",
								"currentlyInvestingUpdatedAt",
								"fundClosedYear",
								"fundStages",
								"locations",
								"previousInvestments",
								"additionalServices",
								"notes",
								"linkedin",
								"twitter",
								"angelList",
								"isOnBoarded",
							],
						];
						const csv = `${data.map((row) => row.join(",")).join("\n")}`;
						const blob = new Blob([csv], { type: "text/csv" });
						const link = document.createElement("a");
						link.href = window.URL.createObjectURL(blob);
						link.download = "import-funder-sample.csv";
						link.click();
					}}
				>
					Download Sample
				</Button>
				<TablePagination
					component={"div"}
					rowsPerPageOptions={[20, 50, 100]}
					align="left"
					count={total}
					rowsPerPage={perPage}
					page={currentPage - 1}
					SelectProps={{
						inputProps: { "aria-label": "rows per page" },
						native: true,
					}}
					onPageChange={(e, newPage) => {
						setCurrentPage(newPage + 1);
					}}
					onRowsPerPageChange={(e) => {
						setPerPage(parseInt(e.target.value, 10));
						setCurrentPage(1);
					}}
				/>
			</div>
		</>
	);
}
