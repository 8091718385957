import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import Save from "@material-ui/icons/Save";
import { useLoaderData, defer, Link, useParams, useNavigate } from "react-router-dom";
import { useCallback } from "react";

import { create, getTeamMember, update } from "src/api/teamMembers";
import { Main } from "src/components/commons/Main";
import { Async } from "src/components/commons/Async";
import { getAllWithAnswers } from "src/api/questions";
import { ContextProvider } from "src/components/campaigns/Details/Context";
import { Details } from "src/components/campaigns/Details";
import { ONBOARDING_STEPS, QUESTION_CATEGORIES, QUESTION_TYPES } from "src/helpers/constants";
import getByPath from "src/helpers/getByPath";
import { localstorage } from "src/lib/storage";
import { useDispatch } from "react-redux";
import { updateUserOnboarding } from "src/store/slices/auth";

export default function EditTeamMemberDetails() {
	const { id, memberId } = useParams();
	const { data } = useLoaderData();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const prepareUserAnswers = useCallback(
		(questions, teamMember) => {
			if (!teamMember.id) {
				return [];
			}
			return questions
				.map((question) => {
					const { field } = question.meta || {};
					let value = getByPath(teamMember, field?.split(".") || []);
					const userAnswer = {
						questionId: question.id,
					};
					if (question.answers.length && question.type === QUESTION_TYPES.MULTIPLE_CHOICE) {
						const userAnswers = [];
						value = Array.isArray(value) ? value : [value];
						value.forEach((val) => {
							const answerId = question.answers.find((a) => val === a.answer)?.id;
							if (answerId) {
								userAnswers.push({
									questionId: question.id,
									answerId,
								});
							} else {
								userAnswers.push({
									questionId: question.id,
									response: val,
								});
							}
						});
						return userAnswers;
					} else if (question.answers.length && question.type !== QUESTION_TYPES.AUTOCOMPLETE) {
						if (field === "isVisible") {
							value = value === true ? "Yes" : "No";
						}
						userAnswer.answerId = question.answers.find((a) => a.answer === value)?.id;
						if (!userAnswer.answerId && question.meta.hasOther) {
							userAnswer.response = value;
						}
					} else {
						if (field === "fullyQualifiedPersonName") {
							value = JSON.stringify(teamMember.fullyQualifiedPersonName || {});
						}
						userAnswer.response = value;
					}
					return userAnswer;
				})
				.flat();
		},
		[memberId]
	);

	const save = useCallback((teamMember) => {
		return async (_, answerObject) => {
			answerObject.id = teamMember.id;
			answerObject.teamId = teamMember.teamId;
			answerObject.fullyQualifiedPersonName = JSON.parse(answerObject.fullyQualifiedPersonName);
			const { firstname, middlename, lastname, suffix } = answerObject.fullyQualifiedPersonName;
			const payload = {
				...answerObject,
				displayName: [firstname, middlename, lastname, suffix].filter((i) => i).join(" "),
				isVisible: answerObject.isVisible === "Yes",
			};
			if (teamMember.id) {
				await update(payload);
				localstorage.removeItem(`team_member_${memberId}`);
			} else {
				await create(payload);
				localstorage.removeItem("team_member_add");
			}
			return;
		};
	}, []);

	return (
		<>
			<Breadcrumbs separator="›" aria-label="breadcrumb" style={{ marginBottom: "1rem" }}>
				<Link to={`/teams`}>
					<Typography color="textSecondary">Teams</Typography>
				</Link>
				<Link to={`/team/${id}/members`}>
					<Typography color="textSecondary">Team Members</Typography>
				</Link>
				<Typography color="textPrimary">Detail</Typography>
			</Breadcrumbs>
			<Main>
				<Box display={"flex"} justifyContent="space-between" p={4} px={4} borderBottom={"1px solid rgb(239 232 251)"}>
					<Typography component={"h2"} variant="h6">
						Team Member Profile
					</Typography>
				</Box>
				<Async error={"Unable to fetch the team member"} promise={data}>
					{({ questions, teamMember }) => {
						return (
							<ContextProvider
								userAnswers={prepareUserAnswers(questions, teamMember)}
								questions={questions}
								saveFn={save(teamMember)}
								category={QUESTION_CATEGORIES.TEAM_MEMBER}
								entityId={memberId}
							>
								{({ validateAndSave, isSaving }) => {
									return (
										<>
											<Box
												component={"form"}
												p={4}
												px={4}
												height={"100%"}
												display="flex"
												flexDirection="column"
												onSubmit={async (e) => {
													e.preventDefault();
													const res = await validateAndSave();
													if (res.error !== true) {
														navigate(`/team/${id}/member/add`);
														window.scroll(0, 0);
													}
												}}
											>
												<Details />
												<Box display={"flex"} justifyContent="center" margin={5} className="gap-4">
													<Button
														color="primary"
														variant="contained"
														startIcon={<Save />}
														type="submit"
														disabled={isSaving}
													>
														{isSaving ? "Saving..." : "Save & Add"}
													</Button>
													<Button
														// color="secondary"
														variant="contained"
														startIcon={<Save />}
														type="button"
														onClick={async () => {
															const res = await validateAndSave();
															if (res.error !== true) {
																await dispatch(
																	updateUserOnboarding({ completedStep: ONBOARDING_STEPS.TEAM, isCompleted: true })
																);
																navigate("/dashboard");
															}
														}}
														disabled={isSaving}
													>
														{isSaving ? "Saving..." : "Save & Exit"}
													</Button>
												</Box>
											</Box>
										</>
									);
								}}
							</ContextProvider>
						);
					}}
				</Async>
			</Main>
		</>
	);
}

export async function teamMemberLoader({ params }) {
	const { id, memberId } = params;
	const promise = memberId === "add" ? Promise.resolve({ teamId: Number(id) }) : getTeamMember(memberId);

	const data = Promise.all([
		getAllWithAnswers({ category: QUESTION_CATEGORIES.TEAM_MEMBER, per_page: 100 }),
		promise,
	]).then(([questions, teamMember]) => {
		return {
			questions: questions.filter((q) => q.meta.field),
			teamMember,
		};
	});
	return defer({ data });
}
