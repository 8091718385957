import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
// import Modal from "@material-ui/core/Modal";
import Save from "@material-ui/icons/Save";
// import Info from "@material-ui/icons/Info";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLoaderData, useNavigate, defer, useAsyncError } from "react-router-dom";
import { update } from "src/api/auth";
import { getAllWithAnswers } from "src/api/questions";
import { Details } from "src/components/campaigns/Details";
import { ContextProvider } from "src/components/campaigns/Details/Context";
import { Async } from "src/components/commons/Async";
import { Main } from "src/components/commons/Main";
import Alert from "src/helpers/alert";
import { clone } from "src/helpers/clone";
import { ONBOARDING_STEPS, QUESTION_CATEGORIES, QUESTION_TYPES, ROLES } from "src/helpers/constants";
import getByPath from "src/helpers/getByPath";
import { setAuthUser } from "src/store/slices/auth";
import { localstorage } from "src/lib/storage";

const questionCategoryMap = {
	[ROLES.FUNDER]: QUESTION_CATEGORIES.FUNDER_PROFILE,
	[ROLES.FOUNDER]: QUESTION_CATEGORIES.FOUNDER_PROFILE,
	[ROLES.VENDOR]: QUESTION_CATEGORIES.VENDOR_PROFILE,
};

export default function Profile() {
	const navigate = useNavigate();
	const { authUser, currentRole } = useSelector(({ auth }) => auth);
	const dispatch = useDispatch();
	const [user] = useState(clone(authUser));
	const { promise } = useLoaderData();
	const error = useAsyncError();
	const storageKey = `${questionCategoryMap[currentRole].toLowerCase()}_${user.id}`;
	const prepareUserAnswers = useCallback((questions, user) => {
		return questions
			.map((question) => {
				const { field = "" } = question.meta || {};
				if (!field) {
					return [];
				}
				let value = getByPath(user, field?.split("."));
				const userAnswer = {
					questionId: question.id,
				};
				if (question.answers.length && question.type === QUESTION_TYPES.MULTIPLE_CHOICE) {
					const userAnswers = [];
					value = Array.isArray(value) ? value : [value];
					value.forEach((val) => {
						const answerId = question.answers.find((a) => val === a.answer)?.id;
						if (answerId) {
							userAnswers.push({
								questionId: question.id,
								answerId,
							});
						} else {
							userAnswers.push({
								questionId: question.id,
								response: val,
							});
						}
					});
					return userAnswers;
				} else if (question.answers.length && question.type !== QUESTION_TYPES.AUTOCOMPLETE) {
					userAnswer.answerId = question.answers.find((a) => a.answer === value)?.id;
				} else {
					if (field === "name") {
						value = JSON.stringify({
							firstname: user.firstName,
							lastname: user.lastName,
							title: user.profile.title,
							suffix: user.profile.suffix,
							middlename: user.profile.middlename,
						});
					} else if (field === "profile.workEmail") {
						value = user.email;
					}
					userAnswer.response = value;
				}
				return userAnswer;
			})
			.flat();
	}, []);

	const save = useCallback(
		async (userAnswers, answerObject, id2questionMap) => {
			const { completedStep, isCompleted } = authUser.onboarding[currentRole];
			try {
				const name = JSON.parse(answerObject.name);
				const payload = {
					id: user.id,
					firstName: name.firstname,
					lastName: name.lastname,
					email: user.email,
					profile: answerObject.profile,
				};
				// check if angel
				const positionQuestion = Array.from(id2questionMap.values()).find((q) => q.meta.field === "profile.positions");
				if (positionQuestion) {
					const positionQuestionUAIds = userAnswers.reduce((acc, ua) => {
						if (ua.questionId === positionQuestion.id) {
							acc.push(ua.answerId);
						}
						return acc;
					}, []);
					const positionKeywords = positionQuestion.answers.reduce((acc, answer) => {
						if (positionQuestionUAIds.includes(answer.id)) {
							acc.add(answer.meta.keyword);
						}
						return acc;
					}, new Set());
					// if more than one, means they invest as group, firm or angel
					// if only one and it is angel, then the user invests as angle only and so mark him as angel
					if (positionKeywords.size === 1 && positionKeywords.has("ANGEL")) {
						payload.profile.isAngel = true;
					}
				}
				payload.profile.middlename = name.middlename;
				payload.profile.suffix = name.suffix;
				payload.profile.title = name.title;
				if (isCompleted === false && (ROLES.FUNDER === currentRole || ROLES.VENDOR === currentRole)) {
					payload.onboarding = { ...authUser.onboarding };
					payload.onboarding[currentRole] = {
						isCompleted: false,
						completedStep: payload.profile.isAngel ? ONBOARDING_STEPS.ORGANIZATION : ONBOARDING_STEPS.PROFILE,
					};
				}
				const _user = await update(payload);
				dispatch(setAuthUser(_user));
				localstorage.removeItem(storageKey);
				if (currentRole === ROLES.FOUNDER && completedStep === ONBOARDING_STEPS.SIGNUP) {
					navigate("/founder-profile");
					return;
				}
				navigate("/dashboard");
				return;
			} catch (err) {
				Alert.error("Unable to save");
				console.error("Error while saving profile", err);
			}
		},
		[user]
	);
	if (error) {
		console.error("Error while fetching data", error);
	}

	return (
		<Main>
			<Async
				error={
					"Oops.. there was an error. Refresh the page and see if that fixes it. If the issue persists, please contact us"
				}
				promise={promise}
			>
				{({ questions }) => {
					return (
						<ContextProvider
							userAnswers={localstorage.getItem(storageKey) ? [] : prepareUserAnswers(questions, user)}
							questions={questions}
							saveFn={save}
							category={questionCategoryMap[currentRole]}
							entityId={user.id}
						>
							{({ validateAndSave, isSaving }) => {
								return (
									<>
										<Box
											display={"flex"}
											justifyContent="space-between"
											alignItems={"center"}
											p={4}
											px={4}
											borderBottom={"1px solid rgb(239 232 251)"}
											className="sticky top-[64px] bg-white rounded-t-lg z-10"
										>
											<Typography component={"h2"} variant="h6">
												My Profile
											</Typography>
											<Button
												form="profile"
												variant="contained"
												color="primary"
												startIcon={<Save />}
												type="submit"
												disabled={isSaving}
											>
												{isSaving ? "Saving..." : "Next"}
											</Button>
										</Box>
										<Box
											component={"form"}
											p={4}
											px={4}
											height={"100%"}
											display="flex"
											flexDirection="column"
											id="profile"
											onSubmit={(e) => {
												e.preventDefault();
												validateAndSave();
											}}
										>
											<Details
												saveBtn={
													<Button
														key={"save-btn"}
														color="primary"
														variant="contained"
														startIcon={<Save />}
														type="submit"
														disabled={isSaving}
													>
														{isSaving ? "Saving..." : "Next"}
													</Button>
												}
											/>
											<Box display={"flex"} justifyContent="center" margin={5}>
												<Button
													key={"save-btn"}
													color="primary"
													variant="contained"
													startIcon={<Save />}
													type="submit"
													disabled={isSaving}
												>
													{isSaving ? "Saving..." : "Next"}
												</Button>
											</Box>
										</Box>
									</>
								);
							}}
						</ContextProvider>
					);
				}}
			</Async>
			{/* <Modal
				open={showIntroPopup}
				// maxWidth="xs"
				onClose={() => setShowIntroPopup(false)}
				aria-labelledby="intro-title"
				aria-describedby="intro-description"
			>
				<Box
					maxWidth={"100%"}
					width="400px"
					position={"absolute"}
					bgcolor="background.paper"
					borderRadius={12}
					padding={4}
					fontSize={16}
					className={"site-modal"}
				>
					<Box component={"h2"} textAlign="center" mb={2} id="intro-title">
						Welcome Aboard {authUser.firstName}!
					</Box>
					<Box component={"p"} className="" textAlign="center" mb={2} id="intro-description">
						Thank you for signing up. Let's begin with your profile.
					</Box>
					<Box display={"flex"} justifyContent="center" marginTop={4}>
						<Button type="button" variant="contained" color="primary" onClick={() => setShowIntroPopup(false)}>
							Let's begin
						</Button>
					</Box>
				</Box>
			</Modal> */}
		</Main>
	);
}

export function profileLoader({ auth }) {
	const category = questionCategoryMap[auth.currentRole];
	const promises = [getAllWithAnswers({ category: category.toUpperCase(), per_page: 100 })];
	// if (auth.currentRole === ROLES.FOUNDER) {
	// 	promises.push(getAllWithAnswers({ category: QUESTION_CATEGORIES.TEAM_MEMBER, per_page: 100 }));
	// }
	const promise = Promise.all(promises).then(([questions]) => {
		// let questions = [];
		// const profileFieldKeys = profileQuestions.reduce((acc, q) => {
		// 	if (q.meta.field) {
		// 		questions.push(q);
		// 		const field = q.meta.field.split(".");
		// 		acc.push(field[field.length - 1]);
		// 	}
		// 	return acc;
		// }, []);
		// if (teamQuestions) {
		// 	questions = questions.concat(
		// 		teamQuestions.reduce((acc, question) => {
		// 			const fieldPath = question.meta.field?.split(".") || [];
		// 			const key = fieldPath[fieldPath.length - 1];
		// 			if (
		// 				!question.meta.field ||
		// 				question.meta.field === "fullyQualifiedPersonName" ||
		// 				(key && profileFieldKeys.includes(key))
		// 			) {
		// 				return acc;
		// 			}
		// 			question.meta.step = 2;
		// 			question.group = "Professional Information";
		// 			question.meta.field = "teamMember." + question.meta.field;
		// 			acc.push(question);
		// 			return acc;
		// 		}, [])
		// 	);
		// }
		return {
			questions,
		};
	});
	return defer({ promise });
}
