import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import DeleteForever from "@material-ui/icons/DeleteForever";
import IconButton from "@material-ui/core/IconButton";
import Edit from "@material-ui/icons/Edit";
import { useEffect, useState } from "react";
import Alert from "src/helpers/alert";
import { create, execute, getAll, remove, update } from "src/api/dataView";

function Row({ row, onSave, onDelete, isSelected, onSelect, onCancel }) {
	const [saving, setSaving] = useState(false);
	const [editing, setEditing] = useState(!row.id);
	const [deleting, setDeleting] = useState(false);
	const [data, setData] = useState(() => ({ ...row }));
	function onChange(field, value) {
		setData({ ...data, [field]: value });
	}

	async function save() {
		try {
			setSaving(true);
			let res = data;
			if (data.id) {
				res = await update(data);
			} else {
				res = await create(data);
			}
			onSave(res);
			setEditing(false);
		} catch (err) {
			console.error("Unable to save industry", err);
			Alert.error("Unable to save");
		} finally {
			setSaving(false);
		}
	}

	async function deleteRow() {
		try {
			setDeleting(true);
			await remove(data);
			onDelete();
		} catch (err) {
			console.error("Unable to delete industry", err);
			Alert.error("Unable to delete");
		} finally {
			setDeleting(false);
		}
	}

	return (
		<TableRow key={data.id}>
			<TableCell className={isSelected ? "bg-gray-100" : ""}>
				{editing && (
					<>
						<input
							className="p-2 border rounded-sm w-full mb-1"
							placeholder="Name"
							value={data.name}
							type="text"
							onChange={(e) => onChange("name", e.target.value)}
						/>
						<textarea
							className="w-full p-2 border rounded-sm"
							placeholder="Description"
							value={data.description}
							type="text"
							onChange={(e) => onChange("description", e.target.value)}
						/>
						<textarea
							className="w-full p-2 border rounded-sm"
							placeholder="Query"
							value={data.query}
							type="text"
							onChange={(e) => onChange("query", e.target.value)}
						/>
						<div className="flex gap-2">
							<Button variant="contained" color="primary" disabled={saving} onClick={save} size="small">
								{saving ? "Saving..." : "Save"}
							</Button>
							<Button
								variant="contained"
								color="Secondary"
								onClick={() => {
									setEditing(false);
									onCancel();
								}}
								size="small"
							>
								Cancel
							</Button>
						</div>
					</>
				)}
				{!editing && (
					<div onClick={onSelect}>
						<div className="flex gap-2 items-center">
							<div className=" flex-grow">{data.name}</div>
							<div className="flex items-center justify-start gap-2">
								<IconButton variant="contained" color="primary" size="small" onClick={() => setEditing(true)}>
									<Edit />
								</IconButton>
								<IconButton variant="contained" color="secondary" disabled={deleting} size="small" onClick={deleteRow}>
									{deleting ? "Deleting..." : <DeleteForever />}
								</IconButton>
							</div>
						</div>
						<div className="my-2 text-slate-500">{data.description}</div>
					</div>
				)}
			</TableCell>
		</TableRow>
	);
}

export function DataView() {
	const [rows, setRows] = useState([]);
	const [selectedRow, setSelectedRow] = useState(null);
	const [result, setResult] = useState([]);
	const [resultColumns, setResultColumns] = useState([]);
	const [query, setQuery] = useState("");
	const [loading, setLoading] = useState(false);
	const [total, setTotal] = useState(-1);
	// const [currentPage, setCurrentPage] = useState(1);
	// const [perPage, setPerPage] = useState(20);
	const [error, setError] = useState(null);
	const [queryLoading, setQueryLoading] = useState(false);
	const [queryError, setQueryError] = useState(null);

	async function run() {
		if (!query.trim()) {
			return;
		}
		setQueryLoading(true);
		setQueryError(null);
		setResult([]);
		setResultColumns([]);
		try {
			const res = await execute({ query });
			if (res.length) {
				setResultColumns(Object.keys(res[0]));
				setResult(res);
			}
		} catch (error) {
			setQueryError(error.message || error);
		} finally {
			setQueryLoading(false);
		}
	}
	useEffect(() => {
		// const options = { limit: perPage, page: currentPage };
		(async () => {
			try {
				setError(null);
				setLoading(true);
				const res = await getAll({});
				setRows(res.data);
				setTotal(res.total);
			} catch (err) {
				console.error("Unable to fetch industries", err);
				setError("Unable to load");
			} finally {
				setLoading(false);
			}
		})();
	}, []);
	return (
		<div className="flex gap-2">
			<div className="w-1/4">
				<TableContainer component={Paper} className="h-[calc(100vh_-_100px)]">
					<Table aria-label="industries table" stickyHeader>
						<TableHead>
							<TableRow>
								<TableCell>
									<Box className="text-xl flex justify-between">
										Data Views ({total}){" "}
										<Button
											variant="contained"
											color="primary"
											size="small"
											onClick={() => {
												const _rows = [...rows];
												_rows.push({ name: "", query: "", description: "" });
												setRows(_rows);
											}}
										>
											+ Add
										</Button>
									</Box>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{(loading || error) && (
								<Box>
									{loading && "Loading..."}
									{error && <Box className="text-red-600">{error}</Box>}
								</Box>
							)}
							{rows.length <= 0 && (
								<TableRow>
									<TableCell colSpan={1}>{loading ? "Loading..." : "No data"}</TableCell>
								</TableRow>
							)}
							{rows.length > 0 &&
								rows.map((row, idx) => (
									<Row
										key={`row-${row.id || idx}`}
										row={row}
										onSave={(res) => {
											const _rows = [...rows];
											_rows[idx] = res;
											setRows(_rows);
										}}
										isSelected={selectedRow?.id === row.id}
										onSelect={() => {
											setSelectedRow(row);
											setQuery(row.query);
										}}
										onDelete={() => {
											const _rows = [...rows];
											_rows.splice(idx, 1);
											setRows(_rows);
										}}
										onCancel={() => {
											if (row.id) {
												return;
											}
											const _rows = [...rows];
											_rows.splice(idx, 1);
											setRows(_rows);
										}}
									/>
								))}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
			<div className="w-3/4">
				<div className="relative mb-2">
					<textarea
						value={query}
						onChange={(e) => setQuery(e.target.value)}
						className="w-full MuiPaper-elevation1 p-2 rounded-sm"
						rows={4}
						placeholder="Query"
					></textarea>
					<div className="absolute bottom-2 right-1">
						<Button disabled={queryLoading} variant="contained" color="primary" size="small" onClick={run}>
							{queryLoading ? "Running..." : "Run"}
						</Button>
					</div>
				</div>
				<div>
					Total Rows: {result.length}
					{result.length > 0 && (
						<Button
							variant="text"
							color="primary"
							size="small"
							onClick={() => {
								let csv = resultColumns.join(",") + "\n";
								csv += result
									.map((i) =>
										Object.values(i)
											.map((i) => {
												const type = typeof i;
												return type === "object"
													? `"${JSON.stringify(i).replaceAll('"', "'")}"`
													: type === "boolean"
													? i.toString()
													: `"${i || ""}"`;
											})
											.join(",")
									)
									.join("\n");
								const blob = new Blob([csv], { type: "text/csv" });
								const link = document.createElement("a");
								link.href = window.URL.createObjectURL(blob);
								link.download = "data.csv";
								link.click();
							}}
						>
							Export
						</Button>
					)}
				</div>
				<TableContainer component={Paper} className="max-h-[calc(100vh_-_200px)] overflow-y-auto">
					<Table aria-label="dataview table" stickyHeader>
						{resultColumns.length > 0 && (
							<TableHead>
								<TableRow>
									{resultColumns.map((col) => (
										<TableCell key={`res-col-${col}`}>{col}</TableCell>
									))}
								</TableRow>
							</TableHead>
						)}
						<TableBody>
							{queryError && (
								<TableRow>
									<TableCell colSpan={1}>
										<span className="text-red-600">{queryError}</span>
									</TableCell>
								</TableRow>
							)}
							{!queryError && result.length <= 0 && (
								<TableRow>
									<TableCell align="center" colSpan={resultColumns.length || 1}>
										{queryLoading ? "Loading..." : "No data"}
									</TableCell>
								</TableRow>
							)}
							{result.length > 0 &&
								result.map((row, idx) => (
									<TableRow key={`res-${idx}`}>
										{Object.values(row).map((value, colIdx) => {
											const type = typeof value;
											return (
												<TableCell key={`row-cell-${idx}-col-${colIdx}`}>
													{value && type !== "object" ? value : JSON.stringify(value)}
												</TableCell>
											);
										})}
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		</div>
	);
}
