import React, { useContext, useEffect } from "react";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ToastContainer } from "react-toastify";
import makeStyles from "@material-ui/styles/makeStyles";
import { IntlProvider } from "react-intl";
// import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createTheme, jssPreset, StylesProvider } from "@material-ui/core/styles";
// import DateFnsUtils from '@date-io/date-fns';
import { create } from "jss";
import rtl from "jss-rtl";
import AppContext from "../contextProvider/AppContextProvider/AppContext";
import AppLocale from "../../../i18n";
import AppLayout from "../AppLayout";
import "react-toastify/dist/ReactToastify.css";

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const useStyles = makeStyles(() => ({
  toastContainer: {
    '& .Toastify__toast': {
      padding: 0,
      minHeight: 48
    }
  },
  toastBody: {
    padding: 0
  }
}))

const AppWrapper = ({ children }) => {
  const classes = useStyles();
	const { theme, locale, direction } = useContext(AppContext);
	const currentAppLocale = AppLocale[locale.locale];
	useEffect(() => {
		if (direction === "rtl") {
			document.body.setAttribute("dir", "rtl");
		} else {
			document.body.setAttribute("dir", "ltr");
		}
	}, [direction]);

	return (
		<IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
			<ThemeProvider theme={createTheme(theme)}>
				{/* <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
				<StylesProvider jss={jss}>
					<CssBaseline />
					<AppLayout>{children}</AppLayout>
					<ToastContainer
						position="bottom-right"
						autoClose={5000}
						hideProgressBar={false}
						newestOnTop={false}
						closeOnClick
            toastClassName={classes.toast}
            className={classes.toastContainer}
            bodyClassName={classes.toastBody}
						rtl={false}
						pauseOnFocusLoss
						draggable
						pauseOnHover
						theme="colored"
					/>
				</StylesProvider>
				{/* </MuiPickersUtilsProvider> */}
			</ThemeProvider>
		</IntlProvider>
	);
};

export default AppWrapper;
