import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { memo } from "react";
import { TenantRules } from "./TenantRules";

export const StepForm = memo(function _StepForm({
	group,
	groupDescription,
	steps,
	stepIndex,
	questions,
	onChange,
	errors = {},
	onStepOrderChange,
}) {
	return (
		<Box p={4}>
			<Typography component={"h2"} variant="h6" style={{ marginBottom: 12 }}>
				Step Details
			</Typography>
			<Box pb={4} width={"50%"}>
				<TextField
					label={"Step Name*"}
					id="edit-step-name"
					variant="outlined"
					value={group}
					autoFocus
					error={!!errors.step}
					helperText={errors.step || ""}
					fullWidth
					onChange={(e) => {
						onChange({ group: e.target.value, groupDescription, questions: questions });
					}}
				/>
			</Box>
			<TextField
				multiline
				label={"Step Description"}
				id="edit-step-Description"
				variant="outlined"
				minRows={3}
				value={groupDescription}
				fullWidth
				onChange={(e) => {
					onChange({ group, groupDescription: e.target.value, questions });
				}}
			/>
			<Box pt={4} width={"50%"}>
				<TextField
					select
					fullWidth
					variant="outlined"
					value={stepIndex}
					label="Order"
					size={"small"}
					onChange={(e) => {
						onStepOrderChange(+e.target.value);
					}}
				>
					<MenuItem value={""}>Order</MenuItem>
					{(steps || []).map((step, idx) => (
						<MenuItem key={`step_order_${idx}`} disabled={stepIndex === idx} value={idx}>
							{idx + 1}
						</MenuItem>
					))}
				</TextField>
			</Box>
			<TenantRules stepIndex={stepIndex} />
		</Box>
	);
});
