import React from "react";
import { useSelector } from "react-redux";
// import { Redirect } from "react-router";
import {
	useLocation,
	Route,
	useNavigate,
	createBrowserRouter,
	createRoutesFromElements,
	Outlet,
	Navigate,
} from "react-router-dom";
import { Dashboard, dashboardLoader } from "./pages/Dashboard";
import { Campaigns, campaignsLoader } from "./pages/Campaigns";
import {
	Opportunities as AdminOpportunities,
	opportunitiesLoader as adminOpportunitiesLoader,
} from "./pages/admin/Opportunities";
import { Opportunities, opportunitiesLoader } from "./pages/Opportunities";
import { Details, detailsLoader } from "./pages/Details";
import Login from "./pages/Login";
import Register from "./pages/Register";
import ForgotPassword from "./pages/ForgotPassword";
import Error404 from "./pages/404";
import AppContextProvider from "./@jumbo/components/contextProvider/AppContextProvider";
import AppWrapper from "./@jumbo/components/AppWrapper";
import { Questions, questionsLoader } from "./pages/admin/questions";
import Profile, { profileLoader } from "./pages/Profile";
import { MatchedOpportunity, matchedOpportunityLoader } from "./pages/MatchedOpportunity";
import { faqLoader, FAQs } from "./pages/FAQs";
import { AdminCampaigns, adminCampaignsLoader } from "./pages/admin/Campaigns";
import {
	AdminViewCampaignDetail,
	adminViewCampaignDetailLoader,
	tenantAdminViewCampaignDetailLoader,
} from "./pages/admin/ViewCampaignDetail";
import AdminMatchView, { adminMatchLoader } from "./pages/admin/Match";
import EditTeamMemberDetails, { teamMemberLoader } from "./pages/Teams/Members/Edit";
import Teams, { teamsLoader } from "./pages/Teams";
import TeamDetail, { teamDetailLoader } from "./pages/Teams/Detail";
import TeamMembers, { teamMembersLoader } from "./pages/Teams/Members";
import { ROLES } from "./helpers/constants";
import Organization, { organizationLoader } from "./pages/Organization";
import { ContactUs } from "./pages/ContactUs";
import { AdminFAQs, FAQsLoader } from "./pages/admin/FAQs";
import { Users } from "./pages/admin/Users";
import { MatchPreview } from "./pages/admin/MatchPreview";
import FounderTeamMemberDetails, { founderTeamMemberLoader } from "./pages/FounderTeamMember";
import { VendorServices, vendorServicesLoader } from "./pages/VendorServices";
import { MatchedCampaign, matchedCampaignLoader } from "./pages/MatchedCampaign";
import { OpportunityMatches } from "./pages/OpportunityMatches";
import { CampaignMatches } from "./pages/CampaignMatches";
import MatchOpportunity from "./pages/admin/MatchOpportunity";
import {
	AdminViewOpportunityDetail,
	adminViewOpportunityDetailLoader,
	tenantAdminViewOpportunityDetailLoader,
} from "./pages/admin/ViewOpportunityDetail";
import FilterAndMatch from "./pages/admin/FilterAndMatch";
import ResetPasswordPage from "./pages/ResetPassword";
import { Industries } from "./pages/admin/Industries";
import { VerifyEmailPage } from "./pages/VerifyEmail";
import { DataView } from "./pages/admin/DataView";
import { FunderDatabase } from "./pages/admin/FunderDatabase";
import { TriggeredActions } from "./pages/admin/TriggeredActions";
import { LeanCanvas, leanCanvasLoader } from "./pages/LeanCanvas";
import { Tenants } from "./pages/admin/Tenants";
import { FounderApplications } from "./pages/FounderApplications";
import { ApplicationTeam, applicationTeamLoader } from "./pages/ApplicationTeam";
import { FunderApplications } from "./pages/FunderApplications";
import { AdminVendors, adminVendorsLoader } from "./pages/admin/Vendors";
import { AdminViewVendorDetail, adminViewVendorDetailLoader } from "./pages/admin/ViewVendorDetail";

const RestrictedRoute = ({ component, children }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const { authUser } = useSelector(({ auth }) => auth);
	if (!authUser) {
		navigate("/login", { state: { from: location.pathname }, replace: true });
		return null;
	}
	return component || children;
};

const RestrictedAdminRoute = ({ children }) => {
	const { authUser } = useSelector(({ auth }) => auth);
	if (authUser && authUser.roles.includes(ROLES.SUPERADMIN)) {
		return children;
	} else {
		return <Navigate to="/dashboard" replace />;
	}
};

export function getRouter(store) {
	return createBrowserRouter(
		createRoutesFromElements(
			<Route
				path="/"
				element={
					<AppContextProvider>
						<AppWrapper>
							<Outlet />
						</AppWrapper>
					</AppContextProvider>
				}
			>
				<Route
					path="/dashboard"
					element={<RestrictedRoute component={<Dashboard />} />}
					loader={(arg) => {
						arg.user = store.getState().auth.authUser;
						arg.currentRole = store.getState().auth.currentRole;
						return dashboardLoader(arg);
					}}
				/>
				<Route path="/lean-canvas" loader={leanCanvasLoader} element={<RestrictedRoute component={<LeanCanvas />} />} />
				<Route
					path="/campaigns"
					loader={campaignsLoader}
					// action={campaignAction}
					shouldRevalidate={({ actionResult }) => Object.keys(actionResult?.errors || {}).length <= 0}
					element={<RestrictedRoute component={<Campaigns />} />}
					// errorElement={	}
				/>
				<Route
					path="/opportunities"
					loader={opportunitiesLoader}
					shouldRevalidate={({ actionResult }) => Object.keys(actionResult?.errors || {}).length <= 0}
					element={<RestrictedRoute component={<Opportunities />} />}
					// errorElement={	}
				/>
				<Route
					path="/opportunity/:id/matches"
					element={<RestrictedRoute component={<OpportunityMatches />} />}
					// errorElement={	}
				/>
				<Route
					path="/campaign/:id/matches"
					element={<RestrictedRoute component={<CampaignMatches />} />}
					// errorElement={	}
				/>
				<Route
					path="/services"
					loader={vendorServicesLoader}
					shouldRevalidate={({ actionResult }) => Object.keys(actionResult?.errors || {}).length <= 0}
					element={<RestrictedRoute component={<VendorServices />} />}
					// errorElement={	}
				/>
				<Route
					path="/match/:id/opportunity"
					loader={matchedOpportunityLoader}
					element={<RestrictedRoute component={<MatchedOpportunity />} />}
					// errorElement={	}
				/>
				<Route
					path="/match/:id/campaign"
					loader={matchedCampaignLoader}
					element={<RestrictedRoute component={<MatchedCampaign />} />}
					// errorElement={	}
				/>
				<Route path="/:type/:id/details" loader={detailsLoader} element={<RestrictedRoute component={<Details />} />} />
				<Route path="/campaign/:id/view" loader={detailsLoader} element={<RestrictedRoute component={<Details />} />} />
				<Route path="/teams" loader={teamsLoader} element={<RestrictedRoute component={<Teams />} />} />
				<Route path="/team/:id" loader={teamDetailLoader} element={<RestrictedRoute component={<TeamDetail />} />} />
				<Route
					path="/team/:id/members"
					loader={teamMembersLoader}
					element={<RestrictedRoute component={<TeamMembers />} />}
				/>
				<Route
					path="/team/:id/member/:memberId"
					loader={teamMemberLoader}
					element={<RestrictedRoute component={<EditTeamMemberDetails />} />}
				/>
				{/* <Route
				path="/opportunities"
				loader={campaignsLoader}
				action={campaignAction}
				element={<RestrictedRoute component={<Campaigns />} />}
			/> */}
				<Route
					path="/profile"
					element={<RestrictedRoute component={<Profile />} />}
					loader={(arg) => {
						arg.auth = store.getState().auth;
						return profileLoader(arg);
					}}
				/>
				<Route
					path="/founder-profile"
					element={<RestrictedRoute component={<FounderTeamMemberDetails />} />}
					loader={(arg) => {
						arg.auth = store.getState().auth;
						return founderTeamMemberLoader(arg);
					}}
				/>
				<Route
					path="/organization"
					loader={(arg) => {
						arg.auth = store.getState().auth;
						return organizationLoader(arg);
					}}
					element={<RestrictedRoute component={<Organization />} />}
				/>
				<Route
					path="/faq"
					loader={(arg) => {
						arg.currentRole = store.getState().auth.currentRole;
						return faqLoader(arg);
					}}
					element={<RestrictedRoute component={<FAQs />} />}
				/>
				<Route
					path="/applications/founders"
					element={<RestrictedRoute component={<FounderApplications />} />}
					// errorElement={	}
				/>
				<Route
					path="/applications/funders"
					element={<RestrictedRoute component={<FunderApplications />} />}
					// errorElement={	}
				/>
				<Route
					path="/application/founder/:id"
					element={<AdminViewCampaignDetail tenant={true} />}
					loader={tenantAdminViewCampaignDetailLoader}
				/>
				<Route path="/application/founder/:id/team" element={<ApplicationTeam />} loader={applicationTeamLoader} />
				<Route
					path="/application/funder/:id"
					element={<AdminViewOpportunityDetail tenant={true} />}
					loader={tenantAdminViewOpportunityDetailLoader}
				/>

				{/* <RestrictedRoute path="/team" component={Team} />
        <RestrictedRoute path="/team-member" component={TeamMember} />
        <RestrictedRoute path="/opportunity" component={Opportunity} />
        <RestrictedRoute path="/task" component={Task} />
        <RestrictedRoute path="/alert" component={Alert} />
        <RestrictedRoute path="/user-story" component={UserStory} />
        <RestrictedRoute path="/user" component={User} />
        <RestrictedRoute path="/user-company" component={Institution} />
        <RestrictedRoute path="/opp" component={VaOpportunity} />
        <RestrictedRoute path="/opportunity-company" component={Company} />
        <RestrictedRoute path="/faq" component={Faq} />
        <RestrictedRoute path="/profile" component={Profile} />
        <RestrictedRoute path="/membership" component={Membership} />
        <AdminRoute path="/admin" component={Admin} /> */}
				<Route
					path="/admin"
					loader={() => {
						console.log("Admin loader");
					}}
					element={
						<RestrictedAdminRoute>
							<Outlet />
						</RestrictedAdminRoute>
					}
				>
					<Route path="questions/:type" element={<Questions />} loader={questionsLoader} />
					<Route path="campaigns" element={<AdminCampaigns />} loader={adminCampaignsLoader} />
					<Route path="campaign/:id" element={<AdminViewCampaignDetail />} loader={adminViewCampaignDetailLoader} />
					<Route path="vendors" element={<AdminVendors />} loader={adminVendorsLoader} />
					<Route path="vendor/:id" element={<AdminViewVendorDetail />} loader={adminViewVendorDetailLoader} />
					<Route path="match-preview" element={<MatchPreview />} />
					<Route path="opportunities" element={<AdminOpportunities />} loader={adminOpportunitiesLoader} />
					<Route
						path="opportunity/:id"
						element={<AdminViewOpportunityDetail />}
						loader={adminViewOpportunityDetailLoader}
					/>
					<Route path="match" element={<AdminMatchView />} loader={adminMatchLoader} />
					<Route path="filter-match" element={<FilterAndMatch />} />
					<Route path="match-opportunity" element={<MatchOpportunity />} />
					<Route path="faqs" element={<AdminFAQs />} loader={FAQsLoader} />
					<Route path="users" element={<Users />} />
					<Route path="industries" element={<Industries />} />
					<Route path="data-view" element={<DataView />} />
					<Route path="triggered-actions" element={<TriggeredActions />} />
					<Route path="funders-database" element={<FunderDatabase />} />
					<Route path="tenants" element={<Tenants />} />
				</Route>
				<Route path="/login" element={<Login />} />
				<Route path="/signup" element={<Register />} />
				<Route path="/forgot-password" element={<ForgotPassword />} />
				<Route path="/reset-password" element={<ResetPasswordPage />} />
				<Route path="/verify-email" element={<VerifyEmailPage />} />
				<Route path="/contact-us" element={<ContactUs />} />
				<Route path="/not-found" element={<Error404 />} />
				<Route path="*" element={<Navigate to="/not-found" />} />
			</Route>
		)
	);
}
