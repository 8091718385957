import { createSlice } from "@reduxjs/toolkit";
import defaultTheme from "../../theme/defaultTheme";

const initialState = {
	...defaultTheme,
};

export const themeSlice = createSlice({
	name: "theme",
	initialState,
	reducers: {
		setThemeSetting(state, action) {
			localStorage.setItem("template-settings", JSON.stringify(action.payload));
			state.theme = action.payload;
		},
		getThemeSetting() {
			return localStorage.getItem("template-settings");
		},
	},
});

export const { setThemeSetting, getThemeSetting } = themeSlice.actions;

export default themeSlice.reducer;
