import React from "react";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import snarkdown from "snarkdown";

const useStyles = makeStyles((theme) => ({
	help: {
		background: theme.palette.primary.light,
		borderRadius: "50%",
		width: 25,
		height: 25,
		display: "inline-block",
		textAlign: "center",
		color: theme.palette.common.white,
	},
	popover: {
		pointerEvents: "none",
		"& pre": {
			whiteSpace: "pre-wrap"
		}
	},
	paper: {
		padding: theme.spacing(3),
		maxWidth: 360,
		marginLeft: 30,
		borderRadius: 12,
	},
}));

export default function HelpPopover({ content }) {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handlePopoverOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	return (
		<>
			<Typography
				classes={{ root: classes.help }}
				aria-haspopup="true"
				onMouseEnter={handlePopoverOpen}
				onMouseLeave={handlePopoverClose}
				component="span"
			>
				?
			</Typography>
			<Popover
				className={classes.popover}
				classes={{
					paper: classes.paper,
				}}
				open={open}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				onClose={handlePopoverClose}
				disableRestoreFocus
			>
				<Typography>
					<span className="whitespace-pre-wrap" dangerouslySetInnerHTML={{ __html: snarkdown(content) }} />
				</Typography>
			</Popover>
		</>
	);
}
