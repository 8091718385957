import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MEETING_TYPE } from "src/helpers/constants";
import { useRef } from "react";

export function TimeSlot({ values, viewOnly = false, idx, onChange, error = null }) {
	const days14 = new Date();
	days14.setDate(days14.getDate() + 14);
	const dt = new Date(values.datetime);
	const dtStr = `${dt.getMonth().toString().padStart(2, "0")}/${dt
		.getDate()
		.toString()
		.padStart(2, "0")}/${dt.getFullYear()} ${dt.getHours()}:${dt.getMinutes()}`;
	const addressLabel = values.type === MEETING_TYPE.INPERSON ? "Meeting Address" : "Meeting Link";
	const timeZones = useRef(Intl.supportedValuesOf("timeZone"));
	return (
		<fieldset className="border p-2 rounded-lg mb-2" key={`time-${idx}`}>
			<legend className="bg-indigo-100 px-2 rounded-md">Time Slot {idx + 1}</legend>
			<div className="grid grid-cols-6 gap-2 mb-2 -mt-2">
				{viewOnly ? (
					<label className="col-span-4">
						Date *
						<input
							type="text"
							required
							className="p-1 border rounded-md w-full"
							placeholder="Date & Time"
							disabled={true}
							value={dtStr}
						/>
						{error?.datetime && <span className="text-red-500">{error.datetime}</span>}
					</label>
				) : (
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<KeyboardDateTimePicker
							className="col-span-4 [&>.MuiFormLabel-root]:text-sm [&>.MuiFormLabel-root]:text-black [&>.MuiFormLabel-root]:translate-y-3px [&>.MuiFormLabel-root]:scale-100 [&>.MuiInputBase-root]:mt-[19px] [&>.MuiInputBase-root]:before:hidden [&>.MuiInputBase-root]:after:hidden"
							format={"MM/dd/yyyy HH:mm"}
							value={values.datetime || null}
							disablePast
							minDate={new Date()}
							disabled={viewOnly}
							maxDate={days14}
							label="Date & Time"
							required
							placeholder="MM/dd/yyyy HH:mm"
							InputLabelProps={{ shrink: true, className: "border-0" }}
							InputProps={{ className: "border pl-1 rounded-md [&>input]:text-sm" }}
							error={!!error?.datetime}
							helperText={error?.datetime || ""}
							onChange={(value) => {
								if (value && (value || {}).toString() !== "Invalid Date") {
									onChange("datetime", value, idx);
								}
							}}
						/>
					</MuiPickersUtilsProvider>
				)}
				<label className="col-span-2">
					TimeZone *
					<select
						className="p-1 border rounded-md w-full"
						required
						disabled={viewOnly}
						value={values.timezone}
						onChange={(e) => onChange("timezone", e.target.value, idx)}
					>
						{timeZones.current.map((tz) => (
							<option value={tz} key={`ts-${idx}-tz-${tz}`}>
								{tz}
							</option>
						))}
					</select>
					{error?.timezone && <span className="text-red-500">{error.timezone}</span>}
				</label>
			</div>
			<div className="grid grid-cols-2 gap-1 mb-2">
				<label>
					Duration(min) *
					<select
						className="p-1 border rounded-md w-full"
						required
						disabled={viewOnly}
						value={values.durationInMins}
						onChange={(e) => onChange("durationInMins", Number(e.target.value), idx)}
					>
						<option value="15">15 Mins</option>
						<option value="30">30 Mins</option>
						<option value="45">45 Mins</option>
						<option value="60">60 Mins</option>
					</select>
					{error?.durationInMins && <span className="text-red-500">{error.durationInMins}</span>}
				</label>
				<label>
					Type *
					<select
						className="p-1 border rounded-md w-full"
						required
						value={values.type}
						disabled={viewOnly}
						onChange={(e) => onChange("type", e.target.value, idx)}
					>
						<option value={MEETING_TYPE.INPERSON}>In Person</option>
						<option value={MEETING_TYPE.VIRTUAL}>Virtual</option>
					</select>
					{error?.type && <span className="text-red-500">{error.type}</span>}
				</label>
			</div>
			<div>
				<label className="col-span-6">
					{addressLabel} *
					{values.type === MEETING_TYPE.INPERSON ? (
						<textarea
							className="p-1 border rounded-md w-full"
							rows={2}
							placeholder={addressLabel}
							required
							disabled={viewOnly}
							value={values.meetingAddress}
							onChange={(e) => onChange("meetingAddress", e.target.value, idx)}
						></textarea>
					) : (
						<input
							type="text"
							className="p-1 border rounded-md w-full"
							placeholder={addressLabel + " (Zoom/Teams/GMeet)"}
							required
							disabled={viewOnly}
							value={values.meetingAddress}
							onChange={(e) => onChange("meetingAddress", e.target.value, idx)}
						/>
					)}
				</label>
			</div>
		</fieldset>
	);
}
