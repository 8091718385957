export const supportEmail = "support@inverstormatch.ai";

export const QUESTION_TYPES = {
	COMBO: "COMBO",
	RADIO: "RADIO",
	MULTIPLE_CHOICE: "MULTIPLE_CHOICE",
	UPLOAD_FILE: "UPLOAD_FILE",
	MULTI_LEVEL_CHOICE: "MULTI_LEVEL_CHOICE",
	TEXT: "TEXT",
	SELECT: "SELECT",
	DATE: "DATE",
	NUMBER: "NUMBER",
	PHONE: "PHONE",
	EMAIL: "EMAIL",
	RANGE: "RANGE",
	AUTOCOMPLETE: "AUTOCOMPLETE",
	FREE_TEXT: "FREE_TEXT",
	URL: "URL",
	NAME: "NAME",
	SORTABLE: "SORTABLE",
	TAGS: "TAGS",
	// OTP: "OTP",
	// ATTACHMENT: "ATTACHMENT",
	// NO_OP: "NO_OP",
	// SELFIE: "SELFIE",
	// AUTO_COMPLETE: "AUTO_COMPLETE",
	// SUCCESS: "SUCCESS",
	// SELFIE_REPORT: "SELFIE_REPORT",
	// FUNCTIONAL_HEALTH_REPORT: "FUNCTIONAL_HEALTH_REPORT",
	// PAYMENT: "PAYMENT",
	// LOGIN: "LOGIN"
};

export const TITLES = ["Mr", "Ms", "Mrs", "Dr", "Prof"];
export const ROLES = {
	SUPERADMIN: "SUPERADMIN",
	FOUNDER: "FOUNDER",
	FUNDER: "FUNDER",
	VENDOR: "VENDOR",
	ACCELERATOR: "ACCELERATOR",
	TENANT_ADMIN: "TENANT_ADMIN",
};

export const SOCIAL_MEDIA = ["Facebook", "Youtube", "Twitter", "LinkedIn", "Instagram", "TikTok"];

export const QUESTION_CATEGORIES = {
	CAMPAIGN: "CAMPAIGN",
	OPPORTUNITY: "OPPORTUNITY",
	VENDOR: "VENDOR",
	FOUNDER_PROFILE: "FOUNDER_PROFILE",
	FOUNDER_MEMBER: "FOUNDER_MEMBER",
	FUNDER_PROFILE: "FUNDER_PROFILE",
	VENDOR_PROFILE: "VENDOR_PROFILE",
	VENDOR_ORGANIZATION: "VENDOR_ORGANIZATION",
	TEAM_MEMBER: "TEAM_MEMBER",
	FUNDER_ORGANIZATION: "FUNDER_ORGANIZATION",
	ACCELERATOR: "ACCELERATOR"
};

export const STATUS = {
	UNCHANGED: 1,
	INPROGRESS: 2,
	DONE: 3,
	ERROR: 4,
};

export const ONBOARDING_STEPS = {
	SIGNUP: 1,
	PROFILE: 2,
	CAMPAIGN: 3,
	ORGANIZATION: 3,
	TEAM: 4,
	OPPORTUNITY: 4,
	SERVICE: 3,
};

export const MATCH_ACTION = {
	ACCEPTED: "ACCEPTED",
	REJECTED: "REJECTED",
	ARCHIVED: "ARCHIVED",
	UNDECIDED: "UNDECIDED",
};

export const STATUS_MAP = { IN_PROGRESS: "In Progress", ACTIVE: "Active", INACTIVE: "Inactive" };

export const months = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];

export const MEETING_TYPE = {
	INPERSON: "INPERSON",
	VIRTUAL: "VIRTUAL",
};

export const IMAGE_ASPECT_RATIOS = {
	square: "square",
	circle: "circle",
	"3:2": "3:2",
	"4:3": "4:3",
	"16:10": "16:10",
	"3:4": "3:4",
};

export const CONTEXT_OPERATORS = {
	EQUAL: "EQUAL",
	NOT_EQUAL: "NOT_EQUAL",
	LESS_THAN: "LESS_THAN",
	GREATER_THAN: "GREATER_THAN",
	STR_CONTAINS: "STR_CONTAINS",
	STR_NOT_CONTAINS: "STR_NOT_CONTAINS",
	HAS_ALL: "HAS_ALL",
	NONE_OF_THESE: "NONE_OF_THESE",
	HAS_ANY: "HAS_ANY",
	IS_EMPTY: "IS_EMPTY",
	IS_NOT_EMPTY: "IS_NOT_EMPTY",
};
