import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Save from "@material-ui/icons/Save";
import { useLoaderData, defer, useNavigate } from "react-router-dom";
import { useCallback } from "react";

import { create, getAll as getAllFounderOrganizations, update } from "src/api/opportunityOrganizations";
import { Main } from "src/components/commons/Main";
import { Async } from "src/components/commons/Async";
import { getAllWithAnswers } from "src/api/questions";
import { ContextProvider } from "src/components/campaigns/Details/Context";
import { Details } from "src/components/campaigns/Details";
import { ONBOARDING_STEPS, QUESTION_CATEGORIES, QUESTION_TYPES } from "src/helpers/constants";
import getByPath from "src/helpers/getByPath";
import { localstorage } from "src/lib/storage";
import { useDispatch } from "react-redux";
import { updateUserOnboarding } from "src/store/slices/auth";

export default function Organization() {
	const { data } = useLoaderData();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const prepareUserAnswers = useCallback((questions, organization) => {
		return questions
			.map((question) => {
				const { field } = question.meta || {};
				let value = getByPath(organization, field?.split(".") || []);
				const userAnswer = {
					questionId: question.id,
				};
				if (question.answers.length && question.type === QUESTION_TYPES.MULTIPLE_CHOICE) {
					const userAnswers = [];
					value = Array.isArray(value) ? value : [value];
					value.forEach((val) => {
						const answerId = question.answers.find((a) => val === a.answer)?.id;
						if (answerId) {
							userAnswers.push({
								questionId: question.id,
								answerId,
							});
						} else {
							userAnswers.push({
								questionId: question.id,
								response: val,
							});
						}
					});
					return userAnswers;
				} else if (question.answers.length && question.type !== QUESTION_TYPES.AUTOCOMPLETE) {
					if (field === "isVisible") {
						value = value === true ? "Yes" : "No";
					}
					userAnswer.answerId = question.answers.find((a) => a.answer === value)?.id;
					if (!userAnswer.answerId && question.meta.hasOther) {
						userAnswer.response = value;
					}
				} else {
					userAnswer.response = value;
				}
				return userAnswer;
			})
			.flat();
	}, []);

	const save = useCallback((organization) => {
		return async (_, answerObject) => {
			answerObject.id = organization.id;
			const payload = {
				...answerObject,
			};
			if (organization.id) {
				await update(payload);
				localstorage.removeItem(`organization_${organization.id}`);
				navigate("/dashboard");
				return;
			} else {
				await create(payload);
				await dispatch(updateUserOnboarding({ isCompleted: false, completedStep: ONBOARDING_STEPS.ORGANIZATION }));
				localstorage.removeItem("organization_add");
				navigate("/dashboard");
				return;
			}
			
		};
	}, []);

	return (
		<>
			<Main>
				<Box display={"flex"} justifyContent="space-between" p={4} px={4} borderBottom={"1px solid rgb(239 232 251)"}>
					<Typography component={"h2"} variant="h6">
						Organization
					</Typography>
				</Box>
				<Async error={"Unable to fetch the organization"} promise={data}>
					{({ questions, organization }) => {
						return (
							<ContextProvider
								userAnswers={prepareUserAnswers(questions, organization)}
								questions={questions}
								saveFn={save(organization)}
								category={QUESTION_CATEGORIES.FUNDER_ORGANIZATION}
								entityId={organization.id}
							>
								{({ validateAndSave, isSaving }) => {
									return (
										<>
											<Box
												component={"form"}
												p={4}
												px={4}
												height={"100%"}
												display="flex"
												flexDirection="column"
												onSubmit={(e) => {
													e.preventDefault();
													validateAndSave();
												}}
											>
												<Details />
												<Box display={"flex"} justifyContent="center" margin={5}>
													<Button
														color="primary"
														variant="contained"
														startIcon={<Save />}
														type="submit"
														disabled={isSaving}
													>
														{isSaving ? "Saving..." : "Save"}
													</Button>
												</Box>
											</Box>
										</>
									);
								}}
							</ContextProvider>
						);
					}}
				</Async>
			</Main>
		</>
	);
}

export function organizationLoader({ auth }) {
	const data = Promise.all([
		getAllWithAnswers({ category: QUESTION_CATEGORIES.FUNDER_ORGANIZATION, per_page: 100 }),
		getAllFounderOrganizations().then((res) => res[0] || { name: auth.authUser?.profile?.orgName || "" }),
	]).then(([questions, organization]) => {
		return {
			questions,
			organization,
		};
	});
	return defer({ data });
}
