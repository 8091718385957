import React from "react";
import { Link } from "react-router-dom";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Chip from "@material-ui/core/Chip";
import Settings from "@material-ui/icons/Settings";
import Popover from "@material-ui/core/Popover";
import makeStyles from "@material-ui/styles/makeStyles";
import CmtCard from "src/@coremat/CmtCard";

const useStyles = makeStyles(() => ({
	popoverRoot: {
		"& .MuiPopover-paper": {
			minWidth: 225,
			marginTop: 12,
			borderRadius: 12,
		},
	},
}));
export function AdminMenu() {
	const [popOverAnchorEl, setPopOverAnchorEl] = React.useState(null);
	const classes = useStyles();
	const onOpenPopOver = (event) => {
		setPopOverAnchorEl(event.currentTarget);
	};

	const onClosePopOver = () => {
		setPopOverAnchorEl(null);
	};
	return (
		<>
			<Chip
				icon={<Settings />}
				label={"Admin"}
				aria-controls="header_roles"
				color="secondary"
				onClick={onOpenPopOver}
				clickable={true}
				deleteIcon={<ArrowDropDownIcon style={{ pointerEvents: "none" }} />}
				onDelete={() => {}}
				variant="outlined"
				aria-haspopup="true"
			/>
			<Popover
				className={classes.popoverRoot}
				id={popOverAnchorEl ? "simple-popover" : undefined}
				open={Boolean(popOverAnchorEl)}
				anchorEl={popOverAnchorEl}
				onClose={onClosePopOver}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				<CmtCard className={classes.cardRoot}>
					<div className="p-4 grid grid-cols-4 gap-4 text-slate-500">
						<div>
							<div className="text-slate-400 text-lg underline">Founders</div>
							<div className="[&>*]:py-2 [&>a:hover]:text-indigo-400 flex flex-col">
								<Link onClick={onClosePopOver} to="/admin/questions/campaign">
									Founder Form
								</Link>
								<Link onClick={onClosePopOver} to="/admin/questions/founder_profile">
									Founder Profile
								</Link>
								<Link onClick={onClosePopOver} to="/admin/questions/founder_member">
									Founder Member
								</Link>
								<Link onClick={onClosePopOver} to="/admin/questions/team_member">
									Team Member
								</Link>
								<Link onClick={onClosePopOver} to="/admin/campaigns">
									All Campaigns
								</Link>
							</div>
						</div>
						<div>
							<div className="text-slate-400 text-lg underline">Funders</div>
							<div className="[&>*]:py-2 [&>a:hover]:text-indigo-400 flex flex-col">
								<Link onClick={onClosePopOver} to="/admin/questions/opportunity">
									Funder Form
								</Link>
								<Link onClick={onClosePopOver} to="/admin/questions/funder_profile">
									Funder Profile
								</Link>
								<Link onClick={onClosePopOver} to="/admin/questions/funder_organization">
									Funders Org
								</Link>
								<Link onClick={onClosePopOver} to="/admin/opportunities">
									All Opportunities
								</Link>
								<Link onClick={onClosePopOver} to="/admin/questions/accelerator">
									Accelerator Form
								</Link>
							</div>
						</div>
						<div>
							<div className="text-slate-400 text-lg underline">Vendors</div>
							<div className="[&>*]:py-2 [&>a:hover]:text-indigo-400 flex flex-col">
								<Link onClick={onClosePopOver} to="/admin/questions/vendor">
									Vendor Form
								</Link>
								<Link onClick={onClosePopOver} to="/admin/questions/vendor_profile">
									Vendor Profile
								</Link>
								<Link onClick={onClosePopOver} to="/admin/vendors">
									All Vendors
								</Link>
							</div>
							<div className="text-slate-400 text-lg underline">Match</div>
							<div className="[&>*]:py-2 [&>a:hover]:text-indigo-400 flex flex-col">
								<Link onClick={onClosePopOver} to="/admin/filter-match">
									Filter & Match
								</Link>
								<Link onClick={onClosePopOver} to="/admin/match-opportunity">
									Match By Name
								</Link>
							</div>
						</div>
						<div>
							<div className="text-slate-400 text-lg underline">Others</div>
							<div className="[&>*]:py-2 [&>a:hover]:text-indigo-400 flex flex-col">
								{/* <Link onClick={onClosePopOver} to="/admin/match">Match</Link> */}
								<Link onClick={onClosePopOver} to="/admin/users">
									Manage Users
								</Link>
								<Link onClick={onClosePopOver} to="/admin/industries">
									Manage Industries
								</Link>
								<Link onClick={onClosePopOver} to="/admin/data-view">
									Data View
								</Link>
								<Link onClick={onClosePopOver} to="/admin/funders-database">
									Funders Database
								</Link>
								<Link onClick={onClosePopOver} to="/admin/triggered-actions">
									Triggered Actions
								</Link>
								<Link onClick={onClosePopOver} to="/admin/tenants">
									White Label Clients
								</Link>
								{/* <Link onClick={onClosePopOver} to="/admin/opportunities">All Opportunities</Link> */}
								{/* <Link onClick={onClosePopOver} to="/admin/faqs">Manage FAQs</Link> */}
								{/* <Link onClick={onClosePopOver} to="/admin/match-preview">Match Preview</Link> */}
							</div>
						</div>
					</div>
				</CmtCard>
			</Popover>
		</>
	);
}
