import { fetchV1, fetchAdminV1 } from "../lib/requester/v1";

export function getAll(params) {
	return fetchV1("/vendor_services", { method: "GET", data: params }, { returnWholeResponse: true });
}

export function getAllForAdmin(params) {
	return fetchAdminV1("/vendor_services", { method: "GET", data: params }, { returnWholeResponse: true });
}

export function getVendorWithUserAnswersForAdmin(id) {
	return fetchAdminV1(`/vendor_services/${id}/user_answers`, { method: "GET" });
}

export function getById(id) {
	return fetchV1(`/vendor_services/${id}`, { method: "GET" });
}

export function create(data) {
	return fetchV1(`/vendor_services`, { method: "POST", data });
}

export function update(data) {
	return fetchV1(`/vendor_services/${data.id}`, { method: "PUT", data });
}

export function remove(id) {
	return fetchV1(`/vendor_services/${id}`, { method: "DELETE" });
}

export function launch(id) {
	return fetchV1(`/vendor_services/${id}/launch`, { method: "GET" }, { returnWholeError: true });
}

export function getActiveVendorServicesWithMatches() {
	return fetchV1("/vendor_services/matches", { method: "GET" });
}
