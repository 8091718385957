import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import makeStyles from "@material-ui/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import { alpha } from "@material-ui/core/styles";
import EmojiNature from "@material-ui/icons/EmojiNature";
import Add from "@material-ui/icons/Add";
import { useRef, useState } from "react";
import { useLoaderData, useLocation } from "react-router-dom";

import { getAll } from "src/api/vendorServices";
import { Main } from "src/components/commons/Main";
import { VendorServiceListItem } from "src/components/vendorServices/ListItem";
import { VendorServiceForm } from "src/components/vendorServices/Form";

const useStyles = makeStyles((theme) => ({
	emptyState: {
		padding: "6rem 0",
		color: alpha(theme.palette.common.black, 0.3),
	},
	listItem: {
		"& img": {
			transform: "scale(0.7)",
			transition: "transform 0.6s",
		},
		"&:hover .listAvatar": {
			background: alpha(theme.palette.primary.main, 0.1),
		},
		"&:hover img": {
			transform: "scale(0.9)",
		},
	},
	avatar: {
		width: 92,
		height: 92 * 0.616,
		background: theme.palette.background.default,
		[theme.breakpoints.up("md")]: {
			width: 150,
			height: 150 * 0.616,
		},
		"& img": {
			objectFit: "contain",
		},
	},
	chip: {
		color: theme.palette.common.white,
		"&.warning": {
			background: theme.palette.warning.dark,
		},
		"&.active": {
			background: theme.palette.success.dark,
		},
	},
	modal: {
		transform: "translate(-50%, -50%)",
	},
}));

export function VendorServices() {
	const { state } = useLocation();
	const paginatedServices = useLoaderData();
	const classes = useStyles();
	const [openFormModal, setOpenFormModal] = useState(state?.create || false);
	const selectedService = useRef(null);

	return (
		<Main>
			<Box display={"flex"} justifyContent="space-between" p={4} px={4} borderBottom={"1px solid rgb(239 232 251)"}>
				<Typography component={"h2"} variant="h6">
					My Services
				</Typography>
				<Box>
					<Button
						variant="text"
						color="primary"
						disabled={false}
						startIcon={<Add />}
						onClick={() => setOpenFormModal(true)}
					>
						Create
					</Button>
				</Box>
			</Box>
			{paginatedServices.data.length === 0 ? (
				<Box textAlign={"center"} className={classes.emptyState}>
					<Box>
						<EmojiNature />
					</Box>
					You don&apos;t have any services so far{" "}
					<Button
						variant="text"
						color="primary"
						disabled={false}
						startIcon={<Add />}
						onClick={() => setOpenFormModal(true)}
					>
						Create One
					</Button>
				</Box>
			) : (
				<List component={"ul"}>
					{paginatedServices.data.map((vendorService) => {
						return (
							<VendorServiceListItem
								key={`vs-list-${vendorService.id}`}
								vendorService={vendorService}
								classes={classes}
								onEdit={(vendorService) => {
									selectedService.current = vendorService;
									setOpenFormModal(true);
								}}
								// onLaunch={launch}
							/>
						);
					})}
				</List>
			)}
			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={openFormModal}
				onClose={() => setOpenFormModal(false)}
				arialabelledby={"form-vs-dialog"}
			>
				<DialogTitle id="form-vs-dialog" pb={0}>
					Service Details
				</DialogTitle>
				<VendorServiceForm vendorService={selectedService.current} close={() => setOpenFormModal(false)} />
			</Dialog>
		</Main>
	);
}

export function vendorServicesLoader() {
	return getAll();
}
