import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import { MaskedInput } from "src/components/commons/Inputs/MaskedInput";
import { countryCodes } from "src/helpers/countryCodes";

export function Phone({ placeholder, phone, onChange, error }) {
	let selectedValue = phone.countryCode ? countryCodes.find((c) => c.code === phone.countryCode) : countryCodes[0];
	return (
		<>
			<Grid container spacing={4}>
				<Grid item xs={12} sm={3}>
					<Autocomplete
						options={countryCodes}
						getOptionLabel={(option) => (option.country ? `+${option.code} ${option.country}` : "")}
						getOptionSelected={(option, value) => {
							return option.code === value.code;
						}}
						value={selectedValue}
						onChange={(e, value) => {
							onChange({ ...phone, countryCode: value?.code || "" });
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								fullWidth
								label={"Country Code*"}
								inputProps={{ ...params.inputProps, autoComplete: "new-password" }}
								autoComplete={"new-password"}
								variant="outlined"
							/>
						)}
					/>
				</Grid>
				<Grid item xs={12} sm={3}>
					<MaskedInput
						fullWidth
						variant="outlined"
						type="text"
						overwrite={true}
						label={placeholder || "Phone Number*"}
						placeholder={placeholder || "Phone Number*"}
						value={phone.phoneNumber || ""}
						mask={"(000) 000-0[000]"}
						onAccept={(value) => {
							if (!value) {
								onChange({ ...phone, phoneNumber: "" });
							}
						}}
						onComplete={(value, mask) => onChange({ ...phone, phoneNumber: mask.unmaskedValue })}
					/>
				</Grid>
				<Grid item xs={12} sm={2}>
					<MaskedInput
						mask={Number}
						style={{ width: "150px" }}
						variant="outlined"
						type="text"
						label={"Extension"}
						placeholder={"Extension"}
						value={phone.extension || ""}
						onComplete={(value) => onChange({ ...phone, extension: value })}
					/>
				</Grid>
			</Grid>
			{error && (
				<FormHelperText style={{ marginInline: 14 }} error={true}>
					{error}
				</FormHelperText>
			)}
		</>
	);
}
