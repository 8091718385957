import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { useState } from "react";

export function MarkAsTenantAdmin({ currentUser, openFormModal, setOpenFormModal, tenants, onSubmit }) {
	const [tenantId, setTenantId] = useState(null);
	const [submitting, setSubmitting] = useState(false);
	return (
		<Dialog
			fullWidth={true}
			maxWidth="xs"
			open={openFormModal}
			onClose={() => setOpenFormModal(false)}
			arialabelledby={"form-tenant-adm-dialog"}
		>
			<DialogTitle id="form-tenant-adm-dialog" pb={0}>
				Select White Label Tenant
			</DialogTitle>
			<Box
				component={"form"}
				className="p-4 flex flex-col justify-end"
				onSubmit={(e) => {
					e.preventDefault();
					setSubmitting(true);
					onSubmit(tenantId);
				}}
			>
				{currentUser && <div className="py-4">For {[currentUser.firstName, currentUser.lastName].join(" ")}</div>}
				<TextField
					value={tenantId || ""}
					margin="normal"
					// className={classes.textField}
					required
					fullWidth
					size="small"
					variant="outlined"
					onChange={(e) => {
						setTenantId(+e.target.value);
					}}
					label="Tenant"
					select
				>
					<MenuItem value="">
						<em>None</em>
					</MenuItem>
					{tenants.map((tenant, idx) => {
						return (
							<MenuItem key={`tenant_us_${idx}_select`} value={tenant.id}>
								{tenant.name}
							</MenuItem>
						);
					})}
				</TextField>
				<Button type={"submit"} variant="contained" color="secondary" disabled={submitting}>
					Submit
				</Button>
			</Box>
		</Dialog>
	);
}
