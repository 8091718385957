import Box from "@material-ui/core/Box";
import { useEffect, useRef, useState } from "react";
import Alert from "src/helpers/alert";
import { getAll, create, remove } from "src/api/tenantQuestionRules";
import { TagsV2 } from "../campaigns/Details/TagsV2";

export function TenantRules({ stepIndex, questionId }) {
	const [tenants, setTenants] = useState([[]]);
	const [saving, setSaving] = useState(false);
	const tenantRuleMapping = useRef(new Map());
	useEffect(() => {
		let filter = `step||$eq||${stepIndex}`;
		if (questionId) {
			filter = `questionId||$eq||${questionId}`;
		}
		getAll({ filter, join: ["tenant"] }).then((rules) => {
			setTenants((st) => {
				st[0] = rules.data.map((i) => {
					tenantRuleMapping.current.set(i.tenant.id, i.id);
					return i.tenant;
				});
				return [...st];
			});
		});
	}, [stepIndex]);

	return (
		<Box className="py-4">
			<Box className="text-xl">Hide for tenants: {saving && <span>Saving...</span>}</Box>
			<TagsV2
				isAdmin={true}
				canAdd={false}
				question={{
					meta: {
						multiple: false,
						placeholder: "Tenant",
						dataSourceURL: "/tenants?filter=name||$contL||{value}&or=domain||$contL||{value}",
					},
				}}
				answers={tenants}
				setUserAnswer={(_, _tenants, isDeleted) => {
					setSaving(true);
					if (isDeleted) {
						const idx = tenants[0].findIndex((t) => {
							return !_tenants[0].find((st) => st.id === t.id);
						});
						const id = tenants[0][idx].id;
						setTenants((st) => {
							st[0].splice(idx, 1);
							return [...st];
						});
						remove(tenantRuleMapping.current.get(id))
							.then(() => {
								setSaving(false);
								tenantRuleMapping.current.delete(id);
							})
							.catch((e) => {
								console.error("error while removing tenant question rule", e);
								Alert.error("Unable to delete");
								setSaving(false);
							});
					} else {
						const rules = _tenants[0]
							.map((tenant) => {
								if (!tenantRuleMapping.current.get(tenant.id)) {
									return { tenantId: tenant.id, step: stepIndex, questionId, action: "HIDE" };
								}
							})
							.filter((i) => i);
						if (!rules.length) {
							return;
						}
						create(rules)
							.then((res) => {
								res.map((i) => {
									tenantRuleMapping.current.set(i.tenantId, i.id);
								});
								setTenants((st) => {
									st[0].push(_tenants[0][_tenants.length - 1]);
									return [...st];
								});
								setSaving(false);
							})
							.catch((e) => {
								console.error("Unable to save tenant question rule", e);
								Alert.error("Unable to save");
								setSaving(false);
							});
					}
				}}
				error={null}
			/>
		</Box>
	);
}
