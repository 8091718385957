import React, { Suspense } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

// import { getAllForAdmin as getAllOpportunities } from "src/api/opportunities";
import { RecommendCampaignsForOpportunity } from "src/components/admin/Match/RecommendCampaignsForOpportunity";

export default function MatchOpportunity() {
	return (
		<Box component={"main"}>
			<Typography component={"h1"} variant={"body1"} color="textSecondary" style={{ marginBottom: "1rem" }}>
				Match By Name
			</Typography>
			<Suspense
				fallback={
					<Box height={"100%"} display="flex" justifyContent={"center"} alignItems={"center"}>
						<CircularProgress />
					</Box>
				}
			>
				<RecommendCampaignsForOpportunity />;
			</Suspense>
		</Box>
	);
}