import React, { useState } from "react";
import { defer, useLoaderData } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { alpha } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";

import { Card /* CardHeader */ } from "src/components/commons/Card";
import { getActiveCampaignsWithMatches, getAll as getAllCampaigns } from "src/api/campaigns";
import { Async } from "src/components/commons/Async";
import { ONBOARDING_STEPS, ROLES } from "src/helpers/constants";
import { getActiveOpportunitiesWithMatches, getAll as getAllOpportunities } from "src/api/opportunities";
import { getActiveVendorServicesWithMatches, getAll as getAllVendorServices } from "src/api/vendorServices";
import { useDispatch, useSelector } from "react-redux";
import { updateUserOnboarding } from "src/store/slices/auth";
import FounderOnboardingWizard from "src/components/dashboard/FounderOnboarding";
import FunderOnboardingWizard from "src/components/dashboard/FunderOnboarding";
import VendorOnboardingWizard from "src/components/dashboard/VendorOnboarding";
import { FounderDashboard } from "src/components/campaigns/Dashboard";
import { FunderDashboard } from "src/components/opportunities/Dashboard";
import { VendorDashboard } from "src/components/vendorServices/Dashboard";
import { Information } from "src/components/dashboard/Information";
import { EmptyState } from "src/components/dashboard/EmptyState";
import { TenantAdminDashboard } from "src/components/opportunities/TenantAdminDashboard";

// import RecentTickets from "./RecentTickets";
// import TasksList from "./TasksList";
// import WelcomeSummary from "./WelcomeSummary";
// import RecentActivities from "./RecentActivities";
// import TicketsStatus from "./TicketsStatus";
// import ProjectCounterCard from "./ProjectCounterCard";
// import OpportunitiesCounterCard from "./OpportunitiesCounterCard";
// import TasksCounterCard from "./TasksCounterCard";
// import TeamsCounterCard from "./TeamsCounterCard";
// import TeamMembersList from "./TeamMembersList";
// import TeamsList from "./TeamsList";
// import { getDashboardData } from "src/redux/actions/Dashboard";

const useStyles = makeStyles((theme) => ({
	emptyState: {
		padding: "5rem",
		color: alpha(theme.palette.common.black, 0.5),
	},
	ulreset: {
		listStyle: "none",
	},
}));
export function Dashboard() {
	const classes = useStyles();
	const { authUser, currentRole } = useSelector(({ auth }) => auth);
	const dispatch = useDispatch();
	const { data, entity } = useLoaderData();
	const [isNonAccreditedInvestor] = useState(() => {
		return !!authUser.profile?.positions?.find((i) => i.toLowerCase().includes("non-accredited"));
	});
	const [showInfoModal, setShowInfoModal] = useState(
		() => authUser.onboarding[currentRole]?.completedStep === ONBOARDING_STEPS.SIGNUP
	);
	// const [showOpportunityModal, setShowOpportunityModal] = useState(false);
	// const selectedMatchedOpportunity = useRef(null);

	async function completeOnboarding() {
		const completedStep =
			currentRole === ROLES.FOUNDER
				? ONBOARDING_STEPS.TEAM
				: currentRole === ROLES.FUNDER
				? ONBOARDING_STEPS.ORGANIZATION
				: 0;
		return dispatch(updateUserOnboarding({ isCompleted: true, completedStep }));
	}

	if (isNonAccreditedInvestor) {
		return (
			<Card>
				<div className="min-h-[320px] p-4 text-center text-xl text-gray-500 flex justify-center items-center">
					You are all caught up. We will let you know once we have relevant opportunities.
				</div>
			</Card>
		);
	}

	if (authUser.onboarding && !authUser.onboarding[currentRole]?.isCompleted) {
		return (
			<Card>
				{showInfoModal && (
					<Modal
						open={showInfoModal}
						// maxWidth="xs"
						onClose={() => setShowInfoModal(false)}
						aria-labelledby="info-modal-title"
						aria-describedby="info-modal-description"
					>
						<Information onClose={() => setShowInfoModal(false)} />
					</Modal>
				)}
				{currentRole === ROLES.FOUNDER ? (
					<FounderOnboardingWizard onCompleteOnboarding={completeOnboarding} />
				) : currentRole === ROLES.FUNDER ? (
					<FunderOnboardingWizard onCompleteOnboarding={completeOnboarding} />
				) : (
					<VendorOnboardingWizard onCompleteOnboarding={completeOnboarding} />
				)}
			</Card>
		);
	}

	return (
		<Box component={"main"} id={`dashboard-${entity}`}>
			<Typography component={"h1"} variant={"body1"} color="textSecondary" style={{ marginBottom: "1rem" }}>
				Dashboard
			</Typography>
			{authUser.roles.includes(ROLES.TENANT_ADMIN) ? (
				<TenantAdminDashboard />
			) : (
				<Async error={"Could not load the dashboard"} promise={data}>
					{({ activeEntityData, allEntityData }) => {
						if (allEntityData.length <= 0) {
							return <EmptyState classes={classes} entity={entity} />;
						}
						if (currentRole === ROLES.FOUNDER) {
							return <FounderDashboard activeCampaignsWithMatches={activeEntityData} allCampaigns={allEntityData} />;
						} else if (currentRole === ROLES.FUNDER) {
							return (
								<FunderDashboard activeOpportunitiesWithMatches={activeEntityData} allOpportunities={allEntityData} />
							);
						} else if (currentRole === ROLES.VENDOR) {
							return (
								<VendorDashboard activeVendorServicesWithMatches={activeEntityData} allVendorServices={allEntityData} />
							);
						} else {
							return <div>Unimplemented</div>;
						}
					}}
				</Async>
			)}
		</Box>
	);
}

export function dashboardLoader({ currentRole }) {
	const promises = [];
	let entity = ROLES.FOUNDER;
	if (currentRole === ROLES.FUNDER) {
		entity = ROLES.FUNDER;
		promises.push(getActiveOpportunitiesWithMatches());
		promises.push(getAllOpportunities());
	} else if (currentRole === ROLES.FOUNDER) {
		entity = ROLES.FOUNDER;
		promises.push(getActiveCampaignsWithMatches());
		promises.push(getAllCampaigns());
	} else if (currentRole === ROLES.VENDOR) {
		entity = ROLES.VENDOR;
		promises.push(getActiveVendorServicesWithMatches());
		promises.push(getAllVendorServices());
	} else {
		throw new Response(`Invalid role ${currentRole}`, { status: 404 });
	}
	const promise = Promise.all(promises).then(([activeEntityData, allEntityData]) => {
		return {
			activeEntityData,
			allEntityData,
		};
	});
	return defer({
		entity,
		data: promise,
	});
}
