import { Breadcrumbs, Typography } from "@material-ui/core";
import { Link, defer, useLoaderData } from "react-router-dom";
import { getLeanCanvasData } from "src/api/campaigns";
import { DrawLeanCanvas } from "src/components/campaigns/DrawLeanCanvas";
import { Async } from "src/components/commons/Async";
import { Main } from "src/components/commons/Main";

export function LeanCanvas() {
	const { promise } = useLoaderData();
	return (
		<>
			<Breadcrumbs separator="›" aria-label="breadcrumb" className={"pb-4"}>
				<Link to={"/dashboard"}>
					<Typography color="textSecondary">Dashboard</Typography>
				</Link>
				<Typography color="textPrimary">Lean Canvas</Typography>
			</Breadcrumbs>
			<Main>
				<Async
					error={
						"Oops.. there was an error. Refresh the page and see if that fixes it. If the issue persists, please contact us"
					}
					promise={promise}
				>
					{({ data }) => <DrawLeanCanvas data={data} />}
				</Async>
			</Main>
		</>
	);
}

export function leanCanvasLoader() {
	const promises = [getLeanCanvasData()];
	const promise = Promise.all(promises).then(([data]) => {
		return {
			data,
		};
	});
	return defer({ promise });
}
