import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import { useState, useMemo } from "react";
import { getTypeDisplayName } from "src/helpers";
import { QUESTION_TYPES, IMAGE_ASPECT_RATIOS } from "src/helpers/constants";
import { Answers } from "./Answers";
import { ComboQuestion } from "./ComboQuestion";
import { ContextRules } from "./ContextRules";
import { AttributeExtraction } from "./AttributeExtraction";
import { TenantRules } from "./TenantRules";

export function Question({
	question,
	idx,
	onChange,
	onStepChange,
	errors = {},
	labels = {},
	referrersByLabel = {},
	steps = [],
	setAnswers,
}) {
	const [error, setError] = useState(null);

	const types = useMemo(() => {
		return Object.values(QUESTION_TYPES).map((item) => {
			return {
				value: item,
				label: getTypeDisplayName(item),
			};
		});
	}, []);

	if (!question) {
		return null;
	}
	const referrer = referrersByLabel[question.meta.label];

	return (
		<Grid
			key={`question_${idx}`}
			container
			spacing={4}
			style={{ padding: "1rem" }}
			onPasteCapture={(e) => {
				const pastedData = (e.clipboardData || window.clipboardData).getData("text");
				try {
					const data = JSON.parse(pastedData);
					if (data.question) {
						data.question.meta.step = question.meta.step;
						data.question.group = question.group;
						data.question.order = question.order;
						if (question.id) {
							data.question.id = question.id;
						} else {
							delete data.question.id;
						}
						if (question.creatorId) {
							data.question.creatorId = question.creatorId;
						} else {
							delete data.question.creatorId;
						}
						onChange({ ...data.question }, idx);
					} else if (data.answers) {
						data.answers.forEach((answer) => {
							delete answer.id;
							delete answer.questionId;
							delete answer.createdAt;
							delete answer.updatedAt;
							delete answer.creatorId;
							delete answer.group;
						});
						onChange({ ...question, answers: [...data.answers] }, idx);
					}
				} catch (error) {
					console.warn("pasted invalid data", pastedData);
				}
			}}
		>
			<Grid item xs={12}>
				<TextField
					multiline
					minRows={2}
					fullWidth
					autoFocus
					required
					variant="outlined"
					value={question.question || ""}
					label={`Question ${idx + 1}`}
					error={!!errors.question}
					helperText={errors.question || ""}
					onChange={(e) => {
						onChange({ ...question, question: e.target.value }, idx);
					}}
				/>
			</Grid>
			<Grid item xs={3}>
				<TextField
					select
					fullWidth
					variant="outlined"
					value={question.type}
					label="Question Type"
					required
					size={"small"}
					error={!!errors.type}
					helperText={errors.type || ""}
					onChange={(e) => {
						onChange({ ...question, type: e.target.value }, idx);
					}}
				>
					<MenuItem value={""}>Select Type</MenuItem>
					{(types || []).map(({ value, label }) => (
						<MenuItem key={label} value={value}>
							{label}
						</MenuItem>
					))}
				</TextField>
				<Box mt={4}>
					<TextField
						fullWidth
						size={"small"}
						variant="outlined"
						value={question.order}
						label="Order"
						type={"number"}
						onChange={(e) => onChange({ ...question, order: Number(e.target.value) }, idx)}
					/>
				</Box>
			</Grid>
			<Grid item xs={6}>
				<TextField
					fullWidth
					multiline
					minRows={4}
					maxRows={4}
					variant="outlined"
					label="Question Description"
					placeholder="Question Description"
					size="small"
					value={question.description || ""}
					onChange={(e) => onChange({ ...question, description: e.target.value }, idx)}
				/>
			</Grid>
			<Grid item xs={3}>
				<TextField
					fullWidth
					multiline
					minRows={4}
					maxRows={4}
					variant="outlined"
					label="Answer Hint"
					placeholder="Answer Hint"
					size="small"
					value={question.meta?.answerHint || ""}
					onChange={(e) =>
						onChange({ ...question, meta: { ...(question.meta || {}), answerHint: e.target.value } }, idx)
					}
				/>
			</Grid>
			<Grid item xs={3}>
				<TextField
					size={"small"}
					fullWidth
					variant="outlined"
					value={question.meta.keyword || ""}
					label="Keyword"
					onChange={(e) =>
						onChange(
							{ ...question, meta: { ...(question.meta || {}), keyword: (e.target.value || "").toUpperCase() } },
							idx
						)
					}
				/>
			</Grid>
			<Grid item xs={3} style={{ display: "flex" }}>
				<TextField
					size={"small"}
					fullWidth
					variant="outlined"
					value={question.meta.field || ""}
					label="Field"
					error={!!errors.field}
					helperText={errors.field || ""}
					onChange={(e) =>
						onChange(
							{
								...question,
								meta: { ...(question.meta || {}), field: e.target.value || "" },
							},
							idx
						)
					}
				/>
			</Grid>
			{QUESTION_TYPES.UPLOAD_FILE === question.type && (
				<Grid item xs={6} className="flex gap-4">
					<TextField
						variant="outlined"
						label="Path"
						fullWidth
						placeholder="Path"
						error={!!errors.path}
						helperText={errors.path || ""}
						size="small"
						value={question.meta?.path || ""}
						onChange={(e) => onChange({ ...question, meta: { ...(question.meta || {}), path: e.target.value } }, idx)}
					/>
					<TextField
						select
						fullWidth
						variant="outlined"
						value={question.meta?.aspectRatio || IMAGE_ASPECT_RATIOS.square}
						label="Aspect Ratio"
						size={"small"}
						onChange={(e) =>
							onChange({ ...question, meta: { ...(question.meta || {}), aspectRatio: e.target.value } }, idx)
						}
					>
						<MenuItem value={""}>Select Aspect Ratio</MenuItem>
						{Object.values(IMAGE_ASPECT_RATIOS).map((value) => (
							<MenuItem key={value} value={value}>
								{value}
							</MenuItem>
						))}
					</TextField>
					<TextField
						fullWidth
						variant="outlined"
						value={question.meta?.allowedFileTypes || "image/*"}
						label="File Types"
						size={"small"}
						onChange={(e) =>
							onChange({ ...question, meta: { ...(question.meta || {}), allowedFileTypes: e.target.value } }, idx)
						}
					/>
				</Grid>
			)}
			{question.type === QUESTION_TYPES.TEXT && (
				<Grid item xs={3}>
					<TextField
						size={"small"}
						fullWidth
						variant="outlined"
						value={question.meta.validationRegex || ""}
						label="Validation Regex"
						onChange={(e) =>
							onChange(
								{
									...question,
									meta: { ...(question.meta || {}), validationRegex: e.target.value || "" },
								},
								idx
							)
						}
					/>
				</Grid>
			)}
			<Grid item xs={3} className="empty:hidden">
				{[
					QUESTION_TYPES.TEXT,
					QUESTION_TYPES.FREE_TEXT,
					QUESTION_TYPES.NUMBER,
					QUESTION_TYPES.EMAIL,
					QUESTION_TYPES.PHONE,
					QUESTION_TYPES.URL,
					QUESTION_TYPES.DATE,
				].includes(question.type) && (
					<TextField
						size={"small"}
						fullWidth
						variant="outlined"
						value={question.meta.defaultValue || ""}
						label="Default Value"
						onChange={(e) =>
							onChange(
								{
									...question,
									meta: { ...(question.meta || {}), defaultValue: e.target.value || "" },
								},
								idx
							)
						}
					/>
				)}
				{[QUESTION_TYPES.RADIO, QUESTION_TYPES.MULTIPLE_CHOICE, QUESTION_TYPES.SELECT].includes(question.type) && (
					<TextField
						size={"small"}
						fullWidth
						select
						variant="outlined"
						value={question.meta.defaultValue || ""}
						label="Default Value"
						onChange={(e) =>
							onChange(
								{
									...question,
									meta: { ...(question.meta || {}), defaultValue: e.target.value || "" },
								},
								idx
							)
						}
					>
						<MenuItem value={""}>Answer</MenuItem>
						{(question.answers || []).map(({ id, answer }, idx) => (
							<MenuItem key={`ans_${idx}`} value={id}>
								{answer}
							</MenuItem>
						))}
					</TextField>
				)}
			</Grid>
			<Grid item xs={3} className="empty:hidden">
				{([
					QUESTION_TYPES.NUMBER,
					QUESTION_TYPES.RANGE,
					QUESTION_TYPES.TEXT,
					QUESTION_TYPES.FREE_TEXT,
					QUESTION_TYPES.AUTOCOMPLETE,
					QUESTION_TYPES.MULTIPLE_CHOICE,
				].includes(question.type) ||
					(question.meta.multiple && QUESTION_TYPES.COMBO)) && (
					<>
						<TextField
							variant="outlined"
							label="Min"
							placeholder="Min. Value"
							size="small"
							style={{ maxWidth: 82, marginLeft: 0 }}
							value={question.meta?.min || 0}
							onChange={(e) => onChange({ ...question, meta: { ...(question.meta || {}), min: +e.target.value } }, idx)}
						/>
						<TextField
							variant="outlined"
							label="Max"
							placeholder="Max. Value"
							size="small"
							style={{ maxWidth: 100, marginLeft: 10 }}
							value={question.meta?.max || 0}
							onChange={(e) => onChange({ ...question, meta: { ...(question.meta || {}), max: +e.target.value } }, idx)}
						/>
					</>
				)}
			</Grid>
			<Grid item xs={12} className="empty:hidden flex gap-4">
				{question.type === QUESTION_TYPES.NUMBER && (
					<TextField
						variant="outlined"
						label="Separator"
						placeholder="Separator"
						size="small"
						value={question.meta?.thousandsSeparator || ""}
						onChange={(e) =>
							onChange({ ...question, meta: { ...(question.meta || {}), thousandsSeparator: e.target.value } }, idx)
						}
					/>
				)}
				{[
					QUESTION_TYPES.TEXT,
					QUESTION_TYPES.FREE_TEXT,
					QUESTION_TYPES.RANGE,
					QUESTION_TYPES.NUMBER,
					QUESTION_TYPES.EMAIL,
					QUESTION_TYPES.PHONE,
					QUESTION_TYPES.TAGS,
				].includes(question.type) && (
					<TextField
						variant="outlined"
						label="Text Box Placeholder"
						placeholder="Text Box Placeholder"
						size="small"
						value={question.meta?.placeholder || ""}
						onChange={(e) =>
							onChange({ ...question, meta: { ...(question.meta || {}), placeholder: e.target.value } }, idx)
						}
					/>
				)}
				{[QUESTION_TYPES.TAGS].includes(question.type) && (
					<TextField
						variant="outlined"
						label="Data Source URL"
						placeholder="Data Source URL"
						size="small"
						value={question.meta?.dataSourceURL || ""}
						onChange={(e) =>
							onChange({ ...question, meta: { ...(question.meta || {}), dataSourceURL: e.target.value } }, idx)
						}
					/>
				)}
				{[QUESTION_TYPES.NUMBER, QUESTION_TYPES.TEXT, QUESTION_TYPES.RANGE].includes(question.type) && (
					<>
						<TextField
							variant="outlined"
							label="Start Adornment"
							placeholder="Start Adornment"
							size="small"
							value={question.meta?.startAdornment || ""}
							onChange={(e) =>
								onChange({ ...question, meta: { ...(question.meta || {}), startAdornment: e.target.value } }, idx)
							}
						/>
						<TextField
							variant="outlined"
							label="End Adornment"
							placeholder="End Adornment"
							size="small"
							value={question.meta?.endAdornment || ""}
							onChange={(e) =>
								onChange({ ...question, meta: { ...(question.meta || {}), endAdornment: e.target.value } }, idx)
							}
						/>
					</>
				)}
			</Grid>
			<Grid item xs={12}>
				<Box display={"flex"}>
					<FormControlLabel
						control={
							<Checkbox
								checked={question.meta.optional || false}
								onChange={(e) => {
									const _question = { ...question };
									_question.meta.optional = e.target.checked;
									onChange(_question, idx);
								}}
								name="optional"
							/>
						}
						label="Optional"
					/>
					<FormControlLabel
						control={
							<Checkbox
								checked={question.meta.display === false}
								onChange={(e) => {
									const _question = { ...question };
									_question.meta.display = !e.target.checked;
									onChange(_question, idx);
								}}
							/>
						}
						label="Hide By Default"
					/>
					{QUESTION_TYPES.UPLOAD_FILE === question.type && (
						<TextField
							variant="outlined"
							value={question.meta?.maxSize || "5M"}
							label="Max. File Size"
							size={"small"}
							onChange={(e) =>
								onChange({ ...question, meta: { ...(question.meta || {}), maxSize: e.target.value } }, idx)
							}
						/>
					)}
					{[QUESTION_TYPES.MULTIPLE_CHOICE, QUESTION_TYPES.RADIO, QUESTION_TYPES.MULTI_LEVEL_CHOICE].includes(
						question.type
					) && (
						<FormControlLabel
							control={
								<Checkbox
									checked={question.meta.hasOther || false}
									onChange={(e) => {
										const _question = { ...question };
										_question.meta.hasOther = e.target.checked;
										onChange(_question, idx);
									}}
								/>
							}
							label="Has Other"
						/>
					)}
					{[QUESTION_TYPES.AUTOCOMPLETE, QUESTION_TYPES.TAGS, QUESTION_TYPES.COMBO].includes(question.type) && (
						<FormControlLabel
							control={
								<Checkbox
									checked={question.meta.multiple || false}
									onChange={(e) => {
										const _question = { ...question };
										_question.meta.multiple = e.target.checked;
										onChange(_question, idx);
									}}
								/>
							}
							label="Multiple"
						/>
					)}
					{[QUESTION_TYPES.TAGS].includes(question.type) && (
						<FormControlLabel
							control={
								<Checkbox
									checked={question.meta.prefetch || false}
									onChange={(e) => {
										const _question = { ...question };
										_question.meta.prefetch = e.target.checked;
										onChange(_question, idx);
									}}
								/>
							}
							label="Pre-fetch"
						/>
					)}
				</Box>
			</Grid>
			<ContextRules question={question} idx={idx} onChange={onChange} errors={errors} />
			{question.type === QUESTION_TYPES.COMBO && (
				<ComboQuestion question={question} onChange={onChange} idx={idx} errors={errors} />
			)}
			<Grid item xs={12} style={{ paddingBottom: "1rem" }}>
				<Answers
					questionIndex={idx}
					errors={errors}
					question={question}
					steps={steps}
					labels={labels}
					setAnswers={setAnswers}
					onQuestionChange={(question, qIndex) => {
						onChange({ ...question }, qIndex !== undefined ? qIndex : idx);
					}}
					onChange={(answers) => {
						onChange({ ...question, answers: [...answers] }, idx);
					}}
				/>
			</Grid>
			<Grid item xs={12} style={{ paddingBottom: "0.5rem" }}>
				<AttributeExtraction question={question} idx={idx} onChange={onChange} errors={errors} />
			</Grid>
			<Grid item xs={12} style={{ paddingBottom: "1rem" }}>
				<TenantRules questionId={question.id} />
			</Grid>
			<Grid item xs={12} className="flex gap-4" style={{ paddingBottom: "1rem" }}>
				<TextField
					select
					fullWidth
					variant="outlined"
					value={""}
					label="Move Question to Step"
					required
					size={"small"}
					onChange={(e) => {
						onStepChange(+e.target.value, question, idx);
					}}
				>
					<MenuItem value={""}>Move Question to Step</MenuItem>
					{(steps || []).map(({ group }, idx) => (
						<MenuItem key={`step_move_${group}`} value={idx}>
							{group}
						</MenuItem>
					))}
				</TextField>
				<Button
					fullWidth
					size="small"
					variant="outlined"
					onClick={() => {
						navigator.clipboard.writeText(JSON.stringify({ question }));
					}}
				>
					Copy Question & Answers
				</Button>
				<Button
					fullWidth
					size="small"
					variant="outlined"
					onClick={() => {
						navigator.clipboard.writeText(JSON.stringify({ answers: question.answers }));
					}}
				>
					Copy Answers
				</Button>
			</Grid>
			{referrer && (
				<Grid item xs={12} style={{ paddingBottom: "1rem" }}>
					<Box>Referred by: </Box>
					{referrer.map((refer, idx) => {
						return (
							<Box key={`refer-${refer.index}-${idx}`}>
								<Box>
									Question: {refer.index}&#41; {refer.question}
								</Box>
								<Box>Answer: {refer.answer}</Box>
							</Box>
						);
					})}
				</Grid>
			)}

			<Snackbar
				open={!!error}
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
				onClose={() => setError(null)}
			>
				<Alert onClose={() => setError(null)} variant="filled" severity={"error"} sx={{ width: "100%" }}>
					{error}
				</Alert>
			</Snackbar>
		</Grid>
	);
}
