import { fetchAdminV1, fetchV1 } from "../lib/requester/v1";

export function getAll(params) {
	return fetchAdminV1("/tenants", { method: "GET", data: params }, { returnWholeResponse: true });
}

export function update(data) {
	return fetchAdminV1(`/tenants/${data.id}`, { method: "PUT", data });
}

export function create(data) {
	return fetchAdminV1(`/tenants`, { method: "POST", data });
}

export function remove(id) {
	return fetchAdminV1(`/tenants/${id}`, { method: "DELETE" });
}

export function isTenantValid() {
	return fetchV1(`/tenants/is_tenant?domain=${window.location.hostname}`, { method: "GET" });
}
