import React from "react";
import { Information } from "src/components/login/Information";
import SignIn from "../@jumbo/components/Common/authComponents/SignIn";

const Login = () => (
	<>
		<Information />
		<SignIn method="default" variant="standard" wrapperVariant="bgColor" />
	</>
);

export default Login;
