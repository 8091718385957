import { fetchWrapper } from ".";
const apiURL = import.meta.env.VITE_API_BASE_URL + "/v1";
const adminAPIURL = import.meta.env.VITE_API_BASE_URL + "/admin/v1";
const tenantAdminAPIURL = import.meta.env.VITE_API_BASE_URL + "/tenant_admin/v1";
console.log("API Base URL", apiURL);

const _fetchV1 = fetchWrapper(apiURL, {});

export const fetchV1 = _fetchV1;

const _fetchAdminV1 = fetchWrapper(adminAPIURL, {});

export const fetchAdminV1 = _fetchAdminV1;

const _fetchTenantAdminV1 = fetchWrapper(tenantAdminAPIURL, {});

export const fetchTenantAdminV1 = _fetchTenantAdminV1;
