import { Box, Grid, MenuItem, Chip, TextField, IconButton, FormControlLabel, Checkbox } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import DeleteForever from "@material-ui/icons/DeleteForever";
import { Alert } from "@material-ui/lab";
import { getTypeDisplayName } from "src/helpers";
import { QUESTION_TYPES } from "src/helpers/constants";
const comboTypes = [
	QUESTION_TYPES.AUTOCOMPLETE,
	QUESTION_TYPES.DATE,
	QUESTION_TYPES.EMAIL,
	QUESTION_TYPES.FREE_TEXT,
	QUESTION_TYPES.NAME,
	QUESTION_TYPES.NUMBER,
	QUESTION_TYPES.TEXT,
	QUESTION_TYPES.URL,
	QUESTION_TYPES.PHONE,
];
export function ComboQuestion({ question, onChange, idx, errors }) {
	function onValueChange(field, index, value) {
		const _question = { ...question };
		_question.meta.comboFields = _question.meta.comboFields || [];
		_question.meta.comboFields[index][field] = value;
		onChange(_question, idx);
	}
	return (
		<>
			<Grid item xs={12}>
				{errors.comboFields && (
					<Box>
						<Alert severity="error">{errors.comboFields}</Alert>
					</Box>
				)}
				{(question.meta?.comboFields || []).map((field, comboIdx) => (
					<Grid key={`combo-field-row-${field.name}-${comboIdx}`} container spacing={1}>
						<Grid item xs={12}>
							<Chip label={`Field ${comboIdx + 1}`} size="small" />
							{errors[`comboFields${comboIdx}`] && (
								<Box color={"red"} ml={4} display={"inline"}>
									{errors[`comboFields${comboIdx}`]}
								</Box>
							)}
						</Grid>
						<Grid item xs={3} style={{ paddingBottom: "0.5rem" }}>
							<TextField
								select
								fullWidth
								variant="outlined"
								value={field.type || ""}
								label="Field Type"
								required
								size={"small"}
								onChange={(e) => {
									if (!e.target.value) {
										return;
									}
									onValueChange("type", comboIdx, e.target.value);
								}}
							>
								<MenuItem value={""}>Select Type</MenuItem>
								{comboTypes.map((type) => (
									<MenuItem key={`combo_typ_${comboIdx}_${type}`} value={type}>
										{getTypeDisplayName(type)}
									</MenuItem>
								))}
							</TextField>
						</Grid>
						<Grid item xs={3}>
							<TextField
								fullWidth
								variant="outlined"
								value={field.fieldName || ""}
								label="Name"
								required
								size={"small"}
								onChange={(e) => {
									if (!e.target.value) {
										return;
									}
									onValueChange("fieldName", comboIdx, e.target.value);
								}}
							/>
						</Grid>
						<Grid item xs={3}>
							<TextField
								fullWidth
								variant="outlined"
								value={field.placeholder || ""}
								label="Placeholder"
								size={"small"}
								onChange={(e) => {
									onValueChange("placeholder", comboIdx, e.target.value);
								}}
							/>
						</Grid>
						<Grid item xs={3}>
							<TextField
								fullWidth
								variant="outlined"
								value={field.defaultValue || ""}
								label="Default Value"
								size={"small"}
								onChange={(e) => {
									onValueChange("defaultValue", comboIdx, e.target.value);
								}}
							/>
						</Grid>
						{[QUESTION_TYPES.AUTOCOMPLETE, QUESTION_TYPES.SELECT].includes(field.type) && (
							<Grid item xs={4}>
								<TextField
									fullWidth
									multiline
									minRows={2}
									variant="outlined"
									value={field.answers || ""}
									label="Answers"
									required
									onChange={(e) => {
										if (!e.target.value) {
											return;
										}
										onValueChange("answers", comboIdx, e.target.value);
									}}
								/>
							</Grid>
						)}
						{field.type === QUESTION_TYPES.NUMBER && <Grid item xs={2}>
							<TextField
								fullWidth
								variant="outlined"
								value={field.thousandsSeparator || ""}
								label="Separator"
								size={"small"}
								onChange={(e) => {
									onValueChange("thousandsSeparator", comboIdx, e.target.value);
								}}
							/>
						</Grid>}
						<Grid item xs={2}>
							<TextField
								fullWidth
								variant="outlined"
								value={field.startAdornment || ""}
								label="Start Adornm.."
								size={"small"}
								onChange={(e) => {
									onValueChange("startAdornment", comboIdx, e.target.value);
								}}
							/>
						</Grid>
						<Grid item xs={2}>
							<TextField
								fullWidth
								variant="outlined"
								value={field.endAdornment || ""}
								label="End Adornme.."
								size={"small"}
								onChange={(e) => {
									onValueChange("endAdornment", comboIdx, e.target.value);
								}}
							/>
						</Grid>
						<Grid item xs={2}>
							<FormControlLabel
								control={
									<Checkbox
										checked={field.optional || false}
										onChange={(e) => {
											onValueChange("optional", comboIdx, e.target.checked);
										}}
									/>
								}
								label="Optional"
							/>
						</Grid>
						<Grid item xs={2}>
							<Box display={"flex"} mt={0} minWidth={"40px"} alignItems="flex-start">
								<IconButton
									variant="contained"
									color="primary"
									size="small"
									onClick={(e) => {
										e.stopPropagation();
										const _question = { ...question };
										_question.meta.comboFields = _question.meta.comboFields || [];
										_question.meta.comboFields.splice(comboIdx + 1, -1, { type: "", fieldName: "", placeholder: "" });
										onChange(_question, idx);
									}}
								>
									<Add size="small" />
								</IconButton>
								<IconButton
									variant="contained"
									color="secondary"
									size="small"
									onClick={() => {
										const _question = { ...question };
										_question.meta.comboFields = _question.meta.comboFields || [];
										_question.meta.comboFields.splice(comboIdx, 1);
										onChange(_question, idx);
									}}
								>
									<DeleteForever size="small" />
								</IconButton>
							</Box>
						</Grid>
					</Grid>
				))}
			</Grid>
			<Grid item xs={4}>
				Add Fields
				<TextField
					select
					fullWidth
					variant="outlined"
					value={""}
					label="Field Type"
					required
					size={"small"}
					onChange={(e) => {
						if (!e.target.value) {
							return;
						}
						const _question = { ...question };
						_question.meta.comboFields = _question.meta.comboFields || [];
						_question.meta.comboFields.push({ type: e.target.value });
						onChange(_question, idx);
					}}
				>
					<MenuItem value={""}>Select Type</MenuItem>
					{comboTypes.map((type) => (
						<MenuItem key={`combo_typ_${type}`} value={type}>
							{getTypeDisplayName(type)}
						</MenuItem>
					))}
				</TextField>
			</Grid>
		</>
	);
}
