import { CircularProgress, FormHelperText } from "@material-ui/core";
import { useContext, useState } from "react";
import { getPresignedUrlToUpload } from "src/api/userAnswers";
import { fillTemplate } from "src/helpers";
import Alert from "src/helpers/alert";
import { IMAGE_ASPECT_RATIOS } from "src/helpers/constants";
import { Context } from "./Context";
const imageDimension = {
	square: { w: "6rem", h: "6rem", optimal: "512x512" },
	circle: { w: "6rem", h: "6rem", optimal: "512x512" },
	"3:2": { w: "9rem", h: "6rem", optimal: "768x512" },
	"4:3": { w: "8rem", h: "6rem", optimal: "682x512" },
	"16:10": { w: "9.6rem", h: "6rem", optimal: "512x512" },
	"3:4": { w: "4.5rem", h: "6rem", optimal: "512x512" },
};
function getFileNameFromUrl(url) {
	const segments = url.split("/");
	const lastSegment = segments[segments.length - 1];
	const nameSegments = lastSegment.split("-"); //remove date prepended while uploading
	nameSegments.shift();
	return decodeURIComponent(nameSegments.join("-"));
}
function getSize(size) {
	const _size = size.toLowerCase();
	if (_size.includes("k")) {
		return parseInt(size) * 1024;
	} else if (_size.includes("m")) {
		return parseInt(size) * Math.pow(1024, 2);
	} else if (_size.includes("g")) {
		return parseInt(size) * Math.pow(1024, 3);
	}
	return size;
}
export function UploadFile({ question, url, setUserAnswer, error }) {
	const acceptedFileTypes = question.meta.allowedFileTypes || "image/*";
	const maxSize = question.meta.maxSize || "5M";
	const { entityId } = useContext(Context);
	const [fileUrl, setUrl] = useState(url);
	const [uploading, setUploading] = useState(false);
	const [sizeError, setSizeError] = useState(null);
	const [isImage, setIsImage] = useState(
		url
			? /apng|png|jpg|jpeg|jfif|pjpeg|pjp|avif|gif|svg|webp/gi.test(url)
			: acceptedFileTypes && /image/gi.test(acceptedFileTypes)
	);
	const [fileName, setFileName] = useState(url && url.startsWith("http") ? getFileNameFromUrl(url) : "File");
	function setAndUploadFile(e) {
		const file = e.target.files[0];
		if (maxSize && file.size > getSize(maxSize)) {
			setSizeError(`File size is greater than ${maxSize}`);
			return;
		}
		setSizeError(null);
		const reader = new FileReader();
		const _fileNameSegments = file.name.split(".");
		const ext = _fileNameSegments[_fileNameSegments.length - 1];
		const fileName = file.name.replace(`.${ext}`, "");
		setIsImage(file.type.includes("image"));
		setFileName(file.name);
		reader.addEventListener(
			"load",
			async () => {
				setUrl(reader.result);
				try {
					setUploading(true);
					const context = {
						uuid: `${Date.now()}-${encodeURIComponent(fileName)}`,
						ext: ext,
						id: entityId || "UNKNOWN",
					};
					// funder/profile/{id}/{uuid}.{ext}
					const path = fillTemplate(question.meta.path, context);
					const url = await getPresignedUrlToUpload({ path });
					await window.fetch(url, { method: "PUT", body: file });
					setUserAnswer(undefined, url.split("?")[0]);
				} catch (err) {
					console.error("Error while uploading file", err);
					Alert.error("Unable to upload");
					setUrl(null);
				} finally {
					setUploading(false);
				}
			},
			false
		);

		if (file) {
			reader.readAsDataURL(file);
		}
	}
	const aspectRatio = question.meta.aspectRatio || IMAGE_ASPECT_RATIOS.square;
	const rounded = aspectRatio === IMAGE_ASPECT_RATIOS.circle ? "rounded-full" : "rounded-md";
	const size = isImage ? imageDimension[aspectRatio] : { w: "auto", h: "auto" };
	const _error = sizeError || error;
	return (
		<div>
			{isImage && <FormHelperText>Recommended Dimension: {size.optimal}</FormHelperText>}
			<FormHelperText>Max Size: {maxSize}</FormHelperText>
			{fileUrl ? (
				<div
					className={`bg-slate-200 inline-flex justify-center items-center relative mt-4 ${rounded}`}
					style={{ width: size.w, height: size.h }}
				>
					<button
						className="absolute -right-2 -top-2 bg-red-500 w-6 h-6 pb-1 rounded-full flex justify-center items-center text-white font-bold text-xl"
						onClick={() => {
							setUrl(null);
							setUserAnswer(undefined, "");
							setIsImage(true);
						}}
					>
						&times;
					</button>
					{isImage ? (
						<img src={fileUrl} alt="Image" className={rounded} />
					) : (
						<a href={fileUrl} target="_blank" rel="noreferrer" className="p-4">
							{fileName}
						</a>
					)}
					{uploading && (
						<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
							<CircularProgress />
						</div>
					)}
				</div>
			) : (
				<label
					className={`bg-slate-200 inline-flex justify-center items-center cursor-pointer mt-4 ${rounded} min-h-[40px]`}
					style={{ width: size.w, height: size.h }}
				>
					<span className="px-4">Upload</span>
					<input type="file" accept={acceptedFileTypes} className="hidden" value={""} onChange={setAndUploadFile} />
				</label>
			)}
			{_error && <FormHelperText error={true}>{_error}</FormHelperText>}
		</div>
	);
}
