import { alpha } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import makeStyles from "@material-ui/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import EmojiNature from "@material-ui/icons/EmojiNature";
import Add from "@material-ui/icons/Add";
import { useState } from "react";
import { useLoaderData, Link as RouterLink } from "react-router-dom";

import { create, getAllForAdmin } from "src/api/opportunities";
import { GENERIC_REQUEST_ERROR } from "src/components/commons/Errors";
import { Main } from "src/components/commons/Main";
import { OpportunityForm } from "src/components/opportunities/Form";
import { populateAllParameters } from "src/api/opportunityMatchingParameters";
import Alert from "src/helpers/alert";

const useStyles = makeStyles((theme) => ({
	emptyState: {
		padding: "6rem 0",
		color: alpha(theme.palette.common.black, 0.3),
	},
	listItemAvatar: {
		marginTop: 0,
	},
	avatar: {
		width: 150,
		height: 150 * 0.616,
		background: theme.palette.background.default,
		"& img": {
			objectFit: "contain",
		},
	},
	chip: {
		color: theme.palette.common.white,
		"&.warning": {
			background: theme.palette.warning.dark,
		},
		"&.active": {
			background: theme.palette.success.dark,
		},
	},
}));
const statusMap = { IN_PROGRESS: "In Progress", ACTIVE: "Active", INACTIVE: "Inactive" };
const statusColorMap = { IN_PROGRESS: "warning", ACTIVE: "active", INACTIVE: "inactive" };
export function Opportunities() {
	const paginatedOpportunities = useLoaderData();
	const classes = useStyles();
	const [openModal, setOpenModal] = useState(false);
	const [isPopulating, setPopulating] = useState(false);
	async function populateAllOpportunityParameters() {
		try {
			setPopulating(true);
			await populateAllParameters();
			Alert.success("Populated successfully");
		} catch (err) {
			console.error("Error while populating all opportunities", err);
			Alert.success("Unable to complete");
		} finally {
			setPopulating(false);
		}
	}
	return (
		<Main>
			<Box display={"flex"} justifyContent="space-between" p={4} px={4} borderBottom={"1px solid rgb(239 232 251)"}>
				<Typography component={"h2"} variant="h6">
					All Opportunities
				</Typography>
				<Button color="primary" variant="contained" onClick={populateAllOpportunityParameters} disabled={isPopulating}>
					Populate Matching Parameters
				</Button>
			</Box>
			{paginatedOpportunities.data.length === 0 ? (
				<Box textAlign={"center"} className={classes.emptyState}>
					<Box>
						<EmojiNature />
					</Box>
					There are no opportunities so far{" "}
					<Button
						variant="text"
						color="primary"
						disabled={false}
						startIcon={<Add />}
						onClick={() => setOpenModal(true)}
					>
						Create One
					</Button>
				</Box>
			) : (
				<List component={"ul"}>
					{paginatedOpportunities.data.map((opportunity) => {
						return (
							<ListItem component={"li"} key={`opp-list-${opportunity.id}`} button alignItems="flex-start">
								<ListItemAvatar className={classes.listItemAvatar}>
									<Avatar
										className={classes.avatar}
										variant={"rounded"}
										alt={opportunity.name}
										src={"/images/icons/campaign.svg"}
									/>
								</ListItemAvatar>
								<Box display={"flex"} flexDirection={"column"} ml={4} alignItems={"flex-start"} width={"100%"}>
									<Chip
										size="small"
										label={statusMap[opportunity.status]}
										className={`${classes.chip} ${statusColorMap[opportunity.status]}`}
									/>
									<ListItemText
										primaryTypographyProps={{ component: "h3" }}
										id={opportunity.name}
										primary={opportunity.name}
									/>
									<Box component={"p"} className="text-slate-500">
										{opportunity.description}
									</Box>
									<Box component={"p"}>
										Created By: {`${opportunity.creator?.firstName} ${opportunity.creator?.lastName}`}
									</Box>
									<Box component={"p"}>Creator Email: {opportunity.creator?.email}</Box>
								</Box>
								<Box display={"flex"} flexDirection={"column"} ml={4} alignItems={"flex-start"}>
									<Button
										component={RouterLink}
										to={`/admin/opportunity/${opportunity.id}`}
										color="primary"
										size="small"
										variant="contained"
									>
										View
									</Button>
								</Box>
							</ListItem>
						);
					})}
				</List>
			)}
			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={openModal}
				onClose={() => setOpenModal(false)}
				arialabelledby={"create-opp-dialog"}
			>
				<DialogTitle id="create-opp-dialog" pb={0}>
					Create Opportunity
				</DialogTitle>
				<OpportunityForm close={() => setOpenModal(false)} />
			</Dialog>
		</Main>
	);
}

export function opportunitiesLoader() {
	return getAllForAdmin({ join: "creator" });
}

export async function opportunityAction({ request }) {
	switch (request.method) {
		case "POST": {
			let formData = await request.formData();
			const name = formData.get("name");
			const description = formData.get("description");
			const errors = {};
			if (typeof name !== "string" || !name.trim()) {
				errors.name = "Name must be a minimum of 5 characters";
			}
			if (Object.keys(errors).length) {
				return { errors };
			}

			try {
				const response = await create({
					name,
					description,
				});
				return new Response(response, { status: 201 });
			} catch (err) {
				console.error("Error while creating opportunity", err);
				if (err instanceof Error) {
					errors.server = GENERIC_REQUEST_ERROR("CN001");
				}
				if (err && err[0]) {
					errors.server = err[0];
				}
				return { errors };
			}
		}
		default: {
			throw new Response("Unknown ", { status: 405 });
		}
	}
}
