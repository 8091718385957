import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ONBOARDING_STEPS, ROLES } from "src/helpers/constants";
import { Typography } from "@material-ui/core";
// import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
	disabled: {
		"& img": {
			filter: "grayscale(0.9) contrast(0.6)",
		},
	},
}));
export default function FunderOnboardingWizard() {
	const classes = useStyles();
	const { authUser } = useSelector(({ auth }) => auth);
	const completedStep = authUser.onboarding[ROLES.FUNDER]?.completedStep || 1;
	return (
		<Box className="grid grid-cols-12 p-4">
			<Typography component={"h1"} variant="h4" className="py-4 col-start-2 col-span-10 text-center">
				Welcome to InvestorMatch.AI
			</Typography>
			<Box className="col-start-2 col-span-10">
				<Box
					className={
						"flex p-4 gap-4 justify-start " +
						(completedStep != ONBOARDING_STEPS.SIGNUP
							? `pointer-events-none text-slate-300 ${classes.disabled}`
							: "")
					}
				>
					<img src="/images/onboarding-profile.svg" width={"200px"} className="aspect-square" />
					<Box className="[&>*]:mb-2">
						<h2 className="text-2xl">Step 1: Profile</h2>
						<p className="text-xl">Fill in your personal details</p>
						<p className="text-lg">Let&apos;s get to know you</p>
						<Box className="pt-4">
							<Button
								component={Link}
								to="/profile"
								variant="contained"
								disabled={completedStep != ONBOARDING_STEPS.SIGNUP}
								color="primary"
							>
								Get Started
							</Button>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box className="col-start-2 col-span-10">
				<Box
					className={
						"flex p-4 gap-4 justify-start " +
						(completedStep != ONBOARDING_STEPS.PROFILE
							? `pointer-events-none text-slate-300 ${classes.disabled}`
							: "")
					}
				>
					<img src="/images/onboarding-org.svg" width={200} />
					<Box className="[&>*]:mb-2">
						<h2 className="text-2xl">Step 2: Organization Details</h2>
						<p className="text-xl">Fill in your organization details</p>
						<p className="text-md p-2 bg-purple-100 rounded-lg">
							Once we match you and both the parties show interest, your organization details will be shown to the
							founders.
						</p>
						<p className="text-lg">Let&apos;s get to know about your organization</p>
						<Box className="pt-4">
							<Button
								component={Link}
								to="/organization"
								variant="contained"
								color="primary"
								disabled={completedStep != ONBOARDING_STEPS.PROFILE}
							>
								Create Organization
							</Button>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box className="col-start-2 col-span-10">
				<Box
					className={
						"flex p-4 gap-4 justify-start " +
						(completedStep != ONBOARDING_STEPS.ORGANIZATION
							? `pointer-events-none text-slate-300 ${classes.disabled}`
							: "")
					}
				>
					<img src="/images/onboarding-form.svg" width={200} />
					<Box className="[&>*]:mb-2">
						<h2 className="text-2xl">Step 3: Opportunity</h2>
						<p className="text-lg">Create and fill in the funding opportunity</p>
						<p className="text-md p-2 bg-purple-100 rounded-lg">
							Here we ask about your fund and the verticals you&apos;d like to deploy the fund together with your
							expectations and readiness to deploy the fund. Typically this would take between 30-45 mins.
						</p>
						<p className="text-xl">Let&apos;s find you some ideal matches!</p>
						<Box className="pt-4">
							<Button
								component={Link}
								to="/opportunities"
								variant="contained"
								state={{ create: true }}
								color="primary"
								disabled={completedStep != ONBOARDING_STEPS.ORGANIZATION}
							>
								Create Opportunity
							</Button>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}
FunderOnboardingWizard.propTypes = {};
