import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Add from "@material-ui/icons/Add";
import DeleteForever from "@material-ui/icons/DeleteForever";
import makeStyles from "@material-ui/styles/makeStyles";
import { useState, memo } from "react";
import Alert from "src/helpers/alert";

import { remove } from "src/api/questions";

const useStyles = makeStyles(() => ({
	list: {
		cursor: "pointer",
		borderBottom: "1px solid rgb(239 232 251)",
		"&.selected": {
			backgroundColor: "#e2e2e3",
		},
		"&:hover": {
			boxShadow: "rgba(0, 0, 0, 0.2) 0px 3px 10px 0px",
			transform: "translateY(-4px)",
			borderBottomColor: "transparent",
			textDecoration: "none",
			backgroundColor: "rgb(245, 247, 250)",
		},
	},
}));

export const QuestionListItem = memo(function QuestionListItem({
	question,
	idx,
	stepIndex,
	onDelete,
	onAdd,
	onSelect,
	isSelected,
	errors = {},
}) {
	const classes = useStyles();
	const [deleting, setDeleting] = useState(false);
	async function deleteQuestion(e) {
		e.stopPropagation();
		if (question.id) {
			setDeleting(true);
			try {
				await remove(question.id);
				onDelete(idx);
			} catch (err) {
				console.error("error while removing question", err);
				Alert.error("Unable to delete the question");
			} finally {
				setDeleting(false);
			}
		} else {
			onDelete(idx);
		}
	}
	return (
		<Box
			component={"li"}
			p={4}
			display="flex"
			id={`question-id-${question.id}`}
			className={`${classes.list} ${isSelected ? "selected" : ""}`}
			onClick={onSelect}
		>
			<Box component={"span"} flex={"1"} style={{ wordBreak: "break-word" }}>
				<Typography
					component={"span"}
					variant="body2"
					color={
						Object.keys(errors[`step${stepIndex}`]?.questions[`question${idx}`] || {}).length
							? "error"
							: "textSecondary"
					}
				>
					{stepIndex + 1}.{idx + 1}&#41;{" "}
					{question.question || (
						<Typography component={"em"} color="inherit" variant="body2">
							New Question
						</Typography>
					)}
				</Typography>
				{(question.meta.field || question.meta.keywords) && (
					<Box>
						<div className="bg-slate-200 text-slate-500 p-1 rounded-full text-xs inline mt-1 mr-1">{question.meta.field}</div>
						{(question.meta.keyword || "")
							.split(",")
							.filter(Boolean)
							.map((keyword) => (
								<div className="bg-slate-200 text-slate-500 p-1 rounded-full text-xs inline mr-1 mt-1" key={`question-id-${question.id}-${keyword}`}>
									{keyword}
								</div>
							))}
					</Box>
				)}
			</Box>
			<Box display={"flex"} mt={0} minWidth={"40px"} alignItems="flex-start">
				<IconButton
					variant="contained"
					color="primary"
					size="small"
					onClick={(e) => {
						e.stopPropagation();
						onAdd(stepIndex, idx);
					}}
				>
					<Add size="small" />
				</IconButton>
				{deleting ? (
					<CircularProgress color="secondary" size={"1rem"} style={{ margin: "0.5rem 0 0 0.5rem" }} />
				) : (
					<IconButton variant="contained" color="secondary" size="small" disabled={deleting} onClick={deleteQuestion}>
						<DeleteForever size="small" />
					</IconButton>
				)}
			</Box>
		</Box>
	);
});
