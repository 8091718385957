import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
// import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Card, CardHeader } from "src/components/commons/Card";
import { DisplayUserAnswers } from "src/components/commons/DisplayUserAnswers";
import { DisplayKeyValuePair } from "src/components/commons/DisplayKeyValuePair";

export function SelectedEntityView({ category, selectedEntity, onPopulate, isFetching = false, userAnswers = [] }) {
	return (
		<Grid item xs={12} md={3}>
			<Card>
				<CardHeader>
					<div className="text-lg">{selectedEntity?.name || `Selected ${category}`}</div>
				</CardHeader>
				<Box height={"calc(100vh - 230px)"} overflow={"auto"} p={4}>
					{selectedEntity ? (
						<>
							<div className="grid grid-cols-12 max-w-full mb-2">
								<div className="col-span-12 capitalize">Email:</div>
								<div className="col-span-12">
									<div className="inline-block bg-slate-200 rounded-md p-1 mb-1 ml-1 font-bold">
										{selectedEntity.creator.email}
									</div>
								</div>
							</div>
							{selectedEntity.matchingParameter ? (
								<DisplayKeyValuePair
									data={selectedEntity.matchingParameter}
									excludeKeys={["id", "createdAt", "updatedAt", "deletedAt", "campaignId", "opportunityId", "industry"]}
									id={`campaign-${selectedEntity.id}`}
								/>
							) : (
								<div className="flex justify-center mb-4">
									<Button variant="outlined" color="primary" onClick={onPopulate}>
										Populate Signals
									</Button>
								</div>
							)}
							<div className="p-4 -mx-4 bg-slate-200">Answers</div>
							{isFetching ? (
								<Box height={"100%"} display="flex" justifyContent={"center"} alignItems={"center"}>
									<CircularProgress />
								</Box>
							) : (
								<DisplayUserAnswers
									id={`${category}-${selectedEntity.id}`}
									isAdmin={true}
									type={category}
									answers={userAnswers}
								/>
							)}
						</>
					) : (
						<Typography component={"p"} variant="subtitle2" className="placeholder" align="center">
							No {category} is selected
						</Typography>
					)}
				</Box>
			</Card>
		</Grid>
	);
}
