export function FunderOrganizationDisplay({ orgDetails }) {
	return (
		<div className="p-4 flex flex-col gap-4">
			{orgDetails.details.logo && (
				<div>
					<img className="rounded-md" src={orgDetails.details.logo} width={300} height={300} alt="details picture" />
				</div>
			)}
			<div className="flex gap-4">
				<div>Name: </div>
				<div className="font-extrabold">{orgDetails.name}</div>
			</div>
			<div className="flex gap-4">
				<div>Type: </div>
				<div className="font-extrabold">{orgDetails.type}</div>
			</div>
			<div className="flex gap-4">
				<div>Vision: </div>
				<div className="font-extrabold">
					{orgDetails.details.thesis || "-"}
				</div>
			</div>
			<div className="flex gap-4">
				<div>Address: </div>
				<div className="font-extrabold">
					{[orgDetails.address1, orgDetails.city, orgDetails.state, orgDetails.country].join(", ")}
				</div>
			</div>
			<div className="flex gap-4">
				<div>Website: </div>
				<div className="font-extrabold">{orgDetails.details.website || "-"}</div>
			</div>
		</div>
	);
}
