import React, { cloneElement, isValidElement } from 'react';
// import { useDispatch } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
// import { setKeyword } from '../../../redux/actions/Search';

const useStyles = makeStyles(theme => ({
  navMenuItem: {
    padding: '12px 0 12px 0',
    position: 'relative',
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      // paddingLeft: 16,
    },
  },
  navMenuLink: {
    display: 'flex',
    flexDirection: "column",
    alignItems: 'center',
    // padding: '9px 16px 9px 32px',
    padding: 0,
    // borderTopRightRadius: 30,
    // borderBottomRightRadius: 30,
    color: theme.palette.sidebar ? theme.palette.sidebar.textColor : theme.palette.primary.main,
    whiteSpace: "nowrap",
    '&:hover, &:focus': {
      color: theme.palette.sidebar ? theme.palette.sidebar.textActiveColor : theme.palette.primary.main,
    //   color: theme.palette.sidebar ? theme.palette.sidebar.textDarkColor : theme.palette.primary.main,
    //   backgroundColor: theme.palette.sidebar ? theme.palette.sidebar.navHoverBgColor : theme.palette.primary.main,
    //   '& .Cmt-icon-root, & .Cmt-nav-text': {
    //     color: theme.palette.sidebar ? theme.palette.sidebar.textDarkColor : theme.palette.primary.main,
    //   },
    },
    '&.active': {
      color: theme.palette.sidebar ? theme.palette.sidebar.textActiveColor : theme.palette.primary.main,
      borderRight: `4px solid ${theme.palette.sidebar.textActiveColor}`,
      // backgroundColor: alpha(theme.palette.sidebar ? theme.palette.sidebar.navActiveBgColor : theme.palette.primary.main, 0.12),
      // borderLeft: `4px solid ${theme.palette.sidebar ? theme.palette.sidebar.navActiveBgColor : theme.palette.primary.main}`,
      '& .Cmt-icon-root, & .Cmt-nav-text': {
        color: theme.palette.sidebar ? theme.palette.sidebar.textActiveColor : theme.palette.primary.main,
      },
      '&:hover, &:focus': {
        '& .Cmt-nav-text, & .Cmt-icon-root': {
          color: theme.palette.sidebar ? theme.palette.sidebar.textActiveColor : theme.palette.primary.main,
        },
      },
    },
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      // justifyContent: 'center',
      // padding: 0,
      // height: 40,
      // width: 40,
      // borderRadius: '50%',
      // marginLeft: 4,
      // marginRight: 4,
    },
  },
  navText: {
    flex: 1,
    fontSize: 12,
    padding: "0 2px",
    whiteSpace: "break-spaces",
    textAlign: "center",
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      // display: 'none',
    },
  },
  iconRoot: {
    // marginRight: 16,
    fontSize: 30,
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      marginRight: 0,
    },
  },
}));

const NavMenuItem = props => {
  const { name, icon, link } = props;
  const classes = useStyles();
  // const dispatch = useDispatch();

  const renderIcon = () => {
    if (icon && isValidElement(icon)) {
      return cloneElement(icon, {
        className: clsx(classes.iconRoot, 'Cmt-icon-root'),
      });
    }

    return null;
  };

  return (
    <List
      component="div"
      className={clsx(classes.navMenuItem, 'Cmt-nav-menu-item')}
      onClick={() => {
        // dispatch(setKeyword());
      }}>
      <NavLink className={clsx(classes.navMenuLink, 'Cmt-nav-menu-link')} to={link}>
        {/* Display an icon if any */}
        {renderIcon()}
        <Box component="span" className={clsx(classes.navText, 'Cmt-nav-text')}>
          {name}
        </Box>
      </NavLink>
    </List>
  );
};

export default NavMenuItem;
