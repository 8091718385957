import Box from "@material-ui/core/Box";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import Button from "@material-ui/core/Button";
import Add from "@material-ui/icons/Add";
import { useLoaderData, defer, Link, useLocation, useParams } from "react-router-dom";

import { Main } from "src/components/commons/Main";
import { Async } from "src/components/commons/Async";
import { getAll } from "src/api/teamMembers";
import { TeamMemberListItem } from "src/components/teamMembers/TeamMemberListItem";
import Edit from "@material-ui/icons/Edit";

export default function TeamMembers() {
	const { data } = useLoaderData();
	const { state } = useLocation();
	const { id } = useParams();

	return (
		<>
			<Breadcrumbs separator="›" aria-label="breadcrumb" style={{ marginBottom: "1rem" }}>
				<Link to={`/teams`}>
					<Typography color="textSecondary">Teams</Typography>
				</Link>
				<Typography color="textPrimary">Team Members</Typography>
			</Breadcrumbs>
			<Main>
				<Box display={"flex"} justifyContent="space-between" p={4} px={4} borderBottom={"1px solid rgb(239 232 251)"}>
					<Typography component={"h2"} variant="h6">
						{state?.team?.name || ""} Members
					</Typography>
					<Box>
						<Button
							startIcon={<Edit />}
							type="button"
							color="primary"
							component={Link}
							to={`/team/${id}`}
							size="small"
							variant="contained"
							style={{ marginRight: "0.5rem", paddingRight: "0.5rem" }}
						>
							Edit Team
						</Button>
						<Button
							variant="contained"
							color="primary"
							size="small"
							startIcon={<Add />}
							component={Link}
							to={`/team/${id}/member/add`}
						>
							Add Member
						</Button>
					</Box>
				</Box>
				<Async error={"Unable to fetch team members"} promise={data}>
					{(members) => {
						if (members.data.length === 0) {
							return (
								<Box
									display={"flex"}
									flexDirection={"column"}
									p={8}
									minHeight={150}
									justifyContent={"space-between"}
									alignItems={"Center"}
								>
									<Typography component={"div"} variant="body2">
										No members
									</Typography>
									<Button
										startIcon={<Add />}
										component={Link}
										to={`/team/${id}/member/add`}
										variant="text"
										color="primary"
										on
									>
										Add Member
									</Button>
								</Box>
							);
						}
						return (
							<List component={"ul"}>
								{members.data.map((member) => {
									return <TeamMemberListItem key={`team-mem-id-${member.id}`} member={member} />;
								})}
							</List>
						);
					}}
				</Async>
			</Main>
		</>
	);
}

export async function teamMembersLoader({ params }) {
	return defer({
		data: getAll({ filter: `teamId||$eq||${params.id}` }),
	});
}
