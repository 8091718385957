import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Grid from "@material-ui/core/Grid";
import { Link, useLoaderData, useParams } from "react-router-dom";
import { FounderProfileDisplay } from "src/components/commons/FounderProfileDisplay";
import { Card, CardHeader } from "src/components/commons/Card";

export function ApplicationTeam() {
	const { id } = useParams();
	const { campaign } = useLoaderData();

	return (
		<>
			<Breadcrumbs separator="›" aria-label="breadcrumb" style={{ marginBottom: 16 }}>
				<Link to={"/applications/founders"}>
					<Typography color="textSecondary">Applications</Typography>
				</Link>
				<Link to={`/application/founder/${id}`}>
					<Typography color="textSecondary">Detail</Typography>
				</Link>
				<Typography color="textPrimary">Team</Typography>
			</Breadcrumbs>
			<Grid container spacing={1}>
				{campaign.teamMembers.map((member) => {
					if (!member.details.isContactPerson && member.isVisible === false) {
						return null;
					}
					const userDetails = {
						...member,
						details: {
							...member.details,
							...(member.email === campaign.creator.email ? campaign.creator.profile : {}),
						},
					};
					return (
						<Grid key={`member_${member.id}`} item xs={12} md={6}>
							<Card>
								<CardHeader>
									<h4 className="text-xl">Founding Team Member</h4>
								</CardHeader>
								<FounderProfileDisplay userDetails={userDetails} />
							</Card>
						</Grid>
					);
				})}
			</Grid>
		</>
	);
}

export function applicationTeamLoader() {
	
}