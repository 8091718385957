import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import { useState } from "react";
import getByPath from "src/helpers/getByPath";
import setByPath from "src/helpers/setByPath";

function Form({ child, idx, path = [], onChange }) {
	const [collapsed, setCollapsed] = useState(true);
	const [isEditing, setEditing] = useState(false);
	const [value, setValue] = useState(child.name);
	return (
		<Box marginLeft={"1rem"} paddingY={"0.5rem"} borderLeft={"1px solid #ccc"} data-idx={path.join("-")}>
			<Box display="flex" alignItems={"center"}>
				{child.children?.length > 0 ? (
					<Box
						style={collapsed ? { cursor: "pointer" } : { cursor: "pointer", transform: "rotate(90deg)" }}
						onClick={() => setCollapsed(!collapsed)}
					>
						▶
					</Box>
				) : (
					<Box>-</Box>
				)}
				{isEditing ? (
					<Box display={"flex"} paddingLeft={"0.1rem"} paddingRight={"0.25rem"} style={{ gap: 12 }}>
						<input
							style={{ border: "1px solid #aaa", width: 300, borderRadius: 6, paddingInline: 8 }}
							value={value || ""}
							onChange={(e) => {
								setValue(e.target.value);
							}}
						/>
						<Button
							size="small"
							variant="contained"
							color="primary"
							onClick={() => {
								onChange(path, value, "update");
								setEditing(false);
								setValue("");
							}}
						>
							Done
						</Button>
						<Button
							size="small"
							variant="contained"
							style={{ color: "#fff", textAlign: "center", background: "#e53e3e" }}
							onClick={() => {
								setEditing(false);
								setValue("");
							}}
						>
							Cancel
						</Button>
					</Box>
				) : (
					<Box marginX={2}>{child.name}</Box>
				)}
				{!isEditing && (
					<Box display={"flex"} paddingX={"0.25rem"} style={{ gap: 16 }}>
						<Button size="small" variant="outlined" color="primary" onClick={() => setEditing(true)}>
							✎ Edit
						</Button>
						<Button size="small" variant="outlined" color="primary" onClick={() => onChange(path, undefined, "add")}>
							+ Add Sibling
						</Button>
						<Button
							size="small"
							variant="outlined"
							color="primary"
							onClick={() => {
								onChange(path, undefined, "addchild");
								setCollapsed(false);
							}}
						>
							++ Add child
						</Button>
						<Button
							size="small"
							style={{ color: "#fff", textAlign: "center", background: "#e53e3e" }}
							onClick={() => onChange(path, undefined, "delete")}
						>
							Delete
						</Button>
					</Box>
				)}
			</Box>
			{!collapsed && (
				<div>
					{child.children?.map((c, index) => {
						return (
							<Form
								key={`child-${idx}-${index}-${c.name}`}
								idx={idx}
								id={path.join("-")}
								path={[...path, "children", index]}
								child={c}
								onChange={onChange}
							/>
						);
					})}
				</div>
			)}
		</Box>
	);
}

export function EditMultiLevelChoice({ title, metaChildren, onDone, onCancel }) {
	const [verticals, setVerticals] = useState([...metaChildren]);

	return (
		<Modal open={true} onClose={() => onCancel()}>
			<Box
				bgcolor={"#fff"}
				p={4}
				width={"calc(100vw - 40px)"}
				height={"calc(100vh - 40px)"}
				margin={"20px auto"}
				borderRadius={12}
			>
				<Box component="h3" textAlign={"center"}>
					{title}
				</Box>
				<Box paddingX={8} overflow={"auto"} height={"calc(100% - 75px)"}>
					{verticals.length <= 0 && (
						<div>
							<button
								onClick={() =>
									setVerticals(() => {
										return [{ name: "New Item" }];
									})
								}
							>
								Add Child
							</button>
						</div>
					)}
					{verticals.map((child, idx) => {
						return (
							<Form
								key={`child-${idx}-${child.name}`}
								idx={`child-${idx}`}
								path={[idx]}
								child={child}
								onChange={(path, value, action) => {
									const _verticals = [...verticals];
									if (action === "delete") {
										const _path = [...path];
										const index = _path[_path.length - 1];
										_path.pop();
										const arr = getByPath(_verticals, _path);
										arr.splice(index, 1);
									} else if (action === "update") {
										setByPath(_verticals, [...path, "name"], value);
									} else if (action === "add") {
										const _path = [...path];
										const index = _path[_path.length - 1];
										_path.pop();
										const arr = getByPath(_verticals, _path);
										arr.splice(index + 1, 0, { name: "New Sibling" });
									} else if (action === "addchild") {
										console.log("CHILD");
										const _path = [...path];
										const object = getByPath(_verticals, _path);
										if (object.children?.length) {
											object.children.push({ name: "New Child" });
										} else {
											object.children = [{ name: "New Child" }];
										}
									}
									setVerticals(_verticals);
								}}
							/>
						);
					})}
				</Box>
				<Box display={"flex"} justifyContent="center" marginTop={4} style={{ gap: 12 }}>
					<Button
						type="button"
						variant="contained"
						style={{ color: "#fff", textAlign: "center", background: "#e53e3e" }}
						onClick={() => {
							onCancel();
						}}
					>
						Close
					</Button>
					<Button
						type="button"
						variant="contained"
						color="primary"
						onClick={() => {
							onDone(verticals);
						}}
					>
						Done
					</Button>
				</Box>
			</Box>
		</Modal>
	);
}
