import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import { getAllForAdmin as getAllOpportunities } from "src/api/opportunities";
import { getAllForAdmin as getAllCampaigns } from "src/api/campaigns";
import { MatchView } from "src/components/admin/Match/Index";

export default function AdminMatchView() {
	const { data } = useLoaderData();

	return (
		<Box component={"main"}>
			<Typography component={"h1"} variant={"body1"} color="textSecondary" style={{ marginBottom: "1rem" }}>
				Match Campaigns
			</Typography>
			<Suspense
				fallback={
					<Box height={"100%"} display="flex" justifyContent={"center"} alignItems={"center"}>
						<CircularProgress />
					</Box>
				}
			>
				<Await
					resolve={data}
					errorElement={
						<Box padding={4} textAlign="center">
							Could not load the data
						</Box>
					}
					children={({ campaigns, opportunities }) => {
						return (
							<MatchView allCampaigns={campaigns} allOpportunities={opportunities} />
						);
					}}
				/>
			</Suspense>
		</Box>
	);
}

export function adminMatchLoader() {
	const promise = Promise.all([
		getAllCampaigns({ filter: "status||$eq||ACTIVE", join: ["creator", "matchingParameter"], limit: 500 }),
		getAllOpportunities({ filter: "status||$eq||ACTIVE", join: ["creator", "matchingParameter"], limit: 500 }),
	]).then(([campaigns, opportunities]) => {
		return {
			campaigns,
			opportunities,
		};
	});
	return defer({
		data: promise,
	});
}
