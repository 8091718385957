import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Save from "@material-ui/icons/Save";
import { useLoaderData, defer, useNavigate } from "react-router-dom";
import { useCallback } from "react";

import { create } from "src/api/teamMembers";
import { Main } from "src/components/commons/Main";
import { Async } from "src/components/commons/Async";
import { getAllWithAnswers } from "src/api/questions";
import { ContextProvider } from "src/components/campaigns/Details/Context";
import { Details } from "src/components/campaigns/Details";
import { ONBOARDING_STEPS, QUESTION_CATEGORIES } from "src/helpers/constants";
import { localstorage } from "src/lib/storage";
import { useDispatch, useSelector } from "react-redux";
import { updateUserOnboarding } from "src/store/slices/auth";

/* 
Component visible to founders only once in the journey to add them as team member
 */
export default function FounderTeamMemberDetails() {
	const { authUser } = useSelector(({ auth }) => auth);
	const { data } = useLoaderData();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const storageKey = `${QUESTION_CATEGORIES.FOUNDER_MEMBER.toLowerCase()}_`;
	const save = useCallback(() => {
		return async (_, answerObject) => {
			const { firstName, lastName, profile } = authUser;
			const teamMember = {
				fullyQualifiedPersonName: {
					firstname: firstName,
					lastname: lastName,
					title: profile.title,
					suffix: profile.suffix,
					middlename: profile.middlename,
				},
				displayName: [firstName, lastName].filter((i) => i).join(" "),
				userId: authUser.id,
				details: {
					address: profile.address,
					city: profile.city,
					state: profile.state,
					locatedCountry: profile.livesInUS?.toLowerCase() === "yes" ? "United States of America (USA)" : profile.country,
					zipcode: profile.zipcode,
					businessPhoneNumber: profile.businessPhoneNumber,
					linkedinUrl: profile.linkedinUrl,
					...(answerObject.details || {}),
				},
				positions: profile.positions,
				email: profile.workEmail || authUser.email,
				isVisible: answerObject.isVisible === "Yes",
				team: {
					name: "Founder/Executive Team",
					description: "Core team to show to the funders",
					creatorId: authUser.id,
				},
			};
			await create(teamMember);
			await dispatch(updateUserOnboarding({ completedStep: ONBOARDING_STEPS.PROFILE, isCompleted: false }));
			localstorage.removeItem(storageKey);
			navigate("/dashboard");
		};
	}, []);

	return (
		<>
			<Main>
				<Async error={"Unable to fetch the team member"} promise={data}>
					{({ questions }) => {
						return (
							<ContextProvider
								userAnswers={[]}
								questions={questions}
								saveFn={save()}
								category={QUESTION_CATEGORIES.FOUNDER_MEMBER}
								entityId={""}
							>
								{({ validateAndSave, isSaving }) => {
									return (
										<>
											<Box
												display={"flex"}
												justifyContent="space-between"
												p={4}
												px={4}
												borderBottom={"1px solid rgb(239 232 251)"}
											>
												<div>
													<Typography component={"h2"} variant="h6">
														{questions[0].group}
													</Typography>
													{questions[0].meta.groupDescription && (
														<Typography component={"p"} variant="body2" className="text-slate-500">
															{questions[0].meta.groupDescription}
														</Typography>
													)}
												</div>
											</Box>
											<Box
												component={"form"}
												p={4}
												px={4}
												height={"100%"}
												display="flex"
												flexDirection="column"
												onSubmit={(e) => {
													e.preventDefault();
													validateAndSave();
												}}
											>
												<Details />
												<Box display={"flex"} justifyContent="center" margin={5}>
													<Button
														color="primary"
														variant="contained"
														startIcon={<Save />}
														type="submit"
														disabled={isSaving}
													>
														{isSaving ? "Saving..." : "Next"}
													</Button>
												</Box>
											</Box>
										</>
									);
								}}
							</ContextProvider>
						);
					}}
				</Async>
			</Main>
		</>
	);
}

export async function founderTeamMemberLoader() {
	const data = Promise.all([getAllWithAnswers({ category: QUESTION_CATEGORIES.FOUNDER_MEMBER, per_page: 100 })]).then(
		([questions]) => {
			return {
				questions,
			};
		}
	);
	return defer({ data });
}
