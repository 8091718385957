import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Save from "@material-ui/icons/Save";
import makeStyles from "@material-ui/styles/makeStyles";
import { useState } from "react";
import { Link, useLoaderData, useNavigate, useParams } from "react-router-dom";

import { create, getTeam, update } from "src/api/teams";
import { Main } from "src/components/commons/Main";
import Alert from "src/helpers/alert";

const useStyles = makeStyles((theme) => {
	return {
		breadcrumb: {
			marginBottom: theme.spacing(4),
		},
	};
});

export default function TeamDetail() {
	const { id } = useParams();
	const classes = useStyles();
	const _team = useLoaderData();
	const navigate = useNavigate();
	const [team, setTeam] = useState({ ..._team });
	const [isSaving, setSaving] = useState(false);

	async function onSubmit(e) {
		e.preventDefault();
		if (!team.name.trim()) {
			Alert.error("Name cannot be empty");
			return;
		}
		setSaving(true);
		try {
			if (team.id) {
				await update(team);
			} else {
				await create(team);
			}
			Alert.success("Saved Successfully");
			navigate("/teams");
		} catch (error) {
			console.error("Error while updating team", error);
			Alert.error("Unable to save");
		} finally {
			setSaving(false);
		}
	}
	function onChange(e) {
		const { name, value } = e.target;
		setTeam((team) => {
			return { ...team, [name]: value };
		});
	}
	return (
		<>
			<Breadcrumbs separator="›" aria-label="breadcrumb" className={classes.breadcrumb}>
				<Link to={"/teams"}>
					<Typography color="textSecondary">Teams</Typography>
				</Link>
				<Link to={`/team/${id}/members`}>
					<Typography color="textSecondary">Team Members</Typography>
				</Link>
				<Typography color="textPrimary">Edit Team</Typography>
			</Breadcrumbs>
			<Main>
				<Box display={"flex"} justifyContent="space-between" p={4} px={4} borderBottom={"1px solid rgb(239 232 251)"}>
					<Typography component={"h2"} variant="h6">
						{team.name || "Team"}
					</Typography>
					<Button
						variant="contained"
						disabled={isSaving}
						color="primary"
						type="submit"
						startIcon={<Save />}
						form="team-form"
					>
						{isSaving ? "Saving..." : "Save"}
					</Button>
				</Box>

				<Box p={4}>
					<Grid component={"form"} container spacing={4} id="team-form" onSubmit={onSubmit}>
						<Grid item xs={12}>
							<TextField
								fullWidth
								required
								variant="outlined"
								id="name"
								label="Name"
								type="text"
								value={team.name}
								name="name"
								onChange={onChange}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								variant="outlined"
								id="description"
								label="Description"
								type="text"
								multiline
								minRows={3}
								value={team.description || ""}
								name="description"
								onChange={onChange}
							/>
						</Grid>
					</Grid>
				</Box>
			</Main>
		</>
	);
}

export async function teamDetailLoader({ params }) {
	if (params.id === "add") {
		return {};
	}
	return getTeam(params.id);
}
