import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { AdminFilterView } from "src/components/admin/Match/AdminFilterView";
import Alert from "src/helpers/alert";
import { MatchView } from "src/components/admin/Match/Index";
import { searchForAdmin as searchOpportunities } from "src/api/opportunities";
import { searchForAdmin as searchCampaigns } from "src/api/campaigns";

export default function FilterAndMatch() {
	const [searching, setSearching] = useState(false);
	const [result, setResult] = useState({ campaigns: { data: [], total: 0 }, opportunities: { data: [], total: 0 } });
	async function setFilters(filters) {
		setSearching(true);
		try {
			const [campaigns, opportunities] = await Promise.all([searchCampaigns(filters), searchOpportunities(filters)]);
			setResult({
				campaigns: { data: campaigns.data, total: campaigns.data.length },
				opportunities: { data: opportunities.data, total: opportunities.data.length },
			});
		} catch (err) {
			Alert.error("Unable to complete the search");
			console.error("err while searching match", err);
		} finally {
			setSearching(false);
		}
	}
	return (
		<Box component={"main"}>
			<Typography component={"h1"} variant={"h5"} color="textPrimary" style={{ marginBottom: "1rem" }}>
				Filter & Match
			</Typography>
			<Box className="flex flex-col gap-4">
				<AdminFilterView onChange={(filters) => setFilters(filters)} searching={searching} />
				<MatchView allCampaigns={result.campaigns} allOpportunities={result.opportunities} />
			</Box>
		</Box>
	);
}
