// import { applyMiddleware, createStore } from "redux";
import { configureStore } from "@reduxjs/toolkit";
// import thunk from "redux-thunk";
import { createBrowserHistory } from "history";
// import { routerMiddleware } from "connected-react-router";
// import reducers from "../reducers";
// import { loadCampaignState, saveCampaignState } from "@jumbo/utils/campaignLocalStorage";
import authReducer from "./slices/auth";
import themeReducer from "./slices/theme";
import commonReducer from "./slices/common";

const history = createBrowserHistory();
// const routeMiddleware = routerMiddleware(history);
// const bindMiddleware = (middleware) => {
// 	if (process.env.NODE_ENV !== "production") {
// 		const { composeWithDevTools } = require("redux-devtools-extension");
// 		return composeWithDevTools(applyMiddleware(...middleware));
// 	}
// 	return applyMiddleware(...middleware);
// };

// function configureStore(initialState = {}) {
// 	const store = createStore(
// 		reducers(history),
// 		{ ...initialState, ...loadCampaignState() },
// 		bindMiddleware([routeMiddleware, thunk])
// 	);
// 	let oldCampaignState = store.getState().campaign;
// 	store.subscribe(() => {
// 		const newCampaignState = store.getState().campaign;
// 		if (oldCampaignState !== newCampaignState) {
// 			saveCampaignState({
// 				campaign: newCampaignState,
// 			});
// 			oldCampaignState = newCampaignState;
// 		}
// 	});
// 	return store;
// }

const store = configureStore({
  reducer: {
    auth: authReducer,
		theme: themeReducer,
		common: commonReducer
  },
})
export { store, history };
