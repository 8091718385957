import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
// import makeStyles from "@material-ui/core/styles/makeStyles";

import { defer, Await, useLoaderData, Link } from "react-router-dom";
import { getVendorWithUserAnswersForAdmin } from "src/api/vendorServices";
import { Suspense } from "react";
import { Main } from "src/components/commons/Main";
import { DisplayUserAnswers } from "src/components/commons/DisplayUserAnswers";
import { FounderProfileDisplay } from "src/components/commons/FounderProfileDisplay";

export function AdminViewVendorDetail() {
	const { data } = useLoaderData();

	return (
		<>
			<Breadcrumbs separator="›" aria-label="breadcrumb" style={{ marginBottom: 16 }}>
				<Link to={"/admin/vendors"}>
					<Typography color="textSecondary">All Vendors</Typography>
				</Link>
				<Typography color="textPrimary">Detail</Typography>
			</Breadcrumbs>
			<Main>
				<Box
					display={"flex"}
					alignItems={"center"}
					justifyContent="space-between"
					p={4}
					borderBottom={"1px solid rgb(239 232 251)"}
				>
					<Typography component={"h2"} variant="h6">
						Detail
					</Typography>
					{/* <Button className="">Team</Button> */}
				</Box>
				<Suspense
					fallback={
						<Box height={"100%"} display="flex" justifyContent={"center"} alignItems={"center"}>
							<CircularProgress />
						</Box>
					}
				>
					<Await
						resolve={data}
						errorElement={
							<Box padding={4} textAlign="center">
								Could not load
							</Box>
						}
						children={({ vendorService }) => {
							return (
								<Box p={4}>
									<Typography component={"h3"} variant="h5">
										{vendorService.name}
									</Typography>
									<h3 className="text-lg py-2 px-4 bg-slate-200 mt-4">Vendor Profile</h3>
									<FounderProfileDisplay
										userDetails={{ ...vendorService.creator, details: vendorService.creator.profile }}
									/>
									<DisplayUserAnswers
										id={`vendor-${vendorService.id}`}
										isAdmin={true}
										type="vendor"
										answers={vendorService.userAnswers}
									/>
								</Box>
							);
						}}
					/>
				</Suspense>
			</Main>
		</>
	);
}

export function adminViewVendorDetailLoader({ params }) {
	const promise = Promise.all([getVendorWithUserAnswersForAdmin(params.id)]).then(([vendorService]) => {
		return {
			vendorService,
		};
	});
	return defer({
		data: promise,
	});
}
