import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import Edit from "@material-ui/icons/Edit";
import Add from "@material-ui/icons/Add";
import Group from "@material-ui/icons/Group";
import { alpha, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	listItem: {
		"& img": {
			transform: "scale(0.7)",
			transition: "transform 0.6s",
		},
		"&:hover .listAvatar": {
			background: alpha(theme.palette.primary.main, 0.1),
		},
		"&:hover img": {
			transform: "scale(0.9)",
		},
	},
	avatar: {
		width: 92,
		height: 92 * 0.616,
		background: theme.palette.background.default,
		color: theme.palette.primary.dark,
		[theme.breakpoints.up("md")]: {
			width: 150,
			height: 150 * 0.616,
		},
		"& img": {
			objectFit: "contain",
		},
	},
	controls: {
		width: "100%",
		[theme.breakpoints.up("md")]: {
			maxWidth: 320,
		},
	},
}));
export function TeamListItem({ team, onSelect }) {
	const classes = useStyles();
	return (
		<ListItem className={classes.listItem} button component={"li"} key={`team-list-${team.id}`} alignItems="flex-start">
			<Box
				component={Link}
				to={`/team/${team.id}/members`}
				state={{ team }}
				display={"flex"}
				width="100%"
				flexWrap={"wrap"}
				onClick={onSelect}
			>
				<Avatar className={clsx([classes.avatar, "listAvatar"])} variant={"rounded"}>
					<Group />
				</Avatar>
				<Box display={"flex"} flex="1" flexDirection={"column"} ml={4} alignItems={"flex-start"} width={"100%"}>
					<Typography component={"h3"} variant="h6" color="textPrimary">
						{team.name}
					</Typography>
					<Typography component={"p"} color="textSecondary">
						{team.description}
					</Typography>
				</Box>
				<Box display={"flex"} ml={4} mt={4} className={classes.controls} alignItems={"end"} justifyContent={"flex-end"}>
				{/* <Button
						startIcon={<Edit />}
						type="button"
						color="primary"
						component={Link}
						to={`/team/${team.id}`}
						size="small"
						variant="contained"
						style={{ marginRight: "0.5rem", paddingRight: "0.5rem" }}
					>
						Edit
					</Button>
					<Button
						startIcon={<Add />}
						type="button"
						color="primary"
						component={Link}
						to={`/team/${team.id}/member/add`}
						size="small"
						variant="contained"
						style={{ marginRight: "0.5rem", paddingRight: "0.5rem" }}
					>
						Add Member
					</Button> */}
				</Box>
			</Box>
		</ListItem>
	);
}
