import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/styles/makeStyles";

const useStyles = makeStyles(() => ({
	paper: {
		borderRadius: 12,
		boxShadow: "rgba(115, 82, 199, 0.176) 0px 0.5rem 1.25rem",
		minHeight: "calc(100vh - 64px - 1.75rem)",
		display: "flex",
		flexDirection: "column",
		marginBottom: "1rem"
	},
}));

export function Main({ children }) {
	const classes = useStyles();
	return (
		<Paper component={"main"} className={classes.paper}>
			{children}
		</Paper>
	);
}
