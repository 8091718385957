import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import { useState } from "react";
import { loginAs } from "src/api/auth";
import { localstorage } from "src/lib/storage";
import Alert from "src/helpers/alert";
import { makeStyles } from "@material-ui/styles";
const useStyles = makeStyles((theme) => ({
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: "25ch",
	},
}));
export function LoginAs({ tenants = [] }) {
	const classes = useStyles();
	const [email, setEmail] = useState(null);
	const [tenantId, setTenantId] = useState(1);
	return (
		<Box
			component={"form"}
			display="flex"
			flexDirection={"row"}
			justifyContent={"flex-start"}
			alignItems="center"
			border={"1px solid #c1c1c1"}
			p={2}
			style={{ gap: 16 }}
			marginTop={4}
			borderRadius={12}
			onSubmit={async (e) => {
				e.preventDefault();
				try {
					localstorage.clear();
					const user = await loginAs({ email, tenantId });
					localstorage.setItem("user", JSON.stringify(user));
					window.location.href = "/";
				} catch (err) {
					console.error("Error while logging", err);
					Alert.error(err.code === "E_42201" ? "Email doesn't exist" : "Unable to complete");
				}
			}}
		>
			<Box component={"h2"}>Login As</Box>
			<TextField
				label={"email"}
				onChange={(event) => setEmail(event.target.value?.toLowerCase())}
				value={email || ""}
				margin="normal"
				required
				size="small"
				variant="outlined"
			/>
			<TextField
				value={tenantId || ""}
				margin="normal"
				className={classes.textField}
				required
				size="small"
				variant="outlined"
				onChange={(e) => {
					setTenantId(+e.target.value);
				}}
				label="Tenant"
				select
			>
				<MenuItem value="">
					<em>None</em>
				</MenuItem>
				{tenants.map((tenant, idx) => {
					return (
						<MenuItem key={`tenant_${idx}_select`} value={tenant.id}>
							{tenant.name}
						</MenuItem>
					);
				})}
			</TextField>
			<Button type={"submit"} variant="contained" color="secondary">
				Login
			</Button>
		</Box>
	);
}
