import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
// import Modal from "@material-ui/core/Modal";
import makeStyles from "@material-ui/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import { alpha } from "@material-ui/core/styles";
import EmojiNature from "@material-ui/icons/EmojiNature";
// import Info from "@material-ui/icons/Info";
import Add from "@material-ui/icons/Add";
import { useRef, useState, useEffect } from "react";
import { useLoaderData, useLocation } from "react-router-dom";

import { getAll } from "src/api/campaigns";
import { getAll as getAllTeams } from "src/api/teams";
import { CampaignForm } from "src/components/campaigns/Form";
import { Main } from "src/components/commons/Main";
import { CampaignListItem } from "src/components/campaigns/ListItem";
// import Alert from "src/helpers/alert";
// import { useHash } from "src/hooks/hash";

const useStyles = makeStyles((theme) => ({
	emptyState: {
		padding: "6rem 0",
		color: alpha(theme.palette.common.black, 0.3),
	},
	listItem: {
		"& img": {
			transform: "scale(0.7)",
			transition: "transform 0.6s",
		},
		"&:hover .listAvatar": {
			background: alpha(theme.palette.primary.main, 0.1),
		},
		"&:hover img": {
			transform: "scale(0.9)",
		},
	},
	avatar: {
		width: 92,
		height: 92 * 0.616,
		background: theme.palette.background.default,
		[theme.breakpoints.up("md")]: {
			width: 150,
			height: 150 * 0.616,
		},
		"& img": {
			objectFit: "contain",
		},
	},
	chip: {
		color: theme.palette.common.white,
		"&.warning": {
			background: theme.palette.warning.dark,
		},
		"&.active": {
			background: theme.palette.success.dark,
		},
	},
	modal: {
		transform: "translate(-50%, -50%)",
	},
}));

export function Campaigns() {
	// const [hash, updateHash] = useHash();
	const location = useLocation();
	const paginatedCampaigns = useLoaderData();
	const classes = useStyles();
	const [openFormModal, setOpenFormModal] = useState(location.state?.create || false);
	const selectedCampaign = useRef(null);
	const [teams, setTeams] = useState([]);

	useEffect(() => {
		getAllTeams()
			.then((teams) => {
				setTeams(teams.data);
			})
			.catch((e) => console.error("Error while fetching teams", e));
	}, []);

	return (
		<Main>
			<Box display={"flex"} justifyContent="space-between" p={4} px={4} borderBottom={"1px solid rgb(239 232 251)"}>
				<Typography component={"h2"} variant="h6">
					My Campaigns
				</Typography>
				<Box>
					<Button
						variant="text"
						color="primary"
						disabled={false}
						startIcon={<Add />}
						onClick={() => setOpenFormModal(true)}
					>
						Create
					</Button>
				</Box>
			</Box>
			{paginatedCampaigns.data.length === 0 ? (
				<Box textAlign={"center"} className={classes.emptyState}>
					<Box>
						<EmojiNature />
					</Box>
					You don&apos;t have any fundraising campaigns so far{" "}
					<Button
						variant="text"
						color="primary"
						disabled={false}
						startIcon={<Add />}
						onClick={() => setOpenFormModal(true)}
					>
						Create One
					</Button>
				</Box>
			) : (
				<List component={"ul"}>
					{paginatedCampaigns.data.map((campaign) => {
						return (
							<CampaignListItem
								key={`campaign-list-${campaign.id}`}
								campaign={campaign}
								classes={classes}
								onEdit={(campaign) => {
									selectedCampaign.current = campaign;
									// updateHash("edit");
									setOpenFormModal(true);
								}}
								// onLaunch={launch}
							/>
						);
					})}
				</List>
			)}
			<Dialog
				fullWidth={true}
				maxWidth="xs"
				open={openFormModal}
				onClose={() => setOpenFormModal(false)}
				arialabelledby={"form-campaign-dialog"}
			>
				<DialogTitle id="form-campaign-dialog" pb={0}>
					Your Fundraising Campaign
				</DialogTitle>
				<CampaignForm
					teams={teams}
					campaign={selectedCampaign.current}
					totalCampaigns={paginatedCampaigns.total}
					close={() => setOpenFormModal(false)}
				/>
			</Dialog>
		</Main>
	);
}

export function campaignsLoader() {
	return getAll();
}
