import { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { getMatchId } from "src/helpers";

export function EachAcceptedMatch({ match, onAction, /* currentRole */ }) {
	const [showDetails, setShowDetails] = useState(false);

	const actions = useMemo(() => {
		return (
			<>
				<Button variant="contained" color="primary" component={Link} to={`/match/${match.id}/campaign`}>
					View Full Report
				</Button>
				{!match.proposedSchedules?.length && (
					<Button variant="contained" color="secondary" onClick={() => onAction(match)}>
						Schedule A Call
					</Button>
				)}
				{match.proposedSchedules.length > 0 && match.proposedScheduleStatus !== "ACCEPTED" && (
					<Button
						variant="outlined"
						color="primary"
						onClick={() => {
							onAction(match);
						}}
					>
						View schedule
					</Button>
				)}
			</>
		);
	}, [match]);
	return (
		<>
			<div
				key={`opp-match-${match.id}`}
				className="flex items-center gap-4 px-4 py-3 cursor-pointer"
				onClick={() => setShowDetails(!showDetails)}
			>
				<div className="font-bold text-lg p-4 border border-green-900 rounded-md">
					<img src="/images/icons/opportunity-match-success.svg" width={45} height={45} />
				</div>
				<div className="flex flex-col gap-2 justify-center items-start flex-grow">
					<div className="text-lg">Match #{getMatchId(match.id)}</div>
					<div className="text-lg text-slate-500">{match.opportunityName}</div>
				</div>
				<div className="flex gap-3">{actions}</div>
			</div>
		</>
	);
}
