export function MatchPreview() {
	return (
		<section className="p-4">
			<div className="mb-8">
				<img src="/images/logo-v1.png" alt="logo" className="card rounded-md block mx-auto w-48" />
			</div>
			<h2 className="text-xl mb-4">
				Matching Results for <span className="text-2xl font-extrabold">MidWest VC&apos;s (Biotech Fund II)</span>
			</h2>
			<div className="card mb-8">
				<h3 className="text-center text-lg py-4 bg-secondary text-white font-bold rounded-t-lg">Funders Criteria</h3>
				<div className="p-4 text-lg">
					<ol className="flex ml-0 [&>*]:ml-4 [&>*:first-child]:ml-0 flex-wrap">
						<li className="">Prioritization:</li>
						<li className="bg-secondary-300 rounded-md px-2 text-white">1. Team</li>
						<li className="bg-secondary-300 rounded-md px-2 text-white">2. Revenue</li>
						<li className="bg-secondary-300 rounded-md px-2 text-white">3. PMF</li>
						<li className="bg-secondary-300 rounded-md px-2 text-white">4. Traction</li>
						<li className="bg-secondary-300 rounded-md px-2 text-white">5. Competitive Advantage</li>
					</ol>
				</div>
			</div>
			<ul className="[&>li]:mb-8">
				<li>
					<section className="rounded-lg card">
						<h3 className="text-center text-lg py-4 bg-primary text-white font-bold rounded-t-lg flex justify-between px-4">
							<span className="text-center bg-white text-primary rounded-full w-7 inline-block">1</span>{" "}
							<span>Founder Data</span>
							<span className="text-center bg-white text-primary rounded-md inline-block px-3">#19753</span>
						</h3>
						<table className="mx-auto w-full">
							<tbody className="[&>*>*]:p-2 [&>*>th]:w-1/3 [&>*>th]:text-gray-700 [&>*>th]:font-extrabold [&>*>td]:text-lg even:[&>tr]:bg-primary-100">
								<tr>
									<th>Industry</th>
									<td>Biotechnology/Synthetic Biology</td>
								</tr>
								<tr>
									<th>Type</th>
									<td>Scalable startup</td>
								</tr>
								<tr>
									<th>Form:</th> <td>C Corp/Delaware</td>
								</tr>
								<tr>
									<th>Stage:</th> <td>Generating Revenue/$5K/mo.</td>
								</tr>
								<tr>
									<th>Location:</th> <td>US Based</td>
								</tr>
								<tr>
									<th>Funding Req:</th> <td>Convertible note</td>
								</tr>
								<tr>
									<th>ESG:</th> <td>In Progress</td>
								</tr>
								<tr>
									<th>Prior Experience:</th> <td>5 yrs+</td>
								</tr>
								<tr>
									<th>Team:</th> <td>2 Co-Founders, F/T, PhD’s, Persistence High</td>
								</tr>
								<tr>
									<th>Board:</th> <td>3 Board members, active</td>
								</tr>
								<tr>
									<th>Prior Funding:</th> <td>Yes - 1 SBIR Grant Phase 1</td>
								</tr>
								<tr>
									<th>Funders Funding:</th> <td>1x $50K Convertible note</td>
								</tr>
								<tr>
									<th>Regulations:</th> <td>Reg Filings in progress</td>
								</tr>
								<tr>
									<th>Due Diligence Documents:</th> <td>In progress 95%</td>
								</tr>
								<tr>
									<th>IP:</th> <td>Issued 2 patents</td>
								</tr>
								<tr>
									<th>Digital Footprint:</th> <td>Website/Social 80%</td>
								</tr>
								<tr>
									<th>Exit:</th> <td>M&A, License or IPO</td>
								</tr>
							</tbody>
						</table>
					</section>
				</li>
				<li>
					<section className="rounded-lg mb-4 card">
						<h3 className="text-center text-lg py-4 bg-primary text-white font-bold rounded-t-lg flex justify-between px-4">
							<span className="text-center bg-white text-primary rounded-full w-7 inline-block">2</span>{" "}
							<span>Founder Data</span>
							<span className="text-center bg-white text-primary rounded-md inline-block px-3">#47624</span>
						</h3>
						<table className="mx-auto w-full">
							<tbody className="[&>*>*]:p-2 [&>*>th]:w-1/3 [&>*>th]:text-gray-700 [&>*>th]:font-extrabold [&>*>td]:text-lg even:[&>tr]:bg-primary-100">
								<tr>
									<th>Industry</th>
									<td>Biotechnology/Synthetic Biology</td>
								</tr>
								<tr>
									<th>Type</th>
									<td>Scalable startup</td>
								</tr>
								<tr>
									<th>Form: </th>
									<td>C Corp/Delaware</td>
								</tr>
								<tr>
									<th>Stage: </th>
									<td>Generating Revenue/$30K/mo.</td>
								</tr>
								<tr>
									<th>Location: </th>
									<td>US Based</td>
								</tr>
								<tr>
									<th>Funding Req: </th>
									<td>Convertible note</td>
								</tr>
								<tr>
									<th>ESG: </th>
									<td>In Progress</td>
								</tr>
								<tr>
									<th>Prior Experience: </th>
									<td>25 yrs+</td>
								</tr>
								<tr>
									<th>Team: </th>
									<td>3 Co-Founders, F/T, Persistence Medium</td>
								</tr>
								<tr>
									<th>Board: </th>
									<td>5 Board members, active</td>
								</tr>
								<tr>
									<th>Prior Funding: </th>
									<td>Yes - 1 SBIR Grant Phase 1 and Phase II</td>
								</tr>
								<tr>
									<th>Funders Funding: </th>
									<td>$500K Convertible note</td>
								</tr>
								<tr>
									<th>Regulations: </th>
									<td>Reg Filings in progress</td>
								</tr>
								<tr>
									<th>Due Diligence Documents: </th>
									<td>In progress 75%</td>
								</tr>
								<tr>
									<th>IP: </th>
									<td>2 filed, 2 issued patents</td>
								</tr>
								<tr>
									<th>Digital Footprint: </th>
									<td>Website/Social 60%</td>
								</tr>
								<tr>
									<th>Exit: </th>
									<td>License or M&A</td>
								</tr>
							</tbody>
						</table>
					</section>
				</li>
				<li>
					<section className="rounded-lg card">
						<h3 className="text-center text-lg py-4 bg-primary text-white font-bold rounded-t-lg flex justify-between px-4">
							<span className="text-center bg-white text-primary rounded-full w-7 inline-block">3</span>{" "}
							<span>Founder Data</span>
							<span className="text-center bg-white text-primary rounded-md inline-block px-3">#28567</span>
						</h3>
						<table className="mx-auto w-full">
							<tbody className="[&>*>*]:p-2 [&>*>th]:w-1/3 [&>*>th]:text-gray-700 [&>*>th]:font-extrabold [&>*>td]:text-lg even:[&>tr]:bg-primary-100">
								<tr>
									<th>Industry:</th>
									<td>Biotechnology/Synthetic Biology</td>
								</tr>
								<tr>
									<th>Type:</th> <td>Scalable startup</td>
								</tr>
								<tr>
									<th>Form:</th> <td>C Corp/Delaware</td>
								</tr>
								<tr>
									<th>Stage:</th> <td>Pre-revenue</td>
								</tr>
								<tr>
									<th>Location:</th> <td>US Based</td>
								</tr>
								<tr>
									<th>Funding Req:</th> <td>Convertible or KISS</td>
								</tr>
								<tr>
									<th>ESG:</th> <td>Not rated</td>
								</tr>
								<tr>
									<th>Prior Experience:</th> <td>10 yrs+</td>
								</tr>
								<tr>
									<th>Team:</th> <td>2 Co-Founders, F/T, PhD’s, Persistence Medium</td>
								</tr>
								<tr>
									<th>Board:</th> <td>2 Board members, active</td>
								</tr>
								<tr>
									<th>Prior Funding:</th> <td>Yes - 1 SBIR Grant Phase 1</td>
								</tr>
								<tr>
									<th>Funders Funding:</th> <td>1x $50K Convertible note</td>
								</tr>
								<tr>
									<th>Regulations:</th> <td>Reg Filings in progress</td>
								</tr>
								<tr>
									<th>Due Diligence Documents:</th> <td>In progress 85%</td>
								</tr>
								<tr>
									<th>IP:</th> <td>Filed, 2 patents</td>
								</tr>
								<tr>
									<th>Digital Footprint:</th> <td>Website/Social 81%</td>
								</tr>
								<tr>
									<th>Exit:</th> <td>M&amp;A or IPO</td>
								</tr>
							</tbody>
						</table>
					</section>
				</li>
			</ul>
		</section>
	);
}
