import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ROLES } from "src/helpers/constants";
import { Async } from "src/components/commons/Async";
import { Matches } from "src/components/campaigns/Matches";
import { getCampaignWithMatches } from "src/api/campaigns";

export function CampaignMatches() {
	const { id } = useParams();
	const { currentRole } = useSelector(({ auth }) => auth);
	if (currentRole !== ROLES.FOUNDER) {
		throw new Error("Not a funder");
	}
	return (
		<div>
			<Async promise={getCampaignWithMatches(id)}>
				{([campaign]) => {
					return (
						<div>
							<h2 className="text-xl mb-4">Matches for {campaign.name}</h2>
							<Matches campaign={campaign} currentRole={currentRole} />
						</div>
					);
				}}
			</Async>
		</div>
	);
}
