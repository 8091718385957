import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useState } from "react";

export function ConfirmDialog({ title, body, onClose, onOK, show, buttonText = "Save", showFooter = true }) {
	const [saving, setSave] = useState(false);
	return (
		<Dialog fullWidth={true} maxWidth="xs" open={show} onClose={() => onClose()} arialabelledby={"confirm-dialog"}>
			{title && (
				<DialogTitle id="confirm-dialog" pb={0}>
					{title}
				</DialogTitle>
			)}
			<hr />
			<DialogContent>{body}</DialogContent>
			{showFooter && (
				<>
					<hr />
					<DialogActions>
						<Button type="button" onClick={onClose}>
							Close
						</Button>
						<Button
							disabled={saving}
							variant="contained"
							color="primary"
							onClick={async () => {
								setSave(true);
								try {
									await onOK();
								} catch (err) {
									console.log(`Error while oking dialog`, err);
								} finally {
									setSave(false);
								}
							}}
						>
							{saving ? <CircularProgress /> : buttonText}
						</Button>
					</DialogActions>
				</>
			)}
		</Dialog>
	);
}
