import { Apple, CalendarTodayRounded } from "@material-ui/icons";
import { addToCalendar } from "src/helpers/addToCalendar";

export function AddToCalendar({ title, description, datetime, durationInMins, location }) {
	const event = {
		title: title || "InvestorMatch.ai - Scheduled Call",
		description: description || "Scheduled exploratory call\n\n"+location,
		location,
		datetime,
		durationInMins,
	};
	return (
		<div className="flex gap-8 pt-4 pb-6 flex-wrap justify-center">
			<button className="flex flex-col items-center" onClick={() => addToCalendar(event, "google")}>
				<img src="/images/icons/google_calendar.svg" height={48} width={48} alt="google calendar icon" />
				<span>Google Calendar</span>
			</button>
			<button className="flex flex-col items-center" onClick={() => addToCalendar(event, "yahoo")}>
				<img src="/images/icons/yahoo.svg" height={32} width={32} className="py-2" alt="yahoo icon" />
				<span>Yahoo Calendar</span>
			</button>
			<button className="flex flex-col items-center" onClick={() => addToCalendar(event, "apple")}>
				<Apple fontSize="large" className="m-[7px]" />
				<span>Apple Calendar</span>
			</button>
			<button className="flex flex-col items-center" onClick={() => addToCalendar(event, "outlookcom")}>
				<img src="/images/icons/outlook.svg" height={40} width={40} alt="outlook icon" className="m-[5px]" />
				<span>Outlook.com Calendar</span>
			</button>
			<button className="flex flex-col items-center" onClick={() => addToCalendar(event, "others")}>
				<CalendarTodayRounded fontSize="large" className="m-[8px]" />
				<span>Other Calendars</span>
			</button>
		</div>
	);
}
