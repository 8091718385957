import { fetchV1 } from "../lib/requester/v1";

export function getOrCreate() {
	return fetchV1("/team_members/create_if_not_exists", { method: "GET" });
}

export function getAll(params) {
	return fetchV1(`/team_members`, { method: "GET", data: params }, { returnWholeResponse: true });
}

export function getTeamMember(id) {
	return fetchV1(`/team_members/${id}`, { method: "GET" });
}

export function update(data) {
	return fetchV1(`/team_members/${data.id}`, { method: "PUT", data });
}

export function create(data) {
	return fetchV1(`/team_members`, { method: "POST", data });
}

export function remove(id) {
	return fetchV1(`/team_members/${id}`, { method: "DELETE", data });
}
