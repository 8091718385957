import * as Sentry from "@sentry/react";
export function init() {
	Sentry.init({
		dsn: "https://75cd7bceea43936cb49b4a02f983db8d@o4505665075412992.ingest.sentry.io/4505665098416128",
		integrations: [
			new Sentry.BrowserTracing({
				// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
				tracePropagationTargets: ["https://app.investormatch.ai"],
			}),
			new Sentry.Replay(),
		],
		// Performance Monitoring
		tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
		enabled: process.env.NODE_ENV !== 'development',
	});
}

export function reportError(err, ...data) {
	err.data = data;
	Sentry.captureException(err);
}

export function ErrorReportingBoundary({ children }) {
	return <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>{children}</Sentry.ErrorBoundary>;
}

export function setErrorReportingUserContext(user) {
	Sentry.setUser(user);
}
