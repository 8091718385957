import { fetchV1 } from "../lib/requester/v1";

export function getAll(params, options) {
	return fetchV1("/questions", { method: "GET", data: params }, options);
}

export function getAllWithAnswers(params, options) {
	return fetchV1("/questions/answers", { method: "GET", data: params }, options);
}

export function bulkUpsert(data) {
	return fetchV1("/questions/bulk", { method: "POST", data: { bulk: data } });
}

export function remove(id) {
	return fetchV1(`/questions/${id}`, { method: "DELETE" });
}

export function deleteMany(ids) {
	return fetchV1(`/questions/many`, { method: "DELETE", data: { ids } });
}
