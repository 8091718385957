import { readable } from "src/helpers";
import { QUESTION_TYPES } from "src/helpers/constants";
import OpenInNewSharpIcon from '@material-ui/icons/OpenInNewSharp';

export function DisplayKeyValuePair({ id, data, type = "", excludeKeys = [], meta = {}, isChild = false }) {
	if (data && type === QUESTION_TYPES.URL) {
		return (
			<a
				className="inline-block underline hover:underline bg-slate-200 rounded-md p-1 mb-1 ml-1 font-bold"
				href={data}
				target="_blank"
				rel="noopener, noreferrer"
			>
				{data} <OpenInNewSharpIcon />
			</a>
		);
	}
	if (!data || typeof data === "string" || typeof data === "number" || typeof data === "boolean") {
		return (
			<div className="inline-block bg-slate-200 rounded-md p-1 mb-1 ml-1 font-bold">
				{meta.startAdornment || ""}
				{typeof data === "boolean" ? (data ? "Yes" : "No") : data || "-"}
				{meta.endAdornment || ""}
			</div>
		);
	}
	if (Array.isArray(data)) {
		return data.length ? (
			data.map((item, idx) => (
				<DisplayKeyValuePair
					key={`${id}-${idx}`}
					type={type}
					id={`${id}-${idx}`}
					data={item}
					excludeKeys={excludeKeys}
					meta={meta}
				/>
			))
		) : (
			<span className="text-slate-300 ml-1 font-bold">-</span>
		);
	}
	if(type === QUESTION_TYPES.COMBO) {
		return (
			<div className={isChild ? "ml-2 font-bold border-l-2 pl-2" : "font-bold border-l-2 pl-2"}>
				{meta.comboFields.length && Object.keys(data).length ? (
					meta.comboFields.map((field) => {
						if (excludeKeys.includes(field.fieldName)) {
							return null;
						}
						return (
							<div className="grid grid-cols-12 max-w-full mb-2" key={`${id}-key-${field.fieldName}`}>
								<div className="col-span-12 capitalize">{field.placeholder || readable(field.fieldName).trim()}:</div>
								<div className="col-span-12">
									<DisplayKeyValuePair
										isChild={true}
										type={field.type}
										id={`${id}-${field.fieldName}`}
										data={data[field.fieldName]}
										excludeKeys={excludeKeys}
										meta={meta}
									/>
								</div>
							</div>
						);
					})
				) : (
					<span className="text-slate-300">None</span>
				)}
			</div>
		);
	}
	return (
		<div className={isChild ? "ml-2 font-bold border-l-2 pl-2" : "font-bold border-l-2 pl-2"}>
			{Object.keys(data).length ? (
				Object.keys(data).map((key) => {
					if (excludeKeys.includes(key)) {
						return null;
					}
					return (
						<div className="grid grid-cols-12 max-w-full mb-2" key={`${id}-key-${key}`}>
							<div className="col-span-12 capitalize">{readable(key).trim()}:</div>
							<div className="col-span-12">
								<DisplayKeyValuePair
									isChild={true}
									id={`${id}-${key}`}
									data={data[key]}
									excludeKeys={excludeKeys}
									meta={meta}
								/>
							</div>
						</div>
					);
				})
			) : (
				<span className="text-slate-300">None</span>
			)}
		</div>
	);
}
