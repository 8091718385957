import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";

import { validateTenant } from "src/store/slices/auth";
import globalStyles from "../../../theme/GlobalCss";
// import '../../../services/api/index';
// import AppContext from "../contextProvider/AppContextProvider/AppContext";
// import VerticalDefault from "./VerticalLayouts/VerticalDefault";
// import { LAYOUT_TYPES } from "../../constants/ThemeOptions";
import VerticalMinimal from "./VerticalLayouts/VerticalMinimal";
// import MinimalNoHeader from "./VerticalLayouts/MinimalNoHeader";
// import ModernSideBar from "./VerticalLayouts/ModernSidebar";
// import HorizontalDefault from "./HorizontalLayouts/HorizontalDefault";
// import HorizontalDark from "./HorizontalLayouts/HorizontalDark";
// import HorizontalMinimal from "./HorizontalLayouts/HorizontalMinimal";
// import HorizontalTopMenu from "./HorizontalLayouts/HorizontalTopMenu";

const useStyles = makeStyles(() => ({
	circularProgressRoot: {
		position: "absolute",
		left: 0,
		top: 0,
		zIndex: 1,
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
	},
}));

const AppLayout = ({ children }) => {
	const { loadUser, authUser, loadTenant, tenant } = useSelector(({ auth }) => auth);
	const dispatch = useDispatch();
	const classes = useStyles();
	const location = useLocation();
	globalStyles();
	useEffect(() => {
		dispatch(validateTenant());
	}, []);

	useEffect(() => {
		if (tenant) {
			const link = document.querySelector("link[rel~='icon']");
			link.href = (tenant.logo || {})["2x"]?.path || "/images/logo-v1.png";
			document.title = tenant.name;
		}
	}, [tenant]);

	if (!loadUser || !loadTenant) {
		return (
			<Box className={classes.circularProgressRoot}>
				<CircularProgress />
			</Box>
		);
	}

	if (!tenant) {
		return (
			<Box className={"text-center p-8"}>
				White Label doesn&apos;t exists. Visit{" "}
				<a href="//app.investormatch.ai" className="underline">
					app.investormatch.ai
				</a>
			</Box>
		);
	}

	if (
		authUser &&
		(location.pathname === "" ||
			location.pathname === "/" ||
			location.pathname === "/login" ||
			location.pathname === "/signup" ||
			location.pathname === "/forgot-password")
	) {
		return <Navigate to="/dashboard" state={{ from: location }} replace />;
	} else if (
		authUser &&
		authUser.isEmailVerified === false &&
		location.pathname !== "/verify-email" &&
		location.pathname !== "/contact-us"
	) {
		return <Navigate to="/verify-email?wait=true" state={{ from: location }} replace />;
	} else if (
		!authUser &&
		!(
			location.pathname === "/contact-us" ||
			location.pathname === "/login" ||
			location.pathname === "/signup" ||
			location.pathname === "/forgot-password" ||
			location.pathname === "/reset-password" ||
			location.pathname === "/verify-email"
		)
	) {
		return <Navigate to="/login" state={{ from: location }} replace />;
	}

	if (
		location.pathname === "/login" ||
		location.pathname === "/signup" ||
		location.pathname === "/forgot-password" ||
		location.pathname === "/reset-password" ||
		location.pathname === "/verify-email" ||
		location.pathname === "/not-found"
	) {
		return (
			<Box display="flex" width={1} style={{ height: "100vh" }}>
				{children}
			</Box>
		);
	}
	return authUser ? <VerticalMinimal>{children}</VerticalMinimal> : children;
};

export default AppLayout;
