export const faqs = {
	founders: [
		{
			question: "What does InvestorMatch.ai do?",
			answer: `InvestorMatch.ai is a global platform that uses your specific criteria to match you and your firm to your ideal funder/s. From the public, private and commercial sectors of funding and their firms we can find the right funder. The highly curated matches are based on your onboarding information giving you high fidelity selective introductions to funders who will be ready for serious conversations and meet your criteria.`,
		},
		{
			question: "Why should you onboard with InvestorMatch.ai?",
			answer: `At InvestorMatch.ai’s the challenges and noise in the discordant interface between funders and founders can be actively reduced. This allows for better efficiency, less time and cost to all stakeholders. Our team have all been in your shoes and walked many miles. If you’re a startup in ideation mode to a firm looking to fund your series round, we’re here for you.`,
		},
		{
			question: "What are the benefits of using InvestorMatch.ai's matching services?",
			answer: `InvestorMatch.ai's matching algorithms uses data intelligently to assist founders in finding ideal funders. The use of machine language and natural language processing allows for high fidelity matches with funders who know your industry, fund firms with your characteristics, and have the potential to assist your firm in scaling and potentially an exit.
InvestorMatch.ai's algorithm has been designed to reduce the challenges and noise in the discordant interface between funders and founders, resulting in better efficiency, less time, and cost for all stakeholders.`,
		},
		{
			question: "What’s the cost to founders?",
			answer: `Nada, zip, zero seriously ! As fellow founders we understand that the raising of capital is an intensive process and by bringing you and your firm opportunities we can profit from the subscriptions of the funders and the revenue by brining curated vendor offerings to you.`,
		},
		{
			question:
				"Does InvestorMatch.ai provide any educational resources or materials to help founders improve their chances of getting matched with funders?",
			answer: `We have created a resource center of amazing vendors who can assist you from your website, pitch to HR solutions and much more. You can find these by going into your portal and noting the resource your needing.`,
		},
		{
			question: "What kind of support does InvestorMatch.ai offer to founders during the fundraising process?",
			answer: `We are responsive via e-mail (<a style="text-decoration:underline;" href="mailto:help@InvestorMatch.ai">help@InvestorMatch.ai</a>) and with a Zoom link to assist your onboarding. Our link to schedule an appointment is: <a style="text-decoration:underline" href="https://tinyurl.com/3rz979zm" target="_blank">https://tinyurl.com/3rz979zm</a>.
Need to modify your responses or have some questions or feedback regarding the process, we’re here for you.`,
		},
		{
			question: "What data do we collect from founders?",
			answer: `For your onboarding, we ask you for key information about your company and what funding your seeking. To be successful we will learn
about the background on the founders, the company’s business model, the amount the company is seeking to raise, along with other financial and business information about the company. The key at IM is to carefully match the risks and opportunities associated with all parties. You'll also be asked, what if any specific terms and resources that your wanting to have from the funders. None of your information will be available to the public or the funders until both parties are introduced with anonymized data and agree to the introduction.`,
		},
		{
			question: "How does InvestorMatch.ai ensure that the information provided by funders is accurate and up-to-date?",
			answer: `We cross correlate information from other sources to ensure the integrity of our data. InvestorMatch.ai will be asking funders for periodic updates and monitoring for their interaction. The funders portal is made with the intention that as they progress through the funding cycle, they will update their information. This will include announcing new funding opportunities and any changes in cycles and more.`,
		},
		{
			question: "Who can see my founders data?",
			answer: `All sensitive information and visibility is highly controlled by only allowing for anonymized data to be seen by both parties prior to an introduction. We believe that the best match’s are made when both parties see the clear potential and are ready to engage. Please see our high level matching documents.`,
		},
		{
			question: "What's InvestorMatch.ai's Web security?",
			answer: `InvestorMatch.ai is in progress toward our SOC2 certification and uses high security AWS servers exclusively.`,
		},
		{
			question: "How we help founders raising money?",
			answer: `InvestorMatch.ai is aggregating the largest database of funding opportunities available. From the public to the private and commercial funders our expanding databases allow us to connect you with specific funders who match your firm. One of the differences in our platform is the
dynamic onboarding of new funders and founders daily. And unlike our competitors the process is free to founders/entrepreneurs.`,
		},
		{
			question: "How can I get exposure to funders on InvestorMatch.ai?",
			answer: `Simply complete your onboarding profile and update your information, as your firm develops. Your profile will be continually matched to new funders as our platform expands. If you’ve found a funder outside of InvestorMathch.ai’s match, ask them to register with us and we can share your data, instead of filling in more forms.`,
		},
		{
			question: "How long will it take me to complete my onboarding profile?",
			answer: `All founders and firms have unique characteristics. Typical times to onboard is between 25-45 minutes. You can start and stop at any time as the data is saved dynamically. The form is separated into small sections and will generally be educational as the comprehensive nature will give you feedback as to your readiness for funding.`,
		},
		{
			question: "Can team members be added/invited to my InvestorMatch.ai profile?",
			answer: `Funders want to know your team. You can add and modify team members at any time, including who you show to the funders.`,
		},
		{
			question: "Can founders request to be removed from the matching pool or have their data deleted at any time?",
			answer: `Simply log into your portal and under ________ indicate your wish to be removed. We will ask for 2 factor authentication to verify your intent.`,
		},
	],
	funders: [
		{
			question: "For funders:",
			answer: `InvestorMatch.ai’s global platform uses your specific criteria to match you to your ideal founders and their firms, for deal flow along with expanding your contact with LP’s. The highly curated matches based on your inputs give you high fidelity selective matches with founders and introductions to potential new LP’s. Co-investing opportunities will also be available.`,
		},

		{
			question: "What are the benefits of using InvestorMatch.ai's matching system?",
			answer: `InvestorMatch.ai's matching algorithm uses data intelligently to assist funders in finding ideal founders and LP’s. The use of machine language and natural language processing allows for high fidelity matches with founders who are industry specific, are looking for funding from firms with your characteristics, and meet your funding criteria. InvestorMatch.ai's algorithm has been designed to reduce the challenges and noise in the discordant interface between funders, LP’s and founders, resulting in better efficiency, less time, and cost for all stakeholders.`,
		},

		{
			question: "How long will it take me to complete my firms onboarding profile?",
			answer: `All funders have unique characteristics. Typical times to onboard is between 20-45 minutes. You can start and stop at any time as the data is saved dynamically. The form is separated into small sections and is easy to complete.`,
		},

		{
			question: "How does InvestorMatch.ai ensure the quality of matches between funders and founders?",
			answer: `We use a combination of our teams 60 years of combined experience, coupled with machine learning and natural language processing to both contextualize and integrate your data to find the ideal matches. Our ongoing learning systems are updating and maximizing the potentials for
all stakeholders.`,
		},

		{
			question:
				"What kind of metrics or success stories can be shared to showcase the effectiveness of InvestorMatch.ai's matching algorithm?",
			answer: `During the next months we will be asking our matches for permissions to post their experiences and matching results. We intend on posting these in both our blogs and on the website. We encourage and appreciate your firm’s willingness to be public and give both your firm and InvestorMatch.ai additional visibility. Our key KPI is the number of successful introductions leading to funding.`,
		},

		{
			question: "Can founders or funders provide feedback on their match experience to improve the matching algorithm?",
			answer: `Absolutely, we encourage your feedback as our platform is consistently evolving to provide the best potentials for our funders, founders and vendors. You can send an e-mail to our CEO @ <a style="text-decoration:underline;" href="mailto:alan@investormatch.ai">Alan@InvestorMatch.ai</a> or within your portal you can send us feedback.`,
		},

		{
			question: "What kind of support does InvestorMatch.ai offer to funders?",
			answer: `We are responsive via e-mail (<a style="text-decoration:underline;" href="mailto:help@investormatch.ai">help@InvestorMatch.ai</a>) and via a Zoom link to assist your onboarding. Our link to schedule an appointment is: <a style="text-decoration:underline" href="https://tinyurl.com/3rz979zm" target="_blank">https://tinyurl.com/3rz979zm</a> Need to modify your responses or have some questions or feedback regarding the process, we’re here for you.`,
		},

		{
			question: "Can founders request to be removed from the matching pool or have their data deleted at any time?",
			answer: `Simply log into your portal and under ________ indicate your wish to be removed. We will ask for 2 factor authentication to verify your intent.`,
		},

		{
			question: "Does InvestorMatch.ai charge any fees or commissions for successful matches or introductions?",
			answer: `The fee schedule for funders is based on a subscription model. The options include a monthly recurrent fee or a reduced yearly payment.`,
		},

		{
			question:
				"How does InvestorMatch.ai ensure that the information provided by founders is accurate and up-to-date?",
			answer: `InvestorMatch.ai will be asking founders for periodic updates and does periodic checks using other databases and services. The portal is made with the intention that as the founders progress through the funding cycle/s they will update their information.`,
		},

		{
			question: "Can funders search for specific industries or regions to find matching founders?",
			answer: `Absolutely, you have the ability to modify your criteria which includes a deep multilevel dive into the industry/ies and locations that are specific to your investment criteria.`,
		},

		{
			question: "Finding LP’s with InvestorMatch.ai?",
			answer: `InvestorMatch.ai is aggregating one of the largest database of high-net-worth investing individuals and organizations. Our onboarding process inquiries about their interest in becoming a Limited Partner and which parameters, from amount of investment to frequency and more will meet their criteria. We use our proprietary algorithms to make the curated introductions to your firm.`,
		},

		{
			question: "What data do we collect?",
			answer: `For your onboarding, we ask for key information about your company and what funding your providing. To make successful matches we’ll get to know your funding thesis and the specifics of your firm’s offerings. None of your information will be available to the public or the funders until both parties are introduced with anonymized data and agree to the introduction.`,
		},

		{
			question: "Who can see my data?",
			answer: `All sensitive information and visibility is highly controlled by only allowing for anonymized data to be seen by both parties prior to an introduction. We believe that the best matches are made when both parties see the clear potential and are ready to engage. Please see our high-level matching documents.`,
		},

		{
			question: "Web security:",
			answer: `InvestorMatch.ai is in progress toward our SOC2 certification and uses high security AWS servers exclusively.`,
		},

		{
			question: "Can team members be added/invited to my InvestorMatch.ai profile?",
			answer: `Funders want to know your team. You can add and modify team members at any time, including who you show to the funders.`,
		},
	],
	vendors: [
		{
			question: "Why should you onboard with InvestorMatch.ai?",
			answer:
				"At InvestorMatch.ai, we offer you and your firm the opportunity to reduce time, cost, and efforts in being introduced to ideal clients. We match stakeholders based on their referral needs, ensuring better efficiency, lower costs, and time savings for all parties.",
		},
		{
			question: "How extensive is the InvestorMatch.ai Network?",
			answer:
				"The InvestorMatch.ai expanding database includes all industries, stages of business development, locations, and more. Our platform is agnostic, welcoming all founders and funders, from private to public and commercial funding sources.",
		},
		{
			question: "What are the benefits of using InvestorMatch.ai's matching services?",
			answer:
				"InvestorMatch.ai's matching algorithms intelligently use data to assist vendors in finding ideal clients. Our algorithm reduces challenges and noise, resulting in better efficiency, less time, and cost for all stakeholders.",
		},
		{
			question: "How do I control my Vendor Criteria?",
			answer:
				"During onboarding, you'll be asked a series of questions to identify your firm, its offerings, and your ideal clients. You can specify a broad range of options to hone in on your ideal client, saving time by avoiding potential clients who are not a good fit.",
		},
		{
			question: "What's the cost to Vendors?",
			answer: "Dependent on the service/product, we will individually propose an attractive price schedule.",
		},
		{
			question: "What data do we collect from founders and funders?",
			answer:
				"During onboarding, we ask comprehensive questions to selectively match clients to your criteria. None of your information will be available to the public or funders until both parties are introduced with anonymized data and agree to the introduction.",
		},
		{
			question:
				"How does InvestorMatch.ai ensure the accuracy and up-to-date information provided by funders and founders?",
			answer:
				"We cross correlate information from other sources to ensure data integrity. We request periodic updates from funders and founders and provide portals for them to update their information as they progress through the funding cycle.",
		},
		{
			question: "Who can see my Vendor's data?",
			answer:
				"Sensitive information and visibility are highly controlled, allowing only anonymized data to be seen by both parties prior to an introduction. We believe the best matches are made when both parties see the clear potential and are ready to engage.",
		},
		{
			question: "What's InvestorMatch.ai's Web security?",
			answer:
				"InvestorMatch.ai is in progress toward SOC2 certification and uses high-security AWS servers exclusively.",
		},
		{
			question: "How can I get exposure to founders and funders on InvestorMatch.ai?",
			answer:
				"Simply complete your vendor onboarding profile and keep it updated as your firm changes offerings. Your profile will be continually matched to new funders and founders as our platform expands.",
		},
		{
			question: "How long will it take me to complete my onboarding profile?",
			answer:
				"All vendors have unique characteristics. Typical onboarding time is between 25-45 minutes. You can start and stop at any time as the data is saved dynamically.",
		},
		{
			question: "Can vendors request to be removed from the matching pool or have their data deleted at any time?",
			answer:
				"Yes, vendors can log into their portal and indicate their wish to be removed. We will ask for 2-factor authentication to verify their intent.",
		},
	],
};
