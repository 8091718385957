import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Add from "@material-ui/icons/Add";
import DeleteForever from "@material-ui/icons/DeleteForever";
import { useState } from "react";

import { removeAnswer } from "src/api/answers";
import { QUESTION_TYPES } from "src/helpers/constants";
import { generateId } from "src/helpers/generateId";
import { Button } from "@material-ui/core";
import { EditMultiLevelChoice } from "./EditMultiLevelChoice";

export function Choice({
	question,
	questionIndex,
	answer,
	index,
	classes,
	errors,
	steps = [],
	labels = {},
	onDelete,
	setAnswer,
	setQuestion,
	addAnswer,
}) {
	const [deleting, setDeleting] = useState(false);
	const [isMLCEditing, setMLCEditing] = useState(false);
	async function deleteAnswer() {
		if (question.answers[index].id) {
			try {
				setDeleting(true);
				await removeAnswer(question.answers[index].id);
				onDelete(index);
			} catch (err) {
				console.error("error while removing answer", err);
			} finally {
				setDeleting(false);
			}
		} else {
			onDelete(index);
		}
	}
	return (
		<Box display={"grid"} gridColumnGap={10} gridTemplateColumns="repeat(24, 1fr)" my={6}>
			{question.type !== QUESTION_TYPES.SELECT && (
				<Box gridColumn="span 1">
					{question.type === QUESTION_TYPES.RADIO && <Radio checked={false} disabled value="a" name="radio-buttons" />}
					{(question.type === QUESTION_TYPES.MULTIPLE_CHOICE ||
						question.type === QUESTION_TYPES.MULTI_LEVEL_CHOICE) && (
						<Checkbox name={"answer_choice_" + questionIndex + "_" + index} disabled />
					)}
				</Box>
			)}
			<Box gridColumn="span 8">
				<TextField
					required
					fullWidth
					variant="outlined"
					placeholder="Value"
					size="small"
					error={!!errors[`answer${index}`]}
					helperText={errors[`answer${index}`] || ""}
					value={answer.answer}
					onChange={(e) => setAnswer(["answer"], e.target.value, index)}
				/>
			</Box>
			<Box gridColumn="span 3">
				<FormControlLabel
					control={
						<Checkbox
							checked={!!answer.meta?.action?.selectAll}
							onChange={(e) => {
								setAnswer(["meta", "action", "selectAll"], e.target.checked, index);
								setAnswer(["meta", "action", "deselectAll"], undefined, index);
							}}
						/>
					}
					classes={{ label: classes.smallFont }}
					label="Select All"
				/>
			</Box>
			<Box gridColumn="span 4">
				<FormControlLabel
					control={
						<Checkbox
							checked={!!answer.meta?.action?.deselectAll}
							onChange={(e) => {
								setAnswer(["meta", "action", "selectAll"], undefined, index);
								setAnswer(["meta", "action", "deselectAll"], e.target.checked, index);
							}}
						/>
					}
					classes={{ label: classes.smallFont }}
					label="Deselect All"
				/>
			</Box>
			{QUESTION_TYPES.RADIO === question.type && (
				<Box gridColumn="span 4">
					<FormControlLabel
						control={
							<Checkbox
								checked={answer.meta?.type === "DATE"}
								onChange={(e) => {
									if (e.target.checked) {
										setAnswer(["meta", "type"], "DATE", index);
									} else {
										setAnswer(["meta", "type"], undefined, index);
									}
								}}
							/>
						}
						classes={{ label: classes.smallFont }}
						label="Date"
					/>
				</Box>
			)}
			<Box gridColumn="span 4">
				<TextField
					fullWidth
					size={"small"}
					variant="outlined"
					value={answer.meta?.keyword || ""}
					label="Keyword"
					type={"text"}
					onChange={(e) => setAnswer(["meta", "keyword"], e.target.value, index)}
				/>
			</Box>
			<Box gridColumn="span 4">
				<Box display={"flex"} style={{ gap: "0.5rem" }} alignItems={"center"}>
					<IconButton variant="contained" color="primary" onClick={() => addAnswer(index)}>
						<Add />
					</IconButton>
					<IconButton variant="contained" color="secondary" disabled={deleting} onClick={() => deleteAnswer(index)}>
						{deleting ? <CircularProgress color="secondary" size={"1rem"} /> : <DeleteForever />}
					</IconButton>
				</Box>
			</Box>
			{QUESTION_TYPES.MULTI_LEVEL_CHOICE === question.type && (
				<Box gridColumn="span 4">
					<Button
						variant="outlined"
						color="primary"
						size="small"
						onClick={() => {
							setMLCEditing(true);
						}}
					>
						Edit Children
					</Button>
				</Box>
			)}
			<Box gridColumn="span 9">
				<TextField
					fullWidth
					multiline
					minRows={QUESTION_TYPES.MULTI_LEVEL_CHOICE === question.type ? 5 : 1}
					maxRows={5}
					variant="outlined"
					label="Answer Description"
					placeholder="Answer Description"
					size="small"
					value={answer.meta?.description || ""}
					onChange={(e) => setAnswer(["meta", "description"], e.target.value, index)}
				/>
			</Box>
			{/* QUESTION_TYPES.MULTI_LEVEL_CHOICE === question.type &&
				{
					<TextField
						fullWidth
						multiline
						minRows={4}
						maxRows={5}
						variant="outlined"
						label="Children"
						placeholder="Children"
						size="small"
						value={JSON.stringify(answer.meta?.children || [], null, "\t")}
						onChange={(e) => setAnswer(["meta", "children"], JSON.parse(e.target.value), index)}
					/> 
				}}*/}
			<Box gridColumn="span 3">
				<TextField
					fullWidth
					size={"small"}
					variant="outlined"
					value={answer.order}
					label="Order"
					type={"number"}
					onChange={(e) => setAnswer(["order"], Number(e.target.value), index)}
				/>
			</Box>
			<Box gridColumn="span 3">
				<TextField
					fullWidth
					size={"small"}
					variant="outlined"
					value={answer.weight}
					label="Weight"
					type={"number"}
					onChange={(e) => setAnswer(["weight"], Number(e.target.value), index)}
				/>
			</Box>
			<Box gridColumn="span 5">
				<InputLabel shrink>Show Questions</InputLabel>
				{answer.meta?.display?.map((s) =>
					labels[s] ? (
						<Tooltip key={`key_question-dis-${s}`} placement={"top"} arrow={true} title={labels[s].question}>
							<Chip label={`Question ${labels[s].index}`} size="small" />
						</Tooltip>
					) : null
				)}
				<FormControl className={classes.formControl}>
					<Select
						multiple
						fullWidth
						value={answer.meta?.display || []}
						onChange={(e) => {
							const values = [...e.target.value];
							const lastAddedVal = values.pop();
							let label;
							if (lastAddedVal) {
								const [stepIndex, qIndex] = lastAddedVal.split("_");
								if (!steps[stepIndex].questions[qIndex].meta.label) {
									label = generateId();
									setQuestion(steps[stepIndex].questions[qIndex], ["meta", "label"], label, qIndex);
								} else {
									label = steps[stepIndex].questions[qIndex].meta.label;
								}
								const vindex = values.findIndex((val) => val === label);
								if (vindex < 0) {
									values.push(label);
								} else {
									values.splice(vindex, 1);
								}
								setAnswer(["meta", "display"], values, index);
							}
						}}
						input={<Input />}
						// renderValue={(selected) => selected.map((s) => `Question ${labels[s].index}`).join(", ")}
						MenuProps={{
							PaperProps: {
								style: {
									maxHeight: 300,
									width: 350,
								},
							},
						}}
					>
						{steps.map(({ questions }, stepidx) => {
							return questions.map((question, qidx) => {
								return (
									<MenuItem
										style={{
											whiteSpace: "pre-wrap",
											fontSize: 12,
											display: "flex",
											flexDirection: "column",
											alignItems: "flex-start",
										}}
										key={`show_ques_${qidx}_${index}_label_${stepidx}`}
										value={`${stepidx}_${qidx}`}
									>
										<span style={{ display: "flex", alignItems: "center" }}>
											<Checkbox checked={(answer.meta?.display || []).indexOf(question.meta.label) > -1} />
											<span>
												{stepidx + 1}.{qidx + 1}&#41;&nbsp;{question.question}
											</span>
										</span>
									</MenuItem>
								);
							});
						})}
					</Select>
				</FormControl>
			</Box>

			<Box gridColumn="span 3">
				<InputLabel shrink>Hide Steps</InputLabel>
				{answer.meta?.hideSteps?.map((s) => (
					<Chip key={`hide-step-${question.id}-${s}`} label={`Step ${s + 1}`} size="small" />
				))}
				<FormControl className={classes.formControl}>
					<Select
						multiple
						fullWidth
						value={answer.meta?.hideSteps || []}
						onChange={(e) => {
							const values = [...e.target.value];
							setAnswer(["meta", "hideSteps"], values, index);
						}}
						input={<Input />}
						renderValue={() => ""}
						MenuProps={{
							PaperProps: {
								style: {
									maxHeight: 300,
									width: 350,
								},
							},
						}}
					>
						{steps.map((step, stepIdx) => {
							if (stepIdx <= question.meta.step) {
								return null;
							}
							return (
								<MenuItem
									style={{
										whiteSpace: "pre-wrap",
										fontSize: 12,
										display: "flex",
										flexDirection: "column",
										alignItems: "flex-start",
									}}
									key={`show_ques_${index}_label_${stepIdx}`}
									value={stepIdx}
								>
									<span style={{ display: "flex", alignItems: "center" }}>
										<Checkbox checked={(answer.meta?.hideSteps || []).indexOf(stepIdx) > -1} />
										<span>
											Step {stepIdx + 1}&nbsp;-&nbsp;{step.questions[0].group}
										</span>
									</span>
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>
			</Box>

			{isMLCEditing && (
				<EditMultiLevelChoice
					title={answer.answer}
					metaChildren={answer?.meta?.children || []}
					onDone={(value) => {
						setAnswer(["meta", "children"], value, index);
						setMLCEditing(false);
					}}
					onCancel={() => {
						setMLCEditing(false);
					}}
				/>
			)}
		</Box>
	);
}
