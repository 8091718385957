// import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useCallback, useEffect, useState } from "react";
import { create, getAll, remove, update } from "src/api/industries";
import { debounce } from "src/helpers/debounce";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Alert from "src/helpers/alert";

function Row({ row, onSave, onDelete, onCancel }) {
	const [saving, setSaving] = useState(false);
	const [editing, setEditing] = useState(!row.id);
	const [deleting, setDeleting] = useState(false);
	const [data, setData] = useState(() => ({ ...row }));
	function onChange(field, value) {
		setData({ ...data, [field]: value });
	}

	async function save() {
		try {
			setSaving(true);
			let res = data;
			if (data.id) {
				res = await update(data);
			} else {
				res = await create(data);
			}
			onSave(res);
			setEditing(false);
		} catch (err) {
			console.error("Unable to save industry", err);
			Alert.error("Unable to save");
		} finally {
			setSaving(false);
		}
	}

	async function deleteRow() {
		try {
			setDeleting(true);
			await remove(data);
			onDelete();
		} catch (err) {
			console.error("Unable to delete industry", err);
			Alert.error("Unable to delete");
		} finally {
			setDeleting(false);
		}
	}

	return (
		<TableRow key={data.id}>
			<TableCell className="pl-4">
				{editing ? (
					<input
						className="p-2 border rounded-sm"
						placeholder="Name"
						value={data.name}
						type="text"
						onChange={(e) => onChange("name", e.target.value)}
					/>
				) : (
					data.name
				)}
			</TableCell>
			<TableCell>
				{editing ? (
					<input
						className="w-full p-2 border rounded-sm"
						placeholder="Synonyms"
						value={data.synonyms}
						type="text"
						onChange={(e) => onChange("synonyms", e.target.value)}
					/>
				) : (
					data.synonyms
				)}
			</TableCell>
			<TableCell>
				<div className="flex gap-2">
					{editing && (
						<>
							<Button variant="contained" color="primary" disabled={saving} onClick={save} size="small">
								{saving ? "Saving..." : "Save"}
							</Button>
							<Button
								variant="contained"
								color="Secondary"
								onClick={() => {
									setEditing(false);
									onCancel();
								}}
								size="small"
							>
								Cancel
							</Button>
						</>
					)}
					{!editing && (
						<Button variant="contained" color="primary" size="small" onClick={() => setEditing(true)}>
							Edit
						</Button>
					)}
					{!editing && (
						<Button variant="contained" color="secondary" disabled={deleting} size="small" onClick={deleteRow}>
							{deleting ? "Deleting..." : "Delete"}
						</Button>
					)}
				</div>
			</TableCell>
		</TableRow>
	);
}

export function Industries() {
	const [rows, setRows] = useState([]);
	const [loading, setLoading] = useState(false);
	const [total, setTotal] = useState(-1);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(20);
	const [search, setSearch] = useState("");
	const [deferredSearch, setDeferredSearch] = useState("");
	const [error, setError] = useState(null);

	const updateSearch = useCallback(
		debounce(function _updateSearch(search) {
			setDeferredSearch(search);
		}, 500),
		[]
	);
	useEffect(() => {
		updateSearch(search);
	}, [search]);
	useEffect(() => {
		const options = { limit: perPage, page: currentPage };
		if (search) {
			options.filter = `name||$contL||${search}`;
			options.or = `synonyms||$contL||${search}`;
		}
		(async () => {
			try {
				setError(null);
				setLoading(true);
				const res = await getAll(options);
				setRows(res.data);
				setTotal(res.total);
			} catch (err) {
				console.error("Unable to fetch industries", err);
				setError("Unable to load");
			} finally {
				setLoading(false);
			}
		})();
	}, [currentPage, deferredSearch, perPage]);
	return (
		<TableContainer component={Paper}>
			<Box className="flex justify-between items-center p-4">
				<Box className="text-xl">Industries</Box>
				<Box>
					{loading && "Loading..."}
					{error && <Box className="text-red-600">{error}</Box>}
				</Box>
				<Box>
					<TextField
						fullWidth
						variant="outlined"
						label="Search"
						type="text"
						size="small"
						value={search}
						onChange={(e) => {
							setSearch(e.target.value);
						}}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										onClick={() => {
											setSearch("");
										}}
									>
										<Close />
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				</Box>
			</Box>
			<Table aria-label="industries table">
				<TableHead>
					<TableRow>
						<TableCell>Name</TableCell>
						<TableCell>Synonyms</TableCell>
						<TableCell style={{ width: 200 }}>Actions</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{error && (
						<TableRow>
							<TableCell colSpan={3}>{error}</TableCell>
						</TableRow>
					)}
					{rows.length <= 0 && (
						<TableRow>
							<TableCell colSpan={3}>{loading ? "Loading..." : "No data"}</TableCell>
						</TableRow>
					)}
					{rows.length > 0 &&
						rows.map((row, idx) => (
							<Row
								key={`row-${row.id || idx}`}
								row={row}
								onSave={(res) => {
									const _rows = [...rows];
									_rows[idx] = res;
									setRows(_rows);
								}}
								onDelete={() => {
									const _rows = [...rows];
									_rows.splice(idx, 1);
									setRows(_rows);
								}}
								onCancel={() => {
									if (row.id) {
										return;
									}
									const _rows = [...rows];
									_rows.splice(idx, 1);
									setRows(_rows);
								}}
							/>
						))}
				</TableBody>
				<TableFooter>
					<TableRow>
						<TableCell>
							<Button
								variant="contained"
								color="primary"
								onClick={() => {
									const _rows = [...rows];
									_rows.push({ name: "", synonyms: "" });
									setRows(_rows);
								}}
							>
								+ Add Industry
							</Button>
						</TableCell>
						<TablePagination
							rowsPerPageOptions={[20, 50, 100]}
							count={total}
							rowsPerPage={perPage}
							page={currentPage - 1}
							SelectProps={{
								inputProps: { "aria-label": "rows per page" },
								native: true,
							}}
							onPageChange={(e, newPage) => {
								setCurrentPage(newPage + 1);
							}}
							onRowsPerPageChange={(e) => {
								setPerPage(parseInt(e.target.value, 10));
								setCurrentPage(1);
							}}
							// ActionsComponent={TablePaginationActions}
						/>
					</TableRow>
				</TableFooter>
			</Table>
		</TableContainer>
	);
}
