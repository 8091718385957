import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { logout as logoutUser, update } from "src/api/auth";
import { ROLES } from "src/helpers/constants";
import { getFunctionalRoles } from "src/helpers";
import { clone } from "src/helpers/clone";
import { isTenantValid } from "src/api/tenants";
import { setErrorReportingUserContext } from "src/helpers/errorReporting";
import { localstorage } from "../../lib/storage";

let user = null;
try {
	const _user = localstorage.getItem("user");
	if (_user) {
		user = JSON.parse(localstorage.getItem("user"));
	}
} catch (error) {
	console.warn("Error while parsing user from local storage", error);
}

const initialState = {
	authUser: user,
	loadUser: true,
	tenant: null,
	loadTenant: false,
};
if (user) {
	const roles = getFunctionalRoles(user.roles);
	initialState.isFunder = user.roles.includes(ROLES.FUNDER);
	initialState.isFounder = user.roles.includes(ROLES.FOUNDER);
	initialState.currentRole = localstorage.getItem("currentRole") || roles[0];
}

export const logout = createAsyncThunk("auth/logout", async () => {
	return await logoutUser();
});
export const validateTenant = createAsyncThunk("tenant/load", async () => {
	return await isTenantValid();
});
export const updateUser = createAsyncThunk("user/update", async (user) => {
	return await update(user);
});
export const updateUserOnboarding = createAsyncThunk("user/update/onboarding", async (onboarding, { getState }) => {
	const state = getState().auth;
	const user = clone(state.authUser);
	user.onboarding[state.currentRole] = onboarding;
	return await update(user);
});

function _setAuthUser(state, action) {
	state.authUser = action.payload;
	state.isFunder = state.authUser.roles.includes(ROLES.FUNDER);
	state.isFounder = state.authUser.roles.includes(ROLES.FOUNDER);
	const roles = getFunctionalRoles(state.authUser.roles);
	state.currentRole = state.currentRole || roles[0];
	localstorage.setItem("user", JSON.stringify(state.authUser));
	localstorage.setItem("currentRole", state.currentRole);
	setErrorReportingUserContext(state.authUser);
	state.loadUser = true;
}

export const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		setAuthUser: _setAuthUser,
		updateLoadUser(state, action) {
			state.loadUser = action.payload;
		},
		updateCurrentRole(state, action) {
			state.currentRole = action.payload;
			localstorage.setItem("currentRole", state.currentRole);
		},
	},
	extraReducers: {
		[logout.fulfilled]: (state) => {
			localstorage.clear();
			state.authUser = null;
			state.currentRole = null;
			state.loadUser = true;
		},
		[updateUser.fulfilled]: (state, action) => {
			_setAuthUser(state, action);
		},
		[updateUserOnboarding.fulfilled]: (state, action) => {
			_setAuthUser(state, action);
		},
		[validateTenant.fulfilled]: (state, action) => {
			state.loadTenant = true;
			state.tenant = action.payload;
		},
		[validateTenant.rejected]: (state) => {
			state.loadTenant = true;
			state.tenant = null;
		},
	},
});

export const { setAuthUser, updateLoadUser, updateCurrentRole } = authSlice.actions;

export default authSlice.reducer;
