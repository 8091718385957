import { Button } from "@material-ui/core";

export function Information({ onClose }) {
	return (
		<div className="bg-white w-2/3 h-80 rounded-lg absolute -translate-x-1/2 left-1/2 top-1/2 -translate-y-1/2 overflow-auto">
			<div className="grid grid-cols-1 md:grid-cols-3 gap-4 text-center p-4 text-xl">
				<div className="flex flex-col gap-4 justify-center items-center">
					<img src="/images/secure.svg" alt="Secure" width={100} height={100} />
					<h6 className="text-2xl">Secure by Default</h6>
					<p>Industry standard security enabled always</p>
				</div>
				<div className="flex flex-col gap-2 justify-center items-center">
					<img src="/images/auto-save.svg" alt="Auto-save" width={100} height={100} />
					<h6 className="text-2xl">Auto-Saved</h6>
					<p>You data is saved to the cloud automatically</p>
				</div>
				<div className="flex flex-col gap-2 justify-center items-center">
					<img src="/images/copy-paste.svg" alt="Secure" width={100} height={100} />
					<h6 className="text-2xl">Copy & Paste</h6>
					<p>Copy & Paste information from linkedin and presentation documents</p>
				</div>
			</div>
			<div className="text-center">
				<Button variant="contained" color='primary' onClick={onClose}>
					Close
				</Button>
			</div>
		</div>
	);
}
