import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Email from "@material-ui/icons/Email";
import Phone from "@material-ui/icons/Phone";
import { useSelector } from "react-redux";
import { Main } from "src/components/commons/Main";

export function ContactUs() {
	const { tenant } = useSelector(({ auth }) => auth);
	const phone = tenant?.contactPhone || "+1 541-903-5090";
	const email = tenant?.contactEmail || "info@investormatch.ai";
	return (
		<Main>
			<Box
				textAlign={"center"}
				height="100%"
				display="flex"
				flexDirection={"column"}
				alignItems="center"
				justifyContent={"center"}
				fontSize={20}
			>
				<img src={(tenant.logo || {})["2x"]?.path || "/images/logo-v1.png"} alt="logo" width={150} />
				<Typography
					component={"h1"}
					variant={"h4"}
					style={{ marginBottom: "1rem", marginTop: "1rem", textDecoration: "underline" }}
				>
					Contact Us
				</Typography>
				<Box marginBottom={4}>
					<Typography component={"h2"} variant={"h5"} style={{ marginBottom: "0.5rem" }}>
						<Phone fontSize="small" /> Call Us
					</Typography>
					<a href={`tel:${phone}`}>{phone}</a>
				</Box>

				<Typography component={"h2"} variant={"h5"}>
					<Email fontSize="small" /> Email Us
				</Typography>
				<a href={`mailto:${email}`}>{email}</a>
			</Box>
		</Main>
	);
}
