import { toast } from "react-toastify";
import MAlert from "@material-ui/lab/Alert";

const Alert = {
	error(error) {
		return toast(
			({ closeToast }) => {
				return (
					<MAlert severity="error" variant="filled" onClose={closeToast}>
						{error}
					</MAlert>
				);
			},
			{ closeButton: false }
		);
	},
	success(message) {
		return toast(
			({ closeToast }) => {
				return (
					<MAlert severity="success" variant="filled" onClose={closeToast}>
						{message}
					</MAlert>
				);
			},
			{ closeButton: false }
		);
	},
	info(message, options = {}) {
		return toast(
			({ closeToast }) => {
				return (
					<MAlert severity="info" variant="filled" onClose={closeToast}>
						{message}
					</MAlert>
				);
			},
			{ closeButton: false, ...options }
		);
	},
	warning(message) {
		return toast(
			({ closeToast }) => {
				return (
					<MAlert severity="warning" variant="filled" onClose={closeToast}>
						{message}
					</MAlert>
				);
			},
			{ closeButton: false }
		);
	},
	custom(message, options = {}) {
		return toast(
			({ closeToast }) => {
				return (
					<MAlert severity={options.severity || "info"}  icon={false} variant="filled" onClose={closeToast}>
						{message}
					</MAlert>
				);
			},
			{ closeButton: false, options }
		);
	},
};
export default Alert;
