import React, { useCallback, useMemo, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { alpha } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CheckBox from "@material-ui/core/Checkbox";
// import FormLabel from "@material-ui/core/FormLabel";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Radio from "@material-ui/core/Radio";
// import RadioGroup from "@material-ui/core/RadioGroup";

import IntlMessages from "src/@jumbo/utils/IntlMessages";
import { register } from "src/api/auth";
import { setAuthUser } from "src/store/slices/auth";
import Alert from "src/helpers/alert";
import { ONBOARDING_STEPS, ROLES } from "src/helpers/constants";
import AuthWrapper from "./AuthWrapper";

const useStyles = makeStyles((theme) => ({
	authThumb: {
		backgroundColor: alpha(theme.palette.primary.main, 0.12),
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		padding: 20,
		[theme.breakpoints.up("md")]: {
			width: "50%",
			order: 2,
		},
	},
	titleRoot: {
		marginBottom: 10,
		// color: theme.palette.text.primary,
	},
	textFieldRoot: {
		// "& .MuiOutlinedInput-notchedOutline": {
		// 	borderColor: alpha(theme.palette.text.primary, 0.12),
		// },
	},
	submitProgress: {
		position: "absolute",
		top: 5,
		left: 46,
	},
	textCapital: {
		textTransform: "capitalize",
	},
	textAcc: {
		textAlign: "center",
		"& a": {
			marginLeft: 4,
		},
	},
	info: {
		textAlign: "center",
		marginBottom: 12,
	},
	radioGroup: {
		marginLeft: "1rem",
	},
}));

//variant = 'default', 'standard', 'bgColor'
const SignUp = ({ variant = "default", wrapperVariant = "default" }) => {
	const { tenant } = useSelector(({ auth }) => auth);
	console.log(tenant);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [role, setRole] = useState(ROLES.FOUNDER);
	const [isSubmitting, setSubmitting] = useState(false);
	const classes = useStyles({ variant });
	const [showPassword, setShowPassword] = useState(false);

	const rolesList = useMemo(() => {
		const _roles = [
			{ value: [ROLES.FOUNDER], label: "Founder/Entrepreneur" },
			{ value: [ROLES.FUNDER], label: "Investor / Funder / Accelerator who funds" },
		];
		if (tenant?.domain.indexOf("investormatch.ai") >= 0) {
			_roles.push({
				value: [ROLES.VENDOR],
				label: "Vendor / Product & Service provider / Accelerator who doesn't fund",
			});
		}	
		return _roles;
	}, [tenant]);

	const onSubmit = useCallback(
		async (e) => {
			e.preventDefault();
			setSubmitting(true);

			try {
				const names = name.split(" ");
				let firstName = names[0],
					lastName = names[1],
					middlename,
					suffix;
				if (names[2]) {
					lastName = names[2];
					middlename = names[1];
				}
				if (names[3]) {
					suffix = names[3];
				}
				if (!lastName) {
					Alert.error("Last Name cannot be empty");
					return;
				}
				if (!tenant) {
					Alert.error("Unable to determine the tenant");
					return;
				}
				const user = await register({
					firstName,
					lastName,
					profile: { middlename, suffix },
					role,
					email,
					password,
					tenantId: tenant.id,
					onboarding: role.split(",").reduce((acc, role) => {
						acc[role] = { isCompleted: false, completedStep: ONBOARDING_STEPS.SIGNUP };
						return acc;
					}, {}),
				});
				dispatch(setAuthUser(user));
				if (user.isEmailVerified) {
					navigate("/dashboard");
					return;
				}
				navigate("/verify-email?wait=true");
				return;
			} catch (err) {
				console.error("Error while signing up", err);
				Alert.error(err);
			} finally {
				setSubmitting(false);
			}
		},
		[name, email, password, role]
	);
	
	return (
		<AuthWrapper variant={wrapperVariant} screen="signup">
			<Typography component="h1" variant="h5" align="center" className={classes.titleRoot}>
				<IntlMessages id="appModule.register" />
			</Typography>
			<form onSubmit={onSubmit}>
				<Box>
					<TextField
						label={<IntlMessages id="appModule.fullname" />}
						fullWidth
						required={true}
						autoFocus
						onChange={(event) => setName(event.target.value)}
						defaultValue={name}
						margin="normal"
						InputProps={{ inputProps: { autoComplete: "name" } }}
						variant="outlined"
						className={classes.textFieldRoot}
					/>
				</Box>
				<Box>
					<TextField
						label={<IntlMessages id="appModule.email" />}
						fullWidth
						required={true}
						onChange={(event) => setEmail(event.target.value?.toLowerCase())}
						defaultValue={email}
						margin="normal"
						InputProps={{ inputProps: { autoComplete: "email" } }}
						variant="outlined"
						className={classes.textFieldRoot}
					/>
				</Box>
				<Box>
					<TextField
						type={showPassword ? "text" : "password"}
						label={<IntlMessages id="appModule.password" />}
						fullWidth
						autoComplete="new-password"
						required={true}
						onChange={(event) => setPassword(event.target.value)}
						defaultValue={password}
						margin="normal"
						variant="outlined"
						className={classes.textFieldRoot}
					/>
				</Box>
				<Box m={0}>
					<FormControlLabel
						control={
							<CheckBox
								checked={showPassword}
								onChange={(e) => {
									setShowPassword(e.target.checked);
								}}
							/>
						}
						label="Show Password"
					/>
				</Box>

				<Box display="flex" alignItems={"center"}>
					<TextField
						select
						fullWidth
						required={true}
						label="I am a"
						onChange={(e) => setRole(e.target.value)}
						value={role || ""}
						margin="normal"
						variant="outlined"
						className={classes.textFieldRoot}
						SelectProps={{
							MenuProps: { style: { width: "250px" } },
						}}
					>
						<MenuItem value="">
							<em style={{ color: "#999" }} disabled>
								I am a
							</em>
						</MenuItem>
						{rolesList.map(({ value, label }) => (
							<MenuItem key={label} value={value.join(",")} style={{ whiteSpace: "break-spaces" }}>
								{label}
							</MenuItem>
						))}
					</TextField>
					{/* <RadioGroup
						row
						className={classes.radioGroup}
						aria-label="role"
						name="role"
						value={role}
						onChange={(event) => {
							setRole(event.target.value);
						}}
					>
						<FormControlLabel
							value={ROLES.FOUNDER}
							control={<Radio checked={role === ROLES.FOUNDER} />}
							label="Founder"
						/>
						<FormControlLabel value={ROLES.FUNDER} control={<Radio checked={role === ROLES.FUNDER} />} label="Funder" />
					</RadioGroup> */}
				</Box>
				<Box
					display="flex"
					flexDirection={{ xs: "column", sm: "row" }}
					alignItems={"center"}
					justifyContent={"center"}
					my={4}
					position="relative"
				>
					<Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
						<IntlMessages id="appModule.register" />
					</Button>
					{isSubmitting && <CircularProgress size={24} variant={"indeterminate"} className={classes.submitProgress} />}
				</Box>
			</form>
			<Typography component={"p"} className={classes.info}>
				Information you provide when registering will be shared with the host and can be used and shared by them in
				accordance with their{" "}
				<a
					target={"_blank"}
					style={{ textDecoration: "underline" }}
					rel="noreferrer"
					href={tenant.tncLink || "https://investormatch.ai/terms-of-service/"}
				>
					Terms
				</a>{" "}
				and{" "}
				<a
					target={"_blank"}
					style={{ textDecoration: "underline" }}
					rel="noreferrer"
					href={tenant.privacyPolicyLink || "https://investormatch.ai/privacy-policy/"}
				>
					Privacy Policy
				</a>
				.
			</Typography>
			<Typography className={classes.textAcc}>
				Have an account?
				<Link component={NavLink} to="/login" underline="always">
					<IntlMessages id="appModule.signIn" />
				</Link>
			</Typography>
		</AuthWrapper>
	);
};

export default SignUp;
