import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Add from "@material-ui/icons/Add";
import { useLoaderData, defer, Link } from "react-router-dom";

import { Main } from "src/components/commons/Main";
import { Async } from "src/components/commons/Async";
import { getAll } from "src/api/teams";
import { TeamListItem } from "src/components/teams/TeamListItem";

export default function Teams() {
	const { data } = useLoaderData();

	return (
		<Main>
			<Box display={"flex"} justifyContent="space-between" p={4} px={4} borderBottom={"1px solid rgb(239 232 251)"}>
				<Typography component={"h2"} variant="h6">
					Teams
				</Typography>
				<Button variant="contained" color="primary" size="small" startIcon={<Add />} component={Link} to={`/team/add`}>
					Add Team
				</Button>
			</Box>
			<Async error={"Unable to fetch teams"} promise={data}>
				{(teams) => {
					if (teams.data.length === 0) {
						return (
							<Box
								display={"flex"}
								flexDirection={"column"}
								p={8}
								minHeight={150}
								justifyContent={"space-between"}
								alignItems={"Center"}
							>
								<Typography component={"div"} variant="body2">
									No teams
								</Typography>
								<Button startIcon={<Add />} component={Link} to={`/team/add`} variant="text" color="primary">
									Add Member
								</Button>
							</Box>
						);
					}
					return (
						<List component={"ul"}>
							{teams.data.map((team) => {
								return <TeamListItem key={`team-id-${team.id}`} team={team} />;
							})}
						</List>
					);
				}}
			</Async>
		</Main>
	);
}

export async function teamsLoader() {
	return defer({
		data: getAll(),
	});
}
