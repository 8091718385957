import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/styles/makeStyles";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
const useStyles = makeStyles(() => {
	return {
		list: {
			counterReset: "inc",
		},
		listItem: {
			listStyle: "none",
			fontSize: "1.2rem",
			"&::before": {
				counterIncrement: "inc",
				content: "counter(inc)",
				background: "#ccc",
				borderRadius: "50%",
				padding: "2px 7px",
				fontSize: 12,
				marginRight: 4,
				display: "inline-block"
			},
			"&:hover": {
				cursor: "grab",
			},
			"&.handler-item": {
				background: "#cccccc",
				cursor: "grabbing",
				userSelect: "none",
			},
		},
	};
});
const SortableItem = SortableElement(({ value }) => {
	const classes = useStyles();
	return (
		<Box
			component={"li"}
			padding={"0.5rem"}
			border={"1px solid #ccc"}
			maxWidth={"100%"}
			width={"300px"}
			borderRadius={5}
			mb={2}
			bgcolor={"#fff"}
			className={classes.listItem}
		>
			{value}
		</Box>
	);
});

const SortableList = SortableContainer(({ items }) => {
	const classes = useStyles();
	return (
		<Box component={"ul"} className={classes.list}>
			{items.map((value, index) => (
				<SortableItem key={`item-sort-${index}-${value}`} index={index} value={value} />
			))}
		</Box>
	);
});

export function Sortable({ answers, setUserAnswer }) {
	function onSortEnd({ oldIndex, newIndex }) {
		if (oldIndex === newIndex) {
			return;
		}
		const _answers = [...answers];
		if (newIndex < 0) {
			newIndex = _answers.length - 1;
		} else if (newIndex >= _answers.length) {
			newIndex = 0;
		}
		_answers.splice(newIndex, 0, _answers.splice(oldIndex, 1)[0]);
		setUserAnswer(undefined, _answers);
	}
	return <SortableList items={answers} onSortEnd={onSortEnd} helperClass={"handler-item"} />;
}
