import React from "react";
import { useSelector } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import makeStyles from "@material-ui/core/styles/makeStyles";
// import ArrowForward from "@material-ui/icons/ArrowForward";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
// import MonetizationOn from "@material-ui/icons/MonetizationOn";
import Person from "@material-ui/icons/Person";
import ShowChart from "@material-ui/icons/ShowChart";
import SearchIcon from "@material-ui/icons/Search";
// import Settings from "@material-ui/icons/Settings";
import Group from "@material-ui/icons/Group";
import MonetizationOn from "@material-ui/icons/MonetizationOn";
// import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import HelpIcon from "@material-ui/icons/Help";
import MarkunreadMailboxIcon from "@material-ui/icons/MarkunreadMailbox";
// import {
//   AccountCircle,
//   ArrowForward,
//   Category,
//   Chat,
//   CheckCircle,
//   CloudUpload,
//   Colorize,
//   ContactMail,
//   Contacts,
//   Domain,
//   DonutSmall,
//   DragIndicator,
//   Edit,
//   EditAttributes,
//   Email,
//   Error,
//   Group,
//   ImportContacts,
//   InsertChart,
//   LibraryBooks,
//   LocalGroceryStore,
//   LockOutlined,
//   Map,
//   MonetizationOn,
//   NotificationImportant,
//   Notifications,
//   Person,
//   ShowChart,
//   Search,
//   Widgets,
// } from '@material-ui/icons';

import { ROLES } from "src/helpers/constants";
import CmtVertical from "../../../../../@coremat/CmtNavigation/Vertical";
import IntlMessages from "../../../../utils/IntlMessages";

const useStyles = makeStyles(() => ({
	perfectScrollbarSidebar: {
		height: "100%",
		transition: "all 0.3s ease",
		".Cmt-sidebar-fixed &, .Cmt-Drawer-container &": {
			height: "calc(100% - 95px)",
		},
		".Cmt-modernLayout &": {
			height: "calc(100% - 72px)",
		},
		".Cmt-miniLayout &": {
			height: "calc(100% - 91px)",
		},
		".Cmt-miniLayout .Cmt-sidebar-content:hover &": {
			height: "calc(100% - 95px)",
		},
	},
}));

const SideBar = () => {
	const classes = useStyles();
	const { currentRole, authUser } = useSelector(({ auth }) => auth);
	let menus = [];
	if (authUser.roles.includes(ROLES.TENANT_ADMIN)) {
		menus.push(
			{
				name: "Founder Applications",
				icon: <MarkunreadMailboxIcon />,
				type: "item",
				link: "/applications/founders",
			},
			{
				name: "Funder Applications",
				icon: <MonetizationOn />,
				type: "item",
				link: "/applications/funders",
			}
		);
	}
	if (currentRole === ROLES.FOUNDER) {
		menus.push(
			{
				name: <IntlMessages id={"sidebar.campaign"} />,
				icon: <SearchIcon />,
				type: "item",
				link: "/campaigns",
			},
			{
				name: "Teams",
				icon: <Group />,
				type: "item",
				link: "/teams",
			}
		);
	} else if (currentRole === ROLES.FUNDER) {
		menus.push({
			name: "Funding Opportunities",
			icon: <MonetizationOn />,
			type: "item",
			link: "/opportunities",
		});
		if (authUser.profile.isAngel !== true) {
			menus.push({
				name: "My Organization",
				icon: <Group />,
				type: "item",
				link: "/organization",
			});
		}
	} else if (currentRole === ROLES.VENDOR) {
		menus.push({
			name: "Services",
			icon: <MonetizationOn />,
			type: "item",
			link: "/services",
		});
	}
	const navigationMenus = [
		{
			name: <IntlMessages id={"sidebar.dashboard"} />,
			icon: <ShowChart />,
			type: "item",
			link: "/dashboard",
		},
		{
			name: "Your Profile",
			icon: <Person />,
			type: "item",
			link: "/profile",
		},
		// {
		// 	name: "Our Organization",
		// 	icon: <AccountBalanceIcon />,
		// 	type: "item",
		// 	link: "/organization",
		// },

		...menus,
		// {
		// 	name: <IntlMessages id={"sidebar.opportunity"} />,
		// 	icon: <MonetizationOn />,
		// 	type: "item",
		// 	link: "/opportunities",
		// },
		// {
		//   name: <IntlMessages id={'sidebar.team'} />,
		//   icon: <Group />,
		//   type: 'item',
		//   link: '/team',
		// },
		// {
		//   name: <IntlMessages id={'sidebar.teammember'} />,
		//   icon: <Contacts />,
		//   type: 'item',
		//   link: '/teammember',
		// },
		// {
		//   name: <IntlMessages id={'sidebar.task'} />,
		//   icon: <CheckCircle />,
		//   type: 'item',
		//   link: '/task',
		// },
		// {
		//   name: <IntlMessages id={'sidebar.alert'} />,
		//   icon: <NotificationImportant />,
		//   type: 'item',
		//   link: '/alert',
		// },
		{
			name: "FAQs",
			icon: <LibraryBooks />,
			type: "item",
			link: "/faq",
		},
		{
			name: "Contact Us",
			icon: <HelpIcon />,
			type: "item",
			link: "/contact-us",
		},
	];

	// if (authUser && authUser.roles.includes("SUPERADMIN")) {
	// 	navigationMenus.push({
	// 		name: <IntlMessages id={"sidebar.admin"} />,
	// 		icon: <Settings />,
	// 		type: "collapse",
	// 		children: [
	// 			{
	// 				name: "Founders",
	// 				icon: <ArrowForward />,
	// 				type: "collapse",
	// 				children: [
	// 					{
	// 						name: "Founders Form",
	// 						icon: <ArrowForward />,
	// 						type: "item",
	// 						link: "/admin/questions/campaign",
	// 					},
	// 					{
	// 						name: "Founder Profile",
	// 						icon: <ArrowForward />,
	// 						type: "item",
	// 						link: "/admin/questions/founder_profile",
	// 					},
	// 					{
	// 						name: "Founder Member",
	// 						icon: <ArrowForward />,
	// 						type: "item",
	// 						link: "/admin/questions/founder_member",
	// 					},
	// 					{
	// 						name: "Team Member",
	// 						icon: <ArrowForward />,
	// 						type: "item",
	// 						link: "/admin/questions/team_member",
	// 					},
	// 					{
	// 						name: "All Campaigns",
	// 						icon: <ArrowForward />,
	// 						type: "item",
	// 						link: "/admin/campaigns",
	// 					},
	// 				],
	// 			},
	// 			{
	// 				name: "Funders",
	// 				icon: <ArrowForward />,
	// 				type: "collapse",
	// 				children: [
	// 					{
	// 						name: "Funders Form",
	// 						icon: <ArrowForward />,
	// 						type: "item",
	// 						link: "/admin/questions/opportunity",
	// 					},
	// 					{
	// 						name: "Funder Profile",
	// 						icon: <ArrowForward />,
	// 						type: "item",
	// 						link: "/admin/questions/funder_profile",
	// 					},
	// 					{
	// 						name: "Funders Org",
	// 						icon: <ArrowForward />,
	// 						type: "item",
	// 						link: "/admin/questions/funder_organization",
	// 					},
	// 					{
	// 						name: "All Opportunities",
	// 						icon: <ArrowForward />,
	// 						type: "item",
	// 						link: "/admin/opportunities",
	// 					},
	// 				],
	// 			},
	// 			{
	// 				name: "Vendors",
	// 				icon: <ArrowForward />,
	// 				type: "collapse",
	// 				children: [
	// 					{
	// 						name: "Vendors Form",
	// 						icon: <ArrowForward />,
	// 						type: "item",
	// 						link: "/admin/questions/vendor",
	// 					},
	// 					{
	// 						name: "Vendors Profile",
	// 						icon: <ArrowForward />,
	// 						type: "item",
	// 						link: "/admin/questions/vendor_profile",
	// 					},
	// 					// {
	// 					// 	name: "Vendors Org",
	// 					// 	icon: <ArrowForward />,
	// 					// 	type: "item",
	// 					// 	link: "/admin/questions/vendor_organization",
	// 					// },
	// 				],
	// 			},
	// 			// {
	// 			// 	name: "All Opportunities",
	// 			// 	icon: <ArrowForward />,
	// 			// 	type: "item",
	// 			// 	link: "/admin/opportunities",
	// 			// },
	// 			{
	// 				name: "Match",
	// 				icon: <ArrowForward />,
	// 				type: "item",
	// 				link: "/admin/match",
	// 			},

	// 			// {
	// 			// 	name: "Manage FAQs",
	// 			// 	icon: <ArrowForward />,
	// 			// 	type: "item",
	// 			// 	link: "/admin/faqs",
	// 			// },
	// 			{
	// 				name: "Manage Users",
	// 				icon: <ArrowForward />,
	// 				type: "item",
	// 				link: "/admin/users",
	// 			},
	// 			// {
	// 			// 	name: "Match Preview",
	// 			// 	icon: <ArrowForward />,
	// 			// 	type: "item",
	// 			// 	link: "/admin/match-preview",
	// 			// },
	// 		],
	// 	});
	// }

	return (
		<PerfectScrollbar className={classes.perfectScrollbarSidebar}>
			<CmtVertical menuItems={navigationMenus} />
		</PerfectScrollbar>
	);
};

export default SideBar;
