export function FunderProfileDisplay({ userDetails }) {
	return (
		<div className="p-4 flex flex-col gap-4">
			{userDetails.profile.picture && (
				<div>
					<img
						className="rounded-md"
						src={userDetails.profile.picture}
						width={300}
						height={300}
						alt="details picture"
					/>
				</div>
			)}
			<div className="flex gap-4">
				<div>Name: </div>
				<div className="font-extrabold">{[userDetails.firstName, userDetails.lastName].join(" ")}</div>
			</div>
			<div className="flex gap-4">
				<div>Email: </div>
				<a className="font-extrabold underline" href={"mailto:" + userDetails.email}>
					{userDetails.email}
				</a>
			</div>
			<div className="flex gap-4">
				<div>Position: </div>
				<div className="font-extrabold">{(userDetails.details.positions || userDetails.positions)?.join(", ")}</div>
			</div>
			<div className="flex gap-4">
				<div>General Check Size: </div>
				<div className="font-extrabold">
					{userDetails.details.cksize || "-"}
				</div>
			</div>
			<div className="flex gap-4">
				<div>LinkedIn URL: </div>
				<a href={userDetails.details.linkedinUrl} target="_blank" rel="noreferrer" className="font-extrabold underline">
					{userDetails.details.linkedinUrl}
				</a>
			</div>
			<div className="flex gap-4">
				<div>Years of experience: </div>
				<div className="font-extrabold">{userDetails.details.yearsOfExperience || "-"} Years</div>
			</div>
			<div className="flex gap-4">
				<div>Prior Exits: </div>
				<div className="font-extrabold">{userDetails.details.exits || "-"}</div>
			</div>
		</div>
	);
}
