import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

import { Suspense } from "react";
import { defer, useLoaderData, Await } from "react-router-dom";

import { faqs } from "src/helpers/faqs";
import { Main } from "src/components/commons/Main";
import { ROLES } from "src/helpers/constants";

export function FAQs() {
	const { data } = useLoaderData();
	return (
		<>
			<Main>
				<Box
					display={"flex"}
					alignItems={"center"}
					justifyContent="space-between"
					p={4}
					borderBottom={"1px solid rgb(239 232 251)"}
				>
					<Typography component={"h2"} variant="h6">
						Frequently Asked Questions
					</Typography>
				</Box>
				<Suspense
					fallback={
						<Box height={"100%"} display="flex" justifyContent={"center"} alignItems={"center"}>
							<CircularProgress />
						</Box>
					}
				>
					<Await
						resolve={data}
						errorElement={
							<Box padding={4} textAlign="center">
								Could not load the faqs
							</Box>
						}
						children={(faqs) => {
							return (
								<>
									<Box p={4} borderBottom="1px solid #ccc">
										{faqs.map((faq, idx) => {
											return (
												<Box component={"section"} mb={4} key={`faq_${idx}`}>
													<Typography component={"h3"} variant="h6">
														{idx+1}&#41; {faq.question}
													</Typography>
													<Typography variant="body2">
														<span dangerouslySetInnerHTML={{ __html: faq.answer }} />
													</Typography>
												</Box>
											);
										})}
									</Box>
								</>
							);
						}}
					/>
				</Suspense>
			</Main>
		</>
	);
}

export function faqLoader({ currentRole }) {
	const promise = Promise.all([Promise.resolve(faqs)]).then(([faqs]) => {
		if (currentRole === ROLES.FOUNDER) {
			return faqs.founders;
		} else if (currentRole === ROLES.FUNDER) {
			return faqs.funders;
		} else if (currentRole === ROLES.VENDOR) {
			return faqs.vendors;
		} else {
			return [];
		}
	});
	return defer({
		data: promise,
	});
}
