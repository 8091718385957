import { Box, Button, MenuItem, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useState } from "react";
import { resetPassword } from "src/api/auth";
import Alert from "src/helpers/alert";
const useStyles = makeStyles((theme) => ({
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: "25ch",
	},
}));
export function ResetPassword({ tenants = [] }) {
	const classes = useStyles();
	const [email, setEmail] = useState(null);
	const [password, setPassword] = useState(null);
	const [tenantId, setTenantId] = useState(null);
	return (
		<Box
			component={"form"}
			display="flex"
			justifyContent={"flex-start"}
			alignItems="center"
			border={"1px solid #c1c1c1"}
			p={2}
			action={"/users/reset-password"}
			style={{ gap: 16 }}
			borderRadius={12}
			method="put"
			onSubmit={async (e) => {
				e.preventDefault();
				try {
					await resetPassword({ email, password });
					Alert.success("Password reset successfully");
					setEmail("");
					setPassword("");
				} catch (err) {
					console.error("Error while resetting password", err);
					Alert.error(err.code === "E_42201" ? "Email doesn't exist" : "Unable to reset password");
				}
			}}
		>
			<h2>Reset Password</h2>
			<TextField
				label={"email"}
				onChange={(event) => setEmail(event.target.value?.toLowerCase())}
				value={email || ""}
				required
				name="email"
				margin="normal"
				size="small"
				variant="outlined"
			/>
			<TextField
				label={"password"}
				onChange={(event) => setPassword(event.target.value)}
				value={password || ""}
				name="password"
				required
				margin="normal"
				size="small"
				variant="outlined"
			/>
			<TextField
				value={tenantId || ""}
				margin="normal"
				className={classes.textField}
				required
				size="small"
				variant="outlined"
				onChange={(e) => {
					setTenantId(+e.target.value);
				}}
				label="Tenant"
				select
			>
				<MenuItem value="">
					<em>None</em>
				</MenuItem>
				{tenants.map((tenant, idx) => {
					return (
						<MenuItem key={`tenant_${idx}_select`} value={tenant.id}>
							{tenant.name}
						</MenuItem>
					);
				})}
			</TextField>
			<Button type={"submit"} variant="contained" color="secondary">
				Reset Password
			</Button>
		</Box>
	);
}
