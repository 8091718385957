import { useSelector } from "react-redux";

export function Information() {
	const { tenant } = useSelector(({ auth }) => auth);
	return (
		<div className="hidden md:flex justify-center flex-col grow shrink-0 basis-5/12 bg-[#728481] text-white px-4 py-8 text-lg shadow-lg overflow-auto">
			<h1 className="text-5xl text-center">
				<span>{tenant?.name || "InvestorMatch.ai™"}</span>
			</h1>
			<p className="text-center mb-4">{tenant.description || "Matching funders to founders"}</p>
			{(tenant.domain.includes("localhost") || tenant.domain.includes("investormatch.ai")) && (
				<>
					<div className="flex justify-center my-4">
						<img
							className="text-center inline-block"
							style={{ filter: "contrast(1.3)" }}
							src="/images/matching.png"
							width={"350px"}
							height={"350px"}
							alt="Matching founders, funders and vendors"
						/>
					</div>
					<ul className="m-0 p-0">
						<li className="rounded-md bg-white py-2 px-4 mb-2 text-[#728481]">✔ Let us know your requirements</li>
						<li className="rounded-md bg-white py-2 px-4 mb-2 text-[#728481]">✔ Get high fidelity matches</li>
						<li className="rounded-md bg-white py-2 px-4 text-[#728481]">
							✔ Save time, money, and make successful connections
						</li>
					</ul>
				</>
			)}
		</div>
	);
}
