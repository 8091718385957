import React, { useState, useCallback } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/styles/makeStyles";
import { alpha } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import IntlMessages from "src/@jumbo/utils/IntlMessages";
import { login } from "src/api/auth";
import { setAuthUser } from "src/store/slices/auth";
import AuthWrapper from "./AuthWrapper";

const useStyles = makeStyles((theme) => ({
	authThumb: {
		backgroundColor: alpha(theme.palette.primary.main, 0.12),
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		padding: 20,
		width: "100%",
		[theme.breakpoints.up("md")]: {
			width: "50%",
			order: 2,
		},
	},
	titleRoot: {
		marginBottom: 10,
		// fontSize: "2rem",
		// color: theme.palette.text.primary,
	},
	textFieldRoot: {
		// "& .MuiOutlinedInput-notchedOutline": {
		// 	borderColor: alpha(theme.palette.common.dark, 0.12),
		// },
	},
	createAccount: {
		padding: "0.5rem 0",
		fontSize: "1.3rem",
	},
	submitProgress: {
		position: "absolute",
		top: 5,
		left: 46,
	},
	formcontrolLabelRoot: {
		"& .MuiFormControlLabel-label": {
			[theme.breakpoints.down("xs")]: {
				fontSize: 12,
			},
		},
	},
}));
//variant = 'default', 'standard'
const SignIn = ({ variant = "default", wrapperVariant = "default" }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { tenant } = useSelector(({ auth }) => auth);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const [isSubmitting, setSubmitting] = useState(false);
	const [error, setError] = useState(null);
	const classes = useStyles({ variant });

	const onSubmit = useCallback(
		async (e) => {
			e.preventDefault();
			setSubmitting(true);

			try {
				setError(null);
				const user = await login({
					email,
					password,
					tenantId: tenant.id,
				});
				dispatch(setAuthUser(user));
				navigate("/dashboard");
			} catch (err) {
				console.error("Error while logging in", err);
				setError(err);
			} finally {
				setSubmitting(false);
			}
		},
		[email, password]
	);

	return (
		<AuthWrapper variant={wrapperVariant}>
			<div className="text-center">
				<p className="text-lg">Don&apos;t have an account?</p>
				<Button
					variant="contained"
					color="primary"
					fullWidth
					className={classes.createAccount + " text-center block"}
					component={NavLink}
					to="/signup"
					underline="always"
				>
					Create Account
				</Button>
			</div>
			<hr className="my-4" />
			<Typography component="h1" variant="h5" align="center" className={classes.titleRoot}>
				Login
			</Typography>
			<form onSubmit={onSubmit}>
				<Box mb={2}>
					<TextField
						label={<IntlMessages id="appModule.email" />}
						fullWidth
						autoFocus
						autoComplete="email"
						onChange={(event) => setEmail(event.target.value?.toLowerCase())}
						defaultValue={email}
						margin="normal"
						variant="outlined"
						className={classes.textFieldRoot}
					/>
				</Box>
				<Box mb={2}>
					<TextField
						type={showPassword ? "text" : "password"}
						label={<IntlMessages id="appModule.password" />}
						fullWidth
						autoComplete="current-password"
						onChange={(event) => setPassword(event.target.value)}
						defaultValue={password}
						margin="normal"
						variant="outlined"
						className={classes.textFieldRoot}
					/>
				</Box>
				<Box mb={2} display="flex" justifyContent={"space-between"} alignItems="center">
					<Link component={NavLink} to="/forgot-password" underline="always">
						<IntlMessages id="appModule.forgotPassword" />
					</Link>
					<FormControlLabel
						control={
							<Checkbox
								checked={showPassword}
								onChange={(e) => {
									setShowPassword(e.target.checked);
								}}
							/>
						}
						label="Show Password"
					/>
				</Box>
				<Box my={4}>
					<Button type="submit" variant="contained" color="primary" fullWidth disabled={isSubmitting}>
						Login
					</Button>
				</Box>
				<Box
					display="flex"
					flexDirection={{ xs: "column", sm: "row" }}
					alignItems={"center"}
					justifyContent={"center"}
					position="relative"
				>
					{isSubmitting && <CircularProgress size={24} variant={"indeterminate"} className={classes.submitProgress} />}
				</Box>
				{error && (
					<Box mb={4}>
						<Alert severity="error">{error}</Alert>
					</Box>
				)}
			</form>
		</AuthWrapper>
	);
};

export default SignIn;
