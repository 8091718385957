// import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useCallback, useEffect, useState } from "react";
import { exportCampaigns, getAllForTenantAdmin } from "src/api/campaigns";
import { debounce } from "src/helpers/debounce";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { Chip } from "src/components/commons/Chip";
import { Alert } from "@material-ui/lab";
const statusMap = { IN_PROGRESS: "In Progress", ACTIVE: "Active", INACTIVE: "Inactive" };

function Row({ row }) {
	const [data] = useState(() => ({ ...row }));

	return (
		<TableRow key={data.id}>
			<TableCell className="pl-4">{data.name}</TableCell>
			<TableCell>{[data.creator.firstName, data.creator.lastName].join(" ")}</TableCell>
			<TableCell>{data.creator.email}</TableCell>
			<TableCell>
				<Chip classes={"text-center"} status={data.status}>
					{statusMap[data.status]}
				</Chip>
			</TableCell>
			<TableCell>{new Intl.DateTimeFormat(navigator.language).format(new Date(data.createdAt))}</TableCell>
			<TableCell>{new Intl.DateTimeFormat(navigator.language).format(new Date(data.updatedAt))}</TableCell>
			<TableCell>
				<div className="flex gap-2">
					<Button color="primary" component={Link} to={`/application/founder/${data.id}`}>
						View
					</Button>
				</div>
			</TableCell>
		</TableRow>
	);
}

export function FounderApplications() {
	const [rows, setRows] = useState([]);
	const [loading, setLoading] = useState(false);
	const [total, setTotal] = useState(-1);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(20);
	const [search, setSearch] = useState("");
	const [deferredSearch, setDeferredSearch] = useState("");
	const [error, setError] = useState(null);
	const [isExporting, setExporting] = useState(false);

	const updateSearch = useCallback(
		debounce(function _updateSearch(search) {
			setDeferredSearch(search);
		}, 500),
		[]
	);
	useEffect(() => {
		updateSearch(search);
	}, [search]);
	useEffect(() => {
		const options = { limit: perPage, page: currentPage, join: "creator" };
		if (search) {
			options.s = JSON.stringify({
				$or: [
					{ name: { $contL: search } },
					{ "creator.firstName": { $contL: search } },
					{ "creator.lastName": { $contL: search } },
					{ "creator.email": { $contL: search } },
				],
			});
		}
		(async () => {
			try {
				setError(null);
				setLoading(true);
				const res = await getAllForTenantAdmin(options);
				setRows(res.data);
				setTotal(res.total);
			} catch (err) {
				console.error("Unable to fetch campaigns", err);
				setError("Unable to load");
			} finally {
				setLoading(false);
			}
		})();
	}, [currentPage, deferredSearch, perPage]);
	return (
		<div>
			<TableContainer className="mt-4" component={Paper}>
				<Box className="flex justify-between items-center p-4">
					<Box className="text-xl flex gap-4">
						Founder Applications
						<Box className="text-xl">
							<Button
								variant="outlined"
								color="primary"
								disabled={isExporting}
								onClick={async () => {
									try {
										setExporting(true);
										const file = await exportCampaigns();
										var a = document.createElement("a");
										a.href = window.URL.createObjectURL(file);
										a.download = `InvestorMatch.ai-Founder-Applications-${new Intl.DateTimeFormat(navigator.language).format(
											new Date()
										)}.csv`;
										a.dispatchEvent(new MouseEvent("click"));
									} catch (err) {
										Alert.error("Unable to complete export");
										console.error("Unable to complete export", err);
									} finally {
										setExporting(false);
									}
								}}
							>
								{isExporting ? "Exporting" : "Export CSV"}
							</Button>
						</Box>
					</Box>

					<Box>
						{loading && "Loading..."}
						{error && <Box className="text-red-600">{error}</Box>}
					</Box>
					<Box>
						<TextField
							fullWidth
							variant="outlined"
							label="Search user / application"
							type="text"
							size="small"
							value={search}
							onChange={(e) => {
								setSearch(e.target.value);
							}}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											onClick={() => {
												setSearch("");
											}}
										>
											<Close />
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</Box>
				</Box>
				<Table aria-label="Applicants table">
					<TableHead>
						<TableRow>
							<TableCell>Application Name</TableCell>
							<TableCell>User Name</TableCell>
							<TableCell>User Email</TableCell>
							<TableCell>Status</TableCell>
							<TableCell>Created At</TableCell>
							<TableCell>Last Updated</TableCell>
							<TableCell style={{ width: 200 }}>Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{error && (
							<TableRow>
								<TableCell colSpan={7}>{error}</TableCell>
							</TableRow>
						)}
						{rows.length <= 0 && (
							<TableRow>
								<TableCell colSpan={7}>{loading ? "Loading..." : "No data"}</TableCell>
							</TableRow>
						)}
						{rows.length > 0 &&
							rows.map((row, idx) => (
								<Row
									key={`row-${row.id || idx}`}
									row={row}
									onDelete={() => {
										const _rows = [...rows];
										_rows.splice(idx, 1);
										setRows(_rows);
									}}
								/>
							))}
					</TableBody>
					<TableFooter>
						<TableRow>
							<TableCell></TableCell>
							<TablePagination
								rowsPerPageOptions={[20, 50, 100]}
								count={total}
								rowsPerPage={perPage}
								page={currentPage - 1}
								SelectProps={{
									inputProps: { "aria-label": "rows per page" },
									native: true,
								}}
								onPageChange={(e, newPage) => {
									setCurrentPage(newPage + 1);
								}}
								onRowsPerPageChange={(e) => {
									setPerPage(parseInt(e.target.value, 10));
									setCurrentPage(1);
								}}
								// ActionsComponent={TablePaginationActions}
							/>
						</TableRow>
					</TableFooter>
				</Table>
			</TableContainer>
		</div>
	);
}
