import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
// import makeStyles from "@material-ui/core/styles/makeStyles";

import { defer, Await, useLoaderData, Link } from "react-router-dom";
import {
	getOpportunitiesWithUserAnswersForTenantAdmin,
	getOpportunityWithUserAnswersForAdmin,
} from "src/api/opportunities";
import { Suspense } from "react";
import { Main } from "src/components/commons/Main";
import { DisplayUserAnswers } from "src/components/commons/DisplayUserAnswers";
import { FunderProfileDisplay } from "src/components/commons/FunderProfileDisplay";

export function AdminViewOpportunityDetail({ tenant }) {
	const { data } = useLoaderData();

	return (
		<>
			<Breadcrumbs separator="›" aria-label="breadcrumb" style={{ marginBottom: 16 }}>
				<Link to={tenant ? "/applications/funders" : "/admin/opportunities"}>
					<Typography color="textSecondary">All {tenant ? "Funder Applications" : "Opportunities"}</Typography>
				</Link>
				<Typography color="textPrimary">Detail</Typography>
			</Breadcrumbs>
			<Main>
				<Box
					display={"flex"}
					alignItems={"center"}
					justifyContent="space-between"
					p={4}
					borderBottom={"1px solid rgb(239 232 251)"}
				>
					<Typography component={"h2"} variant="h6">
						Detail
					</Typography>
				</Box>
				<Suspense
					fallback={
						<Box height={"100%"} display="flex" justifyContent={"center"} alignItems={"center"}>
							<CircularProgress />
						</Box>
					}
				>
					<Await
						resolve={data}
						errorElement={
							<Box padding={4} textAlign="center">
								Could not load the details
							</Box>
						}
						children={({ opportunity }) => {
							return (
								<Box p={4}>
									<Typography component={"h3"} variant="h5">
										{opportunity.name}
									</Typography>
									<h3 className="text-lg py-2 px-4 bg-slate-200 mt-4">Application Creator Details</h3>
									<FunderProfileDisplay
										userDetails={{ ...opportunity.creator, details: opportunity.creator.profile }}
									/>
									<DisplayUserAnswers
										id={`opportunity-${opportunity.id}`}
										isAdmin={true}
										type="opportunity"
										answers={opportunity.userAnswers}
									/>
								</Box>
							);
						}}
					/>
				</Suspense>
			</Main>
		</>
	);
}

export function adminViewOpportunityDetailLoader({ params }) {
	const promise = Promise.all([getOpportunityWithUserAnswersForAdmin(params.id)]).then(([opportunity]) => {
		return {
			opportunity,
		};
	});
	return defer({
		data: promise,
	});
}

export function tenantAdminViewOpportunityDetailLoader({ params }) {
	const promise = Promise.all([getOpportunitiesWithUserAnswersForTenantAdmin(params.id)]).then(([opportunity]) => {
		return {
			opportunity,
		};
	});
	return defer({
		data: promise,
	});
}
