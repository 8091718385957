import Box from "@material-ui/core/Box";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "@material-ui/core/Modal";
import CloudDoneIcon from "@material-ui/icons/CloudDone";
import { Suspense, useState } from "react";
import { Await, defer, Link, useLoaderData, useParams } from "react-router-dom";

import { getById as getCampaignById, launch as launchCampaign } from "src/api/campaigns";
import { getById as getOpportunityById, launch as launchOpportunity } from "src/api/opportunities";
import { getById as getServiceById, launch as launchService } from "src/api/vendorServices";
import { getAllWithAnswers } from "src/api/questions";
import { getAll as getAllUserAnswers } from "src/api/userAnswers";
import { Details as DetailsForm } from "src/components/campaigns/Details";
import { ContextProvider } from "src/components/campaigns/Details/Context";
import { Main } from "src/components/commons/Main";
import Alert from "src/helpers/alert";
import { ONBOARDING_STEPS, STATUS } from "src/helpers/constants";
import { useDispatch, useSelector } from "react-redux";
import { updateUserOnboarding } from "src/store/slices/auth";
import { getTenantRules } from "src/api/tenantQuestionRules";
// import { useSelector } from "react-redux";

// const encouragements = [
// 	"😎 You are doing great!",
// 	"💪 You got this!",
// 	"💯 You are rocking it!",
// 	"👍 You're doing an amazing job!",
// 	"👊 You're killing it!",
// 	"💥 You're absolutely crushing it!",
// 	"🔥 You're on fire!",
// 	"🥇 You're knocking it out of the park!",
// ];

const useStyles = makeStyles((theme) => {
	return {
		header: {
			position: "sticky",
			top: 64,
			zIndex: 2,
			background: theme.palette.common.white,
			borderRadius: "12px 12px 0 0",
			borderBottom: "1px solid rgb(239 232 251)",
			"& > *": {
				flex: 1,
			},
		},
		breadcrumb: {
			marginBottom: theme.spacing(4),
		},
	};
});

const typeMap = {
	campaign: {
		list: "/campaigns",
		title: "Campaigns",
	},
	opportunity: {
		list: "/opportunities",
		title: "Opportunities",
	},
	service: {
		list: "/services",
		title: "Services",
	},
};

const categories = {
	campaign: "CAMPAIGN",
	opportunity: "OPPORTUNITY",
	service: "VENDOR",
};

export function Details() {
	const classes = useStyles();
	const { authUser, currentRole } = useSelector(({ auth }) => auth);
	const { id: entityId, type } = useParams();
	const { questionsAndUserAnswers, entity } = useLoaderData();
	const [showConfirm, setShowConfirm] = useState(false);
	const [isLaunchModalOpen, setLaunchModalOpen] = useState(false);
	const dispatch = useDispatch();

	async function launch(entity) {
		try {
			let response,
				status = false,
				completedStep = ONBOARDING_STEPS.CAMPAIGN;
			if (type === "campaign") {
				response = await launchCampaign(entity.id);
				status = response.campaign.status === "ACTIVE";
			} else if (type === "opportunity") {
				response = await launchOpportunity(entity.id);
				status = response.opportunity.status === "ACTIVE";
				completedStep = ONBOARDING_STEPS.OPPORTUNITY;
			} else if (type === "service") {
				response = await launchService(entity.id);
				status = response.vendorService.status === "ACTIVE";
				completedStep = ONBOARDING_STEPS.SERVICE;
			}
			if (!authUser.onboarding[currentRole].isCompleted) {
				await dispatch(
					updateUserOnboarding({ completedStep, isCompleted: type == "opportunity" || type === "service" })
				);
			}
			if (status) {
				setLaunchModalOpen(true);
			} else {
				Alert.error(`Couldn't launch ${type}`);
			}
		} catch (err) {
			console.error(`Error while launching ${type}`, err);
			if (err.code === "CN004") {
				Alert.error("Questions are not answered correctly. Please fix them and then launch");
				return;
			}
			Alert.error(err.message);
			throw err;
		}
	}

	return (
		<>
			<Breadcrumbs separator="›" aria-label="breadcrumb" className={classes.breadcrumb}>
				<Link to={typeMap[type].list}>
					<Typography color="textSecondary">My {typeMap[type].title}</Typography>
				</Link>
				<Typography color="textPrimary">Details</Typography>
			</Breadcrumbs>
			<Main>
				<Suspense
					fallback={
						<Box height={"100%"} display="flex" justifyContent={"center"} alignItems={"center"}>
							<CircularProgress />
						</Box>
					}
				>
					<Await
						resolve={questionsAndUserAnswers}
						errorElement={
							<Box padding={4} textAlign="center">
								Oops... there was an error
							</Box>
						}
					>
						{({ questions, userAnswers, tenantQuestionRules }) => (
							<ContextProvider
								userAnswers={userAnswers}
								questions={questions}
								tenantQuestionRules={tenantQuestionRules}
								category={categories[type]}
								entityId={entityId}
								shouldAutoSave={true}
								hasLaunchBtn={true}
							>
								{({ validate, save, saveState }) => {
									return (
										<>
											<Box
												display={"flex"}
												alignItems={"center"}
												justifyContent="space-between"
												p={4}
												className={classes.header}
											>
												<Typography component={"h2"} variant="h6">
													{entity.name}{" "}
												</Typography>
												{/* {steps[currentStep] && (
													<Box display={"flex"} justifyContent="center" gridGap={5}>
														{steps[currentStep].group}
													</Box>
												)} */}
												<Box display={"flex"} justifyContent="end" gridGap={5} alignItems={"end"}>
													<Box
														component={"span"}
														fontSize={12}
														marginLeft={4}
														color={saveState === STATUS.DONE ? "green" : "#aaaaaa"}
													>
														<CloudDoneIcon viewBox={"0 8 24 12"} style={{ width: 16, marginRight: 4 }} />
														{saveState === STATUS.DONE
															? "Saved"
															: saveState === STATUS.INPROGRESS
															? "Saving..."
															: "Auto-Save ON"}
													</Box>
													<Button
														color="secondary"
														variant="contained"
														size="small"
														onClick={() => {
															const { isValid } = validate();
															if (isValid) {
																setShowConfirm(true);
															} else {
																Alert.error("Please fix all errors and launch again");
															}
														}}
													>
														🚀 Launch
													</Button>
												</Box>
											</Box>
											<Box px={4} pt={4} display="flex" flexDirection="column">
												<DetailsForm showConfirm={setShowConfirm} />
											</Box>
											<Modal
												open={showConfirm}
												// maxWidth="xs"
												onClose={() => setShowConfirm(false)}
												aria-labelledby="modal-title"
												aria-describedby="modal-description"
											>
												<Box
													maxWidth={"100%"}
													width="400px"
													position={"absolute"}
													bgcolor="background.paper"
													borderRadius={12}
													padding={4}
													className={"site-modal"}
												>
													<Box component={"h2"} textAlign="center" mb={2} id="modal-title">
														{entity.status === "ACTIVE" ? "Save" : "Launch"} {type}
													</Box>
													<Typography component={"p"} align="center" variant="h3">
														🚀
													</Typography>
													<Box component={"p"} textAlign={"center"} py={2} id="modal-description">
														Great Job. Let&apos;s hit the button!
													</Box>
													<Box display={"flex"} justifyContent="flex-end" style={{ gap: 16 }} marginTop={4}>
														<Button type="button" onClick={() => setShowConfirm(false)}>
															Close
														</Button>
														<Button
															type={"button"}
															color="secondary"
															variant="contained"
															onClick={async () => {
																try {
																	await save();
																	if (entity.status !== "ACTIVE") {
																		await launch(entity);
																	}
																	setLaunchModalOpen(true);
																} catch (err) {
																	console.error("Submit failed", err);
																} finally {
																	setShowConfirm(false);
																}
															}}
														>
															Confirm
														</Button>
													</Box>
												</Box>
											</Modal>
											<Modal
												open={isLaunchModalOpen}
												onClose={() => setLaunchModalOpen(false)}
												aria-labelledby="modal-title"
												aria-describedby="modal-description"
											>
												<Box
													maxWidth={"100%"}
													width="400px"
													position={"absolute"}
													bgcolor="background.paper"
													borderRadius={12}
													padding={4}
													className={"site-modal text-lg"}
												>
													<Box component={"h2"} textAlign="center" mb={2} id="modal-title">
														Congrats!
													</Box>
													<Typography component={"p"} align="center" variant="h3">
														🎉
													</Typography>
													<Box component={"p"} textAlign="center" py={2} id="modal-description" className="text-lg">
														The {type} has been launched successfully. Our algorithms are hard at work finding the
														matches. Your matches will be in your dashboard and we will send you an email notification.
														Look forward to hearing that you&apos;re successfully funded. - The InvestorMatch.ai Team
													</Box>
													<Box display={"flex"} justifyContent="flex-end">
														<Button type="button" onClick={() => setLaunchModalOpen(false)}>
															Close
														</Button>
														<Button component={Link} to="/dashboard" color="primary">
															Go to Dashboard
														</Button>
													</Box>
												</Box>
											</Modal>
										</>
									);
								}}
							</ContextProvider>
						)}
					</Await>
				</Suspense>
			</Main>
		</>
	);
}

export async function detailsLoader({ params }) {
	const type = params.type;
	if (type !== "campaign" && type !== "opportunity" && type !== "service") {
		throw new Response(`Invalid type ${type}`, { status: 404 });
	}
	const getFns = {
		campaign: getCampaignById,
		opportunity: getOpportunityById,
		service: getServiceById,
	};
	const entity = await getFns[type](params.id);
	const promise = Promise.all([
		getAllWithAnswers({ category: categories[params.type].toUpperCase() }),
		getAllUserAnswers({
			filter: `${type === "service" ? "vendorService" : type}Id||$eq||${entity.id}`,
			per_page: 15000,
		}),
		type === "campaign" ? getTenantRules() : []
	]).then(([questions, userAnswers, tenantQuestionRules]) => {
		return {
			questions,
			userAnswers,
			tenantQuestionRules
		};
	});
	return defer({
		entity,
		questionsAndUserAnswers: promise,
	});
}
