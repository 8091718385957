import { useEffect } from "react";
import { createContext, useCallback, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { bulkUpsert } from "src/api/userAnswers";
import { mergeDeep } from "src/helpers";
import Alert from "src/helpers/alert";
import { QUESTION_TYPES, STATUS } from "src/helpers/constants";
import { debounce } from "src/helpers/debounce";
import getByPath from "src/helpers/getByPath";
import { isValidNumber, isValidPhone, isValidURL, isValidEmail } from "src/helpers/inputValidation";
import setByPath from "src/helpers/setByPath";
import { localstorage } from "src/lib/storage";
import { execute } from "./contextOperations";

export const Context = createContext();

const entityProperties = {
	CAMPAIGN: "campaignId",
	OPPORTUNITY: "opportunityId",
	VENDOR: "vendorServiceId",
};

export function ContextProvider({
	children,
	userAnswers = [],
	questions = [],
	tenantQuestionRules = [],
	category,
	shouldAutoSave = false,
	saveFn,
	entityId,
	hasLaunchBtn = false,
}) {
	const { authUser } = useSelector(({ auth }) => auth);
	const [currentStep, setCurrentStep] = useState(0);
	const [userQuestionAnswerMap, setUserQuestionAnswerMap] = useState({}); //{qid: {answer: "", answerIds: [], meta: {other: true}}}
	const [isPreparing, setPreparing] = useState(true);
	const [steps, setSteps] = useState([]);
	const [errors, setErrors] = useState({});
	const [isSaving, setSaving] = useState(false);
	const [saveState, setSaveState] = useState(STATUS.UNCHANGED);
	const stepQuestionLabelMap = useRef({});
	const id2questionMap = useRef(new Map());
	const hiddenQuestionCriteriaMap = useRef({}); // {targetQuestionId: {criteriaQuestionId: {answerIds: [], otherRules if needed}}
	const hiddenStepCriteriaMap = useRef(new Map()); // {targetStepIndex: {criteriaQuestionId: {answerIds: [], otherRules if needed}}
	const hiddenQuestionTenantCriteriaMap = useRef(new Map()); // {targetQuestionId: {criteriaQuestionId: {answerIds: [], otherRules if needed}}
	const hiddenStepTenantCriteriaMap = useRef(new Map()); // {targetStepIndex: {criteriaQuestionId: {answerIds: [], otherRules if needed}}
	const entity = category?.toLowerCase();
	const entityProperty = entityProperties[category];
	const latestServerUserAnswers = useRef([...userAnswers]);

	const autoSave = useCallback(
		debounce(function _autoSave(userQuestionAnswerMap) {
			save(userQuestionAnswerMap);
		}, 2000),
		[]
	);

	useEffect(() => {
		const userAnswers = localstorage.getItem(`${entity}_${entityId || ""}`);
		let parsedUserAnswers = {};
		if (userAnswers) {
			parsedUserAnswers = JSON.parse(userAnswers);
		}
		setUserQuestionAnswerMap(parsedUserAnswers);
		prepareSteps(parsedUserAnswers);
		setPreparing(false);
	}, [entityId]);

	const prepareSteps = useCallback(
		function _prepareSteps(userQuestionAnswerMap) {
			const labelQuestionMap = {},
				questionIdMap = new Map(),
				questionIdDefaultAnswerMap = new Map();
			tenantQuestionRules.forEach((rule) => {
				if (rule.questionId) {
					hiddenQuestionTenantCriteriaMap.current.set(rule.questionId, rule);
				}
				if (rule.step !== null) {
					hiddenStepTenantCriteriaMap.current.set(rule.step, rule);
				}
			});
			const groups = questions.reduce((acc, question) => {
				acc[question.group] = {
					group: question.group,
					step: question.meta.step,
					questions: acc[question.group]?.questions || [],
				};
				acc[question.group].questions.push(question);
				if (question.meta.label) {
					labelQuestionMap[question.meta.label] = question.id;
				}
				return acc;
			}, {});
			const steps = Object.values(groups)
				.map((step, idx) => {
					step.questions = step.questions
						.map((question, qIdx) => {
							questionIdMap.set(question.id, question);
							if (question.meta.label) {
								stepQuestionLabelMap.current[question.meta.label] = {
									step: idx,
									questionId: question.id,
									questionIndex: qIdx,
								};
							}
							if (question.meta.defaultValue !== undefined && question.answers.length === 0) {
								questionIdDefaultAnswerMap.set(question.id, {
									questionId: question.id,
									answer: question.meta.defaultValue,
								});
							}
							if (question.meta.defaultValue === undefined && question.type === QUESTION_TYPES.PHONE) {
								questionIdDefaultAnswerMap.set(question.id, {
									questionId: question.id,
									answer: JSON.stringify({ countryCode: "1", phoneNumber: "" }),
								});
							}
							if (question.type === QUESTION_TYPES.SORTABLE) {
								questionIdDefaultAnswerMap.set(question.id, {
									questionId: question.id,
									answer: JSON.stringify(question.answers[0].answer.split("\n")),
								});
							}
							question.answers.forEach((answer) => {
								if (question.meta.defaultValue !== undefined && Number(question.meta.defaultValue) === answer.id) {
									questionIdDefaultAnswerMap.set(question.id, answer);
								}
								if (answer.meta.action?.deselectAll) {
									question.hasDeselectAll = true;
								}
								if (answer.meta.action?.selectAll) {
									question.hasSelectAll = true;
								}
								if (answer.meta.hideSteps?.length > 0) {
									answer.meta.hideSteps.forEach((step) => {
										const stepCriteria = hiddenStepCriteriaMap.current.get(step);
										if (stepCriteria) {
											const stepCriteriaQuestion = stepCriteria.get(question.id);
											if (stepCriteriaQuestion) {
												stepCriteriaQuestion.answerIds.push(answer.id);
											} else {
												stepCriteria.set(question.id, { answerIds: [answer.id] });
											}
										} else {
											hiddenStepCriteriaMap.current.set(step, new Map([[question.id, { answerIds: [answer.id] }]]));
										}
									});
								}
								if (answer.meta.display?.length > 0) {
									answer.meta.display.forEach((label) => {
										const targetQuestionId = labelQuestionMap[label];
										if (!targetQuestionId) {
											console.warn("Label	with no target question id", label);
										}
										hiddenQuestionCriteriaMap.current[targetQuestionId] = hiddenQuestionCriteriaMap.current[
											targetQuestionId
										] || { [question.id]: { answerIds: [] } };

										hiddenQuestionCriteriaMap.current[targetQuestionId][question.id] = hiddenQuestionCriteriaMap
											.current[targetQuestionId][question.id] || { answerIds: [] };

										try {
											hiddenQuestionCriteriaMap.current[targetQuestionId][question.id].answerIds.push(answer.id);
										} catch (err) {
											console.warn(
												err,
												{ label, question, targetQuestionId },
												`There is no target question with label ${label} of answer ${answer.id}`
											);
											// throw err;
										}
									});
								}
							});
							question.answers = question.answers.sort((a, b) => a.order - b.order);
							return question;
						})
						.sort((a, b) => a.order - b.order);
					return step;
				})
				.sort((a, b) => {
					return a.step - b.step;
				});
			setSteps(steps);
			const userQAMap = userAnswers.reduce(
				(acc, answer) => {
					acc[answer.questionId] = acc[answer.questionId] || { answerIds: [], meta: {} };
					if (answer.answerId && !acc[answer.questionId].answerIds.includes(answer.answerId)) {
						acc[answer.questionId].answerIds.push(answer.answerId);
					}
					const question = questionIdMap.get(answer.questionId);
					if (!question) {
						return acc;
					}
					const questionType = question.type;
					if (questionType === QUESTION_TYPES.MULTI_LEVEL_CHOICE && answer.response) {
						try {
							acc[answer.questionId].answer = JSON.parse(answer.response);
						} catch (err) {
							console.error(`Error while parsing ${questionType} response`, err, answer.response);
						}
					} else if (questionType === QUESTION_TYPES.NAME || questionType === QUESTION_TYPES.PHONE) {
						try {
							acc[answer.questionId].answer = JSON.parse(answer.response || "{}");
						} catch (err) {
							console.error(`Error while parsing ${questionType} response`, err, answer.response);
						}
					} else {
						acc[answer.questionId].answer = answer.response;
					}
					return acc;
				},
				{ ...userQuestionAnswerMap }
			);
			for (const answer of questionIdDefaultAnswerMap.values()) {
				const ansObj = userQAMap[answer.questionId] || { answerIds: [], meta: {} };
				if (answer.id && ansObj.answerIds.length === 0) {
					ansObj.answerIds.push(answer.id);
					userQAMap[answer.questionId] = ansObj;
				} else if (!answer.id && !userQAMap[answer.questionId]?.answer) {
					ansObj.answer = answer.answer;
					userQAMap[answer.questionId] = ansObj;
				}
			}
			if (userAnswers.length) {
				setUserQuestionAnswerMap(userQAMap);
			}
			id2questionMap.current = questionIdMap;
		},
		[questions, userAnswers]
	);

	function ifContextAllows(question) {
		if (question.meta.contextRules?.length > 0) {
			const context = { user: authUser };
			return question.meta.contextRules.every((rule) => {
				return execute(getByPath(context, rule.context.split(".")) || "", rule.operator, rule.value);
			});
		}
		return true;
	}

	const canDisplayQuestion = useCallback(
		function _canDisplayQuestion(question, userQAMap) {
			if (!question) {
				// eslint-disable-next-line no-debugger
				debugger;
			}
			if (hiddenQuestionTenantCriteriaMap.current.get(question.id)?.action === "HIDE") {
				return false;
			}
			const _uQAMap = userQAMap || userQuestionAnswerMap;
			const criteria = hiddenQuestionCriteriaMap.current[question.id + ""];
			if (question.meta.display === false && criteria) {
				const didCriteriaMatch = Object.keys(criteria).some((key) => {
					const criteriaQuestionId = key;
					// Prev question is not visible - hide
					if (!canDisplayQuestion(id2questionMap.current.get(+criteriaQuestionId), _uQAMap)) {
						return false;
					}
					const criteriaAnswerIds = criteria[key].answerIds;
					const userQuestionAnswerIds = _uQAMap[criteriaQuestionId + ""]?.answerIds || [];
					if (userQuestionAnswerIds.length > 0) {
						return !!userQuestionAnswerIds.find((answerId) => criteriaAnswerIds.includes(answerId));
					}
					return false;
				});
				return ifContextAllows(question) && didCriteriaMatch;
			}
			return ifContextAllows(question);
		},
		[userQuestionAnswerMap]
	);

	const canDisplayStep = useCallback(
		(stepIndex) => {
			if (hiddenStepTenantCriteriaMap.current.get(stepIndex)?.action === "HIDE") {
				return false;
			}
			const stepCriteria = hiddenStepCriteriaMap.current.get(stepIndex);
			if (stepCriteria) {
				for (const [questionId, criteria] of stepCriteria) {
					const criteriaAnswerIds = criteria.answerIds;
					const userQuestionAnswerIds = userQuestionAnswerMap[questionId + ""]?.answerIds || [];
					if (userQuestionAnswerIds.find((answerId) => criteriaAnswerIds.includes(answerId))) {
						return false;
					}
				}
			}
			return true;
		},
		[userQuestionAnswerMap]
	);

	const onChange = useCallback(
		({ question, answerObject, answerText, isRemove }) => {
			const questionId = question.id;
			if (answerText !== undefined) {
				const _userQuestionAnswerMap = {
					...userQuestionAnswerMap,
					[questionId]: {
						answer: answerText,
						answerIds:
							question.type === QUESTION_TYPES.RADIO || question.type !== QUESTION_TYPES.MULTIPLE_CHOICE
								? []
								: [...(userQuestionAnswerMap[questionId + ""]?.answerIds || [])],
					},
				};
				if (answerObject) {
					if (isRemove === true) {
						_userQuestionAnswerMap[questionId].answerIds = _userQuestionAnswerMap[questionId].answerIds.filter(
							(id) => answerObject.id !== id
						);
					} else {
						_userQuestionAnswerMap[questionId].answerIds.push(answerObject.id);
					}
				}
				setUserQuestionAnswerMap(_userQuestionAnswerMap);
				onChangeDone(question, _userQuestionAnswerMap);
				return;
			}
			if (!answerObject) {
				return;
			}
			let answerIds = userQuestionAnswerMap[questionId + ""]?.answerIds || [];
			answerText = userQuestionAnswerMap[questionId]?.answer;
			if (
				question.hasDeselectAll &&
				(answerIds.length || (answerText || "").trim()) &&
				!answerObject.meta.action?.deselectAll
			) {
				const deselectAllAnswerObjectIds = question.answers.reduce((acc, answer) => {
					if (answer.meta.action?.deselectAll) {
						acc.push(answer.id);
					}
					return acc;
				}, []);
				answerIds = answerIds.filter((id) => !deselectAllAnswerObjectIds.includes(id));
				// answerText = "";
			}
			if (question.hasSelectAll && isRemove && answerIds.length && !answerObject.meta.action?.selectAll) {
				const selectAllAnswerObject = question.answers.find((answer) => answer.meta.action?.selectAll);
				answerIds = answerIds.filter((id) => selectAllAnswerObject.id !== id);
			}
			if (answerObject.meta.action?.deselectAll) {
				answerIds = isRemove ? [] : [answerObject.id];
				answerText = "";
			} else if (answerObject.meta.action?.selectAll) {
				answerIds = question.answers.map((answer) => answer.id);
			} else if ([QUESTION_TYPES.RADIO, QUESTION_TYPES.SELECT, QUESTION_TYPES.AUTOCOMPLETE].includes(question.type)) {
				answerIds = [answerObject.id];
			} else if (
				isRemove === true &&
				(question.type === QUESTION_TYPES.MULTIPLE_CHOICE || question.type === QUESTION_TYPES.MULTI_LEVEL_CHOICE)
			) {
				answerIds = answerIds.filter((id) => answerObject.id !== id);
			} else {
				answerIds = [...answerIds, answerObject.id];
			}
			const map = { ...userQuestionAnswerMap };
			map[questionId] = {
				answer: question.type === QUESTION_TYPES.RADIO ? undefined : answerText,
				answerIds,
			};
			setUserQuestionAnswerMap(map);
			onChangeDone(question, map);
		},
		[questions, steps, userQuestionAnswerMap, autoSave, shouldAutoSave, errors]
	);

	useEffect(() => {
		if (Object.keys(userQuestionAnswerMap).length) {
			localstorage.setItem(`${entity}_${entityId || ""}`, JSON.stringify(userQuestionAnswerMap));
		}
	}, [userQuestionAnswerMap]);

	function validateEachQuestion(question, errors, _userQuestionAnswerMap) {
		const wasQuestionDisplayed = canDisplayQuestion,
			wasStepDisplayed = canDisplayStep,
			userQAMap = _userQuestionAnswerMap || userQuestionAnswerMap;
		const ansObj = userQAMap[question.id + ""] || {};
		let { answer = "", answerIds = [] } = ansObj;
		if (answer && typeof answer !== "string") {
			answer = JSON.stringify(answer);
		}
		const displayed =
			wasStepDisplayed(question.meta.step) &&
			(question.meta.display !== false || (question.meta.display === false && wasQuestionDisplayed(question)));
		if (!displayed || (question.meta.optional && !answer?.trim() && answerIds.length <= 0)) {
			return true;
		}
		if (!question.meta.optional && !answer?.trim() && answerIds.length <= 0) {
			setByPath(errors, ["step" + question.meta.step, "question" + question.id], "Please fill this");
			return false;
		}
		if (question.type === QUESTION_TYPES.URL && !isValidURL(answer)) {
			setByPath(
				errors,
				["step" + question.meta.step, "question" + question.id],
				answer.includes("http") ? "Invalid URL" : "Must include https:// or http://"
			);
			return false;
		} else if (question.type === QUESTION_TYPES.EMAIL && !isValidEmail(answer)) {
			setByPath(errors, ["step" + question.meta.step, "question" + question.id], "Invalid Email");
			return false;
		} else if (question.type === QUESTION_TYPES.PHONE) {
			let phone = answer;
			try {
				phone = JSON.parse(answer);
			} catch (err) {
				console.warn("Parse failed with phone", err, answer);
				phone = {};
			}
			if (question.meta.optional && !phone.phoneNumber) {
				return true;
			}
			if (!isValidPhone("+" + phone.countryCode + phone.phoneNumber)) {
				setByPath(errors, ["step" + question.meta.step, "question" + question.id], "Invalid Phone Number");
				return false;
			}
		} else if (question.type === QUESTION_TYPES.NUMBER && !isValidNumber(answer)) {
			setByPath(errors, ["step" + question.meta.step, "question" + question.id], "Invalid Number");
			return false;
		} else if (question.type === QUESTION_TYPES.NUMBER && isValidNumber(answer)) {
			if (question.meta.min > 0 && +answer < question.meta.min) {
				setByPath(
					errors,
					["step" + question.meta.step, "question" + question.id],
					`Should be greater than ${question.meta.min}`
				);
				return false;
			}
			if (question.meta.max > 0 && +answer > question.meta.max) {
				setByPath(
					errors,
					["step" + question.meta.step, "question" + question.id],
					`Should be less than ${question.meta.max}`
				);
				return false;
			}
		} else if (question.type === QUESTION_TYPES.RANGE) {
			const [min, max] = answer.split("-");
			if (!(min || "").trim() && !isValidNumber(min)) {
				setByPath(errors, ["step" + question.meta.step, "question" + question.id], "Invalid minimum value");
				return false;
			}
			if (!(max || "").trim() && !isValidNumber(max)) {
				setByPath(errors, ["step" + question.meta.step, "question" + question.id], "Invalid maximum value");
				return false;
			}
			if (+min > +max) {
				setByPath(
					errors,
					["step" + question.meta.step, "question" + question.id],
					"Minimum value should be greater than maximum value"
				);
				return false;
			}
		} else if (question.type === QUESTION_TYPES.NAME) {
			if (question.meta.optional) {
				return true;
			}
			let name = answer;
			try {
				name = JSON.parse(answer);
			} catch (err) {
				console.warn("Parse failed with name", err, answer);
				name = {};
			}
			if (!name.firstname?.trim() || !name.lastname?.trim()) {
				setByPath(
					errors,
					["step" + question.meta.step, "question" + question.id],
					"Title, first name and last name are mandatory"
				);
				return false;
			}
		} else if (question.type === QUESTION_TYPES.COMBO) {
			if (question.meta.optional) {
				return true;
			}
			let answers = answer;
			try {
				answers = JSON.parse(answer);
			} catch (err) {
				console.warn("Parse failed with combo", err, answer);
				answers = question.meta.multiple ? [] : {};
			}
			let failedField = null;
			if (question.meta.multiple) {
				answers = Array.isArray(answers) ? answers : [answers]; //backward compatibility for combo changed to multiple
				failedField = answers.find((answer) => {
					return !!question.meta.comboFields.find((field) => {
						if (field.optional !== true && !answer[field.fieldName]) {
							return true;
						}
					});
				});
			} else {
				failedField = question.meta.comboFields.find((field) => {
					if (field.optional !== true && !answers[field.fieldName]) {
						return true;
					}
				});
			}
			if (failedField) {
				setByPath(errors, ["step" + question.meta.step, "question" + question.id], "Please fill all fields");
				return false;
			}
			if (question.meta.min > 0 && answers.length < question.meta.min) {
				setByPath(
					errors,
					["step" + question.meta.step, "question" + question.id],
					`Add at least ${question.meta.min} answers`
				);
				return false;
			}
			if (question.meta.max > 0 && answers.length > question.meta.max) {
				setByPath(
					errors,
					["step" + question.meta.step, "question" + question.id],
					`Only ${question.meta.max} answers are allowed`
				);
				return false;
			}
		} else if (
			(question.type === QUESTION_TYPES.TEXT || question.type === QUESTION_TYPES.FREE_TEXT) &&
			(question.meta.min > 0 || question.meta.max > 0)
		) {
			const length = answer?.trim().length;
			if (question.meta.min !== undefined && length < question.meta.min) {
				setByPath(
					errors,
					["step" + question.meta.step, "question" + question.id],
					`Answer must be at least ${question.meta.min} characters`
				);
				return false;
			}
			if (question.meta.max > 0 && length > question.meta.max) {
				setByPath(
					errors,
					["step" + question.meta.step, "question" + question.id],
					`Answer must not exceed ${question.meta.max} characters`
				);
				return false;
			}
		} else if (question.type === QUESTION_TYPES.MULTIPLE_CHOICE) {
			if (question.meta.min !== undefined && answerIds.length < question.meta.min) {
				setByPath(
					errors,
					["step" + question.meta.step, "question" + question.id],
					`Select at least ${question.meta.min} options`
				);
				return false;
			}
			if (question.meta.max > 0 && answerIds.length > question.meta.max) {
				setByPath(
					errors,
					["step" + question.meta.step, "question" + question.id],
					`At max only ${question.meta.max} options are allowed`
				);
				return false;
			}
		} else if (question.meta.multiple && question.type === QUESTION_TYPES.AUTOCOMPLETE) {
			let answers = answer;
			try {
				answers = JSON.parse(answer);
			} catch (err) {
				console.warn("Parse failed multiple auto complete", err, answer);
				answers = [answer];
			}
			if (question.meta.min > 0 && answers.length < question.meta.min) {
				setByPath(
					errors,
					["step" + question.meta.step, "question" + question.id],
					`Select at least ${question.meta.min} options`
				);
				return false;
			}
			if (question.meta.max > 0 && answers.length > question.meta.max) {
				setByPath(
					errors,
					["step" + question.meta.step, "question" + question.id],
					`At max only ${question.meta.max} options are allowed`
				);
				return false;
			}
		} else if (question.type === QUESTION_TYPES.TAGS) {
			if (question.meta.optional) return true;
			let answers = answer;
			try {
				answers = JSON.parse(answer);
			} catch (err) {
				console.warn("Parse failed multiple tags", err, answer);
				answers = [[]];
			}
			return answers.flat(3).length > 0;
		}
		return true;
	}

	function onChangeDone(question, userQuestionAnswerMap) {
		setErrors((errors) => {
			const _err = {};
			if (
				errors["step" + question.meta.step] &&
				errors["step" + question.meta.step]["question" + question.id] &&
				validateEachQuestion(question, _err, userQuestionAnswerMap)
			) {
				const _errors = { ...errors };
				delete _errors["step" + question.meta.step]["question" + question.id];
				return _errors;
			} else {
				return mergeDeep(errors, _err);
			}
		});
		if (shouldAutoSave === true) {
			autoSave(userQuestionAnswerMap);
		}
	}

	function validate() {
		let errors = {};
		questions.forEach((question) => {
			validateEachQuestion(question, errors);
		});
		const keys = Object.keys(errors);
		const keysCount = keys.length;
		if (keysCount > 0) {
			setCurrentStep(+keys[0].replace("step", ""));
			const firstQuestion = Object.keys(errors[keys[0]])[0];
			setTimeout(() => {
				const dom = document.querySelector(`#question-title-${firstQuestion.replace("question", "")}`);
				dom && document.scrollingElement.scrollTo(0, dom.offsetTop - 130);
			}, 500);
		}
		setErrors(errors);
		return { isValid: keysCount <= 0, errors: keysCount ? errors : {}, userAnswers };
	}

	function validateStep(step) {
		let errors = {};
		steps[step].questions.forEach((question) => {
			validateEachQuestion(question, errors);
		});
		const keys = Object.keys(errors);
		const keysCount = keys.length;
		if (keysCount > 0) {
			setCurrentStep(+keys[0].replace("step", ""));
			const firstQuestion = Object.keys(errors[keys[0]])[0];
			setTimeout(() => {
				const dom = document.querySelector(`#question-title-${firstQuestion.replace("question", "")}`);
				dom && document.scrollingElement.scrollTo(0, dom.offsetTop - 130);
			}, 500);
		}
		setErrors(errors);
		return { isValid: keysCount <= 0, errors: keysCount ? errors : {}, userAnswers };
	}

	const prepareUserAnswers = useCallback(
		function _prepareUserAnswers(userQuestionAnswerMap) {
			const _userAnswers = [];
			const userAnswersQAIdMap = latestServerUserAnswers.current.reduce((acc, { questionId, answerId, id }) => {
				acc.set(`${questionId}_${answerId || ""}`, id);
				return acc;
			}, new Map());
			const _uQAMap = { ...userQuestionAnswerMap };
			questions.forEach((question) => {
				const ansObj = userQuestionAnswerMap[question.id + ""] || {};
				const displayed =
					canDisplayStep(question.meta.step) &&
					(question.meta.display !== false ||
						(question.meta.display === false && canDisplayQuestion(question, _uQAMap)));
				// remove those that are not displayed from userQuestionAnswerMap
				if (!displayed && _uQAMap[question.id + ""]) {
					delete _uQAMap[question.id + ""];
					return;
				}
				let { answer = "", answerIds = [] } = ansObj;
				if (answer && typeof answer !== "string") {
					answer = JSON.stringify(answer);
				}
				const entityObj = {};
				if (entityProperty) {
					entityObj[entityProperty] = +entityId;
				}
				if (answerIds.length) {
					answerIds.forEach((answerId) => {
						const userAnswerId = userAnswersQAIdMap.get(`${question.id}_${answerId || ""}`);
						const userResponse = { answerId, questionId: question.id, ...entityObj };
						if (userAnswerId) {
							userResponse.id = userAnswerId;
						}
						if (question.type === QUESTION_TYPES.MULTI_LEVEL_CHOICE) {
							userResponse.response = answer;
						}
						_userAnswers.push(userResponse);
					});
				}
				if (answer && question.type !== QUESTION_TYPES.MULTI_LEVEL_CHOICE) {
					const userAnswerId = userAnswersQAIdMap.get(`${question.id}_`);
					const userResponse = { response: answer, questionId: question.id, ...entityObj };
					if (userAnswerId) {
						userResponse.id = userAnswerId;
					}
					_userAnswers.push(userResponse);
				}
			});
			setUserQuestionAnswerMap(_uQAMap);
			return _userAnswers;
		},
		[questions, userQuestionAnswerMap]
	);

	async function save(userQAMap) {
		setSaving(true);
		setSaveState(STATUS.INPROGRESS);
		const userAnswers = prepareUserAnswers(userQAMap || userQuestionAnswerMap);
		try {
			if (userAnswers.length) {
				if (saveFn && typeof saveFn === "function") {
					let answerObject = userAnswers.reduce((acc, answer) => {
						const question = id2questionMap.current.get(answer.questionId);
						const {
							answers,
							meta: { field },
						} = question || {};
						let response = answer.response;
						// TODO: Need to check what other types need special handling
						if (question.type === QUESTION_TYPES.MULTIPLE_CHOICE) {
							const answerText = answers.find((a) => a.id === answer.answerId)?.answer;
							let _response = [];
							if (field && field.includes(",")) {
								_response = getByPath(acc, field.split(",")[0].split(".")) || [];
							} else {
								_response = getByPath(acc, field?.split(".")) || [];
							}
							_response.push(answerText || response);
							response = _response;
						} else if (answer.answerId) {
							response = answers.find((a) => a.id === answer.answerId)?.answer;
						}
						if (field) {
							field.split(",").forEach((path) => {
								setByPath(acc, path.split("."), response);
							});
						}
						return acc;
					}, {});
					await saveFn(userAnswers, answerObject, id2questionMap.current);
				} else {
					const response = await bulkUpsert(userAnswers);
					latestServerUserAnswers.current = response;
				}
				setSaveState(STATUS.DONE);
				if (shouldAutoSave !== true) {
					Alert.success("Saved Successfully");
				}
			}
		} catch (err) {
			console.error(`Unable to save ${category} answers`, err);
			Alert.error("Unable to save your answers. Try again");
			setSaveState(STATUS.ERROR);
		} finally {
			setSaving(false);
		}
	}

	async function validateAndSave() {
		const { isValid } = validate();
		if (isValid === true) {
			return save(userQuestionAnswerMap);
		} else {
			Alert.error("Validation Failed: Please correct all the questions that are marked in red");
			return { error: true };
		}
	}

	return (
		<Context.Provider
			value={{
				userAnswers: userQuestionAnswerMap,
				steps,
				isPreparing,
				errors,
				currentStep,
				validate,
				validateStep,
				setCurrentStep,
				onChange,
				canDisplayQuestion,
				canDisplayStep,
				hasLaunchBtn,
				entityId,
			}}
		>
			{children({ save, validateAndSave, validate, saveState, steps, currentStep, isSaving, errors })}
		</Context.Provider>
	);
}
