import Button from "@material-ui/core/Button";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Card, CardHeader } from "src/components/commons/Card";
import { MATCH_ACTION, STATUS_MAP } from "src/helpers/constants";
import { Chip } from "../commons/Chip";
import { SchedulesList } from "../commons/SchedulesList";
import { SuccessfulMatches } from "./SuccessfulMatches";
// import { PendingMatches } from "./PendingMatches";

export function FunderDashboard({ activeOpportunitiesWithMatches = [], allOpportunities = [] }) {
	const { currentRole } = useSelector(({ auth }) => auth);
	const [acceptedMatches, setAcceptedMatches] = useState([]);
	const [pendingMatches, setPendingMatches] = useState([]);

	useEffect(() => {
		const acceptedMatches = [];
		activeOpportunitiesWithMatches.forEach((opportunity) => {
			opportunity.matches.forEach((match) => {
				const founderDisplayDetails = Object.keys(match.founderDisplayDetails).reduce((acc, key) => {
					let value = match.founderDisplayDetails[key];
					if (value && !Array.isArray(value) && typeof value === "object") {
						value = Object.keys(value);
					}
					acc[key] = value;
					return acc;
				}, {});
				const _match = {
					...match,
					founderDisplayDetails,
					opportunityName: opportunity.name,
				};
				if (match.funderAction === MATCH_ACTION.ACCEPTED && match.founderAction === MATCH_ACTION.ACCEPTED) {
					acceptedMatches.push(_match);
				}
				if (match.funderAction === MATCH_ACTION.ACCEPTED && match.founderAction === MATCH_ACTION.UNDECIDED) {
					pendingMatches.push(_match);
				}
			});
		});
		setAcceptedMatches(acceptedMatches);
		setPendingMatches(pendingMatches);
	}, [activeOpportunitiesWithMatches]);

	return (
		<div className="grid grid-cols-1 gap-4">
			<div className="grid grid-cols-1 md:grid-cols-3 gap-4 min-h-[250px]">
				<Card>
					<CardHeader>
						<span className="text-lg">Deployed Funds</span>
					</CardHeader>
					<div className="text-slate-400 flex justify-center items-center h-full">No Data Found</div>
				</Card>
				<Card>
					<CardHeader>
						<span className="text-lg">Funding Opportunities</span>
					</CardHeader>
					<div className="text-slate-400 flex justify-center items-center h-full">No Data Found</div>
				</Card>
				<Card>
					<CardHeader>
						<span className="text-lg">Schedules</span>
					</CardHeader>
					<SchedulesList acceptedMatches={acceptedMatches} />
				</Card>
			</div>
			<Card>
				<div className="p-4 bg-indigo-300 rounded-t-xl">
					<h2 className="text-lg">Match Found</h2>
				</div>
				{activeOpportunitiesWithMatches.length ? (
					activeOpportunitiesWithMatches.map((opportunity) => {
						return (
							<div key={`match-opp-${opportunity.id}`} className="flex w-full items-center p-4 gap-4">
								<div className="p-4 border border-primary rounded-md">
									<img src="/images/icons/opportunity-match.svg" width={45} height={45} />
								</div>
								<div className="flex-grow">
									<div className="font-bold text-lg">{opportunity.name}</div>
									<div className="text-lg">{opportunity.matches.length} match(es) found</div>
								</div>
								{opportunity.matches.length > 0 && (
									<div>
										<Button
											variant="contained"
											color="primary"
											component={Link}
											to={`/opportunity/${opportunity.id}/matches`}
										>
											View Matches
										</Button>
									</div>
								)}
							</div>
						);
					})
				) : (
					<div className="p-8 text-center text-slate-400">There are no pending matches at the moment</div>
				)}
			</Card>
			<SuccessfulMatches acceptedMatches={acceptedMatches} currentRole={currentRole} />
			{/* <PendingMatches pendingMatches={acceptedMatches} currentRole={currentRole} /> */}
			<hr className="my-4" />
			<Card>
				<CardHeader>
					<span className="text-xl">All Opportunities</span>
				</CardHeader>
				{allOpportunities.data.map((opportunity) => {
					return (
						<div key={`all-opportunity-${opportunity.id}`} className="p-4 text-lg flex justify-between">
							<div>
								{opportunity.name}{" "}
								<Chip classes="text-sm inline" status={opportunity.status}>
									{STATUS_MAP[opportunity.status]}
								</Chip>{" "}
							</div>
							<Button
								component={Link}
								variant="contained"
								color="primary"
								to={`/opportunity/${opportunity.id}/details`}
							>
								View
							</Button>
						</div>
					);
				})}
			</Card>
		</div>
	);
}
