import { alpha } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import makeStyles from "@material-ui/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import EmojiNature from "@material-ui/icons/EmojiNature";
import { useLoaderData, Link as RouterLink } from "react-router-dom";

import { getAllForAdmin } from "src/api/campaigns";
import { Main } from "src/components/commons/Main";
import { populateAllParameters } from "src/api/campaignMatchingParameters";
import { useState } from "react";
import Alert from "src/helpers/alert";

const useStyles = makeStyles((theme) => ({
	emptyState: {
		padding: "6rem 0",
		color: alpha(theme.palette.common.black, 0.3),
	},
	listItemAvatar: {
		marginTop: 0,
	},
	avatar: {
		width: 150,
		height: 150 * 0.616,
		background: theme.palette.background.default,
		"& img": {
			objectFit: "contain",
		},
	},
	chip: {
		color: theme.palette.common.white,
		"&.warning": {
			background: theme.palette.warning.dark,
		},
		"&.active": {
			background: theme.palette.success.dark,
		},
	},
}));
const statusMap = { IN_PROGRESS: "In Progress", ACTIVE: "Active", INACTIVE: "Inactive" };
const statusColorMap = { IN_PROGRESS: "warning", ACTIVE: "active", INACTIVE: "inactive" };
export function AdminCampaigns() {
	const paginatedCampaigns = useLoaderData();
	const classes = useStyles();
	const [isPopulating, setPopulating] = useState(false);
	async function populateAllCampaignParameters() {
		try {
			setPopulating(true);
			await populateAllParameters();
			Alert.success("Populated successfully");
		} catch (err) {
			console.error("Error while populating all campaigns", err);
			Alert.success("Unable to complete");
		} finally {
			setPopulating(false);
		}
	}
	return (
		<Main>
			<Box display={"flex"} justifyContent="space-between" p={4} px={4} borderBottom={"1px solid rgb(239 232 251)"}>
				<Typography component={"h2"} variant="h6">
					All Campaigns
				</Typography>
				<Button color="primary" variant="contained" onClick={populateAllCampaignParameters} disabled={isPopulating}>
					Populate Matching Parameters
				</Button>
			</Box>
			{paginatedCampaigns.data.length === 0 ? (
				<Box textAlign={"center"} className={classes.emptyState}>
					<Box>
						<EmojiNature />
					</Box>
					There are no campaigns so far
				</Box>
			) : (
				<List component={"ul"}>
					{paginatedCampaigns.data.map((campaign) => {
						return (
							<ListItem component={"li"} key={`camp-list-${campaign.id}`} button alignItems="flex-start">
								<ListItemAvatar className={classes.listItemAvatar}>
									<Avatar
										className={classes.avatar}
										variant={"rounded"}
										alt={campaign.name}
										src={"/images/icons/campaign.svg"}
									/>
								</ListItemAvatar>
								<Box display={"flex"} flexDirection={"column"} ml={4} alignItems={"flex-start"} width={"100%"}>
									<Box className="flex items-center gap-4">
										<ListItemText
											primaryTypographyProps={{ component: "h3" }}
											id={campaign.id}
											primary={campaign.name}
										/>
										<Chip
											size="small"
											label={statusMap[campaign.status]}
											className={`${classes.chip} ${statusColorMap[campaign.status]}`}
										/>
									</Box>
									<Box component={"p"} className="text-slate-500">
										{campaign.description}
									</Box>
									<Box component={"p"}>Created By: {`${campaign.creator.firstName} ${campaign.creator.lastName}`}</Box>
									<Box component={"p"}>Creator Email: {campaign.creator.email}</Box>
								</Box>
								<Box display={"flex"} flexDirection={"column"} ml={4} alignItems={"flex-start"}>
									<Button
										component={RouterLink}
										to={`/admin/campaign/${campaign.id}`}
										color="primary"
										size="small"
										variant="contained"
									>
										View
									</Button>
								</Box>
							</ListItem>
						);
					})}
				</List>
			)}
		</Main>
	);
}

export function adminCampaignsLoader() {
	return getAllForAdmin({ join: "creator", limit: 1000 });
}
