import React from "react";
import { Information } from "src/components/login/Information";
import SignUp from "../@jumbo/components/Common/authComponents/SignUp";

const Register = () => (
	<>
		<Information />
		<SignUp variant="standard" wrapperVariant="bgColor" />
	</>
);

export default Register;
