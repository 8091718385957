import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import { useState } from "react";
import { Form, useNavigate } from "react-router-dom";
// import { MuiPickersUtilsProvider } from "@material-ui/pickers";
// import DateFnsUtils from "@date-io/date-fns";
// import AppDatePicker from "src/@jumbo/components/Common/formElements/AppDatePicker";
import FormHelperText from "@material-ui/core/FormHelperText";
import { create, update } from "src/api/vendorServices";
import { GENERIC_REQUEST_ERROR } from "../commons/Errors";

export function VendorServiceForm({ vendorService, close }) {
	const navigate = useNavigate();
	const [name, setName] = useState(vendorService?.name || "");
	// const [description, setDescription] = useState(vendorService?.description || "");
	// const [endDate, setEndDate] = useState(getDate(opportunity?.endDate) || "");
	const [errors, setErrors] = useState(null);
	const [saving, setSaving] = useState(false);

	// function getDate(dt) {
	// 	if (dt) {
	// 		const [yyyy, mm, dd] = dt.split("-");
	// 		return `${mm}-${dd}-${yyyy}`;
	// 	}
	// 	return null;
	// }

	return (
		<Form
			replace={false}
			method="post"
			action="/vendor_services"
			onSubmit={async (e) => {
				e.preventDefault();
				setSaving(true);
				const formData = new FormData(e.target);
				const id = formData.get("id");
				const name = formData.get("name");
				// const description = formData.get("description");
				// const endDate = formData.get("endDate") || null;
				const errors = {};
				if (typeof name !== "string" || !name.trim()) {
					errors.name = "Name must be a minimum of 3 characters";
				}
				// if (endDate && Date.now() >= new Date(endDate).getTime()) {
				// 	errors.endDate = "Fund Deployment Date cannot be in the past";
				// }
				if (Object.keys(errors).length) {
					setErrors(errors);
					return;
				}

				try {
					let data = {
							name,
							// description,
							// endDate,
						},
						response;
					if (id) {
						data.id = id;
						response = await update(data);
						close();
					} else {
						response = await create(data);
						navigate(`/service/${response.id}/details`);
					}
					return response;
				} catch (err) {
					console.error("Error while saving vendor service", err);
					if (err instanceof Error) {
						errors.server = GENERIC_REQUEST_ERROR("CN001");
					}
					if (err && err[0]) {
						errors.server = err[0];
					}
					setErrors(errors);
				} finally {
					setSaving(false);
				}
			}}
		>
			{errors?.server && (
				<Box mb={5}>
					<Alert severity="error">{errors?.server}</Alert>
				</Box>
			)}
			<input type="hidden" value={vendorService?.id || ""} name="id" />
			<Box px={5} pb={4}>
				<TextField
					autoFocus
					variant="outlined"
					margin="dense"
					id="service-name"
					label="Service Name"
					type="text"
					size="medium"
					name="name"
					required
					error={errors?.name}
					helperText={errors?.name || ""}
					value={name || ""}
					fullWidth
					onChange={(e) => setName(e.target.value)}
				/>
				<FormHelperText>Example: Recruiting Service</FormHelperText>
			</Box>
			{/* <Box px={5} pb={4}>
				<TextField
					multiline
					variant="outlined"
					margin="dense"
					minRows={2}
					maxRows={4}
					id="Service-desc"
					label="Service Description"
					type="text"
					size="medium"
					name="description"
					fullWidth
					value={description || ""}
					onChange={(e) => setDescription(e.target.value)}
				/>
				<FormHelperText>Example: Helps with building your teams</FormHelperText>
			</Box> */}
			{/* <Box px={5} pb={4}>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<AppDatePicker
						fullWidth
						inputVariant={"outlined"}
						format={"MM/dd/yyyy"}
						name="endDate"
						value={endDate ? new Date(endDate) : null}
						label="Fund Deployment Date"
						placeholder="mm/dd/yyyy"
						size="medium"
						error={!!errors?.endDate}
						helperText={errors?.endDate || ""}
						onChange={(value) => {
							if ((value || {}).toString() !== "Invalid Date") {
								setEndDate(`${value.getMonth() + 1}/${value.getDate()}/${value.getFullYear()}`);
							}
						}}
					/>
					<FormHelperText>When is the next funding deployment?</FormHelperText>
				</MuiPickersUtilsProvider>
			</Box> */}
			<Box px={3} pb={4}>
				<DialogActions>
					<Button type="button" onClick={close}>
						Close
					</Button>
					<Button disabled={saving} type="submit" variant="contained" color="primary">
						{saving ? "Saving..." : "Save"}
					</Button>
				</DialogActions>
			</Box>
		</Form>
	);
}
