import { fetchAdminV1, fetchV1 } from "../lib/requester/v1";

export function getAll(params) {
	return fetchAdminV1("/tenant_question_rules", { method: "GET", data: params }, { returnWholeResponse: true });
}

export function update(data) {
	return fetchAdminV1(`/tenant_question_rules/${data.id}`, { method: "PUT", data });
}

export function create(data) {
	return fetchAdminV1(`/tenant_question_rules/bulk`, { method: "POST", data: { bulk: data } });
}

export function remove(id) {
	return fetchAdminV1(`/tenant_question_rules/${id}`, { method: "DELETE" });
}

export function getTenantRules() {
	return fetchV1("/tenant_question_rules", { method: "GET" });
}
