import React from "react";
// import { useDispatch, useSelector } from "react-redux";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import { alpha } from "@material-ui/core/styles";
import makeStyles from "@material-ui/styles/makeStyles";
// import SearchIcon from "@material-ui/icons/Search";
import Menu from "@material-ui/icons/Menu";
import Hidden from "@material-ui/core/Hidden";
import { useContext } from "react";
// import UserIcon from "@material-ui/icons/VerifiedUserOutlined";
// import AppsMenu from './AppsMenu';
// import HeaderNotifications from "./HeaderNotifications";
// import HeaderMessages from "./HeaderMessages";
// import Logo from '../Logo';
// import SearchPopover from "../SearchPopover";
// import UserDropDown from "../UserDropDown";
import LayoutContext from "src/@coremat/CmtLayouts/Vertical/LayoutContext/LayoutContext";
import UserActions from "./UserActions";
// import { getResults } from '../../../../../redux/actions';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.default,
		color: theme.palette.common.white,
		width: "100%",
		paddingLeft: 16,
		paddingRight: 16,
		minHeight: 64,
		// display: "flex",
		justifyContent: "space-between",
		[theme.breakpoints.up("md")]: {
			minHeight: 72,
		},
		[theme.breakpoints.up("md")]: {
			justifyContent: "flex-end",
			paddingLeft: 24,
			paddingRight: 24,
		},
	},
	searchRoot: {
		position: "relative",
		width: 260,
		[theme.breakpoints.up("md")]: {
			width: 350,
		},
		"& .MuiSvgIcon-root": {
			position: "absolute",
			left: 18,
			top: "50%",
			transform: "translateY(-50%)",
			zIndex: 1,
			color: alpha(theme.palette.common.black, 0.5),
		},
		"& .MuiInputBase-root": {
			width: "100%",
		},
		"& .MuiInputBase-input": {
			height: 40,
			borderRadius: 30,
			backgroundColor: theme.palette.common.white,
			color: alpha(theme.palette.common.black, 0.8),
			boxSizing: "border-box",
			padding: "5px 15px 5px 50px",
			boxShadow: "rgba(0, 0, 0, 0.086) 0px 5px 10px",
			transition: "all 0.3s ease",
			"&:focus": {
				// backgroundColor: alpha(theme.palette.common.dark, 0.58),
				color: alpha(theme.palette.common.black, 0.7),
			},
		},
	},
	iconBtn: {
		color: alpha(theme.palette.common.white, 0.38),
		"&:hover, &:focus": {
			color: theme.palette.common.white,
		},
	},
}));

const Header = () => {
	const classes = useStyles();
	// const dispatch = useDispatch();
	// const { authUser } = useSelector(({ auth }) => auth);
	// const { keyword } = useSelector(({ search }) => search || {});
	const { setOpen } = useContext(LayoutContext);
	// const [keyword, setKeyword] = useState("");

	// const setKeyword = event => {
	//   const val = event.target.value;
	//   dispatch(getResults(val, authUser.uid, 1));
	// };

	return (
		<Toolbar className={classes.root}>
			{/* <Hidden smDown>
				<Box className={classes.searchRoot}>
					<InputBase
						placeholder={"Search here..."}
						inputProps={{ "aria-label": "search" }}
						onChange={setKeyword}
						value={keyword}
					/>
					<SearchIcon />
				</Box>
			</Hidden> */}
			<Hidden mdUp>
				<Box pr={3}>
					<IconButton
						onClick={() => {
							setOpen(true);
						}}
					>
						<Menu />
					</IconButton>
				</Box>
				{/* <SearchPopover iconClassName={classes.iconBtn} /> */}
			</Hidden>
			<Box className="hdhdhdh" display={"flex"} justifyContent="flex-end">
				{/* <HeaderMessages />
				<HeaderNotifications /> */}
				<UserActions />
			</Box>
		</Toolbar>
	);
};

export default Header;
