import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	initialURL: "/",
	error: "",
	loading: false,
};

export const commonSlice = createSlice({
	name: "theme",
	initialState,
	reducers: {
		fetchSuccess(state) {
			state.error = "";
			state.loading = false;
		},
		fetchError(state, action) {
			state.error = action.payload;
			state.loading = false;
		},
		fetchStart(state) {
			state.error = "";
			state.loading = true;
		},
	},
});

export const { fetchSuccess, fetchError, fetchStart } = commonSlice.actions;

export default commonSlice.reducer;
