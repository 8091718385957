import { useCallback, useEffect, useState } from "react";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import Close from "@material-ui/icons/Close";
// import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { fetchV1 } from "src/lib/requester/v1";
import { debounce } from "src/helpers/debounce";
import { DeleteForever } from "@material-ui/icons";

const filter = createFilterOptions();

function SearchBox({ options, idx, loading, question, answers, setUserAnswer, setOptions, fetchData }) {
	const [inputValue, setInputValue] = useState("");

	useEffect(() => {
		fetchData(inputValue);
	}, [inputValue, fetchData]);

	return (
		<Autocomplete
			options={options}
			getOptionLabel={(option) => option}
			loading={loading}
			loadingText={"Fetching..."}
			value={""}
			filterOptions={(options, params) => {
				const filtered = filter(options, params);
				if (params.inputValue !== "" && !options.includes(params.inputValue)) {
					filtered.push(`Add "${params.inputValue}"`);
				}
				return filtered;
			}}
			// clearOnBlur
			clearOnEscape
			handleHomeEndKeys
			inputValue={inputValue}
			onInputChange={(event, newInputValue) => {
				setInputValue(newInputValue);
			}}
			onChange={(e, newValue) => {
				let value = newValue;
				if (value && value.includes("Add")) {
					value = newValue.replace('Add "', "").replace('"', "");
				}
				if (value && !answers[idx].find((i) => value == i)) {
					const _answers = [...answers];
					_answers[idx].push(value);
					setUserAnswer(undefined, _answers);
					setInputValue("");
					if (question.meta.dataSourceURL && !question.meta.prefetch) {
						setOptions([]);
					}
				}
			}}
			// {...props}
			style={{ width: 200 }}
			freeSolo={true}
			renderInput={(params) => {
				// <TextField {...params} size="small" variant="standard" label={question.meta.placeholder || "Search"} />
				return (
					<div ref={params.InputProps.ref}>
						<input
							style={{ width: 200 }}
							type="text"
							{...params.inputProps}
							autoComplete="disabled"
							// value=""
							placeholder={`Search ${question.meta.placeholder || ""}`}
							className="border-b px-2 py-2"
						/>
					</div>
				);
			}}
		/>
	);
}

export function Tags({ question, answers, setUserAnswer, error }) {
	const [options, setOptions] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (question.answers?.length > 0) {
			setOptions(
				(question.answers[0]?.answer?.split("\n") || []).reduce((acc, i) => {
					const option = i.split(",")[1] || i.split(",")[0] || "";
					if (option) {
						acc.push(option.trim());
					}
					return acc;
				}, []) || []
			);
		} else if (question.meta.dataSourceURL && question.meta.prefetch) {
			setTimeout(() => {
				setLoading(true);
				fetchV1(question.meta.dataSourceURL, { method: "GET" })
					.then((res) => {
						setOptions(res.map((item) => item.name));
						setLoading(false);
					})
					.catch((e) => {
						setLoading(false);
						console.error("Unable to fetch tag options", e);
					});
			}, 1000);
		}
	}, [question]);

	const fetchData = useCallback(
		debounce(function _fetchData(value) {
			if (!value) {
				return null;
			}
			setLoading(true);
			const url = question.meta.dataSourceURL?.replace(/\{(.*?)\}/g, () => value);
			fetchV1(url, { method: "GET" })
				.then((res) => {
					setOptions(res.map((item) => item.name));
					setLoading(false);
				})
				.catch((e) => {
					setLoading(false);
					console.error("Unable to fetch tag options", e);
				});
		}, 500),
		[question]
	);

	return (
		<div className="flex flex-col gap-4">
			{answers.length > 0 &&
				answers.map((tags, idx) => {
					return (
						<div key={`tags-row-${question.id}-${idx}`} className="flex flex-col gap-2">
							{question.meta.multiple && (
								<div className="flex items-center">
									<Typography variant="caption" component={"label"} className="text-gray-500">
										Group {idx + 1}{" "}
									</Typography>
									<IconButton
										aria-label="delete"
										color="secondary"
										style={{ padding: 0, marginLeft: "1rem" }}
										onClick={() => {
											const _answers = [...answers];
											_answers.splice(idx, 1);
											setUserAnswer(undefined, _answers);
										}}
									>
										<DeleteForever />
									</IconButton>
								</div>
							)}
							<div className="flex gap-2 flex-wrap">
								{tags.length > 0 &&
									tags.map((tag, tIdx) => {
										return (
											<div
												key={`que-${question.id}-idx${idx}-ans-${tag}`}
												className={`bg-[#3f51b5] text-white rounded-full pl-3 pr-1 py-1 flex justify-center items-center text-xl`}
											>
												{tag}{" "}
												<button
													className="ml-2 w-6 h-6 rounded-full hover:bg-indigo-900/70"
													style={{ lineHeight: 0 }}
													onClick={() => {
														const _answers = [...answers];
														_answers[idx].splice(tIdx, 1);
														setUserAnswer(undefined, _answers);
													}}
												>
													<Close fontSize="small" />
												</button>
											</div>
										);
									})}
								<SearchBox
									question={question}
									idx={idx}
									options={options}
									setOptions={setOptions}
									answers={answers}
									setUserAnswer={setUserAnswer}
									loading={loading}
									fetchData={fetchData}
								/>
							</div>
						</div>
					);
				})}
			{question.meta.multiple && (
				<div>
					<Button
						variant="contained"
						color="primary"
						onClick={() => {
							const _answers = [...answers];
							_answers.push([]);
							setUserAnswer(undefined, _answers);
						}}
					>
						+ Add {question.meta.placeholder || ""}
					</Button>
				</div>
			)}
			{error && (
				<FormHelperText style={{ marginInline: 14 }} error={true}>
					{error}
				</FormHelperText>
			)}
		</div>
	);
}
