import Box from "@material-ui/core/Box";
import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import CmtImage from "../../../../@coremat/CmtImage";

const Logo = (props) => {
	const { tenant } = useSelector(({ auth }) => auth);
	return (
		<Box className="pointer mx-auto" {...props}>
			<NavLink to="/">
				<CmtImage src={(tenant.logo || {})["2x"]?.path || "/images/logo-icon.png"} alt="logo" width={props.width} />
			</NavLink>
		</Box>
	);
};

export default Logo;
