import { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Button from "@material-ui/core/Button";
import Flare from "@material-ui/icons/Flare";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { MATCH_ACTION } from "src/helpers/constants";
import { updateSchedule } from "src/api/matches";
import Alert from "src/helpers/alert";
import { getMatchId } from "src/helpers";
import { toLocaleDate } from "src/helpers/date";
import { ConfirmDialog } from "../commons/Dialog";
import { Schedule } from "../commons/Schedule";

export function EachMatch({ match, onAction, currentRole }) {
	const [showSchedule, setShowSchedule] = useState(false);
	const [showDetails, setShowDetails] = useState(false);
	const [scheduleError, setScheduleError] = useState(null);
	const [scheduledTimes, setScheduledTimes] = useState([]);
	const diff = 72 - Math.floor((Date.now() - new Date(match.createdAt).getTime()) / (1000 * 60 * 60));
	async function saveSchedule(times) {
		try {
			await updateSchedule({ id: match.id, times, currentRole });
			setShowSchedule(false);
		} catch (err) {
			console.error("Unable to save schedule", err);
			if (err.code && err.code === "E_MA_42204") {
				setScheduleError(err.data);
				Alert.error(err.message);
				return;
			}
			Alert.error("Unable to save schedule");
			// TODO: Error display
		}
	}
	const status = useMemo(() => {
		if (match.founderAction === MATCH_ACTION.ACCEPTED && match.funderAction === MATCH_ACTION.UNDECIDED) {
			return <div className="text-gray-500 text-center">⏳ Waiting for the funder to respond</div>;
		} else if (match.founderAction === MATCH_ACTION.UNDECIDED) {
			return <div className="text-yellow-600 font-bold text-center px-2">⏰ You have {diff} hours to respond</div>;
		} else {
			return <></>;
		}
	}, [match]);

	const actions = useMemo(() => {
		if (match.founderAction === MATCH_ACTION.UNDECIDED) {
			return (
				<>
					<Button
						variant="contained"
						color="primary"
						size="large"
						className="text-lg"
						onClick={() => onAction(match, MATCH_ACTION.ACCEPTED)}
					>
						Accept
					</Button>
					<Button
						variant="outlined"
						color="inherit"
						className="text-lg"
						onClick={() => onAction(match, MATCH_ACTION.ARCHIVED)}
					>
						Archive
					</Button>
					<Button
						variant="outlined"
						color="inherit"
						className="text-lg"
						onClick={() => onAction(match, MATCH_ACTION.REJECTED)}
					>
						Ignore
					</Button>
				</>
			);
		} else if (match.founderAction === MATCH_ACTION.ACCEPTED && match.funderAction === MATCH_ACTION.ACCEPTED) {
			return (
				<>
					<Button variant="outlined" color="inherit" component={Link} to={`/match/${match.id}/opportunity`}>
						View Full Details
					</Button>
					{match.opportunity?.didOnboard && (
						<>
							{!match.proposedSchedules?.length && (
								<Button variant="contained" color="secondary" onClick={() => setShowSchedule(true)}>
									Schedule A Call
								</Button>
							)}
							{match.proposedSchedules[0]?.role === currentRole && (
								<Button
									variant="outlined"
									color="inherit"
									onClick={() => {
										setScheduledTimes(match.proposedSchedules[0].times);
										setShowSchedule(true);
									}}
								>
									View schedule
								</Button>
							)}
						</>
					)}
				</>
			);
		} else {
			return <div className="text-gray-500 text-center">⏳ Waiting for response</div>;
		}
	}, [match, onAction]);
	return (
		<>
			<div
				key={`camp-match-${match.id}`}
				className="flex justify-between px-4 py-3 cursor-pointer"
				onClick={() => setShowDetails(!showDetails)}
			>
				<div className="font-bold">
					<Flare className="text-primary" /> Match #{getMatchId(match.id)}
				</div>
				{status}
				<div>{showDetails ? <KeyboardArrowDownIcon /> : <ChevronRight />}</div>
			</div>
			{showDetails && (
				<div className="grid grid-cols-8 border-t text-slate-600">
					<div className="col-span-6 h-80 overflow-auto">
						<div className="[&>*:nth-child(even)]:bg-indigo-50">
							<div className="flex p-2">
								<div className="min-w-[220px] capitalize pr-2">Funding Since:</div>
								<div className="font-bold">
									{match.funderDisplayDetails.fundingSince
										? toLocaleDate(new Date(match.funderDisplayDetails.fundingSince))
										: "-"}
								</div>
							</div>
							<div className="flex p-2">
								<div className="min-w-[220px] capitalize pr-2">Industry:</div>
								<div className="font-bold">
									{match.funderDisplayDetails.industry?.length ? match.funderDisplayDetails.industry.join(", ") : "Any"}
								</div>
							</div>
							<div className="flex p-2">
								<div className="min-w-[220px] capitalize pr-2">Funding Format:</div>
								<div className="font-bold">{match.funderDisplayDetails.fundingFormat?.join(", ")}</div>
							</div>
							<div className="flex p-2">
								<div className="min-w-[220px] capitalize pr-2">Addition Offerings:</div>
								<div className="font-bold">{match.funderDisplayDetails.additionalOfferings?.join(", ")}</div>
							</div>
							<div className="flex p-2">
								<div className="min-w-[220px] capitalize pr-2">Has ESG:</div>
								<div className="font-bold">{match.funderDisplayDetails.hasESG ? "Yes" : "No"}</div>
							</div>
							<div className="flex p-2">
								<div className="min-w-[220px] capitalize pr-2">Has CSR:</div>
								<div className="font-bold">{match.funderDisplayDetails.hasCSR ? "Yes" : "No"}</div>
							</div>
							<div className="flex p-2">
								<div className="min-w-[220px] capitalize pr-2">Location:</div>
								{/* <div className="font-bold">{match.funderDisplayDetails.location?.join(", ")}</div> */}
								<div className="font-bold">{"-"}</div>
							</div>
							<div className="flex p-2">
								<div className="min-w-[220px] capitalize pr-2">Additional Offerings:</div>
								<div className="font-bold">{match.funderDisplayDetails.additionalOfferings?.join(", ") || "-"}</div>
							</div>
							{/* <DisplayKeyValuePair data={match.funderDisplayDetails} /> */}
							{/* {match.founderAction === MATCH_ACTION.UNDECIDED && (
								<div className="p-2 bg-yellow-50 font-bold text-center">⏰ You have {diff} hours to respond</div>
							)} */}
						</div>
						{/* <DisplayKeyValuePair data={match.founderDisplayDetails} /> */}
					</div>
					<div className="col-span-2 flex flex-col justify-center gap-4 px-4 bg-indigo-100">{actions}</div>
				</div>
			)}
			{showSchedule && (
				<ConfirmDialog
					show={true}
					title={`Time Slots for match #${getMatchId(match.id)}`}
					onClose={() => setShowSchedule(false)}
					body={
						<Schedule
							errors={scheduleError}
							scheduleTimes={scheduledTimes}
							viewOnly={!!scheduledTimes.length}
							onDone={(times) => {
								saveSchedule(times);
							}}
							onClose={() => setShowSchedule(false)}
						/>
					}
					showFooter={false}
				/>
			)}
		</>
	);
}
