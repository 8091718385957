import { Button } from "@material-ui/core";
import { useState } from "react";
import { MEETING_TYPE } from "src/helpers/constants";

export function ChooseSchedule({ scheduleTimes = [], onDone, onClose, errors = null }) {
	const [selectedSlot, setSelectedSlot] = useState(null);
	const [saving, setSaving] = useState(false);
	function getReadableDate(datetime) {
		const dt = new Date(datetime);
		return Intl.DateTimeFormat(undefined, {
			day: "2-digit",
			month: "short",
			year: "numeric",
			hour: "2-digit",
			minute: "2-digit",
		}).format(dt);
	}
	async function saveSelectedSlot() {
		setSaving(true);
		try {
			await onDone(selectedSlot);
		} finally {
			setSaving(false);
		}
	}
	if (selectedSlot) {
		return (
			<div className="text-lg">
				<div className="text-center p-4">
					Confirm your {selectedSlot.durationInMins} mins {selectedSlot.type} meeting on{" "}
					{getReadableDate(selectedSlot.datetime)}
				</div>
				<div className="pt-4 flex justify-end gap-2">
					<Button variant="text" disabled={saving} onClick={() => setSelectedSlot(null)}>
						Back
					</Button>
					<Button variant="text" disabled={saving} onClick={() => setSelectedSlot(null)}>
						No
					</Button>
					<Button variant="contained" color="primary" disabled={saving} onClick={saveSelectedSlot}>
						{saving ? "Saving..." : "Yes"}
					</Button>
				</div>
			</div>
		);
	}
	return (
		<div>
			<h4 className="font-bold mb-2 text-lg text-center">Choose a time slot</h4>
			<div className="overflow-auto w-full flex gap-4 pb-4">
				{scheduleTimes.map((item, idx) => {
					const dtStr = getReadableDate(item.datetime);
					return (
						<div
							className="flex-grow min-w-[90%] text-lg border rounded-lg [&>*]:mb-2 [&>*]:px-2"
							key={`time-slot-${idx}`}
						>
							<div className="p-2 bg-indigo-100 rounded-t-md">
								Proposed Slot {idx + 1}/{scheduleTimes.length}
							</div>
							<div className="flex gap-2">
								<div className="min-w-[120px]">Date & Time:</div>
								<div>{dtStr}</div>
							</div>
							<div className="flex gap-2">
								<div className="min-w-[120px]">Meeting Type:</div>
								<div>{item.type === MEETING_TYPE.INPERSON ? "In Person" : "Virtual"}</div>
							</div>
							<div className="flex gap-2">
								<div className="min-w-[120px]">{item.type === MEETING_TYPE.INPERSON ? "Address" : "Link"}:</div>
								<div className="whitespace-nowrap text-ellipsis overflow-hidden">{item.meetingAddress}</div>
							</div>
							<div className="flex gap-2">
								<div className="min-w-[120px]">Duration:</div>
								<div>{item.durationInMins} Mins</div>
							</div>
							<div>
								<Button
									variant="contained"
									color="primary"
									fullWidth
									onClick={() => {
										setSelectedSlot(item);
									}}
								>
									Select
								</Button>
							</div>
						</div>
					);
				})}
			</div>
			<div>{errors}</div>
			<div className="flex justify-end gap-2 py-2 px-6 border-t -mx-6">
				<Button variant="contained" onClick={() => alert("Implement")}>
					Reschedule
				</Button>
				<Button variant="text" onClick={() => onClose()}>
					Close
				</Button>
			</div>
		</div>
	);
}
