import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";
import Autocomplete from "@material-ui/lab/Autocomplete";
import makeStyles from "@material-ui/styles/makeStyles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useContext, useMemo } from "react";
import DateFnsUtils from "@date-io/date-fns";
import AppDatePicker from "src/@jumbo/components/Common/formElements/AppDatePicker";
import HelpPopover from "src/components/commons/Popover";
import { QUESTION_TYPES, TITLES } from "src/helpers/constants";
import { MaskedInput } from "src/components/commons/Inputs/MaskedInput";
import { Context } from "./Context";
import { MultiLevelChoice } from "./MultiLevelChoice";
import { Phone } from "./Phone";
import { Combo } from "./Combo";
import { Sortable } from "./Sortable";
import { UploadFile } from "./UploadFile";
import { Tags } from "./Tags";

const useStyles = makeStyles(() => ({
	hint: {
		whiteSpace: "pre-line",
		fontSize: 16,
	},
	formGroup: {
		display: "block",
	},
}));

export function Answers({ question, questionIndex }) {
	const classes = useStyles();
	const { errors, onChange, userAnswers, currentStep } = useContext(Context);
	const error = (errors[`step${currentStep}`] || {})[`question${question.id}`];
	function setUserAnswer(answerObject, answerText, isRemove) {
		onChange({ question: question, answerObject, answerText, isRemove });
	}
	if (!question.type) {
		return null;
	}

	const result = useMemo(() => {
		switch (question.type) {
			case QUESTION_TYPES.SELECT:
				return (
					<Box maxWidth={300}>
						<FormControl variant="outlined" fullWidth>
							<Select
								value={userAnswers[question.id + ""]?.answerIds[0] || ""}
								autoWidth
								onChange={(e) => {
									const answerObject = question.answers.find((answer) => answer.id === +e.target.value);
									setUserAnswer(answerObject);
								}}
							>
								<MenuItem value="">
									<em>None</em>
								</MenuItem>
								{question.answers.map((answer, idx) => {
									return (
										<MenuItem key={`ques_${questionIndex}_answer_${idx}_select`} value={answer.id}>
											<Box component={"span"} marginRight={2}>
												{answer.answer}
											</Box>
											{answer.meta.description && <HelpPopover content={answer.meta.description} />}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
						{error && (
							<FormHelperText style={{ marginInline: 14 }} error={true}>
								{error}
							</FormHelperText>
						)}
					</Box>
				);
			case QUESTION_TYPES.MULTI_LEVEL_CHOICE:
				return (
					<MultiLevelChoice
						question={question}
						questionIndex={questionIndex}
						answers={question.answers}
						userAnswerObject={userAnswers[question.id + ""] || {}}
						onChange={setUserAnswer}
					/>
				);
			case QUESTION_TYPES.MULTIPLE_CHOICE:
				return (
					<>
						<FormControl component="fieldset">
							<FormGroup
								className={`${
									question.answers.length > 6 ? classes.formGroup : ""
								} columns-1 md:columns-2 lg:columns-3`}
							>
								{question.answers.map((answer, idx) => {
									return (
										<FormControlLabel
											className="w-full"
											key={"answer_" + questionIndex + "_" + idx}
											control={
												<Checkbox
													checked={userAnswers[question.id + ""]?.answerIds.includes(answer.id) || false}
													onChange={(e) => {
														setUserAnswer(answer, undefined, !e.target.checked);
													}}
													name={"answer_" + questionIndex + "_" + idx}
												/>
											}
											label={
												<>
													<span style={{ marginRight: 5 }}>{answer.answer}</span>
													{answer.meta.description && <HelpPopover content={answer.meta.description} />}
												</>
											}
										/>
									);
								})}
								{question.meta.hasOther && (
									<Box>
										<FormControlLabel
											key={"answer_" + questionIndex + "_other"}
											control={
												<Checkbox
													checked={!!userAnswers[question.id + ""]?.answer}
													onChange={(e) => {
														if (e.target.checked) {
															setUserAnswer(undefined, userAnswers[question.id + ""]?.answer || " ");
														} else {
															setUserAnswer(undefined, "");
														}
													}}
												/>
											}
											label={" "}
											style={{ marginRight: 0 }}
										/>
										<TextField
											variant="outlined"
											size="small"
											type="text"
											value={userAnswers[question.id + ""]?.answer || ""}
											placeholder="Other"
											label="Other"
											onChange={(e) => {
												setUserAnswer(undefined, (e.target.value || "").replace(/^\s+/, ""));
											}}
										/>
									</Box>
								)}
							</FormGroup>
						</FormControl>
						{error && (
							<FormHelperText style={{ marginInline: 14 }} error={true}>
								{error}
							</FormHelperText>
						)}
					</>
				);
			case QUESTION_TYPES.RADIO:
				return (
					<>
						<FormControl component="fieldset">
							<RadioGroup
								name={`question${questionIndex}Answers`}
								value={""}
								className={`${
									question.answers?.length > 6 ? classes.formGroup : ""
								} columns-1 md:columns-2 lg:columns-3`}
							>
								{(question.answers || []).map((answer, index) => {
									if (answer.meta.type === "DATE") {
										return (
											<MuiPickersUtilsProvider utils={DateFnsUtils} key={"answer_" + questionIndex + "_" + index}>
												<FormControlLabel
													key={"answer_" + questionIndex + "_other"}
													control={<Radio checked={userAnswers[question.id + ""]?.answer} />}
													label={
														<AppDatePicker
															format={"M/dd/yyyy"}
															onChange={(value) => {
																setUserAnswer(answer, value);
															}}
														/>
													}
													style={{ marginRight: 0 }}
												/>
											</MuiPickersUtilsProvider>
										);
									}
									return (
										<FormControlLabel
											className="w-full"
											key={"answer_" + questionIndex + "_" + index}
											id={"answer_" + questionIndex + "_" + answer.id}
											value={answer.answer}
											control={
												<Radio
													checked={userAnswers[question.id + ""]?.answerIds[0] === answer.id}
													onChange={() => {
														setUserAnswer(answer);
													}}
												/>
											}
											label={
												<>
													<span style={{ marginRight: 5 }}>{answer.answer}</span>
													{answer.meta.description && <HelpPopover content={answer.meta.description} />}
												</>
											}
										/>
									);
								})}
								{question.meta.hasOther && (
									<Box>
										<FormControlLabel
											key={"answer_" + questionIndex + "_other"}
											control={
												<Radio
													checked={!!userAnswers[question.id + ""]?.answer}
													onChange={(e) => {
														if (!e.target.checked) {
															setUserAnswer(undefined, "");
														} else {
															setUserAnswer(undefined, " ");
														}
													}}
												/>
											}
											label={""}
											style={{ marginRight: 0 }}
										/>
										<TextField
											variant="outlined"
											type="text"
											placeholder="Other"
											value={userAnswers[question.id + ""]?.answer || ""}
											label="Other"
											size="small"
											onChange={(e) => {
												setUserAnswer(undefined, (e.target.value || "").trim());
											}}
										/>
									</Box>
								)}
							</RadioGroup>
						</FormControl>
						{error && (
							<FormHelperText style={{ marginInline: 14 }} error={true}>
								{error}
							</FormHelperText>
						)}
					</>
				);
			case QUESTION_TYPES.DATE:
				return (
					<MuiPickersUtilsProvider utils={DateFnsUtils} key={"answer_" + questionIndex + "_date"}>
						<AppDatePicker
							fullWidth={false}
							inputVariant={"outlined"}
							format={"M/dd/yyyy"}
							value={userAnswers[question.id + ""]?.answer || ""}
							size="medium"
							InputProps={{ readOnly: true }}
							onChange={(value) => {
								if ((value || {}).toString() !== "Invalid Date") {
									setUserAnswer(undefined, `${value.getMonth() + 1}/${value.getDate()}/${value.getFullYear()}`);
								}
							}}
						/>
						{error && (
							<FormHelperText style={{ marginInline: 14 }} error={true}>
								{error}
							</FormHelperText>
						)}
					</MuiPickersUtilsProvider>
				);
			case QUESTION_TYPES.PHONE: {
				let phone = userAnswers[question.id + ""]?.answer || "{}";
				if (typeof phone === "string") {
					phone = JSON.parse(phone);
				}
				return (
					<Phone
						phone={phone}
						placeholder={question.meta.placeholder}
						onChange={(phone) => setUserAnswer(undefined, phone)}
						error={error}
					/>
				);
			}
			case QUESTION_TYPES.URL: {
				return (
					<TextField
						fullWidth
						variant="outlined"
						type="text"
						label={question.meta.placeholder || "URL"}
						placeholder={question.meta.placeholder || "URL"}
						value={userAnswers[question.id + ""]?.answer || ""}
						onChange={(e) => setUserAnswer(undefined, e.target.value)}
						error={!!error}
						helperText={error}
					/>
				);
			}
			case QUESTION_TYPES.NUMBER:
				return (
					<MaskedInput
						mask={Number}
						thousandsSeparator={question.meta.thousandsSeparator || ""}
						signed={false}
						radix={"."}
						scale={0}
						unmask={true}
						min={question.meta?.min ?? 0}
						max={question.meta?.max ?? 10000000000000}
						variant="outlined"
						label={question.meta.placeholder || ""}
						placeholder={question.meta.placeholder || ""}
						value={userAnswers[question.id + ""]?.answer || ""}
						onComplete={(value, mask) => {
							setUserAnswer(undefined, mask.unmaskedValue);
						}}
						error={!!error}
						helperText={error}
						InputProps={{
							...(question.meta?.startAdornment && {
								startAdornment: <InputAdornment position="start">{question.meta?.startAdornment}</InputAdornment>,
							}),
							...(question.meta?.endAdornment && {
								endAdornment: <InputAdornment position="end">{question.meta?.endAdornment}</InputAdornment>,
							}),
						}}
					/>
				);
			case QUESTION_TYPES.TEXT:
			case QUESTION_TYPES.EMAIL: {
				const answer = userAnswers[question.id + ""]?.answer || "";
				const answerLength = answer.trim().length;
				return (
					<>
						<TextField
							fullWidth
							variant="outlined"
							type="text"
							InputProps={{
								...(question.meta?.startAdornment && {
									startAdornment: <InputAdornment position="start">{question.meta?.startAdornment}</InputAdornment>,
								}),
								...(question.meta?.endAdornment && {
									endAdornment: <InputAdornment position="end">{question.meta?.endAdornment}</InputAdornment>,
								}),
							}}
							// label={question.meta.placeholder || ""}
							placeholder={question.meta.placeholder || ""}
							value={userAnswers[question.id + ""]?.answer || ""}
							onChange={(e) => {
								setUserAnswer(undefined, e.target.value);
							}}
							error={!!error}
							helperText={error}
						/>
						{(question.meta.min > 0 || question.meta.max > 0) && (
							<Box style={{ gap: 8 }} display={"flex"} justifyContent={"end"}>
								{question.meta.min > 0 && (
									<FormHelperText error={question.meta.min > 0 && answerLength < +question.meta.min}>
										Min. {question.meta.min} Characters
									</FormHelperText>
								)}
								{question.meta.max > 0 && (
									<FormHelperText error={question.meta.max > 0 && answerLength > +question.meta.max}>
										{Math.abs(question.meta.max - answerLength)} Character(s){" "}
										{question.meta.max - answerLength < 0 ? "exceeded" : "left"}
									</FormHelperText>
								)}
							</Box>
						)}
					</>
				);
			}
			case QUESTION_TYPES.FREE_TEXT: {
				const answer = userAnswers[question.id + ""]?.answer || "";
				const answerLength = answer.trim().length;
				return (
					<>
						<TextField
							fullWidth
							variant="outlined"
							multiline
							// label={question.meta.placeholder || ""}
							placeholder={question.meta.placeholder || ""}
							cols="30"
							minRows="3"
							value={userAnswers[question.id + ""]?.answer || ""}
							onChange={(e) => {
								setUserAnswer(undefined, e.target.value);
							}}
							error={!!error}
							helperText={error}
						/>
						<Box style={{ gap: 8 }} display={"flex"} justifyContent={"end"}>
							{question.meta.min !== undefined && <FormHelperText>Min. {question.meta.min} Characters</FormHelperText>}
							{question.meta.max !== undefined && <FormHelperText>Max. {question.meta.max} Characters</FormHelperText>}
							{(question.meta.min !== undefined || question.meta.max !== undefined) && (
								<FormHelperText
									error={
										(question.meta.max !== undefined && answerLength > +question.meta.max) ||
										(question.meta.min !== undefined && answerLength < +question.meta.min)
									}
								>
									{answerLength} Character(s)
								</FormHelperText>
							)}
						</Box>
					</>
				);
			}
			case QUESTION_TYPES.RANGE:
				return (
					<>
						<Box display={"flex"}>
							<MaskedInput
								mask={Number}
								thousandsSeparator={","}
								signed={false}
								radix={"."}
								scale={0}
								unmask={true}
								min={question.meta?.min ?? 1}
								max={question.meta?.max ?? 10000000000000}
								variant="outlined"
								label={`Minimum ${question.meta.placeholder || ""}`}
								placeholder={`Min. ${question.meta.placeholder || ""}`}
								value={userAnswers[question.id + ""]?.answer?.split("-")[0] || ""}
								onComplete={(value, mask) => {
									const [, max] = userAnswers[question.id + ""]?.answer?.split("-") || [];
									setUserAnswer(undefined, `${mask.unmaskedValue}-${max || ""}`);
								}}
								InputProps={{
									...(question.meta?.startAdornment && {
										startAdornment: <InputAdornment position="start">{question.meta?.startAdornment}</InputAdornment>,
									}),
									...(question.meta?.endAdornment && {
										endAdornment: <InputAdornment position="end">{question.meta?.endAdornment}</InputAdornment>,
									}),
								}}
							/>
							<MaskedInput
								mask={Number}
								thousandsSeparator={","}
								signed={false}
								radix={"."}
								scale={0}
								unmask={true}
								min={question.meta?.min ?? 1}
								max={question.meta?.max ?? 10000000000000}
								variant="outlined"
								label={`Maximum ${question.meta.placeholder || ""}`}
								placeholder={`Max. ${question.meta.placeholder || ""}`}
								value={userAnswers[question.id + ""]?.answer?.split("-")[1] || ""}
								onComplete={(value, mask) => {
									const [min] = userAnswers[question.id + ""]?.answer?.split("-") || [];
									setUserAnswer(undefined, `${min || ""}-${mask.unmaskedValue}`);
								}}
								style={{ marginLeft: 12 }}
								InputProps={{
									...(question.meta?.startAdornment && {
										startAdornment: <InputAdornment position="start">{question.meta?.startAdornment}</InputAdornment>,
									}),
									...(question.meta?.endAdornment && {
										endAdornment: <InputAdornment position="end">{question.meta?.endAdornment}</InputAdornment>,
									}),
								}}
							/>
						</Box>
						{error && (
							<FormHelperText style={{ marginInline: 14 }} error={true}>
								{error}
							</FormHelperText>
						)}
					</>
				);
			case QUESTION_TYPES.AUTOCOMPLETE: {
				const lines = question.answers[0]?.answer?.split("\n") || [];
				const isCommaSeparated = lines[0].split(",").length == 2 && lines[1].split(",").length == 2;
				const options =
					lines.reduce((acc, i) => {
						const pieces = i.split(",");
						const option = isCommaSeparated ? pieces[1] || pieces[0] || "" : i;
						if (option) {
							acc.push(option.trim());
						}
						return acc;
					}, []) || [];
				const props = {
					value: userAnswers[question.id + ""]?.answer || null,
				};
				if (question.meta.multiple) {
					props.multiple = true;
					if (props.value) {
						if (Array.isArray(props.value)) {
							props.value = props.value || [];
						} else {
							if (props.value) {
								try {
									props.value = JSON.parse(props.value);
								} catch (e) {
									console.error("Parse failed with autocomplete multi value", e);
									props.value = [props.value];
								}
							}
						}
					} else {
						props.value = [];
					}
				}
				return (
					<>
						<Autocomplete
							options={options}
							getOptionLabel={(option) => option}
							onChange={(e, newValue) => {
								setUserAnswer(undefined, newValue);
							}}
							{...props}
							style={{ width: 300 }}
							renderInput={(params) => (
								<TextField {...params} label={question.meta.placeholder || ""} variant="outlined" />
							)}
						/>
						{error && (
							<FormHelperText style={{ marginInline: 14 }} error={true}>
								{error}
							</FormHelperText>
						)}
					</>
				);
			}
			case QUESTION_TYPES.NAME: {
				let name = userAnswers[question.id + ""]?.answer || "{}";
				if (typeof name === "string") {
					name = JSON.parse(name);
				}
				return (
					<Grid container spacing={4}>
						<Grid item xs={12} sm={2}>
							<TextField
								fullWidth
								variant="outlined"
								label="Title"
								type="text"
								select
								placeholder="Title"
								value={name.title ? (TITLES.includes(name.title) ? name.title : "other") : ""}
								onChange={(e) => {
									setUserAnswer(undefined, { ...name, title: e.target.value });
								}}
							>
								{TITLES.map((option) => (
									<MenuItem key={option} value={option}>
										{option}
									</MenuItem>
								))}
								<MenuItem key={"other"} value={"other"}>
									Other
								</MenuItem>
							</TextField>
						</Grid>
						{name.title && TITLES.includes(name.title) === false && (
							<Grid item xs={12} sm={2}>
								<TextField
									variant="outlined"
									id="other-title"
									required
									label="Title"
									type="text"
									value={name.title !== "other" ? name.title : ""}
									name="title"
									onChange={(e) => {
										setUserAnswer(undefined, { ...name, title: e.target.value });
									}}
								/>
							</Grid>
						)}
						<Grid item xs={12} sm={3}>
							<TextField
								fullWidth
								required
								variant="outlined"
								label="First Name"
								type="text"
								value={name.firstname || ""}
								onChange={(e) => {
									setUserAnswer(undefined, { ...name, firstname: e.target.value });
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={3}>
							<TextField
								fullWidth
								variant="outlined"
								label="Middle Name"
								type="text"
								value={name.middlename || ""}
								onChange={(e) => {
									setUserAnswer(undefined, { ...name, middlename: e.target.value });
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={3}>
							<TextField
								fullWidth
								required
								variant="outlined"
								label="Last Name"
								type="text"
								value={name.lastname || ""}
								onChange={(e) => {
									setUserAnswer(undefined, { ...name, lastname: e.target.value });
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={2}>
							<TextField
								fullWidth
								variant="outlined"
								label="Suffix"
								type="text"
								value={name.suffix || ""}
								onChange={(e) => {
									setUserAnswer(undefined, { ...name, suffix: e.target.value });
								}}
							/>
						</Grid>
						{error && (
							<Grid item xs={12} sm={2}>
								<FormHelperText style={{ marginInline: 14 }} error={true}>
									{error}
								</FormHelperText>
							</Grid>
						)}
					</Grid>
				);
			}
			case QUESTION_TYPES.COMBO: {
				let answers = userAnswers[question.id + ""]?.answer || (question.meta.multiple ? "[]" : "{}");
				if (typeof answers === "string") {
					try {
						answers = JSON.parse(answers);
					} catch (err) {
						console.error("Parse failed with combo", err, answers);
						answers = question.meta.multiple ? [] : {};
					}
				}
				return <Combo question={question} answers={answers} setUserAnswer={setUserAnswer} error={error} />;
			}
			case QUESTION_TYPES.SORTABLE: {
				let answers = userAnswers[question.id + ""]?.answer || question.answers[0]?.answer.trim().split("\n");
				if (typeof answers === "string") {
					try {
						answers = JSON.parse(answers);
					} catch (err) {
						console.error("Parse failed with sortable", err, answers);
						answers = [];
					}
				}
				return <Sortable question={question} answers={answers} setUserAnswer={setUserAnswer} error={error} />;
			}
			case QUESTION_TYPES.UPLOAD_FILE: {
				let url = userAnswers[question.id + ""]?.answer;
				return <UploadFile question={question} url={url} setUserAnswer={setUserAnswer} error={error} />;
			}
			case QUESTION_TYPES.TAGS: {
				let answers = userAnswers[question.id + ""]?.answer || "[[]]";
				if (typeof answers === "string") {
					try {
						answers = JSON.parse(answers);
					} catch (err) {
						console.error("Parse failed with tags", err, answers);
						answers = [[]];
					}
				}
				return <Tags question={question} answers={answers} setUserAnswer={setUserAnswer} error={error} />;
			}
			default: {
				console.error("Unknown type of question", question.type);
				throw "Unknown type: " + question.type;
			}
		}
	}, [question, userAnswers, errors]);

	return (
		<>
			{question.meta.answerHint && <FormHelperText className={classes.hint}>{question.meta.answerHint}</FormHelperText>}
			{result}
		</>
	);
}
