import { Button } from "@material-ui/core";
import { useEffect, useState } from "react";
import { TimeSlot } from "./EachTimeSlot";

export function Schedule({ scheduleTimes = [], viewOnly = false, onDone, onClose, errors = null }) {
	const [times, setTime] = useState([]);
	useEffect(() => {
		setTime(() => {
			if (scheduleTimes?.length) {
				return [...scheduleTimes];
			} else {
				const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
				return [
					{ datetime: "", type: "VIRTUAL", timezone: timeZone, durationInMins: 60 },
					{ datetime: "", type: "VIRTUAL", timezone: timeZone, durationInMins: 60 },
					{ datetime: "", type: "VIRTUAL", timezone: timeZone, durationInMins: 60 },
				];
			}
		});
	}, [scheduleTimes]);
	function setValue(prop, value, index) {
		const _times = [...times];
		_times[index][prop] = value;
		setTime(_times);
	}
	return (
		<div>
			{!scheduleTimes?.length && <h4 className="font-bold mb-4">Provide 3 time slots to pick within next 14 days</h4>}
			<form>
				{times.map((item, idx) => {
					return (
						<TimeSlot
							error={(errors && errors[`slot_${idx}`]) || null}
							key={`time-slot-${idx}`}
							viewOnly={viewOnly}
							onChange={setValue}
							values={item}
							idx={idx}
						/>
					);
				})}
			</form>
			<div className="pt-4 flex justify-end gap-2">
				<Button variant="text" onClick={() => onClose()}>
					Close
				</Button>
				{!scheduleTimes?.length && (
					<Button variant="contained" color="primary" onClick={() => onDone(times)}>
						Save
					</Button>
				)}
			</div>
		</div>
	);
}
