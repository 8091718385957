import { CONTEXT_OPERATORS } from "src/helpers/constants";

export function execute(contextValue, operator, value) {
	switch (operator) {
		case CONTEXT_OPERATORS.EQUAL:
			return contextValue?.toString() == value;
		case CONTEXT_OPERATORS.NOT_EQUAL:
			return contextValue?.toString() != value;
		case CONTEXT_OPERATORS.STR_CONTAINS:
			return contextValue.includes(value);
		case CONTEXT_OPERATORS.STR_NOT_CONTAINS:
			return !contextValue.includes(value);
		case CONTEXT_OPERATORS.NONE_OF_THESE:
			return !(value || "").split(",").find((i) => contextValue.includes(i));
		case CONTEXT_OPERATORS.HAS_ALL:
			return (value || "").split(",").every((i) => contextValue.includes(i));
		case CONTEXT_OPERATORS.HAS_ANY:
			return (value || "").split(",").some((i) => contextValue.includes(i));
		case CONTEXT_OPERATORS.IS_EMPTY: {
			if (!contextValue) return true;
			if (Array.isArray(contextValue) && contextValue.length <= 0) return true;
			if (typeof contextValue === "object" && Object.values(contextValue).length <= 0) return true;
			return false;
		}
		case CONTEXT_OPERATORS.IS_NOT_EMPTY: {
			if (Array.isArray(contextValue) && contextValue.length > 0) return true;
			if (typeof contextValue === "object" && Object.values(contextValue).length > 0) return true;
			if (contextValue) return true;
			return false;
		}
		case CONTEXT_OPERATORS.GREATER_THAN: {
			return contextValue > value
		}
		case CONTEXT_OPERATORS.LESS_THAN: {
			return contextValue > value
		}
		default:
			return false;
	}
}
