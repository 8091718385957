export default function setByPath(obj, segments, value) {
	const lastIndex = segments.length - 1;
	for (let i = 0; i < lastIndex; i++) {
		if (obj[segments[i]]) {
			obj = obj[segments[i]];
		} else if(segments[i+1] !== undefined && Number.isInteger(segments[i+1])) {//array
			obj[segments[i]] = [];
			obj = obj[segments[i]];
		} else {
			obj[segments[i]] = {};
			obj = obj[segments[i]];
		}
	}

	obj[segments[lastIndex]] = value;
	return obj;
}
