import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { MaskedInput } from "src/components/commons/Inputs/MaskedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";

import { useState } from "react";
import { Card } from "src/components/commons/Card";
import { Tags } from "src/components/campaigns/Details/Tags";

export function AdminFilterView({ onChange, searching }) {
	const [filters, setFilters] = useState({
		minAmount: 100,
		maxAmount: 100000,
		industry: [],
		targetDemographies: [],
		targetDemographiesValue: "",
		targetLocations: [],
		targetLocationsValue: "",
		fundingLevels: [],
		developmentStages: [],
		orgTypes: [],
		fundingFormats: [],
		businessTypes: [],
		exitStrategies: [],
		legalClassifications: [],
	});
	return (
		<Card>
			<Grid
				container
				component={"form"}
				className="p-4"
				spacing={4}
				onSubmit={async (e) => {
					e.preventDefault();
					onChange({ ...filters });
				}}
			>
				<Grid item xs={12} md={3}>
					<MaskedInput
						mask={Number}
						fullWidth
						thousandsSeparator={","}
						signed={false}
						radix={"."}
						scale={0}
						unmask={true}
						min={100}
						max={filters.maxAmount}
						variant="outlined"
						placeholder={"Min. Amt"}
						label={"Min. Amt"}
						InputProps={{
							startAdornment: <InputAdornment position="start">$</InputAdornment>,
						}}
						value={filters.minAmount.toString()}
						onComplete={(value, mask) => {
							setFilters({ ...filters, minAmount: mask.unmaskedValue });
						}}
					/>
				</Grid>
				<Grid item xs={12} md={3}>
					<MaskedInput
						mask={Number}
						fullWidth
						thousandsSeparator={","}
						signed={false}
						radix={"."}
						scale={0}
						unmask={true}
						min={filters.minAmount + 1}
						max={1000000000}
						variant="outlined"
						placeholder={"Max. Amt"}
						label={"Max. Amt"}
						InputProps={{
							startAdornment: <InputAdornment position="start">$</InputAdornment>,
						}}
						value={filters.maxAmount.toString()}
						onComplete={(value, mask) => {
							setFilters({ ...filters, maxAmount: mask.unmaskedValue });
						}}
					/>
				</Grid>
				<Grid item xs={12} md={3}>
					<Autocomplete
						options={["Pre-seed", "Seed", "Series A", "Series B", "Series C", "Series D", "Series E"]}
						getOptionLabel={(option) => option}
						multiple={true}
						onChange={(e, newValue) => {
							setFilters({ ...filters, fundingLevels: newValue });
						}}
						value={filters.fundingLevels}
						style={{ width: 300 }}
						renderInput={(params) => <TextField {...params} label={"Funding Level"} variant="outlined" />}
					/>
				</Grid>
				<Grid item xs={12} md={3}>
					<Autocomplete
						options={[
							"Ideation stage",
							"Have MVP's or prototype/s",
							"Generating revenue",
							"Expanding or Scaling",
							"Ready for regulatory filing",
							"Ready to go to market",
							"Awaiting regulatory approval",
							"IPO Ready",
							"Proof of concept for development is present",
							"Prior to generating revenue",
						]}
						getOptionLabel={(option) => option}
						multiple={true}
						onChange={(e, newValue) => {
							setFilters({ ...filters, developmentStages: newValue });
						}}
						value={filters.developmentStages}
						style={{ width: 300 }}
						renderInput={(params) => <TextField {...params} label={"Development Stage"} variant="outlined" />}
					/>
				</Grid>
				<Grid item xs={12} md={3}>
					<Autocomplete
						options={[
							"Small Business/Scalable Startup",
							"Micro Business (<5 employees,<$50K)",
							"Medium to Large Company Startup",
							"Lifestyle Startup",
							"Not for Profit / Non-Profit Organization/s",
							"Family-based Small Business",
							"Public Not for Profit (US)",
							"Joint Ventures",
							"Special Purpose Acquisition Company (SPAC)",
							"Small Disadvantaged Businesses",
							"Technology Transfer from a University",
							"Social Startup (Benefit corp)",
							"Merger and Acquisition (M&A) Startup",
							"Educational Organization",
							"Research and Development Firm (R&D)",
						]}
						getOptionLabel={(option) => option}
						multiple={true}
						onChange={(e, newValue) => {
							setFilters({ ...filters, orgTypes: newValue });
						}}
						value={filters.orgTypes}
						style={{ width: 300 }}
						renderInput={(params) => <TextField {...params} label={"Organization Type"} variant="outlined" />}
					/>
				</Grid>
				<Grid item xs={12} md={3}>
					<Autocomplete
						options={[
							"Scalable Startup",
							"Startup mode",
							"Medium to Large Company that's scaling",
							"Mini-IPO Ready or Preparing (Reg A+)",
							"IPO Ready/Preparing",
							"Have MVP or prototype/s",
							"Ready to go to market",
							"Ready for regulatory filing",
							"Awaiting Regulatory Approval",
							"Ideation Stage",
							"Prior to generating revenue",
							"Merger/Acquisition (M&A) Preparing or Ready",
							"Generating Revenue",
						]}
						getOptionLabel={(option) => option}
						multiple={true}
						onChange={(e, newValue) => {
							setFilters({ ...filters, businessTypes: newValue });
						}}
						value={filters.businessTypes}
						style={{ width: 300 }}
						renderInput={(params) => <TextField {...params} label={"Founder Business Type"} variant="outlined" />}
					/>
				</Grid>
				<Grid item xs={12} md={3}>
					<Autocomplete
						options={[
							"Acquisition by a competitor",
							"Mergers and Acquisitions (M&A)",
							"Private sale",
							"Sell to employees",
							"Initial Public Offering (IPO)",
						]}
						getOptionLabel={(option) => option}
						multiple={true}
						onChange={(e, newValue) => {
							setFilters({ ...filters, exitStrategies: newValue });
						}}
						value={filters.exitStrategies}
						style={{ width: 300 }}
						renderInput={(params) => <TextField {...params} label={"Exit Strategy"} variant="outlined" />}
					/>
				</Grid>
				<Grid item xs={12} md={3}>
					<Autocomplete
						options={["Grant/s (Private/Public/Commercial)", "Loan/s", "Any form of a Note/s", "Equity/Stock"]}
						getOptionLabel={(option) => option}
						multiple={true}
						onChange={(e, newValue) => {
							setFilters({ ...filters, fundingFormats: newValue });
						}}
						value={filters.fundingFormats}
						style={{ width: 300 }}
						renderInput={(params) => <TextField {...params} label={"Funding Formats"} variant="outlined" />}
					/>
				</Grid>
				<Grid item xs={12} md={3}>
					<Autocomplete
						options={[
							"Cooperatives",
							"Corporation (C corp)",
							"Corporation (Benefit corp)",
							"Corporation (S corp)",
							"Government Organization (US)",
							"Government Organization (International)",
							"GmbH",
							"Individual/Sole Proprietor",
							"Joint Ventures",
							"Ltd. (limited) (UK)",
							"Limited Partnership (LP)",
							"Limited Liability Corporation (LLC)",
							"Not For Profit NPO/ Foundation (EU)",
							"Non-profit/ Not for Profit",
							"Partnerships",
							"Private limited company (BV/SRL) (EU)",
							"Not yet formed",
						]}
						getOptionLabel={(option) => option}
						multiple={true}
						onChange={(e, newValue) => {
							setFilters({ ...filters, legalClassifications: newValue });
						}}
						value={filters.legalClassifications}
						style={{ width: 300 }}
						renderInput={(params) => <TextField {...params} label={"Legal Classification"} variant="outlined" />}
					/>
				</Grid>
				<Grid item xs={12} md={3}>
					<Box className="mb-4">
						<Autocomplete
							options={["disability", "underserved", "socioeconomic", "ethnicity", "gender", "religion", "veterans"]}
							getOptionLabel={(option) => option}
							value={filters.targetDemographies[0] || null}
							onChange={(e, newValue) => {
								const filter = { targetDemographies: [], targetDemographiesValue: "" };
								if (newValue) {
									filter.targetDemographies = [newValue];
									filter.targetDemographiesValue = filters.targetDemographiesValue;
								}
								setFilters({ ...filters, ...filter });
							}}
							style={{ width: 300 }}
							renderInput={(params) => <TextField {...params} label={"Demographies"} variant="outlined" />}
						/>
					</Box>
					{filters.targetDemographies?.length > 0 && (
						<TextField
							type={"text"}
							label={"Specific Demography"}
							fullWidth
							autoComplete="current-password"
							onChange={(event) => setFilters({ ...filters, targetDemographiesValue: event.target.value })}
							variant="outlined"
						/>
					)}
				</Grid>
				<Grid item xs={12} md={3}>
					<Box className="mb-4">
						<Autocomplete
							options={["US", "US Cities", "US States", "US Regions", "International Regions", "Countries"]}
							getOptionLabel={(option) => option}
							value={filters.targetLocations[0] || null}
							onChange={(e, newValue) => {
								const filter = { targetLocations: [], targetLocationsValue: "" };
								if (newValue) {
									filter.targetLocations = [newValue];
									filter.targetLocationsValue = filters.targetLocationsValue;
								}
								setFilters({ ...filters, ...filter });
							}}
							style={{ width: 300 }}
							renderInput={(params) => <TextField {...params} label={"Location"} variant="outlined" />}
						/>
					</Box>
					{filters.targetLocations?.length > 0 && (
						<TextField
							type={"text"}
							label={"Specific Locations"}
							fullWidth
							autoComplete="current-password"
							onChange={(event) => setFilters({ ...filters, targetLocationsValue: event.target.value })}
							variant="outlined"
						/>
					)}
				</Grid>
				<Grid item xs={12} md={12}>
					<Tags
						question={{
							meta: {
								multiple: false,
								placeholder: "Industry",
								dataSourceURL: "/industries?filter=name||$contL||{value}&or=synonyms||$contL||{value}",
							},
						}}
						answers={[filters.industry]}
						setUserAnswer={(_, industries) => {
							setFilters({ ...filters, industry: industries[0] });
						}}
						error={null}
					/>
				</Grid>
				<Grid item xs={12}>
					<Box className="flex justify-end">
						<Button type="submit" variant="contained" color="primary" disabled={searching}>
							{searching && <CircularProgress color="inherit" size={"1rem"} className="mr-2" />} Search
						</Button>
					</Box>
				</Grid>
			</Grid>
		</Card>
	);
}
