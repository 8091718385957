import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Suspense } from "react";
import { Await } from "react-router-dom";

export function Async({ error, promise, children }) {
	if (typeof children !== "function") {
		throw "Children of Async component must be a function";
	}
	return (
		<Suspense
			fallback={
				<Box height={"100%"} display="flex" justifyContent={"center"} alignItems={"center"}>
					<CircularProgress />
				</Box>
			}
		>
			<Await
				resolve={promise}
				errorElement={
					<Box padding={4} textAlign="center">
						{error || "Could not complete the request"}
					</Box>
				}
				children={children}
			/>
		</Suspense>
	);
}
