import { CircularProgress } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getActiveTenantAdminCampaignsWithMatches } from "src/api/campaigns";
import { Card } from "src/components/commons/Card";

export function TACampaignMatches() {
	const [campaigns, setCampaigns] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		getActiveTenantAdminCampaignsWithMatches()
			.then((res) => {
				setCampaigns(res);
				setLoading(false);
			})
			.catch((e) => {
				console.error("Error while fetching campaigns for TA", e);
				setError("Unable to fetch founder matches");
				setLoading(false);
			});
	}, []);

	return (
		<Card>
			<div className="p-4 bg-green-300 rounded-t-xl">
				<h2 className="text-lg">Your Founders&apos; Matches</h2>
			</div>
			{loading && (
				<div className="flex justify-center items-center p-20">
					<CircularProgress size={16} />
				</div>
			)}
			{error && <div className="text-red-700 text-center">{error}</div>}
			{!loading && campaigns.length ? (
				<div className="table table-fixed overflow-hidden">
					{campaigns.map((campaign) => {
						return (
							<div key={`ta-camp-id-${campaign.id}`} className="table-row">
								<div className="table-cell px-4 py-3 border-r border-b text-xl align-middle">{campaign.name}</div>
								<div className="table-cell border-b align-middle">
									{campaign.matches.map((match) => (
										<div
											className="flex border-b last:border-b-0 items-center gap-2"
											key={`ta-camp-match-id-${match.id}`}
										>
											<div className="flex-1 px-4 ">
												{match.opportunity.name}
												<Link className="text-indigo-500 ml-4" to={`/match/${match.id}/opportunity`}>
													View
												</Link>
											</div>
											<div className="border-l p-4">
												Matched On:{" "}
												{new Intl.DateTimeFormat(undefined, {
													month: "short",
													day: "2-digit",
													year: "numeric",
												}).format(new Date(match.createdAt))}
											</div>
										</div>
									))}
								</div>
							</div>
						);
					})}
				</div>
			) : (
				<div className="p-8 text-center text-slate-400">There are no founder applications</div>
			)}
		</Card>
	);
}
