import { fetchAdminV1 } from "../lib/requester/v1";

export function populateAllParameters() {
	return fetchAdminV1("/opportunity_matching_parameters/populate/all", { method: "GET" });
}
export function populateParameters(opportunityId) {
	return fetchAdminV1(
		`/opportunity_matching_parameters/populate/${opportunityId}`,
		{ method: "GET" }
	);
}
