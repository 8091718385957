import { Box, CircularProgress, IconButton, Typography } from "@material-ui/core";
import DeleteForever from "@material-ui/icons/DeleteForever";
import { useState } from "react";
import clsx from "clsx";
import { useEffect, useRef } from "react";

export function StepListItem({ onClick, idx, currentStep, classes, group, groupDescription, onDelete, errors = {} }) {
	const [deleting, setDeleting] = useState(false);
	const listItemRef = useRef();

	useEffect(() => {
		if (currentStep?.stepIndex === idx && currentStep?.questionIndex === -1) {
			listItemRef.current?.scrollIntoView();
		}
	}, [currentStep, idx]);

	async function deleteStep(e) {
		e.stopPropagation();
		try {
			setDeleting(true);
			await onDelete(idx);
		} catch (err) {
			console.error(`Error while deleting step ${group}`, err);
			setDeleting(false);
		}
	}
	return (
		<Box
			ref={listItemRef}
			component={"li"}
			px={4}
			py={2}
			className={clsx(
				classes.list,
				"step",
				currentStep?.stepIndex === idx && currentStep?.questionIndex === -1 && "selected"
			)}
			onClick={onClick}
		>
			<Box display={"flex"} justifyContent={"space-between"}>
				<Typography
					variant="subtitle1"
					color={errors[`step${idx}`]?.step || errors[`step${idx}`]?.empty ? "error" : "textSecondary"}
				>
					Step {idx + 1}: {group}
				</Typography>
				{deleting ? (
					<CircularProgress color="secondary" size={"1rem"} style={{ margin: "0.5rem 0 0 0.5rem" }} />
				) : (
					<IconButton variant="contained" color="secondary" size="small" disabled={deleting} onClick={deleteStep}>
						<DeleteForever size="small" />
					</IconButton>
				)}
			</Box>
			<Typography variant="body2" color={"textSecondary"}>
				{groupDescription}
			</Typography>
		</Box>
	);
}
