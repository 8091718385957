import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
// import { useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import { alpha } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
// import CmtImage from "../../../../@coremat/CmtImage";
import IntlMessages from "src/@jumbo/utils/IntlMessages";
// import { AuthMethods } from "src/services/auth";
import ContentLoader from "src/@jumbo/components/ContentLoader";
import Typography from "@material-ui/core/Typography";
// import { CurrentAuthMethod } from "src/@jumbo/constants/AppConstants";
import { forgotPassword } from "src/api/auth";
import AuthWrapper from "./AuthWrapper";

const useStyles = makeStyles((theme) => ({
	authThumb: {
		backgroundColor: alpha(theme.palette.primary.main, 0.12),
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		padding: 20,
		[theme.breakpoints.up("md")]: {
			width: "50%",
			order: 2,
		},
	},
	authContent: {
		padding: 30,
		[theme.breakpoints.up("md")]: {
			order: 1,
			width: (props) => (props.variant === "default" ? "50%" : "100%"),
		},
		[theme.breakpoints.up("xl")]: {
			padding: 50,
		},
	},
	titleRoot: {
		marginBottom: 14,
		color: theme.palette.text.primary,
	},
	textFieldRoot: {
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: alpha(theme.palette.common.dark, 0.12),
		},
	},
}));
//variant = 'default', 'standard', 'bgColor'
const ForgotPassword = ({ variant = "default", wrapperVariant = "default" }) => {
	const [email, setEmail] = useState("");
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(null);
	const { tenant } = useSelector(({ auth }) => auth);
	// const dispatch = useDispatch();
	const classes = useStyles({ variant });

	const onSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		setError(null);
		try {
			await forgotPassword({ email, tenantId: tenant.id });
			setSuccess("Reset link has been sent to your email. Don't forget to check the spam folder too.");
		} catch (err) {
			console.error("Forgot password link sending failed", err);
			setError(err.message || "Unable to send reset link");
		} finally {
			setLoading(false);
		}
	};

	return (
		<AuthWrapper variant={wrapperVariant}>
			<Typography component="h1" variant="h5" align="center" className={classes.titleRoot}>
				Forgot Password
			</Typography>
			{error && <div className="text-red-700 text-center">{error}</div>}
			{success && <div className="text-green-600 text-center">{success}</div>}
			<form onSubmit={onSubmit}>
				<Box mb={5}>
					<TextField
						label={<IntlMessages id="appModule.email" />}
						fullWidth
						onChange={(event) => setEmail(event.target.value)}
						defaultValue={email}
						margin="normal"
						variant="outlined"
						className={classes.textFieldRoot}
					/>
				</Box>
				<Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
					<Box component="p" fontSize={{ xs: 12, sm: 16 }}>
						<NavLink to="/login">Back to login</NavLink>
					</Box>
					<Button type="submit" variant="contained" color="primary">
						Email Reset Link {loading && <CircularProgress color="inherit" size={"1rem"} className="ml-2" />}
					</Button>
				</Box>

				<Box>
					<Typography>
						Don&apos;t remember your email?
						<Box component="span" ml={2}>
							<NavLink to="/contact-us">
								<IntlMessages id="appModule.contactSupport" />
							</NavLink>
						</Box>
					</Typography>
				</Box>
			</form>
			<ContentLoader />
		</AuthWrapper>
	);
};

export default ForgotPassword;
