import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AuthWrapper from "src/@jumbo/components/Common/authComponents/AuthWrapper";
import { verifyEmail } from "src/api/auth";
import { setAuthUser } from "src/store/slices/auth";

export function VerifyEmailPage() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [wait, setWait] = useState(false);
	const [error, setError] = useState(null);
	const data = useRef({ email: null, hash: null, tenantId: null });

	useEffect(() => {
		const usp = new URLSearchParams(window.location.href.split("?")[1]);
		const email = usp.get("email") || "";
		const hash = usp.get("hash");
		const tenantId = usp.get("t");
		const wait = usp.get("wait");
		data.current.hash = hash;
		data.current.email = email;
		data.current.tenantId = tenantId;
		if (wait) {
			setWait(true);
		} else {
			onSubmit();
		}
	}, []);

	const onSubmit = async () => {
		setError(null);
		try {
			const user = await verifyEmail({
				email: data.current.email,
				hash: data.current.hash,
				tenantId: +data.current.tenantId,
			});
			dispatch(setAuthUser(user));
			navigate("/dashboard");
		} catch (err) {
			console.error("Verifying email failed", err);
			setError(err.message || "Unable to verify email address");
		}
	};

	if (wait) {
		return (
			<AuthWrapper variant={"other"}>
				<div className="text-center">
					We have sent you a verification email to your registered email address. Please click the link from the
					verification email to proceed. If you didn&apos;t find the email in the inbox, Please check the spam folder.
				</div>
				<div className="text-center pt-4">You can close this tab.</div>
			</AuthWrapper>
		);
	}

	return (
		<AuthWrapper variant={"other"}>
			{error ? (
				<div className="text-center">
					<div className="text-red-700">{error}</div>
					<div>
						<a href="/contact-us" className="underline">
							Contact Us
						</a>
					</div>
				</div>
			) : (
				<Typography component="h1" variant="h5" align="center">
					<CircularProgress /> Verifying Email...
				</Typography>
			)}
		</AuthWrapper>
	);
}
