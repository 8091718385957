import { useState } from "react";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";
import Alert from "src/helpers/alert";
import { create } from "src/api/auth";
import { ONBOARDING_STEPS, ROLES } from "src/helpers/constants";

export function CreateUser({ tenants = [], onClose }) {
	const [email, setEmail] = useState(null);
	const [firstName, setFirstName] = useState(null);
	const [lastName, setLastName] = useState(null);
	const [roles, setRoles] = useState([]);
	const [password, setPassword] = useState(null);
	const [tenantId, setTenantId] = useState(1);
	const [submitting, setSubmitting] = useState(false);

	return (
		<Box
			component={"form"}
			className="bg-white mx-auto my-20 px-4 rounded-lg"
			width={"400px"}
			onSubmit={async (e) => {
				e.preventDefault();
				setSubmitting(true);
				try {
					await create({
						email,
						tenantId,
						role: roles.join(","),
						firstName,
						lastName,
						password,
						isEmailVerified: true,
						onboarding: roles.reduce((acc, role) => {
							acc[role] = { isCompleted: false, completedStep: ONBOARDING_STEPS.SIGNUP };
							return acc;
						}, {}),
					});
					Alert.success("User successfully created");
					onClose();
				} catch (err) {
					console.error("Error while creating user", err);
					Alert.error(err.code === "E_42201" ? "Email doesn't exist" : "Unable to complete");
				} finally {
					setSubmitting(false);
				}
			}}
		>
			<Box component={"h2"} className="text-xl pt-4">
				Create User
			</Box>
			<TextField
				label={"Email"}
				onChange={(event) => setEmail(event.target.value?.toLowerCase())}
				value={email || ""}
				type="email"
				margin="normal"
				fullWidth
				required
				size="small"
				variant="outlined"
			/>
			<TextField
				label={"First Name"}
				onChange={(event) => setFirstName(event.target.value)}
				value={firstName || ""}
				fullWidth
				margin="normal"
				required
				size="small"
				variant="outlined"
			/>
			<TextField
				label={"Last Name"}
				onChange={(event) => setLastName(event.target.value)}
				value={lastName || ""}
				fullWidth
				margin="normal"
				required
				size="small"
				variant="outlined"
			/>
			<TextField
				label={"Password"}
				onChange={(event) => setPassword(event.target.value)}
				value={password || ""}
				fullWidth
				margin="normal"
				required
				size="small"
				variant="outlined"
			/>
			<Autocomplete
				multiple
				value={roles}
				onChange={(event, newValue) => {
					setRoles(newValue);
				}}
				options={[ROLES.FOUNDER, ROLES.FUNDER, ROLES.VENDOR]}
				renderTags={(tagValue) =>
					tagValue.map((option, index) => (
						<Chip
							label={option}
							key={`c-u-r-${index}`}
							onDelete={() => {
								const index = roles.findIndex((i) => i === option);
								const _roles = [...roles];
								_roles.splice(index, 1);
								index >= 0 && setRoles(_roles);
							}}
						/>
					))
				}
				renderInput={(params) => (
					<TextField
						{...params}
						size="small"
						margin="normal"
						label="Roles"
						fullWidth
						variant="outlined"
						placeholder="Roles"
					/>
				)}
			/>

			<TextField
				value={tenantId || ""}
				margin="normal"
				required
				fullWidth
				size="small"
				variant="outlined"
				onChange={(e) => {
					setTenantId(+e.target.value);
				}}
				label="White Label"
				select
			>
				<MenuItem value="">
					<em>None</em>
				</MenuItem>
				{tenants.map((tenant, idx) => {
					return (
						<MenuItem key={`tenant_${idx}_select`} value={tenant.id}>
							{tenant.name}
						</MenuItem>
					);
				})}
			</TextField>
			<div className="flex gap-4 justify-end py-4 border-t mt-2">
				<Button
					type={"button"}
					disabled={submitting}
					variant="contained"
					color="default"
					onClick={() => onClose(false)}
				>
					Cancel
				</Button>
				<Button type={"submit"} disabled={submitting} variant="contained" color="secondary">
					Create {submitting ? <CircularProgress size={"1rem"} /> : ""}
				</Button>
			</div>
		</Box>
	);
}
