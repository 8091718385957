import { useState, useMemo } from "react";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Button from "@material-ui/core/Button";
import Flare from "@material-ui/icons/Flare";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { MATCH_ACTION } from "src/helpers/constants";
import { updateSchedule } from "src/api/matches";
import Alert from "src/helpers/alert";
import { getMatchId } from "src/helpers";
import { ConfirmDialog } from "../commons/Dialog";
import { Schedule } from "../commons/Schedule";

export function EachMatch({ match, onAction, currentRole }) {
	const [showSchedule, setShowSchedule] = useState(false);
	const [showDetails, setShowDetails] = useState(false);
	const [scheduleError, setScheduleError] = useState(null);
	const [scheduledTimes, setScheduledTimes] = useState([]);
	const diff = 72 - Math.floor((Date.now() - new Date(match.createdAt).getTime()) / (1000 * 60 * 60));
	async function saveSchedule(times) {
		try {
			await updateSchedule({ id: match.id, times, currentRole });
			setShowSchedule(false);
			Alert.success("Schedule sent successfully");
		} catch (err) {
			console.error("Unable to save schedule", err);
			if (err.code && err.code === "E_MA_42204") {
				setScheduleError(err.data);
				Alert.error(err.message);
				return;
			}
			Alert.error("Unable to save schedule");
			// TODO: Error display
		}
	}
	const status = useMemo(() => {
		if (match.founderAction === MATCH_ACTION.UNDECIDED && match.funderAction === MATCH_ACTION.ACCEPTED) {
			return <div className="text-gray-500 text-center">⏳ Waiting for the founder to respond</div>;
		} else if (match.funderAction === MATCH_ACTION.UNDECIDED) {
			return <div className="text-yellow-600 font-bold text-center px-2">⏰ You have {diff} hours to respond</div>;
		} else {
			return <></>;
		}
	}, [match]);

	const actions = useMemo(() => {
		if (match.funderAction === MATCH_ACTION.UNDECIDED) {
			return (
				<>
					<Button
						variant="contained"
						color="primary"
						size="large"
						className="text-lg"
						onClick={() => onAction(match, MATCH_ACTION.ACCEPTED)}
					>
						Accept
					</Button>
					<Button variant="outlined" color="inherit" className="text-lg" onClick={() => onAction(match, MATCH_ACTION.ARCHIVED)}>
						Archive
					</Button>
					<Button variant="outlined" color="inherit" className="text-lg" onClick={() => onAction(match, MATCH_ACTION.REJECTED)}>
						Ignore
					</Button>
				</>
			);
		} else if (match.founderAction === MATCH_ACTION.ACCEPTED && match.funderAction === MATCH_ACTION.ACCEPTED) {
			return (
				<>
					<Button variant="outlined" color="inherit" href={`/match/${match.id}/campaign`}>
						View Full Details
					</Button>
					{!match.proposedSchedules?.length && (
						<Button variant="contained" color="secondary" onClick={() => setShowSchedule(true)}>
							Schedule A Call
						</Button>
					)}
					{match.proposedSchedules[0]?.role === currentRole && (
						<Button
							variant="outlined"
							color="inherit"
							onClick={() => {
								setScheduledTimes(match.proposedSchedules[0].times);
								setShowSchedule(true);
							}}
						>
							View schedule
						</Button>
					)}
				</>
			);
		} else {
			return <div className="text-gray-500 text-center">⏳ Waiting for response</div>;
		}
	}, [match, onAction]);
	return (
		<>
			<div
				key={`opp-match-${match.id}`}
				className="flex justify-between px-4 py-3 cursor-pointer"
				onClick={() => setShowDetails(!showDetails)}
			>
				<div className="font-bold">
					<Flare className="text-primary" /> Match #{getMatchId(match.id)}
				</div>
				{status}
				<div>{showDetails ? <KeyboardArrowDownIcon /> : <ChevronRight />}</div>
			</div>
			{showDetails && (
				<div className="grid grid-cols-8 border-t text-slate-600">
					<div className="col-span-6 h-80 overflow-auto">
						<div className="[&>*:nth-child(even)]:bg-indigo-50">
							<div className="p-2 font-bold text-lg bg-indigo-400 text-white">
								<CheckCircleOutlineIcon />
								Business Details
							</div>
							<div className="flex p-2">
								<div className="min-w-[220px] pr-2">Business Stage:</div>
								<div className="font-bold">{match.founderDisplayDetails.growthStage?.join(", ")}</div>
							</div>
							<div className="flex p-2">
								<div className="min-w-[220px] pr-2">Registered As:</div>
								<div className="font-bold">{match.founderDisplayDetails.legalClassification || "-"}</div>
							</div>
							<div className="flex p-2">
								<div className="min-w-[220px] pr-2">Business Type:</div>
								<div className="font-bold">{match.founderDisplayDetails.businessType?.join(", ") || "-"}</div>
							</div>
							<div className="flex p-2">
								<div className="min-w-[220px] pr-2">Business Form:</div>
								<div className="font-bold">{match.founderDisplayDetails.organizationClassification?.join(", ") || "-"}</div>
							</div>
							<div className="flex p-2">
								<div className="min-w-[220px] pr-2">Industry:</div>
								<div className="font-bold">
									{match.founderDisplayDetails.industry?.length
										? match.founderDisplayDetails.industry.join(", ")
										: "Any"}
								</div>
							</div>
							<div className="flex p-2">
								<div className="min-w-[220px] pr-2">Location:</div>
								<div className="font-bold">{"-"}</div>
							</div>
							<div className="flex p-2">
								<div className="min-w-[220px] pr-2">Due Diligence Docs:</div>
								<div className="font-bold">{match.founderDisplayDetails.availableDueDiligenceDocuments?.join(", ") || "-"}</div>
							</div>
							
						</div>
						<div className="[&>*:nth-child(even)]:bg-indigo-50">
							<div className="p-2 font-bold text-lg bg-indigo-400 text-white">
								<CheckCircleOutlineIcon />
								Funding Details
							</div>
							<div className="flex p-2">
								<div className="min-w-[220px] pr-2">Funding Level:</div>
								<div className="font-bold">{match.founderDisplayDetails.raisingFundLevel?.join(", ") || "-"}</div>
							</div>
							<div className="flex p-2">
								<div className="min-w-[220px] pr-2">Funding Format:</div>
								<div className="font-bold">{match.founderDisplayDetails.acceptableFundingFormats?.join(", ") || "-"}</div>
							</div>
							<div className="flex p-2">
								<div className="min-w-[220px] pr-2">Has Prior Funding:</div>
								<div className="font-bold">{match.founderDisplayDetails.hasPriorFunding ? "Yes" : "No"}</div>
							</div>
						</div>
						<div className="[&>*:nth-child(even)]:bg-indigo-50">
							<div className="p-2 font-bold text-lg bg-indigo-400 text-white">
								<CheckCircleOutlineIcon />
								Competence
							</div>
							<div className="flex p-2">
								<div className="min-w-[220px] pr-2">Exit Strategy:</div>
								<div className="font-bold">{match.founderDisplayDetails.exitStrategy?.join(", ") || "-"}</div>
							</div>
							<div className="flex p-2">
								<div className="min-w-[220px] pr-2">Intellectual Property:</div>
								<div className="font-bold">{match.founderDisplayDetails.intellectualProperty?.intellectualProperties?.join(", ") || "-"}</div>
							</div>
							<div className="flex p-2">
								<div className="min-w-[220px] pr-2">Market Size:</div>
								<div className="font-bold">{match.founderDisplayDetails.marketSize || "-"}</div>
							</div>
						</div>
						<div className="[&>*:nth-child(even)]:bg-indigo-50">
							<div className="p-2 font-bold text-lg bg-indigo-400 text-white">
								<CheckCircleOutlineIcon />
								Team
							</div>
							<div className="flex p-2">
								<div className="min-w-[220px] pr-2">Collective Experience:</div>
								<div className="font-bold">{match.founderDisplayDetails.collectiveExperience || "-"}</div>
							</div>
							<div className="flex p-2">
								<div className="min-w-[220px] pr-2">Total Founders:</div>
								<div className="font-bold">{match.founderDisplayDetails.totalFounders || "-"}</div>
							</div>
							<div className="flex p-2">
								<div className="min-w-[220px] pr-2">Founder Degrees:</div>
								<div className="font-bold">{match.founderDisplayDetails.degrees?.join(", ") || "-"}</div>
							</div>
							<div className="flex p-2">
								<div className="min-w-[220px] pr-2">Total Exits:</div>
								<div className="font-bold">{match.founderDisplayDetails.totalExits || "-"}</div>
							</div>
							<div className="flex p-2">
								<div className="min-w-[220px] pr-2">Total Team Size:</div>
								<div className="font-bold">{match.founderDisplayDetails.totalTeamSize || "-"}</div>
							</div>
							<div className="flex p-2">
								<div className="min-w-[220px] pr-2">Total Board Member(s):</div>
								<div className="font-bold">{match.founderDisplayDetails.board?.totalMembers || "-"}</div>
							</div>
						</div>
						{/* <DisplayKeyValuePair data={match.founderDisplayDetails} /> */}
					</div>
					<div className="col-span-2 flex flex-col justify-center gap-4 px-4 bg-indigo-100">{actions}</div>
				</div>
			)}
			{showSchedule && (
				<ConfirmDialog
					show={true}
					title={`Time Slots for match #${getMatchId(match.id)}`}
					onClose={() => setShowSchedule(false)}
					body={
						<Schedule
							errors={scheduleError}
							scheduleTimes={scheduledTimes}
							viewOnly={!!scheduledTimes.length}
							onDone={(times) => {
								saveSchedule(times);
							}}
							onClose={() => setShowSchedule(false)}
						/>
					}
					showFooter={false}
				/>
			)}
		</>
	);
}
