import React, { useState, useEffect, useRef } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
// import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
// import { alpha } from "@material-ui/core/styles";

import { getOpportunityWithUserAnswersForAdmin } from "src/api/opportunities";
import { getCampaignWithUserAnswersForAdmin } from "src/api/campaigns";
import { create, getMatchByParams } from "src/api/matches";
import Alert from "src/helpers/alert";
import { populateParameters as populateCampaignParameters } from "src/api/campaignMatchingParameters";
import { populateParameters as populateOpportunityParameters } from "src/api/opportunityMatchingParameters";
import { EntityListView } from "./EntityListView";
import { SelectedEntityView } from "./SelectedEntityView";

export function MatchView({ allCampaigns, allOpportunities }) {
	const [campaigns, setCampaigns] = useState({ data: [], total: 0 });
	const [opportunities, setOpportunities] = useState({ data: [], total: 0 });
	const [selectedCampaign, setSelectedCampaign] = useState(null);
	const [selectedOpportunity, setSelectedOpportunity] = useState(null);
	const [isSaving, setSaving] = useState(false);
	const [isFetchingCampaignAnswers, setFetchingCampaignAnswers] = useState(false);
	const [isFetchingOpportunityAnswers, setFetchingOpportunityAnswers] = useState(false);
	const [canMatch, setCanMatch] = useState(false);
	const campaignQuestionAnswers = useRef(new Map());
	const opportunityQuestionAnswers = useRef(new Map());

	useEffect(() => {
		setCampaigns({ ...allCampaigns });
		setSelectedCampaign(null);
	}, [allCampaigns]);

	useEffect(() => {
		setOpportunities({ ...allOpportunities });
		setSelectedOpportunity(null);
	}, [allOpportunities]);

	async function save() {
		if (!canMatch) {
			return false;
		}
		setSaving(true);
		try {
			await create({ opportunityId: selectedOpportunity.id, campaignId: selectedCampaign.id });
			Alert.success("Match Saved Successfully");
			setCanMatch(false);
		} catch (err) {
			Alert.error("Unable to save the match");
			console.error("Unable to save the matches", err);
		} finally {
			setSaving(false);
		}
	}

	useEffect(() => {
		if (selectedCampaign) {
			if (!campaignQuestionAnswers.current.get(selectedCampaign.id)) {
				(async function campaignSelection() {
					setFetchingCampaignAnswers(true);
					const campaign = await getCampaignWithUserAnswersForAdmin(selectedCampaign.id);
					campaignQuestionAnswers.current.set(selectedCampaign.id, campaign.userAnswers);
					setFetchingCampaignAnswers(false);
				})();
			}
		}
	}, [selectedCampaign]);

	useEffect(() => {
		if (selectedOpportunity) {
			if (!opportunityQuestionAnswers.current.get(selectedOpportunity.id)) {
				(async function oppSelection() {
					setFetchingOpportunityAnswers(true);
					const opportunity = await getOpportunityWithUserAnswersForAdmin(selectedOpportunity.id);
					opportunityQuestionAnswers.current.set(selectedOpportunity.id, opportunity.userAnswers);
					setFetchingOpportunityAnswers(false);
				})();
			}
		}
	}, [selectedOpportunity]);

	useEffect(() => {
		if (selectedCampaign && selectedOpportunity) {
			(async function fetchMatch() {
				const result = await getMatchByParams({
					filter: [`campaignId||$eq||${selectedCampaign.id}`, `opportunityId||$eq||${selectedOpportunity.id}`],
				});
				setCanMatch(result.length === 0);
			})();
		}
	}, [selectedCampaign, selectedOpportunity]);
	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<Box display={"flex"} justifyContent="space-between">
					<Box display={"flex"} alignItems={"center"}>
						<Typography component={"h2"} variant={"h5"}>
							Match:{" "}
						</Typography>
						<Typography component={"p"} variant={"h6"} style={{ marginLeft: 8 }}>
							{selectedOpportunity?.name || (
								<Box component="span" className="placeholder">
									Select An Opportunity
								</Box>
							)}
							&nbsp;&lt;=&gt;&nbsp;
							{selectedCampaign?.name || (
								<Box component="span" className="placeholder">
									Select A Campaign
								</Box>
							)}
						</Typography>
					</Box>
					<Button variant="contained" disabled={!canMatch || isSaving} color="secondary" onClick={save}>
						Match
					</Button>
				</Box>
			</Grid>
			<EntityListView
				category={"opportunity"}
				selectedEntity={selectedOpportunity}
				paginatedEntities={opportunities}
				onSelect={(entity) => {
					const _opportunity = { ...entity };
					if (_opportunity.matchingParameter) {
						_opportunity.matchingParameter.description = _opportunity.description;
					}
					setSelectedOpportunity(_opportunity);
				}}
			/>
			<SelectedEntityView
				category={"opportunity"}
				selectedEntity={selectedOpportunity}
				isFetching={isFetchingOpportunityAnswers}
				userAnswers={opportunityQuestionAnswers.current.get(selectedOpportunity?.id) || []}
				onPopulate={async () => {
					try {
						const oppMatchingParameters = await populateOpportunityParameters(selectedOpportunity.id);
						const _allOpportunities = { ...opportunities };
						const index = _allOpportunities.data.findIndex((i) => i.id === selectedOpportunity.id);
						_allOpportunities.data[index].matchingParameter = oppMatchingParameters;
						setOpportunities(_allOpportunities);
						setSelectedOpportunity({
							...selectedOpportunity,
							matchingParameter: { ...oppMatchingParameters },
						});
					} catch (err) {
						Alert.error("Unable to populate");
						console.error("error while populating opportunity params", err);
					}
				}}
			/>
			<SelectedEntityView
				category={"campaign"}
				selectedEntity={selectedCampaign}
				isFetching={isFetchingCampaignAnswers}
				userAnswers={campaignQuestionAnswers.current.get(selectedCampaign?.id) || []}
				onPopulate={async () => {
					try {
						const campaignMatchingParameters = await populateCampaignParameters(selectedCampaign.id);
						const _allCampaigns = { ...campaigns };
						const index = _allCampaigns.data.findIndex((i) => i.id === selectedCampaign.id);
						_allCampaigns.data[index].matchingParameter = campaignMatchingParameters;
						setCampaigns(_allCampaigns);
						setSelectedCampaign({
							...selectedCampaign,
							matchingParameter: { ...campaignMatchingParameters },
						});
					} catch (err) {
						Alert.error("Unable to populate campaign");
						console.error("error while populating campaign params", err);
					}
				}}
			/>
			<EntityListView
				category={"campaign"}
				selectedEntity={selectedCampaign}
				paginatedEntities={campaigns}
				onSelect={(entity) => {
					const _campaign = { ...entity };
					_campaign.matchingParameter = {
						description: _campaign.description,
						..._campaign.matchingParameter,
					};
					setSelectedCampaign(_campaign);
				}}
			/>
		</Grid>
	);
}
