import { fetchV1, fetchAdminV1, fetchTenantAdminV1 } from "../lib/requester/v1";

export function getAll(params) {
	return fetchV1("/campaigns", { method: "GET", data: params }, { returnWholeResponse: true });
}

export function getAllForAdmin(params) {
	return fetchAdminV1("/campaigns", { method: "GET", data: params }, { returnWholeResponse: true });
}

export function getAllForTenantAdmin(params) {
	return fetchTenantAdminV1("/campaigns", { method: "GET", data: params }, { returnWholeResponse: true });
}

export function searchForAdmin(data) {
	return fetchAdminV1("/campaigns/search", { method: "POST", data }, { returnWholeResponse: true });
}

export function getCampaignWithUserAnswersForAdmin(id) {
	return fetchAdminV1(`/campaigns/${id}/user_answers`, { method: "GET" });
}
export function getCampaignWithUserAnswersForTenantAdmin(id) {
	return fetchTenantAdminV1(`/campaigns/${id}/user_answers`, { method: "GET" });
}

export function getCampaignTeamMembers(id) {
	return fetchTenantAdminV1(`/campaigns/${id}/team`, { method: "GET" });
}

export function getActiveCampaignsWithMatches() {
	return fetchV1("/campaigns/matches", { method: "GET" });
}

export function getActiveTenantAdminCampaignsWithMatches() {
	return fetchTenantAdminV1("/campaigns/matches", { method: "GET" });
}

export function getById(id) {
	return fetchV1(`/campaigns/${id}`, { method: "GET" });
}

export function create(data) {
	return fetchV1(`/campaigns`, { method: "POST", data });
}

export function update(data) {
	return fetchV1(`/campaigns/${data.id}`, { method: "PUT", data });
}

export function launch(id) {
	return fetchV1(`/campaigns/${id}/launch`, { method: "GET" }, { returnWholeError: true });
}

export function remove(id) {
	return fetchV1(`/campaigns/${id}`, { method: "DELETE" });
}

export function getCampaignWithMatches(id) {
	return fetchV1(`/campaigns/${id}/matches`, { method: "GET" });
}

export function getLeanCanvasData() {
	return fetchV1("/campaigns/canvas/lean", { method: "GET" });
}

export function exportCampaigns() {
	return fetchTenantAdminV1("/campaigns/export", { method: "GET" });
}
