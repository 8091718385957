import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { useCallback, useMemo } from "react";

export function ManageFAQs({ faqs, formId }) {
	const groupedFAQs = useMemo(() => {
		const groupToFAQs = faqs.reduce((acc, faq) => {
			acc[faq.group] = acc[faq.group] || [];
			acc[faq.group].push(faq);
			return acc;
		}, {});
		return Object.keys(groupToFAQs).map((groupName) => {
			return { group: groupName, faqs: groupToFAQs[groupName] };
		});
	}, [faqs]);
	const save = useCallback(
		(e) => {
			e.preventDefault();
			onSaving(true);
			
		},
		[faqs]
	);
	return (
		<Box component={"form"} id={formId} onSubmit={save}>
			<Grid container style={{ height: "calc(100vh - 136px - 1.5rem)" }}>
				<Grid component={"ul"} item xs={3} style={{ height: "100%", overflow: "auto" }}>

				</Grid>
				<Grid item xs={9} style={{ height: "100%" }}>
					<Box height={"100%"} overflow="auto" borderLeft={"1px solid rgb(239 232 251)"}>

					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}
