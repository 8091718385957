import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
// import { useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import { alpha } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import makeStyles from "@material-ui/core/styles/makeStyles";
// import CmtImage from "../../../../@coremat/CmtImage";
import IntlMessages from "src/@jumbo/utils/IntlMessages";
// import { AuthMethods } from "src/services/auth";
import ContentLoader from "src/@jumbo/components/ContentLoader";
import Typography from "@material-ui/core/Typography";
// import { CurrentAuthMethod } from "src/@jumbo/constants/AppConstants";
import { resetUserPassword } from "src/api/auth";
import AuthWrapper from "./AuthWrapper";

const useStyles = makeStyles((theme) => ({
	authThumb: {
		backgroundColor: alpha(theme.palette.primary.main, 0.12),
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		padding: 20,
		[theme.breakpoints.up("md")]: {
			width: "50%",
			order: 2,
		},
	},
	authContent: {
		padding: 30,
		[theme.breakpoints.up("md")]: {
			order: 1,
			width: (props) => (props.variant === "default" ? "50%" : "100%"),
		},
		[theme.breakpoints.up("xl")]: {
			padding: 50,
		},
	},
	titleRoot: {
		marginBottom: 14,
		color: theme.palette.text.primary,
	},
	textFieldRoot: {
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: alpha(theme.palette.common.dark, 0.12),
		},
	},
}));
//variant = 'default', 'standard', 'bgColor'
const ResetPassword = ({ variant = "default", wrapperVariant = "default" }) => {
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [repeatPwd, setRepeatPwd] = useState("");
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const data = useRef({ hash: null, tenantId: null });
	// const dispatch = useDispatch();
	const classes = useStyles({ variant });

	useEffect(() => {
		const usp = new URLSearchParams(window.location.href.split("?")[1]);
		const email = usp.get("email");
		const hash = usp.get("hash");
		const tenantId = usp.get("t");
		setEmail(email);
		data.current.hash = hash;
		data.current.tenantId = tenantId;
	}, []);

	const onSubmit = async (e) => {
		e.preventDefault();
		if (password != repeatPwd) {
			setError("Password didn't match");
			return;
		}
		setError(null);
		setLoading(true);
		try {
			await resetUserPassword({ email, hash: data.current.hash, password, tenantId: data.current.tenantId });
			navigate("/login");
		} catch (err) {
			console.error("Reset password failed", err);
			setError(err.message || "Unable to reset password");
		} finally {
			setLoading(false);
		}
	};

	return (
		<AuthWrapper variant={wrapperVariant}>
			<Typography component="h1" variant="h5" align="center" className={classes.titleRoot}>
				Reset Password
			</Typography>
			{error && <div className="text-red-700 text-center">{error}</div>}
			<form onSubmit={onSubmit}>
				<div className="p-2 text-center">{email}</div>
				<Box>
					<TextField
						label={"Password"}
						fullWidth
						onChange={(event) => setPassword(event.target.value)}
						value={password}
						type="password"
						margin="normal"
						variant="outlined"
						className={classes.textFieldRoot}
					/>
				</Box>
				<Box mb={5}>
					<TextField
						label={"Repeat Password"}
						fullWidth
						onChange={(event) => setRepeatPwd(event.target.value)}
						value={repeatPwd}
						type="password"
						margin="normal"
						variant="outlined"
						className={classes.textFieldRoot}
					/>
				</Box>
				<Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
					<Box component="p" fontSize={{ xs: 12, sm: 16 }}>
						<NavLink to="/login">Back to login</NavLink>
					</Box>
					<Button type="submit" variant="contained" color="primary">
						<IntlMessages id="appModule.resetPassword" />{" "}
						{loading && <CircularProgress color="inherit" size={"1rem"} className="ml-2" />}
					</Button>
				</Box>

				<Box>
					<Typography>
						Don&apos;t remember your email?
						<Box component="span" ml={2}>
							<Link href="#">
								<IntlMessages id="appModule.contactSupport" />
							</Link>
						</Box>
					</Typography>
				</Box>
			</form>
			<ContentLoader />
		</AuthWrapper>
	);
};

export default ResetPassword;
