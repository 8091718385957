import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import PersonIcon from "@material-ui/icons/Person";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import Box from "@material-ui/core/Box";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { alpha } from "@material-ui/core/styles";
import makeStyles from "@material-ui/styles/makeStyles";
import AccountCircleSharpIcon from "@material-ui/icons/AccountCircleSharp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Chip, Menu, MenuItem } from "@material-ui/core";

import CmtCardContent from "src/@coremat/CmtCard/CmtCardContent";
import CmtCard from "src/@coremat/CmtCard";
import CmtAvatar from "src/@coremat/CmtAvatar";
import { logout, updateCurrentRole } from "src/store/slices/auth";
import { getFunctionalRoles } from "src/helpers";
import { capitalizeFLetter } from "src/@jumbo/utils/commonHelper";
import { AdminMenu } from "./AdminMenu";

const useStyles = makeStyles((theme) => ({
	cardRoot: {
		"& .Cmt-header-root": {
			paddingTop: 4,
			paddingBottom: 4,
		},
		"& .Cmt-card-content": {
			padding: "0 0 16px !important",
		},
	},
	typography: {
		padding: theme.spacing(2),
	},
	iconRoot: {
		position: "relative",
		background: theme.palette.common.white,
		color: alpha(theme.palette.common.black, 0.38),
		boxShadow: "rgba(0, 0, 0, 0.086) 0px 5px 10px",
		padding: 12,
		"&:hover, &.active": {
			backgroundColor: theme.palette.common.white,
			color: alpha(theme.palette.common.dark, 0.5),
		},
	},
	svgIcon: {
		fontSize: "1rem",
	},
	counterRoot: {
		color: theme.palette.common.white,
		border: `solid 1px ${theme.palette.common.white}`,
		backgroundColor: theme.palette.warning.main,
		width: 20,
	},
	scrollbarRoot: {
		height: 300,
		padding: 16,
	},
	popoverRoot: {
		"& .MuiPopover-paper": {
			width: 225,
			marginTop: 12,
			borderRadius: 12,
		},
	},
	menuIcon: {
		minWidth: 30,
	},
	avatar: {
		background: "white",
		color: theme.palette.primary.main,
		boxShadow: "rgb(0 0 0 / 9%) 0px 5px 10px",
	},
}));

const UserActions = () => {
	const navigate = useNavigate();
	const classes = useStyles();
	const dispatch = useDispatch();
	// const { liveAlertList } = useSelector(({ alert }) => alert || { liveAlertList: [] });
	const { authUser, currentRole } = useSelector(({ auth }) => auth);
	const [popOverAnchorEl, setPopOverAnchorEl] = React.useState(null);
	const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
	const roles = useMemo(() => {
		return getFunctionalRoles(authUser.roles);
	}, [authUser]);

	const onOpenPopOver = (event) => {
		setPopOverAnchorEl(event.currentTarget);
	};

	const onClosePopOver = () => {
		setPopOverAnchorEl(null);
	};
	const handleRoleChange = (role) => {
		setMenuAnchorEl(null);
		dispatch(updateCurrentRole(role));
		window.location.href = "/dashboard";
	};

	const logoutHandler = async () => {
		dispatch(logout()).then(() => navigate("/login"));
	};

	return (
		<Box display={"flex"} alignItems={"center"} style={{ gap: 8 }}>
			{authUser.roles.includes("SUPERADMIN") && <AdminMenu />}
			<Chip
				icon={<AccountCircleSharpIcon />}
				label={capitalizeFLetter((currentRole || roles[0]).toLowerCase())}
				aria-controls="header_roles"
				color="primary"
				onClick={(e) => setMenuAnchorEl(e.currentTarget)}
				{...(roles.length > 1
					? { clickable: true, deleteIcon: <ArrowDropDownIcon style={{ pointerEvents: "none" }} />, onDelete: () => {} }
					: { clickable: false, disabled: true })}
				variant="outlined"
				aria-haspopup="true"
			/>
			{roles.length > 1 && (
				<Menu
					id="header_roles"
					anchorEl={menuAnchorEl}
					open={Boolean(menuAnchorEl)}
					onClose={() => setMenuAnchorEl(null)}
					getContentAnchorEl={null}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "left",
					}}
					MenuListProps={{ style: { width: menuAnchorEl?.clientWidth + 4 } }}
				>
					{roles.map((role) => (
						<MenuItem
							key={`ROLE_ME_${role}`}
							onClick={() => handleRoleChange(role)}
							selected={role === currentRole}
							disabled={role === currentRole}
						>
							{capitalizeFLetter(role.toLowerCase())}
						</MenuItem>
					))}
				</Menu>
			)}
			<CmtAvatar
				onClick={onOpenPopOver}
				alt={authUser.firstName}
				className={classes.avatar}
				phCharLength={1}
				variant="circular"
				size="small"
				// color="primary"
			/>

			<Popover
				className={classes.popoverRoot}
				id={popOverAnchorEl ? "simple-popover" : undefined}
				open={Boolean(popOverAnchorEl)}
				anchorEl={popOverAnchorEl}
				onClose={onClosePopOver}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				<CmtCard className={classes.cardRoot}>
					<Box
						display={"flex"}
						flexDirection={"column"}
						alignItems={"center"}
						p={5}
						sx={{ borderBottom: "1px solid #ccc" }}
					>
						<CmtAvatar alt={authUser.firstName} phCharLength={1} variant="circular" color="primary" />
						<Box component={"p"} sx={{ typography: "body2" }} my={3}>
							{authUser.firstName}&nbsp;{authUser.lastName}
						</Box>
						<Typography className="text-ellipsis overflow-hidden w-full text-center" variant="body2">{authUser.email}</Typography>
					</Box>
					<CmtCardContent>
						<nav>
							<List aria-label="basic-list">
								<ListItem button component={Link} to="/profile">
									<ListItemIcon className={classes.menuIcon}>
										<PersonIcon />
									</ListItemIcon>
									<ListItemText>Profile</ListItemText>
								</ListItem>
								<ListItem component={"li"} button={true} onClick={logoutHandler}>
									<ListItemIcon className={classes.menuIcon}>
										<LogoutIcon />
									</ListItemIcon>
									<ListItemText>Logout</ListItemText>
								</ListItem>
							</List>
						</nav>
					</CmtCardContent>
				</CmtCard>
			</Popover>
		</Box>
	);
};

export default UserActions;
