import { useCallback } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DeleteForever from "@material-ui/icons/DeleteForever";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import AppDatePicker from "src/@jumbo/components/Common/formElements/AppDatePicker";
import { QUESTION_TYPES } from "src/helpers/constants";
import { MaskedInput } from "src/components/commons/Inputs/MaskedInput";
import { FullyQualifiedPersonName } from "src/components/commons/FullyQualifiedPersonName";
import { Phone } from "./Phone";

export function Combo({ question, answers, setUserAnswer, error }) {
	const renderField = useCallback(
		(field, answer, onChange) => {
			switch (field.type) {
				case QUESTION_TYPES.DATE:
					return (
						<MuiPickersUtilsProvider utils={DateFnsUtils} key={`combo-date-field-${question.id}`}>
							<AppDatePicker
								fullWidth={false}
								inputVariant={"outlined"}
								format={"M/dd/yyyy"}
								value={answer[field.fieldName] || ""}
								size="medium"
								// label={field.placeholder || "Date"}
								placeholder={field.placeholder || "Date"}
								onChange={(value) => {
									if (value && (value || {}).toString() !== "Invalid Date") {
										onChange(field.fieldName, `${value.getMonth() + 1}/${value.getDate()}/${value.getFullYear()}`);
									}
								}}
							/>
							{/* {error && (
								<FormHelperText style={{ marginInline: 14 }} error={true}>
									{error}
								</FormHelperText>
							)} */}
						</MuiPickersUtilsProvider>
					);
				case QUESTION_TYPES.PHONE: {
					let phone = answer[field.fieldName] || {};
					return (
						<Phone
							placeholder={field.placeholder}
							phone={phone}
							onChange={(phone) => {
								onChange(field.fieldName, phone);
							}}
							// error={error}
						/>
					);
				}
				case QUESTION_TYPES.URL: {
					return (
						<TextField
							fullWidth
							variant="outlined"
							type="text"
							label={field.placeholder || "URL"}
							placeholder={field.placeholder || "URL"}
							value={answer[field.fieldName] || ""}
							onChange={(e) => onChange(field.fieldName, e.target.value)}
							// error={!!error}
							// helperText={error}
						/>
					);
				}
				case QUESTION_TYPES.NUMBER:
					return (
						<MaskedInput
							mask={Number}
							thousandsSeparator={field.thousandsSeparator || ","}
							signed={false}
							fullWidth
							radix={"."}
							scale={0}
							unmask={true}
							min={field.min ?? 0}
							max={field.max ?? 10000000000000}
							variant="outlined"
							label={field.placeholder || ""}
							placeholder={field.placeholder || ""}
							value={answer[field.fieldName] || ""}
							onComplete={(value, mask) => {
								onChange(field.fieldName, mask.unmaskedValue);
							}}
							// error={!!error}
							// helperText={error}
							InputProps={{
								...(field.startAdornment && {
									startAdornment: <InputAdornment position="start">{field.startAdornment}</InputAdornment>,
								}),
								...(field.endAdornment && {
									endAdornment: <InputAdornment position="end">{field.endAdornment}</InputAdornment>,
								}),
							}}
						/>
					);
				case QUESTION_TYPES.TEXT:
				case QUESTION_TYPES.EMAIL:
					return (
						<TextField
							fullWidth
							variant="outlined"
							type="text"
							InputProps={{
								...(field.startAdornment && {
									startAdornment: <InputAdornment position="start">{field.startAdornment}</InputAdornment>,
								}),
								...(field.endAdornment && {
									endAdornment: <InputAdornment position="end">{field.endAdornment}</InputAdornment>,
								}),
							}}
							label={field.placeholder || "Answer"}
							placeholder={field.placeholder || "Answer"}
							value={answer[field.fieldName] || ""}
							onChange={(e) => onChange(field.fieldName, e.target.value)}
							// error={!!error}
							// helperText={error}
						/>
					);
				case QUESTION_TYPES.FREE_TEXT:
					return (
						<TextField
							fullWidth
							variant="outlined"
							multiline
							label={field.placeholder || "Answer"}
							placeholder={field.placeholder || "Answer"}
							cols="30"
							minRows="3"
							value={answer[field.fieldName] || ""}
							onChange={(e) => onChange(field.fieldName, e.target.value)}
							// error={!!error}
							// helperText={error}
						/>
					);
				case QUESTION_TYPES.AUTOCOMPLETE: {
					const lines = field.answers.split("\n") || [];
					const isCommaSeparated = lines[0].split(",").length == 2 && lines[1].split(",").length == 2;
					const options =
						(field.answers?.split("\n") || []).reduce((acc, i) => {
							const pieces = i.split(",");
							const option = isCommaSeparated ? pieces[1] || pieces[0] || "" : i;
							if (option) {
								acc.push(option.trim());
							}
							return acc;
						}, []) || [];
					const props = {
						value: answer[field.fieldName] || null,
					};
					if (field.multiple) {
						props.multiple = true;
						if (props.value) {
							if (Array.isArray(props.value)) {
								props.value = props.value || [];
							} else {
								if (props.value) {
									try {
										props.value = JSON.parse(props.value);
									} catch (e) {
										console.error("Parse failed with autocomplete multi value", e);
										props.value = [props.value];
									}
								}
							}
						} else {
							props.value = [];
						}
					}
					return (
						<>
							<Autocomplete
								options={options}
								getOptionLabel={(option) => option}
								onChange={(e, newValue) => {
									onChange(field.fieldName, newValue);
								}}
								{...props}
								style={{ width: 300 }}
								renderInput={(params) => <TextField {...params} label={field.placeholder || ""} variant="outlined" />}
							/>
							{/* {error && (
								<FormHelperText style={{ marginInline: 14 }} error={true}>
									{error}
								</FormHelperText>
							)} */}
						</>
					);
				}
				case QUESTION_TYPES.NAME: {
					let name = answer[field.fieldName] || {};
					return (
						<>
							<FullyQualifiedPersonName
								fqpname={name || {}}
								onChange={(fqpname) => {
									onChange(field.fieldName, fqpname);
								}}
							/>
							{/* {error && (
								<FormHelperText style={{ marginInline: 14 }} error={true}>
									{error}
								</FormHelperText>
							)} */}
						</>
					);
				}
				default: {
					console.error("Unknown combo field type ", field.type);
					throw "Unknown combo field type: " + field.type;
				}
			}
		},
		[question, answers, setUserAnswer, error]
	);

	function onChange(idx, answers) {
		return (fieldName, value) => {
			if (idx === undefined) {
				setUserAnswer(undefined, { ...answers, [fieldName]: value });
			} else {
				const _answers = [...answers];
				_answers[idx][fieldName] = value;
				setUserAnswer(undefined, _answers);
			}
		};
	}
	let _answers = null;
	if (question.meta.multiple) {
		if (!Array.isArray(answers)) {
			_answers = [{ ...(answers || {}) }];
		} else {
			_answers = [...(answers || [])];
		}
		if ((answers || []).length === 0) {
			_answers.push({});
		}
		if (answers && !Array.isArray(answers)) {
			_answers = [answers];
		}
	} else {
		_answers = { ...(answers || {}) };
	}
	return (
		<>
			{error && (
				<FormHelperText style={{ marginInline: 14 }} error={true}>
					{error}
				</FormHelperText>
			)}
			<Grid container spacing={4}>
				{question.meta.multiple
					? _answers.map((answer, idx) => {
							return (
								<Grid key={`combo-q-${question.id}-ans-row-${idx}`} item xs={12}>
									<Grid container spacing={4}>
										<Grid item xs={10}>
											<Grid container spacing={4}>
												{question.meta.comboFields.map((field) => {
													const fieldsLength = 12 / question.meta.comboFields.length;
													let grid = Math.max(4, fieldsLength);
													return (
														<Grid
															key={`combo-q-${question.id}-row-${idx}-field-${field.fieldName}`}
															item
															xs={grid}
															sm={[QUESTION_TYPES.NAME, QUESTION_TYPES.PHONE].includes(field.type) ? 12 : grid}
														>
															{renderField(field, answer, onChange(idx, _answers))}
														</Grid>
													);
												})}
											</Grid>
										</Grid>
										<Grid item xs={2}>
											<Box display={"flex"} mt={0} minWidth={"40px"} alignItems="flex-start" className="gap-1">
												<Button
													variant="contained"
													aria-label="Add"
													color="primary"
													size="small"
													onClick={() => {
														const _answers = [...answers];
														_answers.splice(idx + 1, -1, {});
														setUserAnswer(undefined, _answers);
													}}
												>
													Add More
												</Button>
												<Button
													variant="contained"
													color="secondary"
													aria-label="Delete"
													size="small"
													disabled={answers.length <= 1}
													onClick={() => {
														const _answers = [...answers];
														_answers.splice(idx, 1);
														setUserAnswer(undefined, _answers);
													}}
												>
													<DeleteForever size="small" />
												</Button>
											</Box>
										</Grid>
									</Grid>
								</Grid>
							);
					  })
					: question.meta.comboFields.map((field) => {
							return (
								<Grid
									key={`combo-q-${question.id}-field-${field.fieldName}`}
									item
									xs={12}
									sm={[QUESTION_TYPES.NAME, QUESTION_TYPES.PHONE].includes(field.type) ? 12 : 4}
								>
									{renderField(field, _answers, onChange(undefined, _answers))}
								</Grid>
							);
					  })}
			</Grid>
		</>
	);
}
