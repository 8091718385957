import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import Edit from "@material-ui/icons/Edit";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { alpha, makeStyles } from "@material-ui/core/styles";
import Group from "@material-ui/icons/Group";
import { Chip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	listItem: {
		"& img": {
			transform: "scale(0.7)",
			transition: "transform 0.6s",
		},
		"&:hover .listAvatar": {
			background: alpha(theme.palette.primary.main, 0.1),
		},
		"&:hover img": {
			transform: "scale(0.9)",
		},
	},
	avatar: {
		width: 92,
		height: 92 * 0.616,
		background: theme.palette.background.default,
		color: theme.palette.primary.dark,
		[theme.breakpoints.up("md")]: {
			width: 150,
			height: 150 * 0.616,
		},
		"& img": {
			objectFit: "contain",
		},
	},
}));
export function TeamMemberListItem({ member, onSelect }) {
	const classes = useStyles();
	return (
		<ListItem
			className={classes.listItem}
			button
			component={"li"}
			key={`team-list-${member.id}`}
			alignItems="flex-start"
		>
			<Box
				component={Link}
				to={`/team/${member.teamId}/member/${member.id}`}
				display={"flex"}
				width="100%"
				onClick={onSelect}
			>
				<Avatar className={clsx([classes.avatar, "listAvatar"])} variant={"rounded"}>
					<Group />
				</Avatar>
				<Box display={"flex"} flex="1" flexDirection={"column"} ml={4} alignItems={"flex-start"} width={"100%"}>
					<Typography component={"h3"} variant="h6" color="textPrimary">
						{member.displayName}{" "}
						{!member.details?.expertise && <Chip size="small" label={"Incomplete"} color={"secondary"} />}
					</Typography>
					<Typography component={"p"} color="textSecondary">
						{member.position}
					</Typography>
				</Box>
				{/* <Box display={"flex"} ml={4} mt={4} className={classes.controls} alignItems={"end"} justifyContent={"flex-end"}>
					<Button startIcon={<Edit />} color="primary" size="small" variant="contained">
						Edit Member
					</Button>
				</Box> */}
			</Box>
		</ListItem>
	);
}
