import { fetchAdminV1 } from "../lib/requester/v1";

export function getAll(params) {
	return fetchAdminV1("/data_queries", { method: "GET", data: params }, { returnWholeResponse: true });
}

export function update(data) {
	return fetchAdminV1(`/data_queries/${data.id}`, { method: "PUT", data });
}

export function remove(data) {
	return fetchAdminV1(`/data_queries/${data.id}`, { method: "DELETE" });
}

export function create(data) {
	return fetchAdminV1(`/data_queries`, { method: "POST", data });
}

export function execute(data) {
	return fetchAdminV1(`/data_queries/run`, { method: "POST", data });
}
