import { fetchAdminV1, fetchV1 } from "../lib/requester/v1";

export function register(data) {
	return fetchV1("/users/register", { method: "POST", data });
}

export function create(data) {
	return fetchAdminV1("/users", { method: "POST", data });
}

export function login(data) {
	return fetchV1("/users/login", { method: "POST", data });
}

export function update(data) {
	return fetchV1(`/users/${data.id}`, { method: "PUT", data });
}

export function me() {
	return fetchV1("/users/me", { method: "GET" });
}

export function logout() {
	return fetchV1("/users/logout", { method: "DELETE" });
}

export function loginAs(data) {
	return fetchAdminV1("/users/login_as", { method: "POST", data }, { returnWholeError: true });
}

export function resetPassword(data) {
	return fetchAdminV1("/users/reset_password", { method: "PUT", data }, { returnWholeError: true });
}

export function forgotPassword(data) {
	return fetchV1("/users/forgot_password", { method: "POST", data }, { returnWholeError: true });
}

export function resetUserPassword(data) {
	return fetchV1("/users/reset_user_password", { method: "POST", data }, { returnWholeError: true });
}

export function verifyEmail(data) {
	return fetchV1("/users/verify_email", { method: "POST", data }, { returnWholeError: true });
}

export function getAll(data) {
	return fetchAdminV1("/users", { method: "GET", data }, { returnWholeResponse: true });
}

export function remove(data) {
	return fetchAdminV1(`/users/${data.id}`, { method: "DELETE" }, { returnWholeResponse: true });
}

export function updateAsAdmin(data) {
	return fetchAdminV1(`/users/${data.id}`, { method: "PATCH", data });
}