import React from "react";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { init, ErrorReportingBoundary } from "./helpers/errorReporting";
import { store } from "./store";
import { getRouter } from "./router";
import "react-perfect-scrollbar/dist/css/styles.css";

init();
const App = () => (
	<Provider store={store}>
		<ErrorReportingBoundary>
			<RouterProvider router={getRouter(store)} />
		</ErrorReportingBoundary>
	</Provider>
);

export default App;
