export default function getByPath(obj, segments) {
	const lastIndex = segments.length - 1;
	for (let i = 0; i <= lastIndex; i++) {
		if (obj[segments[i]]) {
			obj = obj[segments[i]];
		} else {
			return undefined;
		}
	}
	return obj;
}
