import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import makeStyles from "@material-ui/styles/makeStyles";
import Radio from "@material-ui/core/Radio";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormHelperText from "@material-ui/core/FormHelperText";
import Alert from "@material-ui/lab/Alert";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import { useState, Fragment, useMemo, useEffect } from "react";

import { getAll } from "src/api/answers";
import { QUESTION_TYPES, TITLES } from "src/helpers/constants";
import setByPath from "src/helpers/setByPath";
import { Choice } from "./Choice";
import { ComboAnswer } from "./ComboAnswer";

const useStyles = makeStyles(() => ({
	formControl: {
		width: 150,
	},
	smallFont: {
		fontSize: 11,
	},
	checkbox: { padding: "0 9px" },
}));

export function Answers({
	question,
	questionIndex,
	onChange,
	onQuestionChange,
	errors = {},
	labels = {},
	steps = [],
	setAnswers,
}) {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const classes = useStyles();

	function addAnswer(idx) {
		const _answers = [...question.answers];
		_answers.splice(idx + 1, -1, {
			answer: "",
			order: idx + 1,
			meta: {},
		});
		onChange(_answers);
	}
	function setAnswer(fields, value, idx) {
		const _answers = [...question.answers];
		if (!_answers[idx]) {
			_answers[idx] = {};
		}
		if (fields[0] === "order") {
			const existingOrder = _answers[idx]?.order;
			const newOrder = Math.min(Math.max(value, 0), _answers.length);
			if (newOrder && existingOrder !== newOrder) {
				_answers.splice(newOrder - 1, 0, _answers.splice(existingOrder - 1, 1)[0]);
				_answers.forEach((a, idx) => {
					a.order = idx + 1;
				});
			}
		} else {
			setByPath(_answers[idx], fields, value);
		}
		onChange(_answers);
	}
	function setQuestion(question, fields, value, qIndex) {
		const _question = { ...question };
		setByPath(_question, fields, value);
		onQuestionChange(_question, qIndex);
	}
	function removeFromState(idx) {
		const _answers = [...question.answers];
		_answers.splice(idx, 1);
		onChange(_answers);
	}

	async function fetchAnswers() {
		if (
			[
				QUESTION_TYPES.AUTOCOMPLETE,
				QUESTION_TYPES.TAGS,
				QUESTION_TYPES.MULTIPLE_CHOICE,
				QUESTION_TYPES.RADIO,
				QUESTION_TYPES.SELECT,
				QUESTION_TYPES.MULTI_LEVEL_CHOICE,
				QUESTION_TYPES.MULTI_SELECT,
				QUESTION_TYPES.SORTABLE,
			].includes(question.type) &&
			question.id &&
			(question.answers || []).length <= 0
		) {
			try {
				setLoading(true);
				const answers = await getAll({ filter: `questionId||$eq||${question.id}`, limit: 500 });
				setLoading(false);
				setAnswers(answers);
			} catch (err) {
				setError("Unable to fetch answers");
				console.error("Error while fetching answers", err);
			} finally {
				setLoading(false);
			}
		} else {
			setLoading(false);
		}
	}

	useEffect(fetchAnswers, [question]);

	const result = useMemo(() => {
		if (!question.type) {
			return null;
		}
		switch (question.type) {
			case QUESTION_TYPES.MULTIPLE_CHOICE:
			case QUESTION_TYPES.RADIO:
			case QUESTION_TYPES.MULTI_LEVEL_CHOICE:
			case QUESTION_TYPES.SELECT:
				return (
					<Fragment>
						<Typography variant="h6" component={"h5"}>
							Choices
						</Typography>
						{loading && <div>Loading...</div>}
						{errors?.answers && (
							<Box mb={3}>
								<Alert severity="error">{errors.answers}</Alert>
							</Box>
						)}
						{(question.answers || [])
							// .sort((a, b) => a.order - b.order)
							.map((item, index) => {
								return (
									<Choice
										key={"answer_" + questionIndex + "_" + index}
										question={question}
										questionIndex={questionIndex}
										answer={item}
										setAnswer={setAnswer}
										setQuestion={setQuestion}
										index={index}
										classes={classes}
										errors={errors}
										steps={steps}
										labels={labels}
										addAnswer={addAnswer}
										onDelete={removeFromState}
									/>
								);
							})}
						{question.meta.hasOther && (
							<Box display={"grid"} gridTemplateColumns="repeat(24, 1fr)" gridGap={10} my={6}>
								<Box gridColumn="span 1">
									{question.type === QUESTION_TYPES.RADIO && <Radio disabled />}
									{(question.type === QUESTION_TYPES.MULTIPLE_CHOICE ||
										question.type === QUESTION_TYPES.MULTI_LEVEL_CHOICE) && <Checkbox disabled />}
								</Box>
								<Box gridColumn="span 8">
									<TextField
										fullWidth
										disabled
										variant="outlined"
										className="form-control flex1 ml-2 col-4"
										placeholder="Other"
										size="small"
										value={""}
									/>
								</Box>
							</Box>
						)}
						<Button
							type="button"
							variant="contained"
							color="primary"
							disabled={loading}
							size={"small"}
							onClick={() => addAnswer(question.answers.length)}
						>
							Add Choices
						</Button>
					</Fragment>
				);
			case QUESTION_TYPES.AUTOCOMPLETE:
			case QUESTION_TYPES.TAGS:
			case QUESTION_TYPES.SORTABLE: {
				if (loading) {
					return <div>Loading...</div>;
				}
				return (
					<TextField
						fullWidth
						variant="outlined"
						multiline
						placeholder="New line Separated"
						cols="30"
						minRows="4"
						maxRows={"6"}
						onChange={(e) => setAnswer(["answer"], e.target.value, 0)}
						value={question.answers[0]?.answer}
					/>
				);
			}
			case QUESTION_TYPES.TEXT:
			case QUESTION_TYPES.NUMBER:
			case QUESTION_TYPES.DATE:
			case QUESTION_TYPES.PHONE:
			case QUESTION_TYPES.EMAIL:
			case QUESTION_TYPES.URL:
				return (
					<TextField
						fullWidth
						variant="outlined"
						type="text"
						placeholder={question.meta?.placeholder || "Answer"}
						label={question.meta?.placeholder || "Answer"}
						disabled
						InputProps={{
							...(question.meta?.startAdornment && {
								startAdornment: <InputAdornment position="start">{question.meta?.startAdornment}</InputAdornment>,
							}),
							...(question.meta?.endAdornment && {
								endAdornment: <InputAdornment position="end">{question.meta?.endAdornment}</InputAdornment>,
							}),
						}}
					/>
				);
			case QUESTION_TYPES.FREE_TEXT:
				return (
					<TextField
						fullWidth
						variant="outlined"
						multiline
						placeholder={question.meta?.placeholder || "Answer"}
						label={question.meta?.placeholder || "Answer"}
						disabled
						cols="30"
						minRows="3"
					/>
				);
			case QUESTION_TYPES.RANGE:
				return (
					<Box display={"flex"} gridGap={12}>
						<TextField
							fullWidth
							shrink
							variant="outlined"
							placeholder={`Min. ${question.meta?.placeholder}`}
							label={`Minimum ${question.meta?.placeholder}`}
							disabled
							InputProps={{
								...(question.meta?.startAdornment && {
									startAdornment: <InputAdornment position="start">{question.meta?.startAdornment}</InputAdornment>,
								}),
								...(question.meta?.endAdornment && {
									endAdornment: <InputAdornment position="end">{question.meta?.endAdornment}</InputAdornment>,
								}),
							}}
						/>
						<TextField
							fullWidth
							variant="outlined"
							placeholder={`Max. ${question.meta?.placeholder}`}
							label={`Maximum ${question.meta?.placeholder}`}
							disabled
							InputProps={{
								...(question.meta?.startAdornment && {
									startAdornment: <InputAdornment position="start">{question.meta?.startAdornment}</InputAdornment>,
								}),
								...(question.meta?.endAdornment && {
									endAdornment: <InputAdornment position="end">{question.meta?.endAdornment}</InputAdornment>,
								}),
							}}
						/>
					</Box>
				);
			case QUESTION_TYPES.NAME:
				return (
					<Grid container spacing={4}>
						<Grid item xs={2}>
							<TextField
								fullWidth
								variant="outlined"
								id="title"
								value=""
								label="Title"
								required
								type="text"
								select
								disabled
							>
								{TITLES.map((option) => (
									<MenuItem key={option} value={option}>
										{option}
									</MenuItem>
								))}
								<MenuItem key={"other"} value={"other"}>
									Other
								</MenuItem>
							</TextField>
						</Grid>
						<Grid item xs={3}>
							<TextField fullWidth required variant="outlined" id="firstName" label="First Name" type="text" disabled />
						</Grid>
						<Grid item xs={2}>
							<TextField fullWidth variant="outlined" id="middleName" label="Middle Name" type="text" disabled />
						</Grid>
						<Grid item xs={3}>
							<TextField fullWidth required variant="outlined" id="lastName" label="Last Name" type="text" disabled />
						</Grid>
						<Grid item xs={2}>
							<TextField fullWidth variant="outlined" id="suffix" label="Suffix Name" type="text" disabled />
						</Grid>
					</Grid>
				);
			case QUESTION_TYPES.COMBO:
				return <ComboAnswer question={question} />;
			case QUESTION_TYPES.UPLOAD_FILE:
				return (
					<Grid container spacing={4}>
						<Box className="text-slate-300 text-center">
							<Box className="w-24 h-24 rounded-lg bg-slate-200 text-slate-300 text-5xl flex justify-center items-center">
								+
							</Box>
							<Box>Upload File</Box>
						</Box>
					</Grid>
				);
			default: {
				console.error("Unknown type of question", question.type);
				throw "Unknown type: " + question.type;
			}
		}
	}, [question, question.answers, loading, errors]);

	if (!question.type) {
		return null;
	}
	return (
		<>
			{result}
			{question.meta?.answerHint && <FormHelperText>{question.meta.answerHint}</FormHelperText>}
			<Snackbar
				open={!!error}
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
				onClose={() => setError(null)}
			>
				<Alert onClose={() => setError(null)} variant="filled" severity={"error"} sx={{ width: "100%" }}>
					{error}
				</Alert>
			</Snackbar>
		</>
	);
}
